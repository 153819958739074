import { createSelector } from "reselect";
import { IProactiveChatState } from "./proactive-chat.types";

/**
 * Get setup data from the setup state.
 * @param state Setup state.
 * @returns     Setup data property.
 */
const selectData = (data: IProactiveChatState) => data;

/**
 * Get the proactive chat enabled state. Not all clients will have this feature turned on.
 */
export const getIsProactiveChatEnabled = createSelector(selectData, (data) =>
    typeof data === "undefined" ? false : data.enabled
);

/**
 * Get the proactive chat enabled state for a user.
 */
export const getIsProactiveChatEnabledForUser = createSelector(selectData, (data) =>
    typeof data === "undefined" ? false : data.isProactiveChatEnabledOnUser
);

import { FunctionComponent, memo, useEffect } from "react";
import { IProps } from "./authenticated-redirect.types";
import { Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Spinner } from "@edgetier/components";
import { Url } from "@edgetier/types";
import { useCurrentUser } from "utilities/use-current-user";
import axios from "utilities/axios";
import { useRolePermissions } from "@edgetier/roles-permissions";
import { findRedirectPage } from "./authenticated-redirect.utilities";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Once the user is loggged in, redirect them to the appropriate page based on their permissions.
 */
const AuthenticatedRedirect: FunctionComponent<IProps> = () => {
    const navigate = useNavigate();
    const { data: user, isLoading: isUserLoading, isError: isUserError } = useCurrentUser();

    // Request the user's permissions
    const {
        data: userPermissions,
        isLoading: isUserPermissionsLoading,
        isError: isRolePermissionsError,
    } = useRolePermissions(Url.RolePermissions, axios, user?.roleId.toString()!, {
        enabled: typeof user !== "undefined",
    });

    useEffect(() => {
        if (isUserError || isRolePermissionsError) {
            navigate(AbsolutePaths[PageNames.Login]());
            toast.error("There was an error logging you in. Please try again.");
        }
    }, [isRolePermissionsError, isUserError, navigate]);

    if (isUserLoading || isUserPermissionsLoading) {
        return <Spinner fullPage />;
    }

    return <Navigate to={findRedirectPage(userPermissions)} />;
};

export default memo(AuthenticatedRedirect);

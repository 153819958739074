import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import KeyboardShortcuts, { KeyboardShortcutScope } from "services/keyboard-shortcut-service";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Watch for a location change and update the keyboard shortcut scope when appropriate.
 */
const useShortcutScope = () => {
    const currentLocation = useLocation();
    useEffect(() => {
        const currentView = currentLocation.pathname;
        switch (currentView) {
            case AbsolutePaths[PageNames.Chat]():
                KeyboardShortcuts.setScope(KeyboardShortcutScope.Chat);
                break;
            case AbsolutePaths[PageNames.CreateEmail]():
            case AbsolutePaths[PageNames.Email]():
                KeyboardShortcuts.setScope(KeyboardShortcutScope.Email);
                break;
            default:
                KeyboardShortcuts.setScope(KeyboardShortcutScope.Default);
        }
    }, [currentLocation]);
};

export default useShortcutScope;

import { DetailsFieldTitle, DetailsFieldValue } from "~/details";

import { IProps } from "./string-details-field.types";

/**
 * Render a string value from the booking details.
 * @param props.value String value.
 * @returns           String value.
 */
const StringDetailsField = ({ field }: IProps) => (
    <tr className="string-details-field">
        <td>
            <DetailsFieldTitle>{field.title}</DetailsFieldTitle>
        </td>
        <td>
            <DetailsFieldValue copyText={field.value} isNull={typeof field.value !== "string"} warning={field.warning}>
                {field.value}
            </DetailsFieldValue>
        </td>
    </tr>
);

export default StringDetailsField;

import { formatDate } from "@edgetier/utilities";
import { FunctionComponent, memo } from "react";

import "./formatted-date.scss";
import { IProps } from "./formatted-date.types";

/**
 * Format a date for a user showing just the date (i.e. no time).
 * @param props.dateTime Date to render.
 * @returns              Formatted date.
 */
const FormattedDate: FunctionComponent<IProps> = ({ dateTime }) => {
    return <div className="formatted-date">{formatDate(dateTime, { explicitFormat: "dd MMM yyyy" })}</div>;
};

export default memo(FormattedDate);

import { Checkbox } from "@edgetier/components";
import { FormFieldType } from "@edgetier/types";
import { unreachableCaseError } from "@edgetier/utilities";

import ButtonsFormField from "./buttons-form-field";
import CheckboxesFormField from "./checkboxes-form-field";
import DateFormField from "./date-form-field";
import DateTimeFormField from "./date-time-form-field";
import { IProps } from "./dynamic-form-field.types";
import NetPromoterScoreFormField from "./net-promoter-score-form-field";
import NumericFormField from "./numeric-form-field";
import RadiosFormField from "./radios-form-field";
import SelectFormField from "./select-form-field";
import SourcedSelectFormField from "./sourced-select-form-field";
import StarsFormField from "./stars-form-field";
import TextFormField from "./text-form-field";
import TextareaFormField from "./textarea-form-field";
import TimeFormField from "./time-form-field";

/**
 * Display the correct HTML element depending on the type of field in a survey or wrap-up form.
 * @param props Details of the field to render.
 * @returns     A form input, textarea, radio buttons, checkboxes, custom input etc.
 */
export default function DynamicFormField({ axiosInstance, autoFocus, formField, languageId, translation }: IProps) {
    switch (formField.formFieldTypeId) {
        case FormFieldType.Integer:
            return <NumericFormField formField={formField} step={1} translation={translation} autoFocus={autoFocus} />;

        case FormFieldType.Float:
            return <NumericFormField formField={formField} translation={translation} autoFocus={autoFocus} />;

        case FormFieldType.Text:
            return <TextFormField formField={formField} translation={translation} type="text" autoFocus={autoFocus} />;

        case FormFieldType.Date:
        case FormFieldType.Timestamp:
            return <DateFormField formField={formField} translation={translation} autoFocus={autoFocus} />;

        case FormFieldType.DateTime:
            return <DateTimeFormField formField={formField} translation={translation} autoFocus={autoFocus} />;

        case FormFieldType.Time:
            return <TimeFormField formField={formField} translation={translation} autoFocus={autoFocus} />;

        case FormFieldType.Textarea:
            return <TextareaFormField formField={formField} translation={translation} autoFocus={autoFocus} />;

        case FormFieldType.Email:
            return <TextFormField formField={formField} translation={translation} type="email" autoFocus={autoFocus} />;

        case FormFieldType.Select:
            return (
                <SelectFormField
                    autoFocus={autoFocus}
                    formField={formField}
                    languageId={languageId}
                    translation={translation}
                />
            );

        case FormFieldType.SourcedSelect:
            return (
                <SourcedSelectFormField
                    axiosInstance={axiosInstance}
                    formField={formField}
                    languageId={languageId}
                    translation={translation}
                />
            );

        case FormFieldType.Star:
            return <StarsFormField formField={formField} translation={translation} autoFocus={autoFocus} />;

        case FormFieldType.Radios:
            return (
                <RadiosFormField
                    formField={formField}
                    languageId={languageId}
                    translation={translation}
                    autoFocus={autoFocus}
                />
            );

        case FormFieldType.NetPromoterScore:
            return (
                <NetPromoterScoreFormField
                    autoFocus={autoFocus}
                    formField={formField}
                    minimumLabel={translation.minimumLabel}
                    maximumLabel={translation.maximumLabel}
                    translation={translation}
                />
            );

        case FormFieldType.Buttons:
            return (
                <ButtonsFormField
                    autoFocus={autoFocus}
                    languageId={languageId}
                    formField={formField}
                    translation={translation}
                />
            );

        case FormFieldType.Checkboxes:
            return (
                <CheckboxesFormField
                    languageId={languageId}
                    formField={formField}
                    translation={translation}
                    autoFocus={autoFocus}
                />
            );

        case FormFieldType.Boolean:
            return <Checkbox label="" name={formField.fieldName} autoFocus={autoFocus} />;

        default:
            throw unreachableCaseError(formField);
    }
}

import { SubmitOnChange } from "@edgetier/components";
import { clearUrlParameters } from "@edgetier/utilities";
import classNames from "classnames";
import { Form, Formik, FormikValues } from "formik";
import Qs from "qs";
import { FunctionComponent, memo, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./url-form.scss";
import { IProps } from "./url-form.types";
import { getInitialValues } from "./url-form.utilities";

/**
 * Render fields in a form that read from and update the URL.
 * @param props.children      Form fields.
 * @param props.className     Optional additional class name(s).
 * @param props.initialValues Form default values.
 * @param props.formikProps   Other props to pass to Formik.
 * @returns                   Form.
 */
const UrlForm: FunctionComponent<IProps> = ({
    children,
    className,
    initialValues,
    parameterPrefix,
    ...formikProps
}) => {
    const { search } = useLocation();
    const navigate = useNavigate();

    // Take either the initial default values or values from the URL on load.
    const initialFormValues = useMemo(
        () => getInitialValues(initialValues, search.toString()),
        [initialValues, search]
    );

    /**
     * Update the URL when the form values change.
     * @param values Form values.
     */
    const onChange = useCallback(
        (values: FormikValues) => {
            const parameters = Qs.parse(search, { ignoreQueryPrefix: true });
            const clearedSearchParameters = clearUrlParameters(parameters, parameterPrefix);
            navigate(
                Qs.stringify({ ...clearedSearchParameters, ...values }, { addQueryPrefix: true, arrayFormat: "repeat" })
            );
        },
        [navigate, parameterPrefix, search]
    );

    return (
        <Formik initialValues={initialFormValues} onSubmit={onChange} {...formikProps}>
            <Form className={classNames("url-form", className)} name="url-form">
                <>
                    {children}
                    <SubmitOnChange delay={0} />
                </>
            </Form>
        </Formik>
    );
};

export default memo(UrlForm);

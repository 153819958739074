import { FunctionComponent, memo } from "react";
import { useSelector } from "react-redux";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IApplicationState } from "redux/types";

import { IProps } from "./query-link-indicators.types";
import "./query-link-indicators.scss";

/**
 * Link to the email screen. Its appearance will change depending on if the email is unread or not.
 */
const QueryLinkIndicators: FunctionComponent<IProps> = () => {
    const shouldShowIndicator = useSelector<IApplicationState, boolean>(({ email }) => {
        const isExpired = email.emails.query?.isExpired ?? false;
        const isUnread = email.emails.query?.isUnread ?? false;

        return !isExpired && isUnread;
    });

    if (shouldShowIndicator) {
        return (
            <div className="query-link-indicators">
                <div className="query-link-indicators__unread">
                    <FontAwesomeIcon
                        className="query-link-indicators__unread__icon"
                        icon={faBell}
                        title="Unread email"
                    />
                </div>
            </div>
        );
    }

    return null;
};

export default memo(QueryLinkIndicators);

import { VariableType } from "@edgetier/types";
import { unreachableCaseError } from "@edgetier/utilities";

import ArrayDetailsField from "./array-details-field";
import BooleanDetailsField from "./boolean-details-field";
import DateDetailsField from "./date-details-field";
import { IProps } from "./details-field.types";
import LinkDetailsField from "./link-details-field";
import NumericDetailsField from "./numeric-details-field";
import StringDetailsField from "./string-details-field";

/**
 * Choose which component to render based on the type of field returned from the backend.
 * @param props.field The field which will contain a type indicating which field to show.
 * @returns           Whichever component is most suited to rendering the field based on its type.
 */
const DetailsField = ({ field }: IProps) => {
    switch (field.variableTypeId) {
        case VariableType.Array:
            return <ArrayDetailsField field={field} />;

        case VariableType.Boolean:
            return <BooleanDetailsField field={field} />;

        case VariableType.Date:
            return <DateDetailsField field={field} />;

        case VariableType.Link:
            return <LinkDetailsField field={field} />;

        case VariableType.Numeric:
            return <NumericDetailsField field={field} />;

        case VariableType.String:
        case VariableType.Time:
            return <StringDetailsField field={field} />;

        default:
            throw unreachableCaseError(field);
    }
};

export default DetailsField;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { IProps } from "./pagination-button.types";

/**
 * Next or previous button for a table.
 * @param props.icon       Icon to display.
 * @param props.isDisabled If the button should be disabled or not (for example if there is no next page).
 * @param props.onClick    Click handler to go to the next/previous page.
 */
const PaginationButton = ({ icon, isDisabled, onClick }: IProps) => (
    <button
        className={classNames("grid__pagination__button", {
            "grid__pagination__button--disabled": isDisabled,
        })}
        disabled={isDisabled}
        onClick={onClick}
        type="button"
    >
        <FontAwesomeIcon fixedWidth={true} icon={icon} />
    </button>
);

export default PaginationButton;

import { faComments } from "@fortawesome/free-regular-svg-icons/faComments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { IProps } from "./chat-warning.types";
import ChatWarningSingular from "./chat-warning-singular";
import ChatWarningMultiple from "./chat-warning-multiple";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Display a warning when an agent tries to sign out if they have ongoing chats.
 * @param props Contains the number of chats the agent is involved in and a click handler.
 * @returns     Warning message and button to go to the chats screen.
 */
const ChatWarning = ({ chatCount, onClick }: IProps) => (
    <li>
        {chatCount === 1 ? <ChatWarningSingular /> : <ChatWarningMultiple chatCount={chatCount} />}
        <Link className="button button--outline" to={AbsolutePaths[PageNames.Chat]()} onClick={onClick}>
            <FontAwesomeIcon icon={faComments} /> View chat(s)
        </Link>
    </li>
);

export default ChatWarning;

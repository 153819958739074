import { Tooltip } from "@edgetier/components";
import { useIsOverflowed } from "@edgetier/utilities";
import { FunctionComponent, isValidElement, memo, useRef } from "react";

import "./page-header.scss";
import { IProps } from "./page-header.types";

/**
 * Header with title above a page's content.
 * @param props.title       Name of the page.
 * @param props.subTitle
 * @param props.children
 * @returns     Page header.
 */
const PageHeader: FunctionComponent<IProps> = ({ children, title, subTitle }) => {
    const titleRef = useRef(null);
    const isOverflowed = useIsOverflowed(titleRef);
    return (
        <div className="page-header">
            <div className="page-header__title">
                {typeof title === "string" ? (
                    <Tooltip content={title} disableTooltip={!isOverflowed} placement="bottom-center">
                        <h2 ref={titleRef}>{title}</h2>
                    </Tooltip>
                ) : isValidElement(title) ? (
                    title
                ) : undefined}
                {subTitle && <h3>{subTitle}</h3>}
            </div>
            {children}
        </div>
    );
};

export default memo(PageHeader);

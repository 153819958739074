import { animated, config, useTransition } from "react-spring";
import { IProps } from "./animated-route-switch.types";
import { Routes, useLocation } from "react-router-dom";
import { useMemo } from "react";
import { STATIC_ROUTES } from "./animated-route-switch.constants";

/**
 * Animate switching routes.
 * @param props.children  Route content.
 * @param props.className Optional class name.
 * @returns               Animated route switching component.
 */
export function AnimatedRouteSwitch({ children, className }: IProps) {
    const location = useLocation();
    const transition = useTransition(location, {
        config: { ...config.stiff, clamp: true, mass: 0.6, friction: 5 },
        initial: null,
        keys: ({ pathname }) => pathname,
        from: { opacity: 0, transform: "translate3d(-5%, 0, 0)" },
        enter: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
        leave: { opacity: 0 },
        exitBeforeEnter: true,
    });

    // Check if the current route is part of static routes
    const isStaticRoute = useMemo(
        () => STATIC_ROUTES.some((route) => location.pathname.startsWith(route)),
        [location.pathname]
    );

    return (
        <div className={className}>
            {transition((style, item) => (
                <animated.div
                    className="application__authenticated-routes__page"
                    style={isStaticRoute ? undefined : style}
                >
                    <Routes location={item}>{children}</Routes>
                </animated.div>
            ))}
        </div>
    );
}

export default AnimatedRouteSwitch;

import React, { forwardRef, isValidElement, memo } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import Tooltip from "../tooltip";
import { IProps } from "./markdown.types";
import { preprocessString, preserveNewlines } from "./markdown.utilities";

// For the string "issueStart[test-classname,test-description]test-textissueEnd", this regex will return three groups:
// "test-classname", "test-description" and "test-text".
const STRONG_CLASSNAME_REGEX = /issueStart\[(.*),(.*)\]([\s\S]*)issueEnd/;

/**
 * Render markdown. This is just a wrapper to provide styles.
 * @param props.linkTarget     Any links will receive this as their "target" attribute.
 * @param props.markdownString Text to convert to markdown.
 * @param ref                  Optional forward ref.
 */
const Markdown = forwardRef<HTMLDivElement, IProps>(({ linkTarget = "_blank", markdownString }, ref) => (
    <div className="markdown" ref={ref}>
        <ReactMarkdown
            renderers={{
                link: ({ children, href }) => (
                    <a
                        href={href}
                        target={linkTarget}
                        rel={linkTarget === "_blank" ? "noreferrer noopener" : undefined}
                    >
                        {children}
                    </a>
                ),
                strong: ({ children }) => {
                    // Allow a class name and description with the syntax:
                    // "**issueStart[className,description]textissueEnd**".
                    const [child] = React.Children.toArray(children);
                    if (isValidElement(child)) {
                        const match = String(child.props.value).match(STRONG_CLASSNAME_REGEX);
                        if (Array.isArray(match)) {
                            return (
                                <Tooltip content={<div className="markdown__writing-issue-tooltip">{match[2]}</div>}>
                                    <strong className={match[1]} role="strong">
                                        {match[3]}
                                    </strong>
                                </Tooltip>
                            );
                        }
                    }
                    return <strong role="strong">{children}</strong>;
                },
            }}
            plugins={[gfm]}
        >
            {preserveNewlines(preprocessString(markdownString))}
        </ReactMarkdown>
    </div>
));

export default memo(Markdown);

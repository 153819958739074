import { FunctionComponent, memo } from "react";
import { IProps } from "./assignment-details.types";
import { useEmailAssignments } from "./assignment-details.utilities";
import { DetailsSection, DetailsSectionTitle } from "@edgetier/client-components";
import AssignmentDetailsField from "./assignment-details-field";
import { SpinnerUntil } from "@edgetier/components";
import { IEmailAssignment, Permission } from "@edgetier/types";
import { compareDesc } from "date-fns";
import AssignAgents from "components/search-emails/assign-agents";
import VerifyPermission from "components-for/verify-permission";
import "./assignment-details.scss";

/**
 * Show assignment details for an email.
 * @param props.emailId The ID of the email who's details we're showing.
 */
const AssignmentDetails: FunctionComponent<IProps> = ({ emailId, interactionCompleted }) => {
    const { data, isLoading, refetch, isError } = useEmailAssignments(
        emailId,
        {},
        {
            select: (assignments) => {
                return assignments.sort((one, two) =>
                    compareDesc(new Date(one.createDateTime), new Date(two.createDateTime))
                );
            },
        }
    );

    const onSuccess = () => {
        refetch();
    };

    return (
        <div className="assignment-details">
            <DetailsSection>
                <DetailsSectionTitle title="Assignments">
                    {!interactionCompleted && (
                        <VerifyPermission permission={Permission.AssignInteraction}>
                            <AssignAgents emailIds={[emailId]} onSuccess={onSuccess} />
                        </VerifyPermission>
                    )}
                </DetailsSectionTitle>
                <SpinnerUntil<IEmailAssignment[]> isReady={!isLoading} data={data}>
                    {(data) =>
                        Array.isArray(data) && data.length > 0 ? (
                            <table>
                                <tbody>
                                    {data.map((assignment, index) => (
                                        <AssignmentDetailsField
                                            assignment={assignment}
                                            interactionCompleted={interactionCompleted}
                                            key={`${assignment.emailId}-${assignment.userId}-${index}`}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="assignment-details__no-assignments">
                                {isError ? "Error loading assignments." : "No agents have been assigned to this email."}
                            </div>
                        )
                    }
                </SpinnerUntil>
            </DetailsSection>
        </div>
    );
};

export default memo(AssignmentDetails);

import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons/faLongArrowAltLeft";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons/faLongArrowAltRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Rating from "../rating-form-field";
import { IProps } from "./net-promoter-score-form-field.types";

/**
 * Show a range of number that allows customer to vote how likely/unlikely they would recommend/rate something in a
 * survey.
 * @param setFieldValue Method to set the selected number in Formik.
 * @param stars         Total number of numbers.
 * @param value         Currently selected number.
 */
const NetPromoterScoreFormField = ({ maximumLabel, minimumLabel, ...ratingProps }: IProps) => (
    <div className="dynamic-form__net-promoter-score">
        <Rating {...ratingProps}>
            {({ value }) => <div className="dynamic-form__net-promoter-score__item">{value}</div>}
        </Rating>
        <div className="dynamic-form__net-promoter-score__labels">
            <div>
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
                {typeof minimumLabel === "string" && <span>{minimumLabel}</span>}
            </div>
            <div>
                <span>{maximumLabel}</span>
                {typeof maximumLabel === "string" && <FontAwesomeIcon icon={faLongArrowAltRight} />}
            </div>
        </div>
    </div>
);

export default NetPromoterScoreFormField;

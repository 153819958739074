import { Tooltip } from "@edgetier/components";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import "./tooltip-warning.scss";
import { IProps } from "./tooltip-warning.types";
import WarningList from "./warning-list";

/**
 * Tooltip warning may be used to present a warning icon with a note to be displayed on hover
 * @param props.anchor       An optional anchor for suggesting where to position the menu.
 * @param props.children     This will be the trigger of the tooltip. This is optional, by default it will be a warning icon.
 * @param props.warning      This will be the warning(s) that show in the tooltip.
 * @param props.tooltipProps Props that can be added to change the behaviour of the tooltip.
 */
export const TooltipWarning: FunctionComponent<IProps> = ({
    children,
    placement = "top-center",
    warning,
    content,
    ...tooltipProps
}) => {
    const tooltipContent = (
        <div className="tooltip-warning__content">
            {Array.isArray(warning) ? <WarningList warnings={warning} /> : warning}
        </div>
    );

    return (
        <Tooltip
            className="tooltip-warning"
            useArrow
            content={content ?? tooltipContent}
            placement={placement}
            {...tooltipProps}
        >
            {typeof children === "undefined" ? (
                <div className="tooltip-warning__trigger">
                    <div className="tooltip-warning__icon">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    </div>
                </div>
            ) : (
                children
            )}
        </Tooltip>
    );
};

export default TooltipWarning;

import Qs, { type ParsedQs } from "qs";

/**
 * Clear URL of any prefixed parameters
 * @param searchParameters  Parameters parsed
 * @param prefix            Prefix for clearing the parameters
 * @returns All parameters excluding the prefixed parameters
 */
export const createUrl = (
    baseUrl: string,
    options?: {
        params?: Record<string, string>;
        queryParams?: ParsedQs | Record<string, any>;
        addQueryPrefix?: boolean;
    }
) => {
    let url = baseUrl;
    if (options) {
        const { params, queryParams, addQueryPrefix } = options;
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                url = url.replace(`:${key}`, value);
            });
        }
        if (queryParams) {
            url += Qs.stringify(queryParams, { addQueryPrefix: addQueryPrefix ?? true });
        }
    }
    return url;
};

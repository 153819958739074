import { FormFieldType } from "@edgetier/types";
import { formatDate } from "@edgetier/utilities";

/**
 * Check if a date string is valid.
 * @param date The date string to check.
 */
export const isValidDateString = (date: string | null): date is string => {
    if (typeof date !== "string") {
        return false;
    }

    try {
        new Date(date).toISOString();
        return true;
    } catch (error) {
        return false;
    }
};

/**
 * Format a date value to a string based on the form field type
 * @param formFieldTypeId The form field type id.
 * @param value The value to format.
 */
export const formatDateValue = (formFieldTypeId: FormFieldType, value: string) => {
    switch (formFieldTypeId) {
        case FormFieldType.Date:
            return formatDate(new Date(value), { explicitFormat: "dd/MM/yyyy" });
        case FormFieldType.Time:
            return formatDate(new Date(value), { explicitFormat: "HH:mm" });
        case FormFieldType.Timestamp:
        case FormFieldType.DateTime:
            return formatDate(new Date(value), { explicitFormat: "dd/MM/yyyy HH:mm" });
        default:
            return formatDate(new Date(value), { explicitFormat: "dd/MM/yyyy HH:mm" });
    }
};

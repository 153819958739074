import { UniqueId } from "@edgetier/components";
import { formatDate } from "@edgetier/utilities";
import classNames from "classnames";
import TimelineContext from "components/timeline/timeline-context";
import { ITimelineContext } from "components/timeline/timeline-context/timeline-context.types";
import { Field, FieldProps } from "formik";
import React, { memo, useMemo } from "react";

import "./timeline-item.scss";
import { IProps } from "./timeline-item.types";

/**
 * An element within a timeline display.
 * @param props.autoFocus Whether to focus the radio button or not.
 * @param props.children  Any extra content to show.
 * @param props.date      Date of the timeline event.
 * @param props.icon      Icon to display beside the item.
 * @param props.value     Value to select when choosing the item.
 * @param props.guideOnly Display only the guide line without icons and datetime
 * @returns               Timeline item details.
 */
const TimelineItem = <T extends {}>({ autoFocus, children, date, icon, value, guideOnly }: IProps<T>) => {
    const { getItemId, onSelect } = React.useContext<ITimelineContext<T>>(TimelineContext);
    const [formattedDay, formattedTime] = useMemo(
        () => [
            formatDate(date, { intlOptions: { dateStyle: "short" } }),
            formatDate(date, { intlOptions: { timeStyle: "short" } }),
        ],
        [date]
    );
    const itemId = getItemId(value);

    // Pass some props to children node
    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { date, icon } as any); // TODO: LEFTOVER - Understand why this cast is now needed
        }
        return child;
    });

    return (
        <UniqueId>
            {(uniqueId) => (
                <Field name="item">
                    {({ field, form: { setFieldValue } }: FieldProps) => (
                        <div
                            className={classNames("timeline__item", {
                                "timeline__item--active": getItemId(field.value) === itemId,
                            })}
                        >
                            <>
                                <input
                                    autoFocus={autoFocus}
                                    checked={getItemId(field.value) === itemId}
                                    id={uniqueId}
                                    name={field.name}
                                    onChange={(changeEvent) =>
                                        changeEvent.target.checked && setFieldValue(field.name, value)
                                    }
                                    type="radio"
                                />

                                <label htmlFor={uniqueId} onClick={() => onSelect(value)}>
                                    {!guideOnly && <>{icon}</>}
                                    <div className="timeline__item__guide">
                                        <div className="timeline__item__dot" />
                                    </div>

                                    {!guideOnly && (
                                        <div className="timeline__item__date">
                                            <div>{formattedDay}</div>
                                            <div className="timeline__item__time">{formattedTime}</div>
                                        </div>
                                    )}

                                    {childrenWithProps}
                                </label>
                            </>
                        </div>
                    )}
                </Field>
            )}
        </UniqueId>
    );
};

export default memo(TimelineItem, ({ autoFocus }, { autoFocus: next }) => autoFocus === next);

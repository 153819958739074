import * as Yup from "yup";
import Axios, { AxiosRequestConfig } from "axios";
import axios from "utilities/axios";
import InputError from "constants/input-error";
import NoteField from "components-for/notes/note-field";
import SelectSkill from "components/select-skill";
import SubmitFormKeyboardShortcut from "components-for/shortcuts/submit-form-keyboard-shortcut";
import urlJoin from "url-join";
import useCreateNote from "components-for/notes/use-create-note";
import useSkills from "hooks-for/skills/use-skills";
import WarningBox from "components-for/warnings/warning-box";
import { Button, Checkbox } from "@edgetier/components";
import { createGetDepartment, createGetName } from "./change-skills.utilities";
import { doNothing, keyById } from "@edgetier/utilities";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FieldError } from "@edgetier/components";
import { Form, Formik, FormikHelpers } from "formik";
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useRef } from "react";
import { getFeatureToggles } from "redux/modules/setup/setup-selectors";
import { hotToastOperations } from "utilities-for/toast";
import { IApplicationState } from "redux/types";
import { IFormValues, IProps } from "./change-skills.types";
import { IShowServerError } from "shared/modal/server-error-modal";
import { ISkill } from "redux/modules/setup/setup.types";
import { IThunkDispatch } from "redux/types";
import { KeyboardShortcutScope } from "services/keyboard-shortcut-service";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { ServerErrorModal } from "shared/modal";
import { UNCHANGED_ERROR } from "./change-skills.constant";
import { Url } from "@edgetier/types";
import { useDispatch, useSelector } from "react-redux";
import "./change-skills.scss";

/**
 * The backend assigns skills to emails and chats. It's possible it may get this wrong so agents have the ability to
 * change it if so. This component used to allow agents to assign multiple skills but now it only allows one. When the
 * skill is changing department a warning is displayed.
 * @param props.interactionDetailId Chat or email identifier.
 * @param props.onChange            A function that will run after the skills change.
 * @param props.queryId             The ID of the query that the interaction is a part of.
 * @param props.skillsIds           The potential skills
 */
const ChangeSkills: FunctionComponent<IProps> = ({ interactionDetailId, onChange, queryId, skillIds }) => {
    const cancelTokenSource = useRef(Axios.CancelToken.source());
    useEffect(() => cancelTokenSource.current.cancel, []);
    const dispatch = useDispatch<IThunkDispatch>();

    const { departmentRestrictedTransfersEnabled } = useSelector(({ setup }: IApplicationState) =>
        getFeatureToggles(setup)
    );

    const { mutateAsync: createNote } = useCreateNote(
        { interactionDetailId, queryId },
        // Override default toast error because the onSubmit will show any errors in a modal.
        { onError: doNothing }
    );

    const { data: skills } = useSkills({ unrestricted: departmentRestrictedTransfersEnabled });

    const [skillLookup, getDepartment, getName] = useMemo(() => {
        const lookup = keyById(skills ?? [], ({ skillId }) => skillId);
        return [lookup, createGetDepartment(lookup), createGetName(lookup)];
    }, [skills]);

    /**
     * Change the skill of an interaction.
     * @param showServerError Show errors in a modal.
     * @param values Form values.
     */
    const onSubmit = async (showServerError: IShowServerError, { note, skillId }: IFormValues) => {
        dispatch(loadingBlockerOperations.showLoadingBlocker(true));
        try {
            // PUT request with the new skill ID.
            const skillIds = [skillId];
            const configuration: AxiosRequestConfig = { cancelToken: cancelTokenSource.current.token };
            const url = urlJoin(Url.Interactions, interactionDetailId.toString());
            const { data } = await axios.put<{ keepInteraction: boolean }>(
                url,
                { canReturnToQueue: true, skillIds },
                configuration
            );

            // Pass the skills back to the parent component.
            const newSkills = skillIds.map((skillId) => skillLookup[skillId]);
            onChange(newSkills, data.keepInteraction);
            hotToastOperations.showSuccessToast("Success", "Skill has been updated.");

            // Create the note if one was made.
            // Creating the note must happen after changing the skill because once the note is made, we navigate to that
            // note and it will cancel the request to change the skill.
            if (note.trim().length > 0) await createNote(note);
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                showServerError(serverError);
            }
        } finally {
            dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
        }
    };

    const skillId = useMemo(() => skillIds[0], [skillIds]);

    /**
     * When the skill is changed, uncheck the department confirmation checkbox if the new and old skill are in different
     * departments.
     */
    const onSelectSkill = useCallback(
        (skill: ISkill, setFieldValue: FormikHelpers<IFormValues>["setFieldValue"]) => {
            setFieldValue("departmentConfirmed", getDepartment(skillId) === getDepartment(skill.skillId));
        },
        [getDepartment, skillId]
    );

    return (
        <Fragment>
            <p>
                If the skill below is incorrect, please reassign it and the query will be passed to an agent with the
                appropriate skills setup.
            </p>

            <ServerErrorModal>
                {(showServerError) => (
                    <Formik<IFormValues>
                        initialValues={{ departmentConfirmed: true, note: "", skillId }}
                        onSubmit={onSubmit.bind(null, showServerError)}
                        validationSchema={Yup.object({
                            departmentConfirmed: Yup.boolean().test("confirmed", InputError.Required, (value) => value),
                            note: Yup.string().trim(),
                            skillId: Yup.number()
                                .transform((value) => (isNaN(value) ? undefined : value))
                                .required(InputError.Required)
                                .test("changed", UNCHANGED_ERROR, (value: number) => skillId !== value),
                        })}
                    >
                        {({ setFieldValue, values }) => (
                            <Form className="change-skills">
                                <div className="field">
                                    <label htmlFor="skillId">Skill</label>
                                    <SelectSkill
                                        onSelect={(skill: any) => onSelectSkill(skill, setFieldValue)}
                                        isSingleSelect
                                        name="skillId"
                                        parameters={{ unrestricted: departmentRestrictedTransfersEnabled }}
                                    />
                                    <FieldError name="skillId" />
                                </div>

                                <NoteField placeHolder="Reason for changing skill or details about the interaction&hellip;" />

                                {getDepartment(skillId) !== getDepartment(values.skillId) && (
                                    <>
                                        <WarningBox>
                                            <p>
                                                Changing the skill to &ldquo;{getName(values.skillId)}&rdquo; will move
                                                it from the <strong>{getDepartment(skillId)}</strong> department to{" "}
                                                <strong>{getDepartment(values.skillId)}</strong>. Are you sure this is
                                                correct?
                                            </p>
                                        </WarningBox>
                                        <div className="field">
                                            <Checkbox label="Confirm change of department" name="departmentConfirmed" />
                                            <FieldError name="departmentConfirmed" />
                                        </div>
                                    </>
                                )}

                                <SubmitFormKeyboardShortcut scope={KeyboardShortcutScope.Modal} setScopeWhileActive />
                                <Button icon={faCheck} styleName="positive" type="submit">
                                    Change Skill
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </ServerErrorModal>
        </Fragment>
    );
};

export default ChangeSkills;

import classNames from "classnames";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { faArrowLeft, faClose } from "@fortawesome/pro-solid-svg-icons";

import { Button } from "@edgetier/components";
import type { IProps } from "./back-button.types";
import "./back-button.scss";

/**
 * A go back button using relative paths to navigate back.
 * @param props.children  Button content
 * @param props.className Optional extra class name(s) to add.
 * @returns               BackButton component.
 */
const BackButton: FunctionComponent<IProps> = ({ children, className, icon }) => {
    const navigate = useNavigate();
    return (
        <Button
            data-testid="back-button"
            outline
            styleName="positive"
            icon={icon || window.history.length > 1 ? faArrowLeft : faClose}
            onClick={() => (window.history.length > 1 ? navigate(-1) : window.close())}
            className={classNames("back-button", className)}
        >
            {children || (window.history.length > 1 ? "Back" : "Close Review")}
        </Button>
    );
};

export default BackButton;

import Language from "./language";

export enum Translation {
    AgentIsTyping,
    Back,
    BlockedAttachmentMessage,
    BlockedAttachmentTitle,
    Cancel,
    ChangeLanguage,
    ChatEnded,
    ChatInputPlaceholder,
    CloseWindow,
    DownloadTranscriptButton,
    EmailTranscript,
    EndChatButton,
    EndChatModalMessage,
    EndChatModalTitle,
    EmptySelect,
    Error,
    ErrorInvalid,
    ErrorRequired,
    FinishedChatMoreOptions,
    FormError,
    GoToSurvey,
    LiveChat,
    MinimiseWindow,
    Mute,
    NoItemsFound,
    NewChat,
    OpenChat,
    PrintTranscriptButton,
    RequestTranscriptButton,
    RequestTranscriptEmailLabel,
    RequestTranscriptModalTitle,
    SkipSurvey,
    StartChat,
    Submit,
    SurveyThanks,
    Title,
    TooEarly,
    TooLate,
    TranscriptRequested,
    TryingReconnect,
    TypeToSearch,
    Typing,
    Unmute,
    ViewChat,
    Yes,
}

/* tslint:disable:max-line-length */
export const locales: { [index: number]: { [key in Translation]: string } } = {
    [Language.English]: {
        [Translation.ChatEnded]: "The chat has ended.",
        [Translation.ChangeLanguage]: "Change Language",
        [Translation.SurveyThanks]: "Thanks for your feedback.",
        [Translation.Unmute]: "Unmute notification sounds",
        [Translation.OpenChat]: "Open Chat Support",
        [Translation.Error]: "Error",
        [Translation.BlockedAttachmentMessage]: "Please enable pop-ups in your browser to view this attachment.",
        [Translation.RequestTranscriptButton]: "Email Transcript",
        [Translation.Cancel]: "Cancel",
        [Translation.Submit]: "Submit",
        [Translation.RequestTranscriptModalTitle]: "Request Transcript",
        [Translation.FormError]: "Please fix the errors on the form to continue.",
        [Translation.EndChatModalMessage]: "Are you sure you want to end the chat?",
        [Translation.ViewChat]: "View Transcript",
        [Translation.Typing]: "typing",
        [Translation.ErrorInvalid]: "Invalid",
        [Translation.TranscriptRequested]: "Transcript Requested",
        [Translation.RequestTranscriptEmailLabel]: "Your email address",
        [Translation.Yes]: "Yes",
        [Translation.BlockedAttachmentTitle]: "Attachments Blocked",
        [Translation.AgentIsTyping]: "The agent is typing...",
        [Translation.EndChatButton]: "End Chat",
        [Translation.SkipSurvey]: "Skip Feedback",
        [Translation.ErrorRequired]: "Required",
        [Translation.NewChat]: "New Chat",
        [Translation.GoToSurvey]: "Rate Your Experience",
        [Translation.LiveChat]: "Live Chat",
        [Translation.ChatInputPlaceholder]: "Your message...",
        [Translation.Mute]: "Mute notification sounds",
        [Translation.FinishedChatMoreOptions]: "More Options",
        [Translation.EmailTranscript]: "Email Transcript",
        [Translation.PrintTranscriptButton]: "Print Transcript",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Help",
        [Translation.TooEarly]: "Too early",
        [Translation.TooLate]: "Too late",
        [Translation.TryingReconnect]: "Trying to reconnect",
        [Translation.Back]: "Back",
        [Translation.StartChat]: "Start Chat",
        [Translation.EndChatModalTitle]: "End Chat",
        [Translation.MinimiseWindow]: "Minimise Chat Window",
        [Translation.PrintTranscriptButton]: "Print Transcript",
        [Translation.DownloadTranscriptButton]: "Download Transcript",
        [Translation.EmptySelect]: "Nothing selected",
        [Translation.NoItemsFound]: "No results found",
        [Translation.TypeToSearch]: "Search...",
    },
    [Language.Estonian]: {
        [Translation.ChatEnded]: "Vestlus on lõppenud",
        [Translation.ChangeLanguage]: "Muuda keelt",
        [Translation.SurveyThanks]: "Täname tagasiside eest.",
        [Translation.Unmute]: "Tühista teavitushelide vaigistus",
        [Translation.OpenChat]: "Avage vestluse tugi",
        [Translation.Error]: "Viga",
        [Translation.BlockedAttachmentMessage]: "Selle manuse vaatamiseks lubage oma brauseris hüpikaknad.",
        [Translation.RequestTranscriptButton]: "Meili ärakiri",
        [Translation.Cancel]: "Tühista",
        [Translation.Submit]: "Esita",
        [Translation.RequestTranscriptModalTitle]: "Taotle ärakirja",
        [Translation.FormError]: "Jätkamiseks parandage vormil olevad vead.",
        [Translation.EndChatModalMessage]: "Kas olete kindel, et soovite vestluse lõpetada?",
        [Translation.ViewChat]: "Vaata ärakirja",
        [Translation.Typing]: "trükkimine",
        [Translation.ErrorInvalid]: "Kehtetu",
        [Translation.TranscriptRequested]: "Taotletud ärakirja",
        [Translation.RequestTranscriptEmailLabel]: "Sinu emaili aadress",
        [Translation.Yes]: "Jah",
        [Translation.BlockedAttachmentTitle]: "Manused blokeeritud",
        [Translation.AgentIsTyping]: "Agent kirjutab...",
        [Translation.EndChatButton]: "Lõpeta vestlus",
        [Translation.SkipSurvey]: "Jäta tagasiside vahele",
        [Translation.ErrorRequired]: "Nõutud",
        [Translation.NewChat]: "Uus vestlus",
        [Translation.GoToSurvey]: "Hinnake oma kogemusi",
        [Translation.LiveChat]: "Otse vestlus",
        [Translation.ChatInputPlaceholder]: "Sinu sõnum...",
        [Translation.Mute]: "Vaigista teavitushelid",
        [Translation.FinishedChatMoreOptions]: "Veel valikuid",
        [Translation.EmailTranscript]: "Meili ärakiri",
        [Translation.PrintTranscriptButton]: "Prindi ärakiri",
        [Translation.CloseWindow]: "Sulgege vestlusaken",
        [Translation.Title]: "Abi",
        [Translation.TooEarly]: "Liiga vara",
        [Translation.TooLate]: "Liiga hilja",
        [Translation.TryingReconnect]: "Proovin uuesti ühendust luua",
        [Translation.Back]: "Tagasi",
        [Translation.StartChat]: "Alusta vestlust",
        [Translation.EndChatModalTitle]: "Lõpeta vestlus",
        [Translation.MinimiseWindow]: "Minimeeri vestlusaken",
        [Translation.PrintTranscriptButton]: "Prindi ärakiri",
        [Translation.DownloadTranscriptButton]: "Laadige alla transkriptsioon",
        [Translation.EmptySelect]: "Midagi pole valitud",
        [Translation.NoItemsFound]: "Tulemusi ei leitud",
        [Translation.TypeToSearch]: "Otsing...",
    },
    [Language.German]: {
        [Translation.ChatEnded]: "Der Chat wurde beendet.",
        [Translation.ChangeLanguage]: "Sprache ändern",
        [Translation.SurveyThanks]: "Vielen Dank für Ihr Feedback.",
        [Translation.Unmute]: "Stummschaltung der Benachrichtigungen aufheben",
        [Translation.OpenChat]: "Chat Support öffnen",
        [Translation.Error]: "Fehler",
        [Translation.BlockedAttachmentMessage]:
            "Bitte aktivieren Sie Pop-ups in Ihrem Browser, um diesen Anhang anzuzeigen.",
        [Translation.RequestTranscriptButton]: "Email Transkript",
        [Translation.Cancel]: "Nein",
        [Translation.Submit]: "Einreichen",
        [Translation.RequestTranscriptModalTitle]: "Transkript anfordern",
        [Translation.FormError]: "Bitte korrigieren Sie die Fehler im Formular um fortzufahren.",
        [Translation.EndChatModalMessage]: "Sind Sie sicher, dass Sie den Chat beenden möchten?",
        [Translation.ViewChat]: "Transkript anzeigen",
        [Translation.Typing]: "Schreibt",
        [Translation.ErrorInvalid]: "Ungültig",
        [Translation.TranscriptRequested]: "Transkript angefordert",
        [Translation.RequestTranscriptEmailLabel]: "Deine Emailadresse",
        [Translation.Yes]: "Ja",
        [Translation.BlockedAttachmentTitle]: "Anhänge blockiert",
        [Translation.AgentIsTyping]: "Der Mitarbeiter schreibt...",
        [Translation.EndChatButton]: "Chat beenden",
        [Translation.SkipSurvey]: "Umfrage überspringen",
        [Translation.ErrorRequired]: "Erforderlich",
        [Translation.NewChat]: "Neuer Chat",
        [Translation.GoToSurvey]: "Bewerten Sie Ihre Erfahrung",
        [Translation.LiveChat]: "Live-Chat",
        [Translation.ChatInputPlaceholder]: "Ihre Nachricht",
        [Translation.Mute]: "Benachrichtigungen stummschalten",
        [Translation.FinishedChatMoreOptions]: "Weitere Optionen",
        [Translation.EmailTranscript]: "E-Mail Kopie des Chats",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Hilfe",
        [Translation.TooEarly]: "Ungültig",
        [Translation.TooLate]: "Ungültig",
        [Translation.TryingReconnect]: "Verbindung wird wiederhergestellt",
        [Translation.Back]: "Zurück",
        [Translation.StartChat]: "Chat starten",
        [Translation.EndChatModalTitle]: "Chat beenden",
        [Translation.MinimiseWindow]: "Chat Fenster minimieren",
        [Translation.PrintTranscriptButton]: "Protokoll ausdrucken",
        [Translation.DownloadTranscriptButton]: "Protokoll herunterladen",
        [Translation.EmptySelect]: "Keine Auswahl getätigt",
        [Translation.NoItemsFound]: "Keine Ergebnisse gefunden",
        [Translation.TypeToSearch]: "Suchen...",
    },
    [Language.Spanish]: {
        [Translation.ChatEnded]: "El chat ha finalizado.",
        [Translation.ChangeLanguage]: "Cambiar idioma",
        [Translation.SurveyThanks]: "Gracias por su opinión.",
        [Translation.Unmute]: "Activar notificaciones",
        [Translation.OpenChat]: "Inicar chat de soporte",
        [Translation.Error]: "Error",
        [Translation.BlockedAttachmentMessage]:
            "Habilite las ventanas emergentes en su navegador para poder ver este archivo adjunto.",
        [Translation.RequestTranscriptButton]: "Enviar Transcripción",
        [Translation.Cancel]: "Cancelar",
        [Translation.Submit]: "Enviar",
        [Translation.RequestTranscriptModalTitle]: "Solicitar Transcripción",
        [Translation.FormError]: "Por favor, solucione los errores en el formulario para continuar",
        [Translation.EndChatModalMessage]: "¿Está seguro de que quiere finalizar el chat?",
        [Translation.ViewChat]: "Ver Transcripción",
        [Translation.Typing]: "Escribiendo",
        [Translation.ErrorInvalid]: "Inválido",
        [Translation.TranscriptRequested]: "Transcripción Solicitada",
        [Translation.RequestTranscriptEmailLabel]: "Su dirección de correo electrónico",
        [Translation.Yes]: "Sí",
        [Translation.BlockedAttachmentTitle]: "Archivos Adjuntos Bloqueados",
        [Translation.AgentIsTyping]: "El agente está escribiendo...",
        [Translation.EndChatButton]: "Terminar chat",
        [Translation.SkipSurvey]: "Ignorar encuesta",
        [Translation.ErrorRequired]: "Necesario",
        [Translation.NewChat]: "Nuevo Chat",
        [Translation.GoToSurvey]: "Valore su experiencia",
        [Translation.LiveChat]: "Chat en Vivo",
        [Translation.ChatInputPlaceholder]: "Tu mensaje...",
        [Translation.Mute]: "Silenciar notificaciones",
        [Translation.FinishedChatMoreOptions]: "Más opciones",
        [Translation.EmailTranscript]: "Transcripción del correo electrónico",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Ayuda",
        [Translation.TooEarly]: "Inválido",
        [Translation.TooLate]: "Inválido",
        [Translation.TryingReconnect]: "Intentando reconectar",
        [Translation.Back]: "Atrás",
        [Translation.StartChat]: "Comenzar chat",
        [Translation.EndChatModalTitle]: "Finalizar Chat",
        [Translation.MinimiseWindow]: "Minimizar la venta del chat",
        [Translation.PrintTranscriptButton]: "Imprimir transcripción",
        [Translation.DownloadTranscriptButton]: "Descargar transcripción",
        [Translation.EmptySelect]: "Nada seleccionado",
        [Translation.NoItemsFound]: "No se han encontrado resultados",
        [Translation.TypeToSearch]: "Búsqueda...",
    },
    [Language.Italian]: {
        [Translation.ChatEnded]: "La chat é terminata",
        [Translation.ChangeLanguage]: "Cambia lingua",
        [Translation.SurveyThanks]: "La ringraziamo per il suo feedback.",
        [Translation.Unmute]: "Attiva l'audio delle notifiche",
        [Translation.OpenChat]: "Apri chat di supporto",
        [Translation.Error]: "Errore",
        [Translation.BlockedAttachmentMessage]:
            "Per favore, abiliti pop-ups nel suo browser per visualizzare questo allegato.",
        [Translation.RequestTranscriptButton]: "Inviare Trascrizione",
        [Translation.Cancel]: "Cancella",
        [Translation.Submit]: "Invia",
        [Translation.RequestTranscriptModalTitle]: "Richiedi Trascrizione",
        [Translation.FormError]: "Per favore, correggi gli errori sul modulo per continuare",
        [Translation.EndChatModalMessage]: "É sicuro di voler terminare questa chat?",
        [Translation.ViewChat]: "Vedi trascrizione",
        [Translation.Typing]: "Scrivendo",
        [Translation.ErrorInvalid]: "Invalido",
        [Translation.TranscriptRequested]: "Trascrizione Richiesta",
        [Translation.RequestTranscriptEmailLabel]: "Il tuo indirizzo email",
        [Translation.Yes]: "Si",
        [Translation.BlockedAttachmentTitle]: "Allegati bloccati",
        [Translation.AgentIsTyping]: "L'operatore sta scrivendo...",
        [Translation.EndChatButton]: "Chiudi chat",
        [Translation.SkipSurvey]: "Salta il sondaggio",
        [Translation.ErrorRequired]: "Richiesto",
        [Translation.NewChat]: "Nuova chat",
        [Translation.GoToSurvey]: "Valora la sua esperienza",
        [Translation.LiveChat]: "Chat dal Vivo",
        [Translation.ChatInputPlaceholder]: "Il tuo messaggio...",
        [Translation.Mute]: "Disattiva l'audio delle notifiche",
        [Translation.FinishedChatMoreOptions]: "Altre opzioni",
        [Translation.EmailTranscript]: "Chat trascritta via email",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Aiuto",
        [Translation.TooEarly]: "Invalido",
        [Translation.TooLate]: "Invalido",
        [Translation.TryingReconnect]: "Riconnessione in corso",
        [Translation.Back]: "Indietro",
        [Translation.StartChat]: "Inizia la chat",
        [Translation.EndChatModalTitle]: "Termina chat",
        [Translation.MinimiseWindow]: "Riduci la chat",
        [Translation.PrintTranscriptButton]: "Stampa trascrizione",
        [Translation.DownloadTranscriptButton]: "Scarica trascrizione",
        [Translation.EmptySelect]: "Niente selezionato",
        [Translation.NoItemsFound]: "Nessun risultato trovato",
        [Translation.TypeToSearch]: "Cerca...",
    },
    [Language.French]: {
        [Translation.ChatEnded]: "Le chat est terminé",
        [Translation.ChangeLanguage]: "Changement de langue",
        [Translation.SurveyThanks]: "Merci pour vos commentaires.",
        [Translation.Unmute]: "Réactiver le son des notifications",
        [Translation.OpenChat]: "Ouverture du chat",
        [Translation.Error]: "Erreur",
        [Translation.BlockedAttachmentMessage]:
            "Veuillez activer les 'pop-ups' dans votre navigateur pour afficher cette pièce jointe.",
        [Translation.RequestTranscriptButton]: "Envoyer Transcription",
        [Translation.Cancel]: "Annuler",
        [Translation.Submit]: "Soumettre",
        [Translation.RequestTranscriptModalTitle]: "Demande de Transcription",
        [Translation.FormError]: "S'il vous plaît veuillez corriger l'erreur afin de continuer",
        [Translation.EndChatModalMessage]: "Êtes-vous sûr de vouloir mettre fin à ce chat?",
        [Translation.ViewChat]: "Voir Transcription",
        [Translation.Typing]: "En train d'écrire",
        [Translation.ErrorInvalid]: "Erreur/ Pas valide",
        [Translation.TranscriptRequested]: "Transcription demandée",
        [Translation.RequestTranscriptEmailLabel]: "Votre adresse email",
        [Translation.Yes]: "Oui",
        [Translation.BlockedAttachmentTitle]: "Pièces jointes bloquées",
        [Translation.AgentIsTyping]: "L'agent est en train d'écrire",
        [Translation.EndChatButton]: "Terminer le chat",
        [Translation.SkipSurvey]: "Passer enquête de satisfaction",
        [Translation.ErrorRequired]: "Requis",
        [Translation.NewChat]: "Nouveau Chat",
        [Translation.GoToSurvey]: "Évaluez votre experience",
        [Translation.LiveChat]: "Chat en Direct",
        [Translation.ChatInputPlaceholder]: "Votre texte...",
        [Translation.Mute]: "Désactiver le son des notifications",
        [Translation.FinishedChatMoreOptions]: "Plus d'options",
        [Translation.EmailTranscript]: "Transcription par email",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Aidez-moi",
        [Translation.TooEarly]: "Erreur/ Pas valide",
        [Translation.TooLate]: "Erreur/ Pas valide",
        [Translation.TryingReconnect]: "On essaye de vous reconnecter",
        [Translation.Back]: "Revenier en arrière",
        [Translation.StartChat]: "Commencer le chat",
        [Translation.EndChatModalTitle]: "Terminer le chat",
        [Translation.MinimiseWindow]: "Réduire le chat",
        [Translation.PrintTranscriptButton]: "Enquête",
        [Translation.DownloadTranscriptButton]: "Télécharger le script",
        [Translation.EmptySelect]: "Rien n'a été sélectionné",
        [Translation.NoItemsFound]: "Aucun résultat n'a été trouvé",
        [Translation.TypeToSearch]: "Rechercher...",
    },
    [Language.Dutch]: {
        [Translation.ChatEnded]: "De chat is beëindigd.",
        [Translation.ChangeLanguage]: "Verander taal",
        [Translation.SurveyThanks]: "Bedankt voor uw feedback.",
        [Translation.Unmute]: "Demping van meldingsgeluiden opheffen",
        [Translation.OpenChat]: "Open chat venster",
        [Translation.Error]: "Fout",
        [Translation.BlockedAttachmentMessage]: "Laat pop-ups toe in uw browser om deze bijlagen te bekijken.",
        [Translation.RequestTranscriptButton]: "Email transcript",
        [Translation.Cancel]: "Annulleren",
        [Translation.Submit]: "Verzenden",
        [Translation.RequestTranscriptModalTitle]: "Vraag transcript aan",
        [Translation.FormError]: "Corrigeer aub de fouten op het formulier om door te gaan.",
        [Translation.EndChatModalMessage]: "Weet u zeker dat u de chat wilt beëindigen?",
        [Translation.ViewChat]: "Bekijk transcript",
        [Translation.Typing]: "Aan het typen",
        [Translation.ErrorInvalid]: "Ongeldig",
        [Translation.TranscriptRequested]: "Transcript aangevraagd",
        [Translation.RequestTranscriptEmailLabel]: "Uw emailadres",
        [Translation.Yes]: "Ja",
        [Translation.BlockedAttachmentTitle]: "Bijlagen geblokkeerd",
        [Translation.AgentIsTyping]: "De medewerker typt...",
        [Translation.EndChatButton]: "Beëindig chat",
        [Translation.SkipSurvey]: "Sla feedback over",
        [Translation.ErrorRequired]: "Verplicht",
        [Translation.NewChat]: "Nieuwe chat",
        [Translation.GoToSurvey]: "Beoordeel uw ervaring",
        [Translation.LiveChat]: "Live Chat",
        [Translation.ChatInputPlaceholder]: "Uw bericht ...",
        [Translation.Mute]: "Mute-meldingsgeluiden",
        [Translation.FinishedChatMoreOptions]: "Meer opties",
        [Translation.EmailTranscript]: "Email transcript",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Help",
        [Translation.TooEarly]: "Ongeldig",
        [Translation.TooLate]: "Ongeldig",
        [Translation.TryingReconnect]: "Probeert opnieuw te verbinden",
        [Translation.Back]: "Terug",
        [Translation.StartChat]: "Begin chat",
        [Translation.EndChatModalTitle]: "Chat eindigen",
        [Translation.MinimiseWindow]: "Verklein chat venster",
        [Translation.PrintTranscriptButton]: "Transcript printen",
        [Translation.DownloadTranscriptButton]: "Transcript downloaden",
        [Translation.EmptySelect]: "Niets geselecteerd",
        [Translation.NoItemsFound]: "Geen resultaten gevonden",
        [Translation.TypeToSearch]: "Zoeken…",
    },
    [Language.Swedish]: {
        [Translation.ChatEnded]: "Denna Chatt har avslutats",
        [Translation.ChangeLanguage]: "Ändra språk",
        [Translation.SurveyThanks]: "Tack för din feedback.",
        [Translation.Unmute]: "Sätt på notifikationssignalen",
        [Translation.OpenChat]: "Öppna Chat Support",
        [Translation.Error]: "Fel",
        [Translation.BlockedAttachmentMessage]: "Aktivera popup-fönster i din webbläsare för att se den här bilagan",
        [Translation.RequestTranscriptButton]: "Skicka transkript via epost",
        [Translation.Cancel]: "Annullera",
        [Translation.Submit]: "Bekräfta",
        [Translation.RequestTranscriptModalTitle]: "Begär transkript",
        [Translation.FormError]: "Fixa felen på formuläret för att fortsätta.",
        [Translation.EndChatModalMessage]: "Är du säker på att du vill avsluta Chatten?",
        [Translation.ViewChat]: "Se transkript",
        [Translation.Typing]: "Skriver",
        [Translation.ErrorInvalid]: "Ogiltig",
        [Translation.TranscriptRequested]: "Transkript har begärts",
        [Translation.RequestTranscriptEmailLabel]: "Din epost adress",
        [Translation.Yes]: "Ja",
        [Translation.BlockedAttachmentTitle]: "Bilagor blockerade",
        [Translation.AgentIsTyping]: "Agenten skriver ...",
        [Translation.EndChatButton]: "Avsluta Chatten",
        [Translation.SkipSurvey]: "Skippa Feedback",
        [Translation.ErrorRequired]: "Nödvändig",
        [Translation.NewChat]: "Ny Chatt",
        [Translation.GoToSurvey]: "Betygsätta din upplevelse",
        [Translation.LiveChat]: "Livechatt",
        [Translation.ChatInputPlaceholder]: "Ditt meddelande",
        [Translation.Mute]: "Sätt notifikationssignalen på ljudlös",
        [Translation.FinishedChatMoreOptions]: "Fler alternativ",
        [Translation.EmailTranscript]: "Chatt transkript",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Hjälp",
        [Translation.TooEarly]: "Ogiltig",
        [Translation.TooLate]: "Ogiltig",
        [Translation.TryingReconnect]: "Försöker återansluta",
        [Translation.Back]: "Tillbaka",
        [Translation.StartChat]: "Starta chatt",
        [Translation.EndChatModalTitle]: "Avlsuta Chatt",
        [Translation.MinimiseWindow]: "Minimera chattfönstret",
        [Translation.PrintTranscriptButton]: "Printa kopia",
        [Translation.DownloadTranscriptButton]: "Ladda ner kopia",
        [Translation.EmptySelect]: "Inget valt",
        [Translation.NoItemsFound]: "Inga resultat funna",
        [Translation.TypeToSearch]: "Sök...",
    },
    [Language.Polish]: {
        [Translation.ChatEnded]: "Czat zakonczony",
        [Translation.ChangeLanguage]: "Zmien jezyk",
        [Translation.SurveyThanks]: "Dziekujemy za feedback",
        [Translation.Unmute]: "Wlacz dzwieki powiadomien",
        [Translation.OpenChat]: "Otworz Czat",
        [Translation.Error]: "Blad",
        [Translation.BlockedAttachmentMessage]: "Prosze wlaczyc pop-upy w przrgladarce aby zobaczyc ten zalacznik.",
        [Translation.RequestTranscriptButton]: "Zapis emaila",
        [Translation.Cancel]: "Anuluj",
        [Translation.Submit]: "Zatwierdz",
        [Translation.RequestTranscriptModalTitle]: "Popros o zapis",
        [Translation.FormError]: "Aby kontynuowac, prosimy poprawic bledy w formularzu",
        [Translation.EndChatModalMessage]: "Czy na pewno chcesz zakonczyc czat?",
        [Translation.ViewChat]: "Zobacz zapis czatu",
        [Translation.Typing]: "Pisze wiadomosc",
        [Translation.ErrorInvalid]: "Niewazny",
        [Translation.TranscriptRequested]: "Wyspanie zapytania o zapis",
        [Translation.RequestTranscriptEmailLabel]: "Twoj email",
        [Translation.Yes]: "Tak",
        [Translation.BlockedAttachmentTitle]: "Zalaczniki zablokowane",
        [Translation.AgentIsTyping]: "Agent pisze wiadomosc...",
        [Translation.EndChatButton]: "Zakoncz czat",
        [Translation.SkipSurvey]: "Omin feedback",
        [Translation.ErrorRequired]: "Wymagany",
        [Translation.NewChat]: "Nowy czat",
        [Translation.GoToSurvey]: "Podziel się opinią",
        [Translation.LiveChat]: "Czat na żywo",
        [Translation.ChatInputPlaceholder]: "Twoja wiadomosc...",
        [Translation.Mute]: "Wycisz dzwieki powiadomien",
        [Translation.FinishedChatMoreOptions]: "Więcej opcji",
        [Translation.EmailTranscript]: "Zapis emaila",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Pomoc",
        [Translation.TooEarly]: "Niewazny",
        [Translation.TooLate]: "Niewazny",
        [Translation.TryingReconnect]: "Uzyskiwanie polaczenie",
        [Translation.Back]: "Wstecz",
        [Translation.StartChat]: "Rozpocznij czat",
        [Translation.EndChatModalTitle]: "Zakoncz czas",
        [Translation.MinimiseWindow]: "Zminimalizuj okno czatu",
        [Translation.PrintTranscriptButton]: "Wydrukuj tranksrypcję",
        [Translation.DownloadTranscriptButton]: "Pobierz transkrypcję",
        [Translation.EmptySelect]: "Nie wybrano żadnej opcji.",
        [Translation.NoItemsFound]: "Brak wyników wyszukiwania. ",
        [Translation.TypeToSearch]: "Szukaj...",
    },
    [Language.Russian]: {
        [Translation.ChatEnded]: "Чат закончен",
        [Translation.ChangeLanguage]: "Изменить язык",
        [Translation.SurveyThanks]: "Спасибо за Ваш отзыв",
        [Translation.Unmute]: "Включить звук уведомлений",
        [Translation.OpenChat]: "Открыть чат",
        [Translation.Error]: "Ошибка",
        [Translation.BlockedAttachmentMessage]:
            "Пожалуйста, включите появляющиеся окна в Вашем браузере, чтобы просмотреть эти вложения",
        [Translation.RequestTranscriptButton]: "Выслать стенограмму на электронный адрес",
        [Translation.Cancel]: "Отменить",
        [Translation.Submit]: "Отправить",
        [Translation.RequestTranscriptModalTitle]: "Запросить стенограмму",
        [Translation.FormError]: "Пожалуйста, исправьте ошибки в форме, чтобы продолжить.",
        [Translation.EndChatModalMessage]: "Вы действительно хотите закончить чат?",
        [Translation.ViewChat]: "Смотреть стенограмму",
        [Translation.Typing]: "Написание",
        [Translation.ErrorInvalid]: "Недействительно",
        [Translation.TranscriptRequested]: "Стенограмма запрошена",
        [Translation.RequestTranscriptEmailLabel]: "Адрес Вашей электронной почты",
        [Translation.Yes]: "Да",
        [Translation.BlockedAttachmentTitle]: "Вложения заблокированы",
        [Translation.AgentIsTyping]: "Агент печатает",
        [Translation.EndChatButton]: "Кнопка завершения чата",
        [Translation.SkipSurvey]: "Пропустить отзыв",
        [Translation.ErrorRequired]: "Обязательно",
        [Translation.NewChat]: "Новый чат",
        [Translation.GoToSurvey]: "Оцените ваш опыт",
        [Translation.LiveChat]: "Онлайн-чат",
        [Translation.ChatInputPlaceholder]: "Заполнитель чата",
        [Translation.Mute]: "Выключить звук уведомлений",
        [Translation.FinishedChatMoreOptions]: "Другие варианты",
        [Translation.EmailTranscript]: "Выслать стенограмму на электронный адрес",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Помощь",
        [Translation.TooEarly]: "Недействительно",
        [Translation.TooLate]: "Недействительно",
        [Translation.TryingReconnect]: "Попытка соединения",
        [Translation.Back]: "Назад",
        [Translation.StartChat]: "Начать чат",
        [Translation.EndChatModalTitle]: "Закончить чат",
        [Translation.MinimiseWindow]: "Свернуть окно",
        [Translation.PrintTranscriptButton]: "Распечатка стенограммы",
        [Translation.DownloadTranscriptButton]: "Скачать стенограмму",
        [Translation.EmptySelect]: "Ничего не выбрано",
        [Translation.NoItemsFound]: "Результаты не найдены",
        [Translation.TypeToSearch]: "Поиск…",
    },
    [Language.Portuguese]: {
        [Translation.ChatEnded]: "O chat terminou",
        [Translation.ChangeLanguage]: "Trocar língua",
        [Translation.SurveyThanks]: "Obrigado pelo seu feedback",
        [Translation.Unmute]: "Ativar sons the notificacao",
        [Translation.OpenChat]: "Abrir Suporte por Chat",
        [Translation.Error]: "Erro",
        [Translation.BlockedAttachmentMessage]: "Por favor, habilite os pop-ups em seu navegador para ver este anexo.",
        [Translation.RequestTranscriptButton]: "Transcricao de email",
        [Translation.Cancel]: "Cancelar",
        [Translation.Submit]: "Enviar",
        [Translation.RequestTranscriptModalTitle]: "Solicitar transcricao",
        [Translation.FormError]: "Por favor, corrija os erros no formulário para continuar.",
        [Translation.EndChatModalMessage]: "Voce tem certeza que deseja finalizar o chat?",
        [Translation.ViewChat]: "Ver transcricao do chat",
        [Translation.Typing]: "Digitando",
        [Translation.ErrorInvalid]: "Erro/Invalido",
        [Translation.TranscriptRequested]: "Transcricao solicitada",
        [Translation.RequestTranscriptEmailLabel]: "Seu endereco de email",
        [Translation.Yes]: "Sim",
        [Translation.BlockedAttachmentTitle]: "Anexo bloqueado",
        [Translation.AgentIsTyping]: "Agenten skriver...",
        [Translation.EndChatButton]: "Terminar Chat",
        [Translation.SkipSurvey]: "Pular feedback",
        [Translation.ErrorRequired]: "Erro/Obrigatorio",
        [Translation.NewChat]: "Novo chat",
        [Translation.GoToSurvey]: "Avalie sua experiência",
        [Translation.LiveChat]: "Chat em Direto",
        [Translation.ChatInputPlaceholder]: "Sua mensagem...",
        [Translation.Mute]: "Silenciar sons the notificacao",
        [Translation.FinishedChatMoreOptions]: "Mais opções",
        [Translation.EmailTranscript]: "Transcricao de email",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Ajuda",
        [Translation.TooEarly]: "Erro/Invalido",
        [Translation.TooLate]: "Erro/Invalido",
        [Translation.TryingReconnect]: "Tentando reconectar",
        [Translation.Back]: "Voltar",
        [Translation.StartChat]: "Iniciar bate-papo",
        [Translation.EndChatModalTitle]: "Fim do chat",
        [Translation.MinimiseWindow]: "Minimizar a janela do Chat",
        [Translation.PrintTranscriptButton]: "Imprimir a transcrição.",
        [Translation.DownloadTranscriptButton]: "Baixar a transcrição.",
        [Translation.EmptySelect]: "Nenhum criterio foi seleccionado",
        [Translation.NoItemsFound]: "Sem resultado",
        [Translation.TypeToSearch]: "Procurar...",
    },
    [Language.Norwegian]: {
        [Translation.ChatEnded]: "Chatten er avsluttet.",
        [Translation.ChangeLanguage]: "Endre språk",
        [Translation.SurveyThanks]: "Takk for din tilbakemelding.",
        [Translation.Unmute]: "Slå av lyden for varsling",
        [Translation.OpenChat]: "Åpne Chat Support",
        [Translation.Error]: "Feil",
        [Translation.BlockedAttachmentMessage]: "Vennligst tillatt pop-ups i din nettleser for å se vedlegget.",
        [Translation.RequestTranscriptButton]: "Send kopi på e-post",
        [Translation.Cancel]: "Kanseller",
        [Translation.Submit]: "Send",
        [Translation.RequestTranscriptModalTitle]: "Be om kopi",
        [Translation.FormError]: "Vennligst fiks feilen i skjemaet for å fortsette",
        [Translation.EndChatModalMessage]: "Er du sikker på at du vil avslutte chatten?",
        [Translation.ViewChat]: "Se kopi av Chat",
        [Translation.Typing]: "Skriver",
        [Translation.ErrorInvalid]: "Ikke Gyldig",
        [Translation.TranscriptRequested]: "Forespørsel om kopi sendt",
        [Translation.RequestTranscriptEmailLabel]: "Din e-post adresse",
        [Translation.Yes]: "Ja",
        [Translation.BlockedAttachmentTitle]: "Vedlegg blokkert",
        [Translation.AgentIsTyping]: "Agenten skriver...",
        [Translation.EndChatButton]: "Avslutt Chatten",
        [Translation.SkipSurvey]: "Hopp Over Tilbakemelding",
        [Translation.ErrorRequired]: "Nødvendig",
        [Translation.NewChat]: "Ny Chat",
        [Translation.GoToSurvey]: "Vurder din opplevelse",
        [Translation.LiveChat]: "Livechat",
        [Translation.ChatInputPlaceholder]: "Din melding",
        [Translation.Mute]: "Dempende varsling lyder",
        [Translation.FinishedChatMoreOptions]: "Flere alternativ",
        [Translation.EmailTranscript]: "Send kopi på e-post",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Hjelp",
        [Translation.TooEarly]: "Ikke Gyldig",
        [Translation.TooLate]: "Ikke Gyldig",
        [Translation.TryingReconnect]: "Forsøker å koble til igjen",
        [Translation.Back]: "Tilbake",
        [Translation.StartChat]: "Start chat",
        [Translation.EndChatModalTitle]: "Avslutt Chat",
        [Translation.MinimiseWindow]: "Minimer chatvinduet",
        [Translation.PrintTranscriptButton]: "Print kopi",
        [Translation.DownloadTranscriptButton]: "Last ned kopi",
        [Translation.EmptySelect]: "Ingenting valgt",
        [Translation.NoItemsFound]: "Fant ingen resultater",
        [Translation.TypeToSearch]: "Søk...",
    },
    [Language.Turkish]: {
        [Translation.ChatEnded]: "Chat sona erdi.",
        [Translation.ChangeLanguage]: "Dili Değiştir",
        [Translation.SurveyThanks]: "Geri bildiriminiz için teşekkür ederiz.",
        [Translation.Unmute]: "Bildirim seslerini aç",
        [Translation.OpenChat]: "sohbet desteğini aç",
        [Translation.Error]: "Hata",
        [Translation.BlockedAttachmentMessage]:
            "Bu eki görüntülemek için lütfen tarayıcınızdaki açılır pencereleri etkinleştirin.",
        [Translation.RequestTranscriptButton]: "Transkript İste",
        [Translation.Cancel]: "İptal",
        [Translation.Submit]: "Onayla",
        [Translation.RequestTranscriptModalTitle]: "Transkript İste",
        [Translation.FormError]: "Devam etmek için lütfen formdaki hataları düzeltin",
        [Translation.EndChatModalMessage]: "Sohbeti bitirmek istediğinize emin misiniz?",
        [Translation.ViewChat]: "Sohbeti Göster",
        [Translation.Typing]: "Yazıyor",
        [Translation.ErrorInvalid]: "Geçersiz",
        [Translation.TranscriptRequested]: "Transkript Talep Edildi",
        [Translation.RequestTranscriptEmailLabel]: "E-post adresiniz",
        [Translation.Yes]: "Evet",
        [Translation.BlockedAttachmentTitle]: "Ek Blokelendi",
        [Translation.AgentIsTyping]: "Temsilci yazıyor...",
        [Translation.EndChatButton]: "Canlı Sohbeti Sonlandır",
        [Translation.SkipSurvey]: "Anketi Atla",
        [Translation.ErrorRequired]: "Gerekli",
        [Translation.NewChat]: "Yeni Sohbet",
        [Translation.GoToSurvey]: "Deneyiminizi değerlendirin",
        [Translation.LiveChat]: "Canlı Sohbet",
        [Translation.ChatInputPlaceholder]: "Mesajınız...",
        [Translation.Mute]: "Bildirim sesini kapat",
        [Translation.FinishedChatMoreOptions]: "Daha fazla seçenek",
        [Translation.EmailTranscript]: "E-posta Transkripti",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Yardım",
        [Translation.TooEarly]: "Geçersiz",
        [Translation.TooLate]: "Geçersiz",
        [Translation.TryingReconnect]: "Yeniden Bağlanıyor",
        [Translation.Back]: "Geri",
        [Translation.StartChat]: "Sohbeti başlat",
        [Translation.EndChatModalTitle]: "Sohbeti Sonlandır",
        [Translation.MinimiseWindow]: "Canlı Sohbet Penceresini Küçült",
        [Translation.PrintTranscriptButton]: "Transkripti Yazdır",
        [Translation.DownloadTranscriptButton]: "Transkripti İndir",
        [Translation.EmptySelect]: "Hiçbir şey seçilmedi",
        [Translation.NoItemsFound]: "Hiçbir sonuç bulunamadı",
        [Translation.TypeToSearch]: "Arama...",
    },
    [Language.Thai]: {
        [Translation.ChatEnded]: "แชทได้สิ้นสุดลงแล้ว",
        [Translation.ChangeLanguage]: "เปลี่ยนภาษา",
        [Translation.SurveyThanks]: "ขอบคุณสำหรับความคิดเห็นของคุณ",
        [Translation.Unmute]: "เปิดเสียงปิดเสียงเตือน",
        [Translation.OpenChat]: "เปิดการสนับสนุนการแชท",
        [Translation.Error]: "ความผิดพลาด",
        [Translation.BlockedAttachmentMessage]: "โปรดเปิดใช้งานป๊อปอัพในเบราว์เซอร์ของคุณเพื่อดูสิ่งที่แนบมานี้",
        [Translation.RequestTranscriptButton]: "Transcript อีเมล์",
        [Translation.Cancel]: "ยกเลิก",
        [Translation.Submit]: "เสนอ",
        [Translation.RequestTranscriptModalTitle]: "ที่ขอ Transcript",
        [Translation.FormError]: "โปรดแก้ไขข้อผิดพลาดในแบบฟอร์มเพื่อดำเนินการต่อ",
        [Translation.EndChatModalMessage]: "คุณแน่ใจหรือไม่ว่าต้องการที่จะจบการสนทนา?",
        [Translation.ViewChat]: "ดู Transcript",
        [Translation.Typing]: "การพิมพ์",
        [Translation.ErrorInvalid]: "โมฆะ",
        [Translation.TranscriptRequested]: "Transcript ขอ",
        [Translation.RequestTranscriptEmailLabel]: "ที่อยู่อีเมลของคุณ",
        [Translation.Yes]: "ใช่",
        [Translation.BlockedAttachmentTitle]: "สิ่งที่แนบมา ถูกบล็อก",
        [Translation.AgentIsTyping]: "เจ้าหน้าที่กำลังพิมพ์...",
        [Translation.EndChatButton]: "ปิดแชต",
        [Translation.SkipSurvey]: "ข้ามการสำรวจ",
        [Translation.ErrorRequired]: "จำเป็นต้องใช้",
        [Translation.NewChat]: "แชทใหม่",
        [Translation.GoToSurvey]: "เรทประสบการณ์ของคุณ",
        [Translation.LiveChat]: "แชทสด",
        [Translation.ChatInputPlaceholder]: "ข้อความของคุณ...",
        [Translation.Mute]: "ปิดเสียงการแจ้งเตือน",
        [Translation.FinishedChatMoreOptions]: "ข้อเลือกเพิ่มเติม",
        [Translation.EmailTranscript]: "Transcript อีเมล์",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "ช่วยด้วย",
        [Translation.TooEarly]: "โมฆะ",
        [Translation.TooLate]: "โมฆะ",
        [Translation.TryingReconnect]: "พยายามที่จะเชื่อมต่อ",
        [Translation.Back]: "กลับ",
        [Translation.StartChat]: "เริ่มแชทใหม่",
        [Translation.EndChatModalTitle]: "จบการพูดคุย",
        [Translation.MinimiseWindow]: "ปิดหน้าต่างแชต",
        [Translation.PrintTranscriptButton]: "พิมพ์ทรานสคริปต์",
        [Translation.DownloadTranscriptButton]: "ดาวน์โหลดทรานสคริปต์",
        [Translation.EmptySelect]: "ไม่ได้เลือกรายการใด",
        [Translation.NoItemsFound]: "ไม่พบผลการค้นหา",
        [Translation.TypeToSearch]: "ค้นหา...",
    },
    [Language.Bosnian]: {
        [Translation.ChatEnded]: "Razgovor je završen",
        [Translation.ChangeLanguage]: "Promijenite jezik",
        [Translation.SurveyThanks]: "Zahvaljujemo Vam na povratnoj informaciji",
        [Translation.Unmute]: "Uključi zvukove obavijesti",
        [Translation.OpenChat]: "Podrška za otvoreni chat",
        [Translation.Error]: "Pogreška",
        [Translation.BlockedAttachmentMessage]:
            "Molimo dopustite skočne prozore u Vašem izborniku da biste vidjeli privitak",
        [Translation.RequestTranscriptButton]: "Prijepis elektronske pošte",
        [Translation.Cancel]: "Otkazati",
        [Translation.Submit]: "Podnijeti",
        [Translation.RequestTranscriptModalTitle]: "Zahtjev za prijepisom",
        [Translation.FormError]: "Ispravite greške na obrascu da biste nastavili",
        [Translation.EndChatModalMessage]: "Da li ste sigurni da želite završiti razgovor?",
        [Translation.ViewChat]: "Pogledaj prijepis",
        [Translation.Typing]: "Tipkanje",
        [Translation.ErrorInvalid]: "Nevažeći",
        [Translation.TranscriptRequested]: "Prijepis zatražen",
        [Translation.RequestTranscriptEmailLabel]: "Vaša adresa elektronske pošte",
        [Translation.Yes]: "Da",
        [Translation.BlockedAttachmentTitle]: "Privici blokirani",
        [Translation.AgentIsTyping]: "Agent kuca...",
        [Translation.EndChatButton]: "Kraj chata",
        [Translation.SkipSurvey]: "Preskoči povratnu informaciju",
        [Translation.ErrorRequired]: "Potreban",
        [Translation.NewChat]: "Novi razgovor",
        [Translation.GoToSurvey]: "Ocijenite Vaše iskustvo",
        [Translation.LiveChat]: "Live Chat",
        [Translation.ChatInputPlaceholder]: "Vaša poruka...",
        [Translation.Mute]: "Priguši zvukove obavijesti",
        [Translation.FinishedChatMoreOptions]: "Više opcija",
        [Translation.EmailTranscript]: "Prijepis elektronske pošte",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Pomoć",
        [Translation.TooEarly]: "Nevažeći",
        [Translation.TooLate]: "Nevažeći",
        [Translation.TryingReconnect]: "Pokušavamo prespojiti",
        [Translation.Back]: "Natrag",
        [Translation.StartChat]: "Započni razgovor",
        [Translation.EndChatModalTitle]: "Završetak razgovora",
        [Translation.MinimiseWindow]: "Smanjite prozor za chat",
        [Translation.PrintTranscriptButton]: "Print Transcript",
        [Translation.DownloadTranscriptButton]: "Download Transcript",
        [Translation.EmptySelect]: "Nothing selected",
        [Translation.NoItemsFound]: "No results found",
        [Translation.TypeToSearch]: "Search...",
    },
    [Language.Serbian]: {
        [Translation.ChatEnded]: "Razgovor je završen",
        [Translation.ChangeLanguage]: "Promenite jezik",
        [Translation.SurveyThanks]: "Zahvaljujemo Vam na povratnoj informaciji",
        [Translation.Unmute]: "Uključi zvukove novosti",
        [Translation.OpenChat]: "Podrška za otvoreno časkanje",
        [Translation.Error]: "Pogreška",
        [Translation.BlockedAttachmentMessage]:
            "Molimo dopustite skočne prozore u Vašem izborniku da biste vidjeli privitak",
        [Translation.RequestTranscriptButton]: "Prijepis elektronske pošte",
        [Translation.Cancel]: "Otkazati",
        [Translation.Submit]: "Podnijeti",
        [Translation.RequestTranscriptModalTitle]: "Zahtjev za prijepisom",
        [Translation.FormError]: "Ispravite pogreške na obrascu da biste nastavili",
        [Translation.EndChatModalMessage]: "Da li ste sigurni da želite završiti razgovor?",
        [Translation.ViewChat]: "Pogledaj prijepis",
        [Translation.Typing]: "Tipkanje",
        [Translation.ErrorInvalid]: "Nevažeći",
        [Translation.TranscriptRequested]: "Prijepis zatražen",
        [Translation.RequestTranscriptEmailLabel]: "Vaša adresa elektronske pošte",
        [Translation.Yes]: "Da",
        [Translation.BlockedAttachmentTitle]: "Privici blokirani",
        [Translation.AgentIsTyping]: "Agent kuca...",
        [Translation.EndChatButton]: "Kraj časkanja",
        [Translation.SkipSurvey]: "Preskoči povratnu informaciju",
        [Translation.ErrorRequired]: "Potreban",
        [Translation.NewChat]: "Novi razgovor",
        [Translation.GoToSurvey]: "Ocenite Vaše iskustvo",
        [Translation.LiveChat]: "Ćaskanje uživo",
        [Translation.ChatInputPlaceholder]: "Vaša poruka...",
        [Translation.Mute]: "Priguši zvukove novosti",
        [Translation.FinishedChatMoreOptions]: "Više opcija",
        [Translation.EmailTranscript]: "Prijepis elektronske pošte",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Pomoć",
        [Translation.TooEarly]: "Nevažeći",
        [Translation.TooLate]: "Nevažeći",
        [Translation.TryingReconnect]: "Pokušavamo prespojiti",
        [Translation.Back]: "Natrag",
        [Translation.StartChat]: "Započni razgovor",
        [Translation.EndChatModalTitle]: "Završetak razgovora",
        [Translation.MinimiseWindow]: "Minimizirajte prozor za časkanje",
        [Translation.PrintTranscriptButton]: "Print Transcript",
        [Translation.DownloadTranscriptButton]: "Download Transcript",
        [Translation.EmptySelect]: "Nothing selected",
        [Translation.NoItemsFound]: "No results found",
        [Translation.TypeToSearch]: "Search...",
    },
    [Language.Croatian]: {
        [Translation.ChatEnded]: "Razgovor je završen",
        [Translation.ChangeLanguage]: "Promijenite jezik",
        [Translation.SurveyThanks]: "Zahvaljujemo Vam na povratnoj informaciji",
        [Translation.Unmute]: "Uključi zvukove obavijesti",
        [Translation.OpenChat]: "Podrška za otvoreni chat",
        [Translation.Error]: "Pogreška",
        [Translation.BlockedAttachmentMessage]:
            "Molimo dopustite skočne prozore u Vašem izborniku da biste vidjeli privitak",
        [Translation.RequestTranscriptButton]: "Prijepis elektronske pošte",
        [Translation.Cancel]: "Otkazati",
        [Translation.Submit]: "Podnijeti",
        [Translation.RequestTranscriptModalTitle]: "Zahtjev za prijepisom",
        [Translation.FormError]: "Ispravite greške na obrascu da biste nastavili",
        [Translation.EndChatModalMessage]: "Da li ste sigurni da želite završiti razgovor?",
        [Translation.ViewChat]: "Pogledaj prijepis",
        [Translation.Typing]: "Tipkanje",
        [Translation.ErrorInvalid]: "Nevažeći",
        [Translation.TranscriptRequested]: "Prijepis zatražen",
        [Translation.RequestTranscriptEmailLabel]: "Vaša adresa elektronske pošte",
        [Translation.Yes]: "Da",
        [Translation.BlockedAttachmentTitle]: "Privici blokirani",
        [Translation.AgentIsTyping]: "Agent piše...",
        [Translation.EndChatButton]: "Završetak chata",
        [Translation.SkipSurvey]: "Preskoči povratnu informaciju",
        [Translation.ErrorRequired]: "Potreban",
        [Translation.NewChat]: "Novi razgovor",
        [Translation.GoToSurvey]: "Ocijenite Vaše iskustvo",
        [Translation.LiveChat]: "Razgovor uživo",
        [Translation.ChatInputPlaceholder]: "Vaša poruka...",
        [Translation.Mute]: "Utišaj zvukove obavijesti",
        [Translation.FinishedChatMoreOptions]: "Više opcija",
        [Translation.EmailTranscript]: "Prijepis elektronske pošte",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Pomoć",
        [Translation.TooEarly]: "Nevažeći",
        [Translation.TooLate]: "Nevažeći",
        [Translation.TryingReconnect]: "Pokušavamo prespojiti",
        [Translation.Back]: "Natrag",
        [Translation.StartChat]: "Započni razgovor",
        [Translation.EndChatModalTitle]: "Završetak razgovora",
        [Translation.MinimiseWindow]: "Smanjite prozor za chat",
        [Translation.PrintTranscriptButton]: "Printaj prepisku",
        [Translation.DownloadTranscriptButton]: "Skini prepisku",
        [Translation.EmptySelect]: "Ništa nije odabrano",
        [Translation.NoItemsFound]: "Nisu pronađeni rezultati",
        [Translation.TypeToSearch]: "Pretražite...",
    },
    [Language.Romanian]: {
        [Translation.ChatEnded]: "Chat-ul s-a încheiat.",
        [Translation.ChangeLanguage]: "Modificați limba",
        [Translation.SurveyThanks]: "Vă mulțumim pentru feedback-ul dumneavoastră.",
        [Translation.Unmute]: "Se anulează sunetele de notificare",
        [Translation.OpenChat]: "Deschideți Chat Support",
        [Translation.Error]: "Eroare",
        [Translation.BlockedAttachmentMessage]:
            "Activați opțiunea pop-up în browserul dvs. pentru a vizualiza acest atașament.",
        [Translation.RequestTranscriptButton]: "Trimite transcrierea pe e-mail",
        [Translation.Cancel]: "Anulează",
        [Translation.Submit]: "Trimite",
        [Translation.RequestTranscriptModalTitle]: "Solicită transcrierea",
        [Translation.FormError]: "Vă rugăm să remediați erorile din formular pentru a continua.",
        [Translation.EndChatModalMessage]: "Sigur doriți să încheiați chatul?",
        [Translation.ViewChat]: "Vizualizează transcrierea",
        [Translation.Typing]: "Scrie",
        [Translation.ErrorInvalid]: "Invalid",
        [Translation.TranscriptRequested]: "Transcript Requested",
        [Translation.RequestTranscriptEmailLabel]: "Adresa dvs. de e-mail",
        [Translation.Yes]: "Da",
        [Translation.BlockedAttachmentTitle]: "Atașamente blocate",
        [Translation.AgentIsTyping]: "Agentul scrie...",
        [Translation.EndChatButton]: "Terminați  conversația",
        [Translation.SkipSurvey]: "Nu doresc să trimit Feedback",
        [Translation.ErrorRequired]: "Solicitat",
        [Translation.NewChat]: "Un nou Chat",
        [Translation.GoToSurvey]: "Evaluați-vă experiența",
        [Translation.LiveChat]: "Chat în Direct",
        [Translation.ChatInputPlaceholder]: "Mesajul dumneavoastră",
        [Translation.Mute]: "Sună notificarea mută",
        [Translation.FinishedChatMoreOptions]: "Mai multe opțiuni",
        [Translation.EmailTranscript]: "Trimite transcrierea pe e-mail",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Asistență",
        [Translation.TooEarly]: "Invalid",
        [Translation.TooLate]: "Invalid",
        [Translation.TryingReconnect]: "Încercăm să vă reconectăm",
        [Translation.Back]: "Înapoi",
        [Translation.StartChat]: "Incepe conversatia",
        [Translation.EndChatModalTitle]: "Termină Chat",
        [Translation.MinimiseWindow]: "Minimizați fereastra de conversație",
        [Translation.PrintTranscriptButton]: "Tipărire transcriere",
        [Translation.DownloadTranscriptButton]: "Descărcare transcriere",
        [Translation.EmptySelect]: "Nimic selectat",
        [Translation.NoItemsFound]: "Nu au fost găsite rezultate",
        [Translation.TypeToSearch]: "Căutare...",
    },
    [Language.Catalan]: {
        [Translation.ChatEnded]: "El xat ha finalitzat",
        [Translation.ChangeLanguage]: "Cambiar l'idioma",
        [Translation.SurveyThanks]: "Gràcies per la seva opinó",
        [Translation.Unmute]: "Activa els sons de notificació",
        [Translation.OpenChat]: "Iniciar el xat de suport",
        [Translation.Error]: "Error",
        [Translation.BlockedAttachmentMessage]: "Siusplau habiliti pop-ups al vostre navegador per veure aquest adjunt",
        [Translation.RequestTranscriptButton]: "Transcripció de correu electrònic",
        [Translation.Cancel]: "Cancelació",
        [Translation.Submit]: "Enviar",
        [Translation.RequestTranscriptModalTitle]: "Transcripció requerida",
        [Translation.FormError]: "Siusplau, solucioni els errors del formulari per a continuar",
        [Translation.EndChatModalMessage]: "Estàs segur de que vols finalitzar el xat?",
        [Translation.ViewChat]: "Veure transcripció",
        [Translation.Typing]: "Escrivint",
        [Translation.ErrorInvalid]: "Invàlid",
        [Translation.TranscriptRequested]: "Transcripció requerida",
        [Translation.RequestTranscriptEmailLabel]: "La seva direcció de correu electrònic",
        [Translation.Yes]: "Si",
        [Translation.BlockedAttachmentTitle]: "Adjunts blocats",
        [Translation.AgentIsTyping]: "L'agent esta escrivint",
        [Translation.EndChatButton]: "Finalitzar el xat",
        [Translation.SkipSurvey]: "Ignorar enquesta",
        [Translation.ErrorRequired]: "Requerit",
        [Translation.NewChat]: "Nou xat",
        [Translation.GoToSurvey]: "Valoreu la vostra experiència",
        [Translation.LiveChat]: "Xat en Directe",
        [Translation.ChatInputPlaceholder]: "El teu missatge",
        [Translation.Mute]: "Sona la notificació en silenciar",
        [Translation.FinishedChatMoreOptions]: "Més opcions",
        [Translation.EmailTranscript]: "Transcripció de correu electrònic",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Ajuda",
        [Translation.TooEarly]: "Invàlid",
        [Translation.TooLate]: "Invàlid",
        [Translation.TryingReconnect]: "Tractant de conectar de nou",
        [Translation.Back]: "Enrere",
        [Translation.StartChat]: "Inicia el xat",
        [Translation.EndChatModalTitle]: "Xat finalitzat",
        [Translation.MinimiseWindow]: "Minimitzar la finestra del xat",
        [Translation.PrintTranscriptButton]: "Imprimir la transcripció",
        [Translation.DownloadTranscriptButton]: "Descarregar la transcripció",
        [Translation.EmptySelect]: "No s’ha seleccionat res",
        [Translation.NoItemsFound]: "No s’han trobat resultats",
        [Translation.TypeToSearch]: "Cerca...",
    },
    [Language.Malay]: {
        [Translation.ChatEnded]: "Sembang telah berakhir",
        [Translation.ChangeLanguage]: "Tukar Bahasa",
        [Translation.SurveyThanks]: "Terima kasih atas maklum balas anda.",
        [Translation.Unmute]: "Nyahaktifkan bunyi pemberitahuan",
        [Translation.OpenChat]: "sokongan sembang terbuka",
        [Translation.Error]: "Ralat",
        [Translation.BlockedAttachmentMessage]: "Sila aktifkan timbulan di pelayar anda untuk melihat lampiran ini",
        [Translation.RequestTranscriptButton]: "Transkrip e-mel",
        [Translation.Cancel]: "Batalkan",
        [Translation.Submit]: "Hantar",
        [Translation.RequestTranscriptModalTitle]: "Meminta transkrip",
        [Translation.FormError]: "Sila perbaiki ralat dalam borang untuk meneruskan",
        [Translation.EndChatModalMessage]: "Adakah anda pasti mahu menamatkan sembang?",
        [Translation.ViewChat]: "Lihat transkrip",
        [Translation.Typing]: "Menaip",
        [Translation.ErrorInvalid]: "Tidak sah",
        [Translation.TranscriptRequested]: "Transkrip yang diminta",
        [Translation.RequestTranscriptEmailLabel]: "Alamat e-mel anda",
        [Translation.Yes]: "Ya",
        [Translation.BlockedAttachmentTitle]: "Lampiran dihalangi",
        [Translation.AgentIsTyping]: "Agen sedang menaip...",
        [Translation.EndChatButton]: "Tamatkan Perbualan",
        [Translation.SkipSurvey]: "Langkau maklum balas",
        [Translation.ErrorRequired]: "Dikehendaki",
        [Translation.NewChat]: "Sembang Baru",
        [Translation.GoToSurvey]: "Kadar pengalaman anda",
        [Translation.LiveChat]: "Sembang Langsung",
        [Translation.ChatInputPlaceholder]: "Mesej anda...",
        [Translation.Mute]: "Bunyi pemberitahuan bisu",
        [Translation.FinishedChatMoreOptions]: "Pilihan yang banyak",
        [Translation.EmailTranscript]: "Transkrip e-mel",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Bantuan",
        [Translation.TooEarly]: "Tidak sah",
        [Translation.TooLate]: "Tidak sah",
        [Translation.TryingReconnect]: "Cuba menyambung semula",
        [Translation.Back]: "Kembali",
        [Translation.StartChat]: "Mula sembang",
        [Translation.EndChatModalTitle]: "Tamat sembang",
        [Translation.MinimiseWindow]: "Kecilkan Tetingkap Perbualan",
        [Translation.PrintTranscriptButton]: "Cetak Transkrip",
        [Translation.DownloadTranscriptButton]: "Muat Turun Transkrip",
        [Translation.EmptySelect]: "Tiada satu pun dipilih",
        [Translation.NoItemsFound]: "Tiada hasil ditemui",
        [Translation.TypeToSearch]: "Cari...",
    },
    [Language.Finnish]: {
        [Translation.ChatEnded]: "Chatti on päättynyt.",
        [Translation.ChangeLanguage]: "Vaihda kieltä",
        [Translation.SurveyThanks]: "Kiitos palautteestasi",
        [Translation.Unmute]: "Ohita ilmoitusäänet",
        [Translation.OpenChat]: "avoin chat-tuki",
        [Translation.Error]: "Virhe",
        [Translation.BlockedAttachmentMessage]: "Nähdäksesi liitteen, sinun tulee sallia selaimessasi ponnahdusikkunat",
        [Translation.RequestTranscriptButton]: "Lähetä keskustelu sähköpostiin",
        [Translation.Cancel]: "Peru",
        [Translation.Submit]: "Lähetä",
        [Translation.RequestTranscriptModalTitle]: "Pyydä kopio sähköpostiin",
        [Translation.FormError]: "Korjaa lomakkeella olevat virheet voidaksesi jatkaa",
        [Translation.EndChatModalMessage]: "Oletko varma, että haluat lopettaa chatin?",
        [Translation.ViewChat]: "Katso keskustelu",
        [Translation.Typing]: "Kirjoittaa",
        [Translation.ErrorInvalid]: "Virheellinen",
        [Translation.TranscriptRequested]: "Pyyntö lähetetty",
        [Translation.RequestTranscriptEmailLabel]: "Sähköpostiosoitteesi",
        [Translation.Yes]: "Kyllä",
        [Translation.BlockedAttachmentTitle]: "Liitteet estetty",
        [Translation.AgentIsTyping]: "Asiakaspalvelija kirjoittaa...",
        [Translation.EndChatButton]: "Lopeta chatti",
        [Translation.SkipSurvey]: "Ohita palaute",
        [Translation.ErrorRequired]: "Pakollinen",
        [Translation.NewChat]: "Uusi chatti",
        [Translation.GoToSurvey]: "Arvostele kokemuksesi",
        [Translation.LiveChat]: "Live Chat",
        [Translation.ChatInputPlaceholder]: "Viestisi...",
        [Translation.Mute]: "Mykistä ilmoitus kuulostaa",
        [Translation.FinishedChatMoreOptions]: "Lisää vaihtoehtoja",
        [Translation.EmailTranscript]: "Lähetä keskustelu sähköpostiin",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Tuki",
        [Translation.TooEarly]: "Virheellinen",
        [Translation.TooLate]: "Virheellinen",
        [Translation.TryingReconnect]: "Yritetään muodostaa uutta yhteyttä",
        [Translation.Back]: "Takaisin",
        [Translation.StartChat]: "Aloita chatti",
        [Translation.EndChatModalTitle]: "Lopeta chatti",
        [Translation.MinimiseWindow]: "Pienennä chatti-ikkuna",
        [Translation.PrintTranscriptButton]: "Tulosta selostus",
        [Translation.DownloadTranscriptButton]: "Lataa selostus",
        [Translation.EmptySelect]: "Mitään ei ole valittu",
        [Translation.NoItemsFound]: "Tuloksia ei löytynyt",
        [Translation.TypeToSearch]: "Hae...",
    },
    [Language.Japanese]: {
        [Translation.ChatEnded]: "チャットは終了しました。",
        [Translation.ChangeLanguage]: "言語の変更",
        [Translation.SurveyThanks]: "ご意見をいただきありがとうございます。",
        [Translation.Unmute]: "ミュート解除通知音",
        [Translation.OpenChat]: "オープンチャットサポート",
        [Translation.Error]: "エラー",
        [Translation.BlockedAttachmentMessage]:
            "この添付ファイルを表示するには、ブラウザのポップアップを有効にしてください。",
        [Translation.RequestTranscriptButton]: "メールトランスクリプト",
        [Translation.Cancel]: "キャンセル",
        [Translation.Submit]: "提出する",
        [Translation.RequestTranscriptModalTitle]: "トランスクリプトを要求",
        [Translation.FormError]: "フォームのエラーを修正してから続けてください",
        [Translation.EndChatModalMessage]: "チャットを終了しますか",
        [Translation.ViewChat]: "トランスクリプトを見る",
        [Translation.Typing]: "タイピング",
        [Translation.ErrorInvalid]: "無効",
        [Translation.TranscriptRequested]: "要求された成績証明書",
        [Translation.RequestTranscriptEmailLabel]: "メールアドレス",
        [Translation.Yes]: "はい",
        [Translation.BlockedAttachmentTitle]: "ブロックされた添付ファイル",
        [Translation.AgentIsTyping]: "エージェントが入力しています...",
        [Translation.EndChatButton]: "チャットの終了",
        [Translation.SkipSurvey]: "フィードバックをスキップ",
        [Translation.ErrorRequired]: "必須",
        [Translation.NewChat]: "新しいチャット",
        [Translation.GoToSurvey]: "あなたの経験を評価する",
        [Translation.LiveChat]: "ライブチャット",
        [Translation.ChatInputPlaceholder]: "あなたのメッセージ...",
        [Translation.Mute]: "ミュート通知音",
        [Translation.FinishedChatMoreOptions]: "より多くのオプション",
        [Translation.EmailTranscript]: "メールトランスクリプト",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "チャットヘルプ",
        [Translation.TooEarly]: "無効",
        [Translation.TooLate]: "無効",
        [Translation.TryingReconnect]: "再接続しようとしています",
        [Translation.Back]: "戻り",
        [Translation.StartChat]: "チャットを始める",
        [Translation.EndChatModalTitle]: "チャットを終了",
        [Translation.MinimiseWindow]: "チャットウィンドウの最小化",
        [Translation.PrintTranscriptButton]: "チャットの内容を印刷",
        [Translation.DownloadTranscriptButton]: "チャットの内容をダウンロード",
        [Translation.EmptySelect]: "選択していません。",
        [Translation.NoItemsFound]: "該当するものがありません。",
        [Translation.TypeToSearch]: "検索中・・・",
    },
    [Language.Korean]: {
        [Translation.ChatEnded]: "채팅이 종료 되었습니다.",
        [Translation.ChangeLanguage]: "언어 변경",
        [Translation.SurveyThanks]: "설문에 참여해 주셔서 감사합니다.",
        [Translation.Unmute]: "음소거 해제 알림 음",
        [Translation.OpenChat]: "열린 채팅 지원",
        [Translation.Error]: "오류",
        [Translation.BlockedAttachmentMessage]: "이 첨부 파일을 보려면 브라우저에서 팝업을 활성화하십시오.",
        [Translation.RequestTranscriptButton]: "이메일 기록",
        [Translation.Cancel]: "취소",
        [Translation.Submit]: "제출",
        [Translation.RequestTranscriptModalTitle]: "기록 요청하기",
        [Translation.FormError]: "양식에서 오류를 바로잡은 후 계속해주세요",
        [Translation.EndChatModalMessage]: "정말로 채팅을 끝내시겠습니까?",
        [Translation.ViewChat]: "채팅 기록(대화록) 보기",
        [Translation.Typing]: "입력중",
        [Translation.ErrorInvalid]: "유효하지 않음",
        [Translation.TranscriptRequested]: "기록 요청됨",
        [Translation.RequestTranscriptEmailLabel]: "고객님의 이메일 주소",
        [Translation.Yes]: "네",
        [Translation.BlockedAttachmentTitle]: "첨부 파일 거절됨",
        [Translation.AgentIsTyping]: "담당자가 입력 중...",
        [Translation.EndChatButton]: "상담 종료",
        [Translation.SkipSurvey]: "설문 건너뛰기",
        [Translation.ErrorRequired]: "요구됨",
        [Translation.NewChat]: "새로운 채팅",
        [Translation.GoToSurvey]: "고객님의 경험을 평가 해 주시겠습니까?",
        [Translation.LiveChat]: "라이브 채팅",
        [Translation.ChatInputPlaceholder]: "내 메시지...",
        [Translation.Mute]: "알림 음소거",
        [Translation.FinishedChatMoreOptions]: "옵션 더 보기",
        [Translation.EmailTranscript]: "이메일 기록",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "도움",
        [Translation.TooEarly]: "유효하지 않음",
        [Translation.TooLate]: "유효하지 않음",
        [Translation.TryingReconnect]: "연결을 다시 시도하고 있습니다.",
        [Translation.Back]: "뒤로 가기",
        [Translation.StartChat]: "채팅 시작하기",
        [Translation.EndChatModalTitle]: "채팅 종료",
        [Translation.MinimiseWindow]: "상담창 최소화",
        [Translation.PrintTranscriptButton]: "채팅 내용 인쇄",
        [Translation.DownloadTranscriptButton]: "채팅 내용 다운로드",
        [Translation.EmptySelect]: "선택된 항목 없음",
        [Translation.NoItemsFound]: "결과를 찾을 수 없음",
        [Translation.TypeToSearch]: "검색...",
    },
    [Language.Chinese]: {
        [Translation.ChatEnded]: "聊天已经结束。",
        [Translation.ChangeLanguage]: "變更語言",
        [Translation.SurveyThanks]: "感谢您的反馈意见。",
        [Translation.Unmute]: "取消靜音通知聲音",
        [Translation.OpenChat]: "打开聊天支持",
        [Translation.Error]: "错误",
        [Translation.BlockedAttachmentMessage]: "请启用弹出式窗口在浏览器中查看该附件。",
        [Translation.RequestTranscriptButton]: "电子邮件成绩单",
        [Translation.Cancel]: "取消",
        [Translation.Submit]: "提交",
        [Translation.RequestTranscriptModalTitle]: "申请成绩单",
        [Translation.FormError]: "請修正表格上的錯誤以繼續",
        [Translation.EndChatModalMessage]: "你确定你想结束聊天？",
        [Translation.ViewChat]: "观看文稿",
        [Translation.Typing]: "打字",
        [Translation.ErrorInvalid]: "无效",
        [Translation.TranscriptRequested]: "成绩单请求",
        [Translation.RequestTranscriptEmailLabel]: "您的电子邮件地址",
        [Translation.Yes]: "是",
        [Translation.BlockedAttachmentTitle]: "附件阻止",
        [Translation.AgentIsTyping]: "專員正在輸入……",
        [Translation.EndChatButton]: "結束聊天",
        [Translation.SkipSurvey]: "跳过反馈",
        [Translation.ErrorRequired]: "需要",
        [Translation.NewChat]: "新的聊天室",
        [Translation.GoToSurvey]: "评价你的经验",
        [Translation.LiveChat]: "在线聊天",
        [Translation.ChatInputPlaceholder]: "您的訊息……",
        [Translation.Mute]: "靜音通知聲音",
        [Translation.FinishedChatMoreOptions]: "更多的选择",
        [Translation.EmailTranscript]: "电子邮件成绩单",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "救命",
        [Translation.TooEarly]: "无效",
        [Translation.TooLate]: "无效",
        [Translation.TryingReconnect]: "正尝试重新连接",
        [Translation.Back]: "背部",
        [Translation.StartChat]: "开始聊天",
        [Translation.EndChatModalTitle]: "结束聊天",
        [Translation.MinimiseWindow]: "將聊天視窗最小化",
        [Translation.PrintTranscriptButton]: "打印脚本",
        [Translation.DownloadTranscriptButton]: "下载脚本",
        [Translation.EmptySelect]: "未选择任何内容",
        [Translation.NoItemsFound]: "未找到任何结果",
        [Translation.TypeToSearch]: "搜索...",
    },
    [Language.ChineseTaiwan]: {
        [Translation.ChatEnded]: "聊天已经结束。",
        [Translation.ChangeLanguage]: "變更語言",
        [Translation.SurveyThanks]: "感谢您的反馈意见。",
        [Translation.Unmute]: "取消靜音通知聲音",
        [Translation.OpenChat]: "打開聊天支持",
        [Translation.Error]: "错误",
        [Translation.BlockedAttachmentMessage]: "请启用弹出式窗口在浏览器中查看该附件。",
        [Translation.RequestTranscriptButton]: "电子邮件成绩单",
        [Translation.Cancel]: "取消",
        [Translation.Submit]: "提交",
        [Translation.RequestTranscriptModalTitle]: "申请成绩单",
        [Translation.FormError]: "請修正表格上的錯誤即可繼續",
        [Translation.EndChatModalMessage]: "你确定你想结束聊天？",
        [Translation.ViewChat]: "观看文稿",
        [Translation.Typing]: "打字",
        [Translation.ErrorInvalid]: "无效",
        [Translation.TranscriptRequested]: "成绩单请求",
        [Translation.RequestTranscriptEmailLabel]: "您的电子邮件地址",
        [Translation.Yes]: "是",
        [Translation.BlockedAttachmentTitle]: "附件阻止",
        [Translation.AgentIsTyping]: "服務人員正在輸入……",
        [Translation.EndChatButton]: "結束交談",
        [Translation.SkipSurvey]: "跳过反馈",
        [Translation.ErrorRequired]: "需要",
        [Translation.NewChat]: "新的聊天室",
        [Translation.GoToSurvey]: "评价你的经验",
        [Translation.LiveChat]: "即時聊天",
        [Translation.ChatInputPlaceholder]: "您的訊息……",
        [Translation.Mute]: "靜音通知聲音",
        [Translation.FinishedChatMoreOptions]: "更多的选择",
        [Translation.EmailTranscript]: "电子邮件成绩单",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "救命",
        [Translation.TooEarly]: "无效",
        [Translation.TooLate]: "无效",
        [Translation.TryingReconnect]: "正尝试重新连接",
        [Translation.Back]: "背部",
        [Translation.StartChat]: "开始聊天",
        [Translation.EndChatModalTitle]: "结束聊天",
        [Translation.MinimiseWindow]: "將聊天室視窗最小化",
        [Translation.PrintTranscriptButton]: "列印聊天內容",
        [Translation.DownloadTranscriptButton]: "下載聊天內容",
        [Translation.EmptySelect]: "沒有選取項目",
        [Translation.NoItemsFound]: "無結果",
        [Translation.TypeToSearch]: "搜尋...",
    },
    [Language.Danish]: {
        [Translation.ChatEnded]: "Chat er afsluttet",
        [Translation.ChangeLanguage]: "Ændr sprog",
        [Translation.SurveyThanks]: "Tak for din tilbagemelding",
        [Translation.Unmute]: "Dæmp underretningslyde",
        [Translation.OpenChat]: "åben chat support",
        [Translation.Error]: "Forkert",
        [Translation.BlockedAttachmentMessage]: "Tillad popup-vinduer i din browser for at se vedhæftet fil.",
        [Translation.RequestTranscriptButton]: "Kopi af e-mail",
        [Translation.Cancel]: "Afbestille",
        [Translation.Submit]: "Indsend",
        [Translation.RequestTranscriptModalTitle]: "Anmod om en kopi",
        [Translation.FormError]: "Ret venligst fejlene i formularen for at fortsætte",
        [Translation.EndChatModalMessage]: "Er du sikker på, at du vil afslutte chatten?",
        [Translation.ViewChat]: "Se kopi",
        [Translation.Typing]: "skriver",
        [Translation.ErrorInvalid]: "Ikke gyldig",
        [Translation.TranscriptRequested]: "Forespørgsel sendt",
        [Translation.RequestTranscriptEmailLabel]: "Din e-mail adresse",
        [Translation.Yes]: "Ja",
        [Translation.BlockedAttachmentTitle]: "Vedhæftet fil blokeret",
        [Translation.AgentIsTyping]: "Medarbejder skriver ...",
        [Translation.EndChatButton]: "Afslut chat",
        [Translation.SkipSurvey]: "Spring over tibagemelding",
        [Translation.ErrorRequired]: "Nødvendigt",
        [Translation.NewChat]: "Ny chat",
        [Translation.GoToSurvey]: "Overvej din oplevelse",
        [Translation.LiveChat]: "Livechat",
        [Translation.ChatInputPlaceholder]: "Din besked ...",
        [Translation.Mute]: "Lyd om lydlyd",
        [Translation.FinishedChatMoreOptions]: "flere muligheder",
        [Translation.EmailTranscript]: "Send kopi via e-mail",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Hjælp",
        [Translation.TooEarly]: "Ikke gyldig",
        [Translation.TooLate]: "Ikke gyldig",
        [Translation.TryingReconnect]: "Forsøger at genoprette forbindelse",
        [Translation.Back]: "tilbage",
        [Translation.StartChat]: "chat med os",
        [Translation.EndChatModalTitle]: "Afslut chat",
        [Translation.MinimiseWindow]: "Minimér chatvindue",
        [Translation.PrintTranscriptButton]: "Udskriv kopi",
        [Translation.DownloadTranscriptButton]: "Download kopi",
        [Translation.EmptySelect]: "Intet valgt",
        [Translation.NoItemsFound]: "Ingen resultater fundet",
        [Translation.TypeToSearch]: "Søg...",
    },
    [Language.Slovenian]: {
        [Translation.ChatEnded]: "Prekinitev spletnega pogovora",
        [Translation.ChangeLanguage]: "Spremeni jezik",
        [Translation.SurveyThanks]: "Hvala za Vaše mnenje",
        [Translation.Unmute]: "Vklopi se zvok obvestila",
        [Translation.OpenChat]: "odprta podpora za klepet",
        [Translation.Error]: "Napaka",
        [Translation.BlockedAttachmentMessage]: "Prosimo, če omogočite piškotke na Vašem spletnem brskalniku",
        [Translation.RequestTranscriptButton]: "Prepis spletne pošte",
        [Translation.Cancel]: "Razveljavi",
        [Translation.Submit]: "Potrdi",
        [Translation.RequestTranscriptModalTitle]: "Zahtevaj prepis",
        [Translation.FormError]: "Če želite nadaljevati, popravite napake v obrazcu",
        [Translation.EndChatModalMessage]: "Ali ste prepričani, da hočete prekiniti Vaš spletni pogovor",
        [Translation.ViewChat]: "Poglej prepis",
        [Translation.Typing]: "Tipkam",
        [Translation.ErrorInvalid]: "Neveljavno",
        [Translation.TranscriptRequested]: "Zahtevan prepis",
        [Translation.RequestTranscriptEmailLabel]: "Naslov spletne pošte",
        [Translation.Yes]: "Da",
        [Translation.BlockedAttachmentTitle]: "Neveljavna priponka",
        [Translation.AgentIsTyping]: "Zastopnik vnaša odgovor ...",
        [Translation.EndChatButton]: "Končaj klepet",
        [Translation.SkipSurvey]: "Preskoči",
        [Translation.ErrorRequired]: "Obvezno",
        [Translation.NewChat]: "Novi spletni pogovor",
        [Translation.GoToSurvey]: "Ocenite Vašo izkušnjo",
        [Translation.LiveChat]: "Klepet v živo",
        [Translation.ChatInputPlaceholder]: "Vaše sporočilo ...",
        [Translation.Mute]: "Izključi zvok obvestila",
        [Translation.FinishedChatMoreOptions]: "Dodatno",
        [Translation.EmailTranscript]: "Prepis spletne pošte",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Pomoč",
        [Translation.TooEarly]: "Neveljavno",
        [Translation.TooLate]: "Neveljavno",
        [Translation.TryingReconnect]: "Vzpostavljam povezavo",
        [Translation.Back]: "Nazaj",
        [Translation.StartChat]: "Začnite spletni pogovor",
        [Translation.EndChatModalTitle]: "Prekini spletni pogovor",
        [Translation.MinimiseWindow]: "Pomanjšaj okno za klepet",
        [Translation.PrintTranscriptButton]: "Print Transcript",
        [Translation.DownloadTranscriptButton]: "Download Transcript",
        [Translation.EmptySelect]: "Nothing selected",
        [Translation.NoItemsFound]: "No results found",
        [Translation.TypeToSearch]: "Search...",
    },
    [Language.Finnish]: {
        [Translation.ChatEnded]: "Chatti on päättynyt.",
        [Translation.ChangeLanguage]: "Vaihda kieltä",
        [Translation.SurveyThanks]: "Kiitos palautteestasi",
        [Translation.Unmute]: "Ohita ilmoitusäänet",
        [Translation.OpenChat]: "avoin chat-tuki",
        [Translation.Error]: "Virhe",
        [Translation.BlockedAttachmentMessage]: "Nähdäksesi liitteen, sinun tulee sallia selaimessasi ponnahdusikkunat",
        [Translation.RequestTranscriptButton]: "Lähetä keskustelu sähköpostiin",
        [Translation.Cancel]: "Peru",
        [Translation.Submit]: "Lähetä",
        [Translation.RequestTranscriptModalTitle]: "Pyydä kopio sähköpostiin",
        [Translation.FormError]: "Korjaa lomakkeella olevat virheet voidaksesi jatkaa",
        [Translation.EndChatModalMessage]: "Oletko varma, että haluat lopettaa chatin?",
        [Translation.ViewChat]: "Katso keskustelu",
        [Translation.Typing]: "Kirjoittaa",
        [Translation.ErrorInvalid]: "Virheellinen",
        [Translation.TranscriptRequested]: "Pyyntö lähetetty",
        [Translation.RequestTranscriptEmailLabel]: "Sähköpostiosoitteesi",
        [Translation.Yes]: "Kyllä",
        [Translation.BlockedAttachmentTitle]: "Liitteet estetty",
        [Translation.AgentIsTyping]: "Asiakaspalvelija kirjoittaa...",
        [Translation.EndChatButton]: "Lopeta chatti",
        [Translation.SkipSurvey]: "Ohita palaute",
        [Translation.ErrorRequired]: "Pakollinen",
        [Translation.NewChat]: "Uusi chatti",
        [Translation.GoToSurvey]: "Arvostele kokemuksesi",
        [Translation.LiveChat]: "Live Chat",
        [Translation.ChatInputPlaceholder]: "Viestisi...",
        [Translation.Mute]: "Mykistä ilmoitus kuulostaa",
        [Translation.FinishedChatMoreOptions]: "Lisää vaihtoehtoja",
        [Translation.EmailTranscript]: "Lähetä keskustelu sähköpostiin",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Tuki",
        [Translation.TooEarly]: "Virheellinen",
        [Translation.TooLate]: "Virheellinen",
        [Translation.TryingReconnect]: "Yritetään muodostaa uutta yhteyttä",
        [Translation.Back]: "Takaisin",
        [Translation.StartChat]: "Aloita chatti",
        [Translation.EndChatModalTitle]: "Lopeta chatti",
        [Translation.MinimiseWindow]: "Pienennä chatti-ikkuna",
        [Translation.PrintTranscriptButton]: "Tulosta selostus",
        [Translation.DownloadTranscriptButton]: "Lataa selostus",
        [Translation.EmptySelect]: "Mitään ei ole valittu",
        [Translation.NoItemsFound]: "Tuloksia ei löytynyt",
        [Translation.TypeToSearch]: "Hae...",
    },
    [Language.Czech]: {
        [Translation.ChatEnded]: "Chat skončil.",
        [Translation.ChangeLanguage]: "Změnit jazyk",
        [Translation.SurveyThanks]: "Děkujeme za vaši zpětnou vazbu.",
        [Translation.Unmute]: "Zazní zvuky upozornění",
        [Translation.OpenChat]: "otevřená podpora chatu",
        [Translation.Error]: "Chyba",
        [Translation.BlockedAttachmentMessage]:
            "Chcete-li zobrazit tuto přílohu, povolte ve svém prohlížeči vyskakovací okna.",
        [Translation.RequestTranscriptButton]: "Poslat přepis e-mailem",
        [Translation.Cancel]: "Zrušit",
        [Translation.Submit]: "Odeslat",
        [Translation.RequestTranscriptModalTitle]: "Vyžádat si přepis",
        [Translation.FormError]: "Chcete-li pokračovat, opravte chyby ve formuláři",
        [Translation.EndChatModalMessage]: "Opravdu chcete tento chat ukončit?",
        [Translation.ViewChat]: "Zobrazit přepis",
        [Translation.Typing]: "píšu",
        [Translation.ErrorInvalid]: "Neplatné",
        [Translation.TranscriptRequested]: "Přepis vyžádán",
        [Translation.RequestTranscriptEmailLabel]: "Vaše e-mailová adresa",
        [Translation.Yes]: "Ano",
        [Translation.BlockedAttachmentTitle]: "Přílohy blokovány",
        [Translation.AgentIsTyping]: "Zprostředkovatel píše...",
        [Translation.EndChatButton]: "Ukončit chat",
        [Translation.SkipSurvey]: "Přeskočit zpětnou vazbu",
        [Translation.ErrorRequired]: "Vyžadováno",
        [Translation.NewChat]: "Nový chat",
        [Translation.GoToSurvey]: "Ohodnoťte svou zkušenost",
        [Translation.LiveChat]: "Živý chat",
        [Translation.ChatInputPlaceholder]: "Vaše zpráva...",
        [Translation.Mute]: "Ztlumit zvuky upozornění",
        [Translation.FinishedChatMoreOptions]: "Více možností",
        [Translation.EmailTranscript]: "Poslat přepis e-mailem",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Pomoc",
        [Translation.TooEarly]: "Neplatné",
        [Translation.TooLate]: "Neplatné",
        [Translation.TryingReconnect]: "Pokouším se o nové spojení",
        [Translation.Back]: "Zpět",
        [Translation.StartChat]: "Zahájit chat",
        [Translation.EndChatModalTitle]: "Ukončit chat",
        [Translation.MinimiseWindow]: "Minimalizovat okno chatu",
        [Translation.PrintTranscriptButton]: "Vytisknout přepis",
        [Translation.DownloadTranscriptButton]: "Stáhnout přepis",
        [Translation.EmptySelect]: "Nic nebylo vybráno",
        [Translation.NoItemsFound]: "Nebyly nalezeny žádné výsledky",
        [Translation.TypeToSearch]: "Vyhledávání...",
    },
    [Language.Indonesian]: {
        [Translation.ChatEnded]: "Obrolan telah berakhir.",
        [Translation.ChangeLanguage]: "Ganti Bahasa",
        [Translation.SurveyThanks]: "Terima kasih atas umpan balik Anda.",
        [Translation.Unmute]: "Suarakan suara notifikasi",
        [Translation.OpenChat]: "buka dukungan obrolan",
        [Translation.Error]: "Eror",
        [Translation.BlockedAttachmentMessage]: "Harap aktifkan munculan di peramban Anda untuk melihat lampiran ini.",
        [Translation.RequestTranscriptButton]: "Kirim Transkripsi ke Surel",
        [Translation.Cancel]: "Batalkan",
        [Translation.Submit]: "Kirimkan",
        [Translation.RequestTranscriptModalTitle]: "Minta Transkripsi",
        [Translation.FormError]: "Harap perbaiki kesalahan pada formulir untuk melanjutkan",
        [Translation.EndChatModalMessage]: "Anda yakin ingin mengakhiri obrolan?",
        [Translation.ViewChat]: "Lihat Transkripsi",
        [Translation.Typing]: "mengetik",
        [Translation.ErrorInvalid]: "Tidak Benar",
        [Translation.TranscriptRequested]: "Transkripsi Telah Diminta",
        [Translation.RequestTranscriptEmailLabel]: "Alamat surel Anda",
        [Translation.Yes]: "Ya",
        [Translation.BlockedAttachmentTitle]: "Lampiran Diblokir",
        [Translation.AgentIsTyping]: "Agen sedang mengetik...",
        [Translation.EndChatButton]: "Akhiri Obrolan",
        [Translation.SkipSurvey]: "Lewatkan Umpan Balik",
        [Translation.ErrorRequired]: "Wajib",
        [Translation.NewChat]: "Obrolan Baru",
        [Translation.GoToSurvey]: "Beri Nilai Pengalaman Anda",
        [Translation.LiveChat]: "Percakapan Langsung",
        [Translation.ChatInputPlaceholder]: "Pesan Anda...",
        [Translation.Mute]: "Bisukan pemberitahuan berbunyi",
        [Translation.FinishedChatMoreOptions]: "Opsi Lainnya",
        [Translation.EmailTranscript]: "Kirim Transkripsi ke Surel",
        [Translation.CloseWindow]: "Close chat window",
        [Translation.Title]: "Bantuan",
        [Translation.TooEarly]: "Tidak Benar",
        [Translation.TooLate]: "Tidak Benar",
        [Translation.TryingReconnect]: "Mencoba menyambung kembali",
        [Translation.Back]: "Kembali",
        [Translation.StartChat]: "Mulai Obrolan",
        [Translation.EndChatModalTitle]: "Akhiri Obrolan",
        [Translation.MinimiseWindow]: "Minimalkan Jendela Obrolan",
        [Translation.PrintTranscriptButton]: "Cetak Transkrip",
        [Translation.DownloadTranscriptButton]: "Unduh Transkrip",
        [Translation.EmptySelect]: "Tidak ada yang dipilih",
        [Translation.NoItemsFound]: "Hasil pencarian tidak ditemukan",
        [Translation.TypeToSearch]: "Cari...",
    },
    [Language.Hungarian]: {
        [Translation.ChatEnded]: "A chat véget ért.",
        [Translation.ChangeLanguage]: "Válts nyelvet",
        [Translation.SurveyThanks]: "Köszönjük a visszajelzést.",
        [Translation.Unmute]: "Unmute hangértesítései",
        [Translation.OpenChat]: "Nyílt Chat Support",
        [Translation.Error]: "Hiba",
        [Translation.BlockedAttachmentMessage]:
            "Kérjük, engedélyezze a pop-up a böngészőben, hogy megtekinthesse ezt a mellékletet.",
        [Translation.RequestTranscriptButton]: "Email Transcript",
        [Translation.Cancel]: "Megszünteti",
        [Translation.Submit]: "Beküldés",
        [Translation.RequestTranscriptModalTitle]: "Request Transcript",
        [Translation.FormError]: "Kérjük, javítsa ki a hibákat az űrlap a folytatáshoz.",
        [Translation.EndChatModalMessage]: "Biztosan be szeretné fejezni a chat?",
        [Translation.ViewChat]: "Részletek Transcript",
        [Translation.Typing]: "gépelés",
        [Translation.ErrorInvalid]: "Érvénytelen",
        [Translation.TranscriptRequested]: "transcript megkeresett",
        [Translation.RequestTranscriptEmailLabel]: "az email címed",
        [Translation.Yes]: "Igen",
        [Translation.BlockedAttachmentTitle]: "Mellékletek Blokkolt",
        [Translation.AgentIsTyping]: "A szer gépelés ...",
        [Translation.EndChatButton]: "Csevegés vége",
        [Translation.SkipSurvey]: "Skip Visszajelzés",
        [Translation.ErrorRequired]: "Kívánt",
        [Translation.NewChat]: "új Chat",
        [Translation.GoToSurvey]: "Értékeld Te élményed",
        [Translation.LiveChat]: "Élő chat",
        [Translation.ChatInputPlaceholder]: "Az üzeneted...",
        [Translation.Mute]: "Mute hangértesítései",
        [Translation.FinishedChatMoreOptions]: "Több lehetőség",
        [Translation.EmailTranscript]: "E-mail átirat",
        [Translation.CloseWindow]: "Bezár chat ablak",
        [Translation.Title]: "Segítség",
        [Translation.TooEarly]: "Érvénytelen",
        [Translation.TooLate]: "Érvénytelen",
        [Translation.TryingReconnect]: "Újracsatlakozási",
        [Translation.Back]: "Vissza",
        [Translation.StartChat]: "Beszélgetés indítása",
        [Translation.EndChatModalTitle]: "Csevegés vége",
        [Translation.MinimiseWindow]: "Kis méret chat ablak",
        [Translation.PrintTranscriptButton]: "Nyomtassa átirat",
        [Translation.DownloadTranscriptButton]: "Letöltés átirat",
        [Translation.EmptySelect]: "Nincs semmi kiválasztva",
        [Translation.NoItemsFound]: "Nincs találat",
        [Translation.TypeToSearch]: "Keresés...",
    },
    [Language.Icelandic]: {
        [Translation.ChatEnded]: "Spjallinu er lokið.",
        [Translation.ChangeLanguage]: "Skipta um tungumál",
        [Translation.SurveyThanks]: "Takk fyrir álit þitt.",
        [Translation.Unmute]: "Kveikja á hljóði tilkynninga",
        [Translation.OpenChat]: "Opnaðu Chat Support",
        [Translation.Error]: "Villa",
        [Translation.BlockedAttachmentMessage]:
            "Vinsamlegast virkjaðu sprettiglugga í vafranum þínum til að skoða þetta viðhengi.",
        [Translation.RequestTranscriptButton]: "Tölvupóstafrit",
        [Translation.Cancel]: "Afbókun",
        [Translation.Submit]: "Sendu inn",
        [Translation.RequestTranscriptModalTitle]: "Óska eftir afriti",
        [Translation.FormError]: "Vinsamlegast lagfærðu villurnar á eyðublaðinu til að halda áfram.",
        [Translation.EndChatModalMessage]: "Ertu viss um að þú viljir hætta spjallinu?",
        [Translation.ViewChat]: "Skoða afrit",
        [Translation.Typing]: "vélritun",
        [Translation.ErrorInvalid]: "Ógilt",
        [Translation.TranscriptRequested]: "Uppskrift óskað",
        [Translation.RequestTranscriptEmailLabel]: "Netfangið þitt",
        [Translation.Yes]: "Já",
        [Translation.BlockedAttachmentTitle]: "Lokað fyrir viðhengi",
        [Translation.AgentIsTyping]: "Umboðsmaðurinn er að skrifa...",
        [Translation.EndChatButton]: "Ljúka spjalli",
        [Translation.SkipSurvey]: "Slepptu athugasemdum",
        [Translation.ErrorRequired]: "Áskilið",
        [Translation.NewChat]: "Nýtt spjall",
        [Translation.GoToSurvey]: "Gefðu reynslu þína einkunn",
        [Translation.LiveChat]: "Lifandi spjall",
        [Translation.ChatInputPlaceholder]: "Skilaboð þín...",
        [Translation.Mute]: "Þagga tilkynningarhljóð",
        [Translation.FinishedChatMoreOptions]: "Fleiri valkostir",
        [Translation.EmailTranscript]: "Tölvupóstafrit",
        [Translation.PrintTranscriptButton]: "Prenta afrit",
        [Translation.CloseWindow]: "Lokaðu spjallglugganum",
        [Translation.Title]: "Hjálp",
        [Translation.TooEarly]: "Of snemmt",
        [Translation.TooLate]: "Of seint",
        [Translation.TryingReconnect]: "Er að reyna að tengjast aftur",
        [Translation.Back]: "Til baka",
        [Translation.StartChat]: "Byrjaðu spjall",
        [Translation.EndChatModalTitle]: "Ljúka spjalli",
        [Translation.MinimiseWindow]: "Lágmarkaðu spjallglugga",
        [Translation.PrintTranscriptButton]: "Prenta afrit",
        [Translation.DownloadTranscriptButton]: "Sækja afrit",
        [Translation.EmptySelect]: "Ekkert valið",
        [Translation.NoItemsFound]: "Engar niðurstöður fundust",
        [Translation.TypeToSearch]: "Leita...",
    },
};

export default locales;

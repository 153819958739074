import { faWindowClose } from "@fortawesome/free-regular-svg-icons/faWindowClose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalContext from "../../context";
import { MODAL_CLASS } from "../modal.constants";
import { IProps } from "./close-button.types";

/**
 * Render a modal close button.
 * @returns A button element that will close a modal.
 */
const CloseButton = ({ label }: IProps) => (
    <ModalContext.Consumer>
        {(context) => (
            <button className={`${MODAL_CLASS}__close`} onClick={context.hideModal} aria-label={label}>
                <FontAwesomeIcon title="Close modal" icon={faWindowClose} />
            </button>
        )}
    </ModalContext.Consumer>
);

export default CloseButton;

import * as Sentry from "@sentry/react";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { IApplicationState } from "redux/types";
import { IRole } from "types-for/permissions/permissions.types";
import { getUserId } from "utilities/local-storage";

import actions from "./authentication-actions";

type IAuthenticationThunk<T = any> = ThunkAction<T, IApplicationState, void, AnyAction>;

/**
 * Add the user to the redux store and set the Sentry user.
 * @param userEmail The email address of the user signing in.
 * @param roleId    The ID that identifies the role of the user (agent, administrator etc.)
 */
export function signIn(userEmail: string, roleId: IRole["roleId"]): IAuthenticationThunk {
    return (dispatch) => {
        dispatch(actions.signIn({ userEmail, roleId }));
        const userId = getUserId();
        Sentry.setUser({ userId, role: roleId });
    };
}

/**
 * Remove the user from the redux store and clear the Sentry user.
 */
export function signOut(): IAuthenticationThunk {
    return (dispatch) => {
        dispatch(actions.signOut());
        Sentry.configureScope((scope) => scope.setUser(null));
    };
}

export default { ...actions, signIn, signOut };

import { Fragment } from "react";

import findSelectTranslation from "../../find-select-translation";
import { IProps } from "./select-options.types";

/**
 * Select options are used to display checkboxes, single/multi-select drop-downs, radio buttons etc. Each option has a
 * translation for the user's language provided by the backend. This component finds the correct translation and passes
 * it onto a child component to render.
 * @param props Props containing the select options and user language.
 * @returns     Child component.
 */
const SelectOptions = ({ children, languageId, selectOptions }: IProps) => (
    <Fragment>
        {selectOptions.map(({ selectOptionId, selectOptionTranslations }, index) =>
            children({
                index,
                label: findSelectTranslation(selectOptionTranslations, languageId),
                selectOptionId,
            })
        )}
    </Fragment>
);

export default SelectOptions;

import { FieldError } from "@edgetier/components";
import cx from "classnames";
import classNames from "classnames";
import { Fragment } from "react";

import MaximumLength from "../character-count";
import DynamicFormField from "../dynamic-form-field";
import ErrorFocus from "../error-focus";
import FieldTranslation from "../field-translation";
import { IProps } from "./dynamic-form-field-set.types";

const DynamicFormFieldSet = ({
    axiosInstance,
    autoFocusFirstField,
    fieldId,
    fields,
    childMappings,
    languageId,
    labelColour,
    values,
    selectedFieldId,
    onSelectField,
    isSingleField = false,
    isDisabled = false,
}: IProps) => {
    const formComponent = fields[fieldId];
    const noValue = !values[formComponent.fieldName] || !values[formComponent.fieldName].length;
    const childFields = childMappings[fieldId];

    return (
        <fieldset disabled={isDisabled} key={formComponent.fieldName}>
            <div
                className={classNames("field-container", {
                    "field-container--clickable": typeof onSelectField === "function",
                })}
                onClick={() => (typeof onSelectField === "function" ? onSelectField(formComponent) : undefined)}
            >
                <div
                    className="field"
                    style={typeof onSelectField === "function" ? { pointerEvents: "none" } : undefined}
                >
                    <FieldTranslation languageId={languageId} translationLookup={formComponent.translationLookup}>
                        {(translation) => (
                            <Fragment>
                                {!isSingleField && (
                                    <label htmlFor={formComponent.fieldName} style={{ color: labelColour }}>
                                        {translation.formFieldTranslation}
                                    </label>
                                )}

                                <FieldError name={formComponent.fieldName} />

                                {"maximumLength" in formComponent.configuration &&
                                    typeof formComponent.configuration.maximumLength === "number" && (
                                        <MaximumLength
                                            length={values[formComponent.fieldName].toString().length}
                                            maximumLength={formComponent.configuration.maximumLength}
                                        />
                                    )}

                                <DynamicFormField
                                    axiosInstance={axiosInstance}
                                    autoFocus={autoFocusFirstField}
                                    formField={formComponent}
                                    languageId={languageId}
                                    translation={translation}
                                />

                                <ErrorFocus />
                            </Fragment>
                        )}
                    </FieldTranslation>
                </div>
            </div>

            {Array.isArray(childFields) && childFields.length && (
                <div className={cx("form-children", { disabled: noValue })}>
                    {childFields.map((childFieldId) => (
                        <DynamicFormFieldSet
                            axiosInstance={axiosInstance}
                            key={childFieldId}
                            fieldId={childFieldId}
                            fields={fields}
                            childMappings={childMappings}
                            values={values}
                            languageId={languageId}
                            labelColour={labelColour}
                            selectedFieldId={selectedFieldId}
                            onSelectField={onSelectField}
                        />
                    ))}
                </div>
            )}
        </fieldset>
    );
};

export default DynamicFormFieldSet;

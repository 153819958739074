import { Spinner, useDelay } from "@edgetier/components";
import classNames from "classnames";

import Wrapper from "../wrapper";
import "./lazy-wrapper.scss";
import { ILazyModalProps, IProps } from "./lazy-wrapper.types";

/**
 * Redered inside a modal (by the wrapper) showing a spinner until the specified delay expires
 * Delay hook must be included here because the Wrapper component controls show/hide
 * @param delay    A delay in ms
 */
const LazyModal = function LazyModal({ delay, children }: ILazyModalProps) {
    const isReady = useDelay(delay);
    return (
        <div className={classNames("lazy-wrapper", { "lazy-wrapper--placeholder": !isReady })}>
            {isReady ? children : <Spinner />}
        </div>
    );
};

/**
 * Uses the normal wrapper but wraps the modal content in a lazy placeholder
 * Avoids stuttering transitions especially with complex dom structures.
 * @param delay    Optional, a delay in ms
 */
const LazyModalWrapper = ({ children, delay = 700, ...otherProps }: IProps) => (
    <Wrapper {...otherProps}>
        <LazyModal delay={delay}>{children}</LazyModal>
    </Wrapper>
);

export default LazyModalWrapper;

import { IActivity, IChatQuery, IChatState } from "redux/modules/chat/chat.types";

import actions from "../chat-actions";
import ActivityType from "constants/activity-type";
import { ChatEventType } from "@edgetier/types";

/**
 * Store one or more proposed chat activities. The backend decides whether the each set of proposed activities should
 * append or replace the existing ones for a given chat (the "append" property in the event payload).
 * @param state  Application state before storing a proposed activity.
 * @param action Action containing a payload of the proposed activity to store.
 * @returns      Chat state with new proposed activity
 */
export default function storeProposedActivity(
    state: IChatState,
    { payload }: ReturnType<typeof actions.storeProposedActivities>
): IChatState {
    const { append, chatToken } = payload;
    const chat = state.chats[chatToken];
    if (typeof chat === "undefined") {
        return state;
    }

    // All suggested chat messages already on screen.
    const existingMessages = getExistingMessages(chat);

    // Either append to or replace the existing proposed activities.
    const proposedActivities = append ? { ...chat.proposedActivities } : {};

    payload.proposedActivities.forEach((proposedActivity) => {
        const chatMessages = getChatMessagesText(proposedActivity.activitiesOnExecute);

        const isTimed = typeof proposedActivity.sendAfterSeconds === "number";
        const someMessagesExist = chatMessages.some((chatMessage) => existingMessages.includes(chatMessage));

        // Don't add another proposed message that is already visible.
        if (!someMessagesExist || isTimed) {
            proposedActivities[proposedActivity.proposedActivityId] = proposedActivity;
        }
    });

    const chats = { ...state.chats, [chatToken]: { ...chat, proposedActivities } };
    return { ...state, chats };
}

/**
 * Get the text of all proposed chat messages.
 * @param activitiesOnExecute Activities to execute which may include chat messages.
 * @returns Text from proposed chat messages.
 */
const getChatMessagesText = (activitiesOnExecute: IActivity[]) => {
    return activitiesOnExecute
        .map((activity) => {
            return activity.activityTypeId === ActivityType.ChatEvent &&
                activity.data.chatEventTypeId === ChatEventType.Message
                ? activity.data.message?.trim()
                : null;
        })
        .filter((text): text is string => typeof text === "string");
};

/**
 * Get all the active proposed chat messages for a given chat.
 * @param chat Active chat.
 * @returns Array of proposed chat messages.
 */
const getExistingMessages = (chat: IChatQuery) => {
    return Object.values(chat.proposedActivities).flatMap(({ activitiesOnExecute }) =>
        getChatMessagesText(activitiesOnExecute)
    );
};

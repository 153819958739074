import { Button } from "@edgetier/components";
import DynamicForm, { IFormValues } from "@edgetier/dynamic-form";
import Modal, { ModalContent, ModalHeader, useModal } from "@edgetier/modal";
import { DEFAULT_LANGUAGE_ID, IForm, Url } from "@edgetier/types";
import { getServerError } from "@edgetier/utilities";
import { faEdit, faSave } from "@fortawesome/pro-solid-svg-icons";
import { default as Axios } from "axios";
import InputError from "constants/input-error";
import useSimpleQuery from "queries/use-simple-query";
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { IFormSubmissionField } from "redux/application.types";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { IThunkDispatch } from "redux/types";
import ServerErrorModal, { IShowServerError } from "shared/modal/server-error-modal";
import urlJoin from "url-join";
import { hotToastOperations } from "utilities-for/toast";
import axios from "utilities/axios";
import { IProps } from "./edit-wrap-up-details.types";
import { getFormFieldValue } from "./edit-wrap-up-details.utilities";

/**
 * Edit the wrap-up details of an interaction.
 * @param props.formSubmissionId The ID of the form to get the form field initial values.
 * @param props.interaction      The interaction to edit.
 * @param props.interactionTypeId The ID of the interaction type.
 * @param props.skillId          The ID of the skill.
 */
const EditWrapUpDetails: FunctionComponent<IProps> = ({
    formSubmissionId,
    interaction,
    interactionTypeId,
    skillId,
}) => {
    const dispatch = useDispatch<IThunkDispatch>();
    const { data: formData } = useSimpleQuery<{ formSubmissionFields: IFormSubmissionField[] }>(
        urlJoin(Url.FormSubmissions, formSubmissionId.toString())
    );
    const queryClient = useQueryClient();
    const [wrapUpForm, setWrapUpForm] = useState<IForm>();
    const [isEditWrapUpFormSubmitting, setIsEditWrapUpFormSubmitting] = useState(false);
    const cancelTokenSource = useRef(Axios.CancelToken.source());

    useEffect(() => cancelTokenSource.current.cancel, []);

    const initialValues = useMemo((): Partial<IFormValues> | undefined => {
        return (
            formData?.formSubmissionFields.reduce((fields: Partial<IFormValues | any>, field) => {
                const { fieldName } = field.formField;
                const fieldValue = getFormFieldValue(field);

                fields[fieldName] = fieldValue;

                return fields;
            }, {}) || undefined
        );
    }, [formData]);

    const { closeModal, openModal, modalProps } = useModal();

    const submitButton = useMemo(
        () => (
            <Button
                icon={faSave}
                isLoading={isEditWrapUpFormSubmitting}
                disabled={isEditWrapUpFormSubmitting}
                type="submit"
            >
                Save
            </Button>
        ),
        [isEditWrapUpFormSubmitting]
    );

    const openWrapUpForm = useCallback(
        async (showServerError: IShowServerError): Promise<void> => {
            if (typeof wrapUpForm !== "undefined") {
                // Already have the wrap-up form so just display it.
                openModal();
            } else {
                dispatch(loadingBlockerOperations.showLoadingBlocker(true));
                try {
                    // Request the wrap-up form.
                    const { data } = await axios.get(Url.WrapUpForms, {
                        params: {
                            interactionDetailId: interaction.interactionDetailId,
                            interactionTypeId,
                            skillId: skillId,
                            setupId: "setupId" in interaction ? interaction.setupId : undefined,
                        },
                    });
                    const [wrapUpForm] = data.items;
                    setWrapUpForm(wrapUpForm);
                    openModal();
                } catch (serverError) {
                    showServerError(serverError);
                } finally {
                    dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
                }
            }
        },
        [dispatch, interaction, interactionTypeId, skillId, wrapUpForm, openModal]
    );

    const onEditWrapUpSubmit = useCallback(
        async (form: IFormValues, clearSubmitting: VoidFunction): Promise<void> => {
            dispatch(loadingBlockerOperations.showLoadingBlocker(true));
            setIsEditWrapUpFormSubmitting(true);

            try {
                // Ask the backend to update the wrap-up details.
                const configuration = { cancelToken: cancelTokenSource.current.token };

                const updatedFormFields = Object.keys(form).map((key) => ({
                    formFieldId: Number(key),
                    ...(Array.isArray(form[key]) ? { selectedOptionIds: form[key] || [] } : { value: form[key] || "" }),
                }));

                const { data } = await axios.patch(
                    urlJoin(Url.FormSubmissions, formSubmissionId.toString()),
                    { formSubmissionFields: updatedFormFields },
                    configuration
                );

                queryClient.setQueryData<IForm>(urlJoin(Url.FormSubmissions, formSubmissionId.toString()), () => {
                    return { ...data };
                });

                // Close the wrap-up details modal.
                hotToastOperations.showSuccessToast("Updated", "Successfully editted wrap-up details");
                closeModal();
            } catch (serverError) {
                if (Axios.isAxiosError(serverError)) {
                    hotToastOperations.showErrorToast("Error updating wrap-up details", getServerError(serverError));
                }
                return;
            } finally {
                dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
                setIsEditWrapUpFormSubmitting(false);
                clearSubmitting();
            }
        },
        [closeModal, dispatch, formSubmissionId, queryClient]
    );

    return (
        <ServerErrorModal>
            {(showServerError) => (
                <Fragment>
                    <Modal size="wide" {...modalProps}>
                        <ModalHeader>
                            <h2>Edit Wrap-Up Details</h2>
                        </ModalHeader>
                        <ModalContent>
                            {typeof wrapUpForm !== "undefined" && (
                                <DynamicForm
                                    axiosInstance={axios}
                                    autoFocusFirstField
                                    formComponents={wrapUpForm.formComponents}
                                    formId={formSubmissionId}
                                    languageId={DEFAULT_LANGUAGE_ID}
                                    initialValues={initialValues}
                                    invalidError={InputError.Invalid}
                                    onSubmit={({ form, clearSubmitting }) => onEditWrapUpSubmit(form, clearSubmitting)}
                                    requiredError={InputError.Required}
                                    submitButton={() => submitButton}
                                />
                            )}
                        </ModalContent>
                    </Modal>

                    <Button icon={faEdit} onClick={() => openWrapUpForm(showServerError)}>
                        Edit Details
                    </Button>
                </Fragment>
            )}
        </ServerErrorModal>
    );
};

export default EditWrapUpDetails;

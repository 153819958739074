import { formatDate as formatDateUtility } from "@edgetier/utilities";

/**
 * Format the date in an appropriate way for the field.
 * @param date The date to format.
 * @returns If the date is valid, the formatted date, otherwise, undefined.
 */
export const formatDate = (date: string | null | undefined) => {
    return typeof date === "string" ? formatDateUtility(new Date(date), { explicitFormat: "yyyy-MM-dd" }) : undefined;
};

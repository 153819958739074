import { doNothing } from "@edgetier/utilities";
import { useCallback } from "react";
import { useState } from "react";

import { IUseModal, UseModalConfiguration } from "./use-modal.types";

/**
 * A hook that is used to supply the Modal component with the props it needs. It also allows the user to use the modal
 * utilities in the same comopnent that the Modal is rendered in. E.g you can open/close the modal.
 * @param configuration.canClose A boolean indicating if the modal can be closed by the user.
 * @param configuration.isOpen   A boolean indicating if the modal is open.
 * @returns Modal utilitites that the calling components can use and props that will be passed to the Modal component.
 */
const useModal = ({
    canDismiss = true,
    canClose = true,
    isOpen: initialIsOpen = false,
    onCloseModal = doNothing,
}: UseModalConfiguration = {}): IUseModal => {
    const [isOpen, setIsOpen] = useState(initialIsOpen);
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
        onCloseModal();
    }, [onCloseModal]);

    return {
        closeModal,
        onCloseModal,
        openModal,
        isOpen,
        modalProps: { canClose, canDismiss, closeModal, onCloseModal, openModal, isOpen },
    };
};

export default useModal;

import { FunctionComponent } from "react";

import EllipsisMenuContext, { defaultContext } from "../ellipsis-menu-context/ellipsis-menu-context";
import { IProps } from "./ellipsis-menu-provider.types";

const EllipsisMenuProvider: FunctionComponent<IProps> = ({ children, context }) => (
    <EllipsisMenuContext.Provider value={{ ...defaultContext, ...context }}>{children}</EllipsisMenuContext.Provider>
);

export default EllipsisMenuProvider;

import { Matcher, screen } from "@testing-library/react";
import { UserEvent } from "@testing-library/user-event";

import clickMenuButton from "./get-menu-button";
import openSelectMenu from "./open-select-menu";

/**
 * Get the text of the labels in the menu.
 * @param options Optional configuration options.
 * @returns           Array of label text.
 */
const getSelectLabelsText = async (options?: { menuMatcher?: Matcher; userEventInstance: UserEvent }) => {
    let labels: (string | undefined)[] = [];
    await openSelectMenu({ menuMatcher: options?.menuMatcher, userEventInstance: options?.userEventInstance });
    labels = screen.getAllByRole("option").map(({ textContent }) => textContent?.trim());
    await clickMenuButton({ menuMatcher: options?.menuMatcher, userEventInstance: options?.userEventInstance });
    return labels;
};

export default getSelectLabelsText;

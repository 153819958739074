export enum TextLocalisationTooltip {
    JOINING_QUEUE = "Let the customer know they have entered the chat queue.",
    QUEUE_POSITION = "Let customers know how many are ahead of them in the chat queue.",
    NEXT_IN_QUEUE = "Tell customers they are at the top of the chat queue.",
    CONNECTED_TO_AGENT = "Chat status message informing a customer of the agent's name after connection.",
    FIRST_INACTIVE_WARNING = "First chat warning for an unresponsive customer.",
    SECOND_INACTIVE_WARNING = "Second chat warning for an unresponsive customer.",
    CHAT_TERMINATED_BY_INACTIVITY = "Third unresponsive customer message informing them that the chat has been ended.",
    AGENT_DISCONNECTED = "Chat status message informing a customer of an agent leaving a chat.",
    AGENT_ENDED_CHAT = "Customer chat message signalling the end of a chat by an agent.",
    CHAT_TRANSCRIPT_EMAIL_SUBJECT = "Subject line of a chat transcript email requested by a customer.",
    CHAT_TRANSCRIPT_EMAIL_HEADING = "Heading in a chat transcript email requested by a customer.",
    CHAT_TRANSCRIPT_EMAIL_INTRO = "Opening paragraph in a chat transcript email requested by a customer.",
    NO_AGENT_AVAILABLE = "Let a customer know the chat can't be started when no agents are available for the language.",
    OPENING_CHAT_MESSAGE = "Opening agent chat message to a customer.",
    OPENING_TRANSLATED_CHAT_MESSAGE = "Agent chat message informing a customer that the chat is being translated.",
    EMAIL_SURVEY_INTRO = "Email survey introduction paragraph.",
    EMAIL_SURVEY_LINK_MESSAGE = "Text for the link to start a survey from an email.",
    EMAIL_SURVEY_SUBJECT = "Subject line of a survey email sent to a customer.",
    EMAIL_SURVEY_WARNING = "Disclaimer for an email survey (e.g. inform a customer that it will not be possible to reply to any comments they leave in the survey).",
    EMAIL_SURVEY_DISCLAIMER = "Email survey disclaimer text.",
    GENDER_NEUTRAL_GREETING = "Neutral greeting when a customer's name is not known.",
    CHAT_QUEUE_OVER_LIMIT = "Message when a customer attempts to start a chat when the queue is beyond maximum capacity (if this is enabled).",
    WORD_AGENT = 'The word for "agent" as used in chat transcripts.',
    WORD_SYSTEM = 'The word for "system" as used in chat transcripts.',
    WORD_YOU = 'The word for "you" as used in chat transcripts.',
    FEATURE_UNAVAILABLE_MESSAGE = "Inform a customer that a feature is not available.",
    CHAT_ATTACHMENT_UNAVAILABLE_MESSAGE = "Message to reject a customer attachment if the feature is not enabled.",
    EXISTING_SUBMISSION_MESSAGE = "Message to a customer when they submit a second survey.",
    QUEUE_POSITION_WITH_TIME_ESTIMATION = "Let customers know how many are ahead of them in the chat queue and a time estimation of how long the wait could be.",
    ENTER_QUEUE_WELCOME_MESSAGE = "Prompt a customer for further information to speed up a chat.",
    FIRST_MESSAGE_BIRD_INACTIVE_WARNING = "First warning for an unresponsive customer on a MessageBird/WhatsApp chat.",
    MESSAGE_BIRD_CHAT_TERMINATED_BY_INACTIVITY = "Second unresponsive customer message informing them that the MessageBird/WhatsApp chat has been ended.",
}

export interface ITextLocalisation {
    readonly editable: boolean;
    readonly textLocalisation: string;
    readonly textLocalisationId: number;
}

export interface ITextLocalisationTranslation {
    readonly textLocalisationTranslationId: number;
    readonly textLocalisationId: number;
    readonly textLocalisationTranslation: string;
    readonly languageId: number;
}

export interface ITextLocalisationTranslationParameters {
    readonly languageId: number;
    readonly limit?: number;
}

import { useEffect } from "react";

import KeyboardShortcuts, { KeyboardShortcutScope } from "services/keyboard-shortcut-service";

import { IProps } from "./keyboard-shortcut.types";

/**
 * Add a new keyboard shortcut.
 * @param callback            The function that will run when the shortcut is executed.
 * @param scope               An optional scope in which the shortcut will be active.
 * @param setScopeWhileActive If true, will change the scope to the one provided while this shortcut is active.
 * @param shortcut            Keys for the shortcut in moustrap format.
 */
const KeyboardShortcut = ({
    callback,
    scope = KeyboardShortcutScope.Default,
    setScopeWhileActive = false,
    shortcut,
}: IProps) => {
    useEffect(() => {
        KeyboardShortcuts.addKeyboardShortcut(shortcut, callback, scope);
        if (setScopeWhileActive) KeyboardShortcuts.setScope(scope);
        return () => {
            KeyboardShortcuts.removeKeyboardShortcut(shortcut, scope);
            if (setScopeWhileActive) KeyboardShortcuts.resetScopeToLastActive();
        };
    }, [callback, scope, setScopeWhileActive, shortcut]);

    return null;
};

export default KeyboardShortcut;

import classNames from "classnames";
import { FunctionComponent, memo } from "react";

import "./client-navigation.scss";
import { IProps } from "./client-navigation.types";

/**
 * Primary navigation for an application.
 * @param props.children Navigation content.
 * @param props.logo     Application logo.
 * @returns              Navigation container.
 */
const ClientNavigation: FunctionComponent<IProps> = ({ children, logo }) => {
    return (
        <nav className="client-navigation">
            <div
                className={classNames("client-navigation__logo", {
                    "client-navigation__logo--inverted": process.env.NODE_ENV !== "production",
                })}
            >
                <img src={logo} alt="EdgeTier" />
            </div>

            <ul className="client-navigation__links">{children}</ul>
        </nav>
    );
};

export default memo(ClientNavigation);

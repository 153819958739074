import {
    DetailsFieldTitle,
    DetailsFieldValue,
    DetailsSection,
    DetailsSectionTitle,
    SplitTimestamp,
} from "@edgetier/client-components";
import { SpinnerUntil } from "@edgetier/components";
import { FormFieldType, Permission, Url } from "@edgetier/types";
import { intersectWithEnum } from "@edgetier/utilities";
import { FunctionComponent, memo } from "react";

import VerifyPermission from "components-for/verify-permission";
import FormSubmissionField from "components/form-submission/form-submission-field";
import useSimpleQuery from "queries/use-simple-query";
import { IFormSubmissionField, IUser } from "redux/application.types";
import urlJoin from "url-join";
import { v4 as uuid } from "uuid";
import EditWrapUpDetails from "./edit-wrap-up-details/edit-wrap-up-details";
import "./wrap-up-details.scss";
import { IProps } from "./wrap-up-details.types";

/**
 * Wrap-up details of an interaction.
 * @param props.formSubmissionId The ID of the form to get the form submission fields.
 * @param props.editButton       The edit button.
 */
const WrapUpDetails: FunctionComponent<IProps> = ({ formSubmissionId, interaction, interactionTypeId, skillId }) => {
    const { data: formData, isLoading } = useSimpleQuery<{
        formSubmissionFields: IFormSubmissionField[];
        editedAtDateTime: Date;
        editedByUser: IUser;
    }>(urlJoin(Url.FormSubmissions, formSubmissionId.toString()));

    return (
        <div className="wrap-up-details">
            <DetailsSection>
                <DetailsSectionTitle title="Wrap-Up Details">
                    <VerifyPermission permission={[Permission.EditWrapUpDetails]}>
                        <EditWrapUpDetails
                            formSubmissionId={formSubmissionId}
                            interaction={interaction}
                            interactionTypeId={interactionTypeId}
                            skillId={skillId}
                        />
                    </VerifyPermission>
                </DetailsSectionTitle>

                <SpinnerUntil<IFormSubmissionField[]> data={formData?.formSubmissionFields} isReady={!isLoading}>
                    {(data) => (
                        <table key={uuid()}>
                            <tbody>
                                {intersectWithEnum(
                                    FormFieldType,
                                    data,
                                    ({ formField }) => formField.formFieldTypeId
                                ).map((field, index) => (
                                    <FormSubmissionField
                                        field={
                                            {
                                                ...field,
                                                formFieldTypeId: field.formField.formFieldTypeId,
                                            } as IFormSubmissionField
                                        }
                                        key={index}
                                    />
                                ))}
                                {formData?.editedAtDateTime && (
                                    <tr>
                                        <td>
                                            <DetailsFieldTitle>Edited</DetailsFieldTitle>
                                        </td>
                                        <td>
                                            <DetailsFieldValue>
                                                <SplitTimestamp date={new Date(formData.editedAtDateTime)} />
                                            </DetailsFieldValue>
                                        </td>
                                    </tr>
                                )}
                                {formData?.editedByUser && (
                                    <tr>
                                        <td>
                                            <DetailsFieldTitle>Edited by</DetailsFieldTitle>
                                        </td>
                                        <td>
                                            <DetailsFieldValue>{formData.editedByUser.email}</DetailsFieldValue>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </SpinnerUntil>
            </DetailsSection>
        </div>
    );
};

export default memo(WrapUpDetails);

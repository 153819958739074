import { createFieldErrorId } from "@edgetier/utilities";
import { Field, FieldProps } from "formik";

import { IProps } from "./date-form-field.types";
import { formatDate } from "./date-form-field.utilities";

/**
 * Render a date field.
 * @param props.formField   Date form field.
 * @param props.translation Text elements in the user's language.
 * @param props.inputProps  Any other props to pass to the input element.
 * @returns                 Date input.
 */
const DateFormField = ({ formField, translation, ...inputProps }: IProps) => {
    const defaultValue = formField.configuration.defaultValue;
    const maximumDate = formField.configuration.maximumValue;
    const minimumDate = formField.configuration.minimumValue;
    return (
        <Field name={formField.fieldName}>
            {({ field, form }: FieldProps) => (
                <input
                    {...inputProps}
                    {...field}
                    aria-describedby={createFieldErrorId(field.name)}
                    aria-invalid={typeof form.errors[field.name] === "string"}
                    aria-required={formField.required}
                    autoComplete="off"
                    id={formField.fieldName}
                    max={formatDate(maximumDate)}
                    min={formatDate(minimumDate)}
                    name={field.name}
                    placeholder={translation.placeholderText || ""}
                    type="date"
                    defaultValue={formatDate(defaultValue)}
                />
            )}
        </Field>
    );
};

export default DateFormField;

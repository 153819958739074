import { useFlagsIdentify } from "hooks-for/feature-flags";

/**
 * Calls the useFlagsIdentify hook to identify the user for feature flags.
 * @returns null
 */
const FeatureFlagsIdentify = () => {
    useFlagsIdentify();
    return null;
};

export default FeatureFlagsIdentify;

import { useCurrentUser } from "utilities/use-current-user";
import { Permission, Url } from "@edgetier/types";
import axios from "utilities/axios";
import { useRolePermissions } from "@edgetier/roles-permissions";

/**
 * Establish if the current user has permission for a page or element
 * @param permission The permission(s) to check. If multiple are passed, the permissions are OR-ed.
 */
const usePermission = (permission: Permission | Permission[]) => {
    const permissions = Array.isArray(permission) ? permission : [permission];

    const { data: user, isLoading: isUserLoading, isError: isUserError } = useCurrentUser();

    const roleId = user?.roleId;

    const {
        data: rolePermissions,
        isLoading: arePermissionsLoading,
        isError: isPermissionsError,
        ...otherProperties
    } = useRolePermissions(Url.RolePermissions, axios, roleId?.toString()!, {
        enabled: typeof roleId === "number",
    });

    // The subset of the permissions passed that the user has.
    const userPermissions = (rolePermissions ?? []).filter(({ permissionId }) => permissions.includes(permissionId));

    return {
        hasPermission: userPermissions.length > 0,
        permissions: userPermissions,
        isLoading: isUserLoading || arePermissionsLoading,
        isError: isUserError || isPermissionsError,
        ...otherProperties,
    };
};

export default usePermission;

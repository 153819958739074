import BooleanIcon from "~/boolean-icon";
import { DetailsFieldTitle, DetailsFieldValue } from "~/details";

import { IProps } from "./boolean-details-field.types";

/**
 * Render a boolean value from the booking details.
 * @param props.value String value.
 * @returns           String value.
 */
const BooleanDetailsField = ({ field }: IProps) => (
    <tr className="boolean-details-field">
        <td>
            <DetailsFieldTitle>{field.title}</DetailsFieldTitle>
        </td>
        <td>
            <DetailsFieldValue isNull={typeof field.value !== "boolean"} warning={field.warning}>
                <BooleanIcon value={field.value} />
            </DetailsFieldValue>
        </td>
    </tr>
);

export default BooleanDetailsField;

import Axios from "axios";
import { FunctionComponent, useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Url, IPaginated } from "@edgetier/types";

import axios from "utilities/axios";
import logo from "images/colour-light-background.svg";

import { IAuthMethod, IProps } from "./login.types";
import Version from "./version";
import "./login.scss";
import imageUrl from "./background-cover-image.jpg";

/**
 * Sign in page. The backend provides a list of possible authentication providers (Salesforce, Zendesk etc.) that are
 * presented to the user.
 * @returns Application version number and sign in button(s).
 */
const Login: FunctionComponent<IProps> = ({ showServerError }) => {
    const [authMethods, setAuthMethods] = useState<IAuthMethod[] | null>(null);

    // Download authentication options.
    useEffect(() => {
        const cancelTokenSource = Axios.CancelToken.source();
        const fetchAuthMethods = async () => {
            try {
                const { data } = await axios.get<IPaginated<IAuthMethod>>(Url.AuthMethods);
                setAuthMethods(data.items);
            } catch (serverError) {
                if (Axios.isAxiosError(serverError)) {
                    showServerError(serverError);
                }
            }
        };

        fetchAuthMethods();

        return cancelTokenSource.cancel;
    }, [showServerError]);

    /**
     * Production will transform this whole function into url => url
     * This is so the development server can log in via an SSO provider but it will not impact production
     * @param url
     */
    const rewriteLoginUrl = (url: string) => {
        if (process.env.NODE_ENV === "development") {
            const redirectMatch = decodeURIComponent(url).match(/redirect_uri=(.+)\/authenticate/);
            if (redirectMatch === null || typeof redirectMatch[1] === "undefined") return url;

            return url.replace(redirectMatch[1], window.location.origin);
        } else {
            return url;
        }
    };

    return (
        <>
            <div className="login__background" style={{ backgroundImage: `url(${imageUrl})` }} />
            <div className="login">
                <img src={logo} alt="EdgeTier" />
                <h1>Arthur</h1>
                {!Array.isArray(authMethods) ? (
                    <div className="login__loading">
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </div>
                ) : (
                    authMethods.map(({ displayName, loginUrl }) => (
                        <a className="button" href={rewriteLoginUrl(loginUrl)} key={displayName}>
                            Sign in with {displayName}
                        </a>
                    ))
                )}
                <Version />
            </div>
        </>
    );
};

export default Login;

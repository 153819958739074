import { createFieldErrorId } from "@edgetier/utilities";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "formik";

import Tooltip from "../tooltip";
import { IProps } from "./field-error.types";

/**
 * Render a field error message with an icon.
 * @param props.name Field name.
 * @param props.isTiny Flag to show the error in tiny mode, showing the icon and placing the message in a tooltip.
 * @returns          Formik error message.
 */
const FieldError = ({ name, isTiny }: IProps) => {
    return (
        <ErrorMessage name={name}>
            {(message) =>
                typeof message === "string" ? (
                    <div className="field__error" id={createFieldErrorId(name)}>
                        {isTiny ? (
                            <Tooltip content={message}>
                                <FontAwesomeIcon icon={faExclamationTriangle} aria-label="exclamation-triangle" />
                            </Tooltip>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faExclamationTriangle} aria-label="exclamation-triangle" />
                                {message}
                            </>
                        )}
                    </div>
                ) : null
            }
        </ErrorMessage>
    );
};

export default FieldError;

/**
 * Function to filter object keys based on a custom filter function.
 * @param {object} object - The object to filter.
 * @param {function} filterEntry - The function to filter each key-value pair.
 * @returns {object} Filtered object with keys that pass the filter function.
 */
export const filterObjectKeys = <T extends {}>(object: T, filterEntry: (entry: [string, any]) => boolean) => {
    return Object.entries(object).reduce<Partial<T>>(
        (acc, [key, value]) => ({
            ...acc,
            ...(filterEntry([key, value]) ? { [key]: value } : {}),
        }),
        {}
    );
};

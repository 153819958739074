import { type IPermission, Permission } from "@edgetier/types";

/**
 * Check if a given permission(s) is in the array of permissions.
 * @param permission      Permission(s) to check.
 * @param userPermissions Array of permissions to check against.
 * @returns True if the permission(s) is in the array of permissions.
 */
export const getHasPermission = (permission: Permission | Permission[], userPermissions: IPermission[]) => {
    const handlePermissions = Array.isArray(permission) ? permission : [permission];
    const hasPermission = userPermissions.some((thing) => handlePermissions.includes(thing.permissionId));

    return hasPermission;
};

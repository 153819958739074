import { useLayoutEffect, useState } from "react";

/**
 * Check if the input element is overflowed.
 * @param inputElement  Input element.
 * @param selectedItems The currently Selected items.
 * @returns Whether a boolean indicating if the input element is overflowed.
 */
export const useIsInputOverflowed = (inputElement: HTMLInputElement | null, isOpen: boolean) => {
    const [isOverflowed, setIsOverflowed] = useState(false);

    useLayoutEffect(() => {
        if (!isOpen && inputElement !== null) {
            // We only care about overflow when the dropdown is closed and we're dislaying the selected items.
            const initialValue = inputElement.value;

            inputElement.value = inputElement.placeholder;
            const isOverflowed = inputElement.scrollWidth - inputElement.clientWidth > 2;
            inputElement.value = initialValue;

            setIsOverflowed(isOverflowed);
        }
    }, [inputElement, isOpen]);

    return isOverflowed;
};

import React from "react";

import ModalContext from "../context";
import { IModalContext } from "../context/context.types";

import { IProps } from "./provider.types";

export default class Provider extends React.PureComponent<IProps, IModalContext> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            canDismiss: typeof props.canDismiss === "boolean" ? props.canDismiss : true,
            canClose: typeof props.canClose === "boolean" ? props.canClose : true,
            hideModal: this.hideModal,
            isOpen: props.isOpen,
            showModal: this.showModal,
            title: undefined,
            updateTitle: this.updateTitle,
        };
    }

    /**
     * Update the modal's title
     * @param title New title
     */
    updateTitle = (title: string) => {
        this.setState({ title });
    };

    /**
     * Scroll the page when the modal opens.
     * @param _previousProps Previous props (unused).
     * @param previousState  Previous state.
     */
    componentDidUpdate(_previousProps: IProps, previousState: IModalContext): void {
        if (previousState.isOpen === false && this.state.isOpen === true) {
            document.documentElement.scrollTop = 0;
        }
    }

    /**
     * Close the modal.
     */
    hideModal = () => {
        this.props.onHide();
        this.setState({ isOpen: false });
    };

    /**
     * Render children passing
     */
    render() {
        return <ModalContext.Provider value={this.state}>{this.props.children}</ModalContext.Provider>;
    }

    /**
     * Open the modal.
     */
    showModal = () => {
        this.props.onShow();
        this.setState({ isOpen: true });
    };
}

import { Fragment, FunctionComponent, memo, useMemo, useState } from "react";
import { ILanguage } from "@edgetier/types";
import { useSelector } from "react-redux";

import EmailHtmlButton from "components-for/emails/email-html-button";
import isSourceDocumentSupported from "utilities/is-source-document-supported";
import { IApplicationState } from "redux/types";
import { setupSelectors } from "redux/modules/setup";
import { ToggleTranslationButton } from "shared/toggle-translation";

import Call from "./call";
import Chat from "./chat";
import Email from "./email";
import QueryHistoryChangeSkill from "./query-history-change-skill";
import SendEmailButton from "./send-email-button";
import HandleQueryButton from "./handle-query-button";
import TranslationDescription from "./translation-description";
import DownloadChatTranscriptButton from "components-for/chats/download-chat-transcript-button";
import DownloadEmailTranscriptButton from "components-for/emails/download-email-transcript-button";
import { IProps } from "./query-history-interaction-content.types";
import { Permission } from "@edgetier/types";
import usePermission from "hooks-for/permissions/use-permission";
import "./query-history-interaction-content.scss";
import { checkLastSeenActivity } from "./query-history-interaction-content.utilities";
import { useFlags } from "hooks-for/feature-flags";
import { FEATURE_FLAGS } from "types-for/feature-flags";

/**
 * Show the contents of an interaction and various buttons.
 * @param props.bookingId                 Booking ID associated with the call, chat, or email.
 * @param props.chatSkillsEnabled         Setting which, if enabled, might show a change skill button.
 * @param props.emailAddress              Customer email associated with the interaction being viewed.
 * @param props.emailFunctionalityEnabled When true, the client supports emails.
 * @param props.emailSkillsEnabled        Setting which, if enabled, might show a change skill button.
 * @param props.interaction               The call, chat, or email being viewed.
 * @param props.interactionUrl            Backend end point where the interaction was retrieved. Needed for query cache
 *                                        updates.
 * @param props.interactionDetailId       Identifier for the interaction.
 * @param props.isAssigned                Boolean showing if the has an user or has an assigned user.
 * @param props.preferredLanguageId       For a translated chat or email, this is the primary language.
 * @param props.queryId                   Query ID associated with the call, chat, or email.
 * @param props.secondaryLanguageId       For a translated chat or email, this is the secondary language.
 * @param props.skills                    Array of skills associated with the interaction.
 * @returns                               Interaction content and some action buttons.
 */
const QueryHistoryInteractionContent: FunctionComponent<IProps> = ({
    bookingId,
    chatSkillsEnabled,
    emailAddress,
    emailFunctionalityEnabled,
    emailSkillsEnabled,
    interaction,
    interactionUrl,
    interactionDetailId,
    isAssigned,
    isCompleted,
    lastSeenByUserTime,
    preferredLanguageId,
    queryId,
    secondaryLanguageId,
    skills,
}) => {
    const flags = useFlags([FEATURE_FLAGS.HandleQueryButton]);
    const languages = useSelector<IApplicationState, ILanguage[]>(({ setup }) => setupSelectors.getLanguages(setup));
    const canChangeChatSkill = chatSkillsEnabled && "chatId" in interaction && isCompleted;
    const canChangeEmailSkill = emailSkillsEnabled && "emailId" in interaction;
    const isInteractionActive = useMemo(() => checkLastSeenActivity(lastSeenByUserTime), [lastSeenByUserTime]);
    const [isEmailRouted, setIsEmailRouted] = useState(false);

    const { permissions } = usePermission([
        Permission.HandleInteraction,
        Permission.EditSkill,
        Permission.HandleUnRoutedEmail,
    ]);

    // Decide whether or not to show the change skill button. Chats and inbound emails can have their skill changed if
    // chat/email skills are enabled.
    const { canEmail, canChangeSkill, canHandleUnRoutedEmail } = useMemo(() => {
        return permissions.reduce(
            (permissions, permission) => {
                if (permission.permissionId === Permission.HandleInteraction) {
                    return {
                        ...permissions,
                        canEmail: true && emailFunctionalityEnabled,
                    };
                }

                if (permission.permissionId === Permission.EditSkill) {
                    return {
                        ...permissions,
                        canChangeSkill: true && (canChangeChatSkill || canChangeEmailSkill),
                    };
                }

                if (permission.permissionId === Permission.HandleUnRoutedEmail) {
                    return {
                        ...permissions,
                        canHandleUnRoutedEmail: true && !isAssigned && !isCompleted && isInteractionActive,
                    };
                }

                return permissions;
            },
            { canEmail: false, canChangeSkill: false, canHandleUnRoutedEmail: false }
        );
    }, [
        canChangeChatSkill,
        canChangeEmailSkill,
        emailFunctionalityEnabled,
        isAssigned,
        isCompleted,
        permissions,
        isInteractionActive,
    ]);

    const isEmail = "emailId" in interaction || "outboundEmailActionId" in interaction;

    return (
        <Fragment>
            <div className="query-history-interaction-content__interaction">
                {"chatId" in interaction ? (
                    <Chat
                        chatId={interaction.chatId}
                        interactionUrl={interactionUrl}
                        isCompleted={isCompleted}
                        events={interaction.events}
                        preferredLanguageId={preferredLanguageId}
                        secondaryLanguageId={secondaryLanguageId}
                    />
                ) : isEmail ? (
                    <Email
                        attachments={interaction.attachments}
                        bodyHtml={interaction.bodyHtml}
                        bodyText={interaction.bodyText}
                        emailAddress={emailAddress}
                        fromEmailAddress={"fromEmailAddress" in interaction ? interaction.fromEmailAddress : undefined}
                        interactionUrl={interactionUrl}
                        isEmailIn={"emailId" in interaction}
                        emailId={"emailId" in interaction ? interaction.emailId : null}
                        recipients={"recipients" in interaction ? interaction.recipients : []}
                        subject={interaction.subject}
                    />
                ) : (
                    <Call events={interaction.events} />
                )}
            </div>

            {preferredLanguageId !== secondaryLanguageId && (
                <div className="query-history-interaction-content__translation">
                    <TranslationDescription
                        agentLanguageId={preferredLanguageId}
                        languageId={secondaryLanguageId}
                        languages={languages}
                    />
                    <ToggleTranslationButton />
                </div>
            )}

            {(canEmail || canChangeSkill || isEmail || canHandleUnRoutedEmail) && (
                <div className="query-history-interaction-content__buttons">
                    {"chatId" in interaction && <DownloadChatTranscriptButton chatId={interaction.chatId} />}
                    {"conversationId" in interaction && isEmail && (
                        <DownloadEmailTranscriptButton conversationId={interaction.conversationId} />
                    )}

                    {isEmail && isSourceDocumentSupported() && (
                        <EmailHtmlButton
                            bodyHtml={interaction.bodyHtml}
                            subject={interaction.subject}
                            bodyText={interaction.bodyText}
                        />
                    )}

                    {canChangeSkill && (
                        <QueryHistoryChangeSkill
                            interactionDetailId={interactionDetailId}
                            interactionUrl={interactionUrl}
                            queryId={queryId}
                            skills={skills}
                        />
                    )}
                    {canEmail && (
                        <>
                            {isEmail &&
                            canHandleUnRoutedEmail &&
                            !isEmailRouted &&
                            flags.handle_query_button.enabled ? (
                                <HandleQueryButton
                                    interaction={interaction}
                                    toggleRoutedEmail={() => setIsEmailRouted(true)}
                                />
                            ) : (
                                <SendEmailButton
                                    bookingId={bookingId}
                                    emailAddress={emailAddress}
                                    interaction={interaction}
                                    interactionDetailId={interactionDetailId}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default memo(QueryHistoryInteractionContent);

import { Fragment } from "react";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NavigationLink from "../navigation-link";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Link to the text templates page.
 * @returns A link to the page.
 */
const TemplatesLink = () => (
    <NavigationLink view={AbsolutePaths[PageNames.Templates]()}>
        <Fragment>
            <FontAwesomeIcon icon={faFileAlt} />
            Templates
        </Fragment>
    </NavigationLink>
);

export default TemplatesLink;

import { ILanguage, IForm } from "@edgetier/types";

import { ISettings } from "redux/application.types";
import { IBrand } from "types-for/brands/brands.types";

export interface ISetupState {
    data?: ISetupData;
}

export enum FeatureToggles {
    BlockedQueryStateEnabled = "blockedQueryStateEnabled",
    CallFunctionalityEnabled = "callFunctionalityEnabled",
    ChatFunctionalityEnabled = "chatFunctionalityEnabled",
    ChatSkillsEnabled = "chatSkillsEnabled",
    EmailAddressSkillAssignmentEnabled = "emailAddressSkillAssignmentEnabled",
    EmailAllowedTimeMinutes = "emailAllowedTimeMinutes",
    EmailFunctionalityEnabled = "emailFunctionalityEnabled",
    EmailSkillsEnabled = "emailSkillsEnabled",
    DepartmentRestrictedBrandsEnabled = "departmentRestrictedBrandsEnabled",
    DepartmentRestrictedTransfersEnabled = "departmentRestrictedTransfersEnabled",
    DepartmentRestrictedSetupsEnabled = "departmentRestrictedSetupsEnabled",
    ProactiveChatEnabled = "proactiveChatEnabled",
}

export type IFeatureToggles = Pick<ISettings, FeatureToggles>;

export interface ISetupData extends IFeatureToggles {
    readonly bookingDescription: string;
    readonly bookingIdName: string;
    readonly bookingIdPattern: string;
    readonly brands: IBrand[] | null;
    readonly chatDomainId?: number;
    readonly chatTranslationsEnabled: boolean;
    readonly clientLogoInverse: string;
    readonly clientName: string;
    readonly emailTranslationsEnabled: boolean;
    readonly languages: ILanguage[];
    readonly reassignmentMinutes: number;
    readonly translationsEnabled: boolean;
    readonly wrapUpForm: IForm;
}

export interface ISkill {
    readonly chatRoutingPriority: number;
    readonly skill: string;
    readonly skillId: number;
    readonly department: string;
    readonly departmentId: number;
}

export interface IDispositionCode {
    readonly dispositionCode: string;
    readonly dispositionCodeId: number;
}

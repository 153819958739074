import { FunctionComponent, memo } from "react";

import { IProps } from "./warning-list.types";

/**
 * Display a list of warnings.
 * @param props.warnings A string array of warnings.
 */
const WarningList: FunctionComponent<IProps> = ({ warnings }) => (
    <ul>
        {warnings.map((warning, index) => (
            <li className="tooltip-warning__content__warning" key={index}>
                <span>{warning}</span>
            </li>
        ))}
    </ul>
);

export default memo(WarningList);

import LoadingSkeleton from "~/loading-skeleton";

import "./loading-cell.scss";

/**
 * Cell in a table when it is loading.
 * @returns Loading display.
 */
const LoadingCell = () => (
    <div className="grid__loading-cell">
        <LoadingSkeleton />
    </div>
);

export default LoadingCell;

import { Children, isValidElement } from "react";

import ColumnProvider from "../../column-provider";
import TableColumns from "../../table-columns";
import { IProps } from "./table-heading.types";

/**
 * Heading of the table component. The children passed here should contain various column definitions and within each
 * there should be heading components. These headings are extracted and displayed.
 * @param props Props containing the children of the entire table component and a sorting function to sort by column.
 * @returns     Table heading element.
 */
const TableHeading = ({ children }: IProps) => (
    <thead>
        <tr>
            <ColumnProvider isBody={false}>
                {Children.toArray(children).filter((child) => isValidElement(child) && child.type === TableColumns)}
            </ColumnProvider>
        </tr>
    </thead>
);

export default TableHeading;

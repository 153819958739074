import { Url } from "@edgetier/types";
import { AxiosError } from "axios";
import { AbsolutePaths } from "constants/routes";
import useSimpleQuery, { useSimplePrefetch } from "queries/use-simple-query";
import { useEffect } from "react";
import { UseQueryOptions } from "react-query";
import { useNavigate } from "react-router-dom";
import { IUser } from "redux/application.types";
import { PageNames } from "types-for/routes";
import urlJoin from "url-join";

import { getUserId } from "./local-storage";

export const usePrefetchCurrentUser = () => {
    const userId = getUserId();
    useSimplePrefetch(urlJoin(Url.Users, userId!.toString()), {});
};

/**
 * A query for requesting the current user from the backend.
 */
export const useCurrentUser = <T = IUser>(configuration: UseQueryOptions<IUser, AxiosError, T> = {}) => {
    const userId = getUserId();
    const navigate = useNavigate();

    // Redirect to login view if the userId is missing
    useEffect(() => {
        if (typeof userId !== "number") {
            navigate(AbsolutePaths[PageNames.Login]());
        }
    }, [navigate, userId]);

    return useSimpleQuery<IUser, T>(urlJoin(Url.Users, (userId ?? "").toString()), {
        enabled: typeof userId === "number",
        staleTime: 1000 * 60 * 5,
        ...configuration,
    });
};

import { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { ILanguage } from "@edgetier/types";
import { SpinnerUntil } from "@edgetier/components";

import CustomerUrlsLoader from "components/customer-urls-loader";
import FormSubmission from "components/form-submission";
import FormSubmissionLoader from "components/form-submission-loader";
import useSimpleQuery from "queries/use-simple-query";
import { IApplicationState } from "redux/types";
import { setupSelectors } from "redux/modules/setup";
import { ToggleTranslation } from "shared/toggle-translation";
import { IInteraction, ISettings } from "redux/application.types";
import { useSettings } from "queries/use-settings";
import getInteractionInformation from "utilities/get-interaction-information";

import CommonDetails from "./common-details";
import QueryHistoryInteractionContent from "./query-history-interaction-content";
import { IProps } from "./interaction.types";
import DeviceDetails from "components-for/query-history/device-details";
import LocationDetails from "components-for/query-history/location-details";
import "./interaction.scss";
import AssignmentDetails from "components-for/query-history/assignment-details";
import ChatSource from "constants/chat-source";
import VerifyPermission from "components-for/verify-permission/verify-permission";
import { Permission } from "@edgetier/types";
import { WrapUpDetails } from "components-for/query-history/wrap-up-details";

/**
 * Display a chat, call or email in the interaction search results. Each interaction type has some common information such
 * as the agent that handled it, disposition codes etc., and some unique information such as the messages in a chat or
 * the content of an email.
 * @param props.interactionSearchItem The interaction object relevant for search.
 */
const Interaction: FunctionComponent<IProps> = ({ interactionSearchItem }) => {
    const { url: interactionUrl, idValue: interactionId } = getInteractionInformation(interactionSearchItem);
    const interactionQuery = useSimpleQuery<IInteraction>(interactionUrl);
    const languages = useSelector<IApplicationState, ILanguage[]>(({ setup }) => setupSelectors.getLanguages(setup));

    const settingsQuery = useSettings();

    const [preferredLanguageId, secondaryLanguageId] = useMemo(() => {
        const languageId = interactionSearchItem.languageId;
        const interaction = interactionQuery.data;
        if (typeof interaction !== "undefined") {
            const preferredLanguageId =
                "translatedToLanguageId" in interaction && typeof interaction.translatedToLanguageId === "number"
                    ? interaction.translatedToLanguageId
                    : interactionSearchItem.languageId;

            const secondaryLanguageId = languageId;
            return [preferredLanguageId, secondaryLanguageId];
        }
        return [languageId, languageId];
    }, [interactionQuery.data, interactionSearchItem.languageId]);

    return (
        <div className="query-history__interaction">
            <ToggleTranslation
                languages={languages}
                languageIds={[preferredLanguageId, secondaryLanguageId]}
                preferredLanguageId={preferredLanguageId}
            >
                <div className="query-history__interaction__content">
                    <SpinnerUntil<{ interaction: IInteraction; settings: ISettings }>
                        data={{ interaction: interactionQuery.data, settings: settingsQuery.data }}
                        isReady={
                            typeof interactionQuery.data !== "undefined" && typeof settingsQuery.data !== "undefined"
                        }
                    >
                        {({ interaction, settings }) => (
                            <QueryHistoryInteractionContent
                                bookingId={interactionSearchItem.bookingId}
                                chatSkillsEnabled={settings.chatSkillsEnabled}
                                emailAddress={interactionSearchItem.emailAddress}
                                emailFunctionalityEnabled={settings.emailFunctionalityEnabled}
                                emailSkillsEnabled={settings.emailSkillsEnabled}
                                interaction={interaction}
                                interactionDetailId={interaction.interactionDetailId}
                                interactionUrl={interactionUrl}
                                isCompleted={interactionSearchItem.completed}
                                preferredLanguageId={preferredLanguageId}
                                queryId={interactionSearchItem.queryId}
                                secondaryLanguageId={secondaryLanguageId}
                                skills={interaction.skills || []}
                                lastSeenByUserTime={interactionSearchItem.lastSeenByUserDateTime}
                                isAssigned={
                                    interactionSearchItem.userId !== null ||
                                    (Array.isArray(interactionSearchItem.assignedTo) &&
                                        interactionSearchItem.assignedTo.length > 0)
                                }
                            />
                        )}
                    </SpinnerUntil>
                </div>

                <div className="query-history__interaction__details">
                    <SpinnerUntil<IInteraction>
                        data={interactionQuery.data}
                        isReady={typeof interactionQuery.data !== "undefined"}
                    >
                        {(interaction) => (
                            <>
                                <CommonDetails
                                    agentFinishTime={
                                        interactionSearchItem.agentFinishTime === null
                                            ? null
                                            : new Date(interactionSearchItem.agentFinishTime)
                                    }
                                    brandId={"brandId" in interaction ? interaction.brandId : null}
                                    conversationKey={interaction.conversationKey}
                                    chatId={"chatId" in interaction ? interaction.chatId : null}
                                    completed={interactionSearchItem.completed}
                                    date={interactionSearchItem.dateTime}
                                    handlingTypeId={interactionSearchItem.handlingTypeId}
                                    interactionId={interactionId}
                                    isCall={"callId" in interaction}
                                    isChat={"chatId" in interaction}
                                    isEmailIn={"emailId" in interaction}
                                    interactionDetailId={interaction.interactionDetailId}
                                    language={interactionSearchItem.language}
                                    queryId={interactionSearchItem.queryId}
                                    setupId={"setupId" in interaction ? interaction.setupId : null}
                                    skills={interaction.skills || []}
                                    totalHandlingTime={interactionSearchItem.totalHandlingTime}
                                    user={interactionSearchItem.user}
                                />
                                {typeof interactionSearchItem.wrapUpFormSubmissionId === "number" && (
                                    <WrapUpDetails
                                        formSubmissionId={interactionSearchItem.wrapUpFormSubmissionId}
                                        interaction={interaction}
                                        interactionTypeId={interactionSearchItem.interactionTypeId}
                                        skillId={interactionSearchItem.skillId}
                                    />
                                )}

                                {"chatId" in interaction &&
                                    Array.isArray(interaction.formSubmission) &&
                                    interaction.formSubmission.length > 0 && (
                                        <>
                                            <FormSubmission
                                                formSubmissionFields={interaction.formSubmission}
                                                preferredLanguageId={preferredLanguageId}
                                                secondaryLanguageId={secondaryLanguageId}
                                                title="Start Form"
                                            />
                                            <CustomerUrlsLoader chatId={interaction.chatId} />
                                        </>
                                    )}

                                {"surveyFormSubmissionId" in interactionSearchItem &&
                                    typeof interactionSearchItem.surveyFormSubmissionId === "number" && (
                                        <FormSubmissionLoader
                                            formSubmissionId={interactionSearchItem.surveyFormSubmissionId}
                                            preferredLanguageId={preferredLanguageId}
                                            secondaryLanguageId={secondaryLanguageId}
                                            title="Survey"
                                        />
                                    )}
                                {"chatId" in interaction && interaction.chatSourceId === ChatSource.Internal && (
                                    <>
                                        <DeviceDetails chatId={interaction.chatId} />
                                        <LocationDetails chatId={interaction.chatId} />
                                    </>
                                )}
                                {"emailId" in interaction && (
                                    <VerifyPermission
                                        permission={[Permission.AssignInteraction, Permission.ViewEmailAssignment]}
                                    >
                                        <AssignmentDetails
                                            emailId={interaction.emailId}
                                            interactionCompleted={interactionSearchItem.completed}
                                        />
                                    </VerifyPermission>
                                )}
                            </>
                        )}
                    </SpinnerUntil>
                </div>
            </ToggleTranslation>
        </div>
    );
};

export default Interaction;

import { FunctionComponent, memo } from "react";

import Page from "../page";
import "./page-sidebar.scss";
import { IProps } from "./page-sidebar.types";

/**
 * A sidebar that permanently sits beside a page.
 * @param props.children The sidebar contents.
 */
const PageSidebar: FunctionComponent<IProps> = ({ children }) => {
    return <Page className="page-sidebar">{children}</Page>;
};

export default memo(PageSidebar);

import AccordionItemContainer from "~/accordion/accordion-item-container";
import AccordionItemProvider from "~/accordion/accordion-item-provider";

import "./accordion-item.scss";
import { IProps } from "./accordion-item.types";

/**
 * Container for an accordion item's header and content.
 * @param props.canOpen  If the accordion has content to show.
 * @param props.children Accordion item header and content.
 * @param props.isOpen   Default open state of the item.
 * @returns              Header and content of an accordion item.
 */
const AccordionItem = ({ canOpen = true, children, isOpen = false, onToggleOpen }: IProps) => (
    <AccordionItemProvider canOpen={canOpen} isOpen={isOpen} onToggleOpen={onToggleOpen}>
        <AccordionItemContainer>{children}</AccordionItemContainer>
    </AccordionItemProvider>
);

export default AccordionItem;

import { FunctionComponent } from "react";

import "./client-authenticated-routes.scss";
import { IProps } from "./client-authenticated-routes.types";

/**
 * Wrapper around client authenticated routes. This is mostly for applying styles.
 * @param props.children Route content.
 * @returns              Container.
 */
const ClientAuthenticatedRoutes: FunctionComponent<IProps> = ({ children }) => {
    return <div className="client-authenticated-routes">{children}</div>;
};

export default ClientAuthenticatedRoutes;

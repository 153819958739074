import { createSelector } from "reselect";
import type { IChatState } from "./chat.types";

const selectData = (data: IChatState) => data;

/**
 * Count all the unread chat messages.
 * @returns Total number of unread messages.
 */
export const getUnreadMessageCount = createSelector(selectData, ({ chats }) => {
    return Object.values(chats).reduce((total, { unreadMessages }) => total + unreadMessages, 0);
});

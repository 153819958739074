import { getTimeToRead } from "@edgetier/utilities";
import { AxiosError, AxiosInstance } from "axios";
import toast from "react-hot-toast";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { IRole } from "@edgetier/types";
import urljoin from "url-join";

/**
 * Create a request with POST for a new role or update the role with PUT
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 * @param role  Optional existing role
 */
const createOrUpdateRole =
    (routeUrl: string, axiosInstance: AxiosInstance, role: IRole | undefined) => async (submittedRole: string) => {
        const trimRoleValues = submittedRole.trim();

        // Check if a note was passed which will mean a PATCH should be made instead of a POST.
        if (typeof role !== "undefined") {
            await axiosInstance.patch(urljoin(routeUrl, role.roleId.toString()), { role: trimRoleValues });
            return { ...role, role: trimRoleValues };
        } else {
            const { data } = await axiosInstance.post<IRole>(routeUrl, { role: trimRoleValues });
            return { ...data };
        }
    };

/**
 * Custom hook to create or update an existing role and updating the roles query cache
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 * @param role              Optional existing role
 * @param configuration     Optional Axios configuration
 * @returns A mutation to create or update an existing role
 */
const useCreateRole = (
    routeUrl: string,
    axiosInstance: AxiosInstance,
    role?: IRole,
    configuration: UseMutationOptions<IRole, AxiosError, string> = {}
) => {
    const queryClient = useQueryClient();

    /**
     * Success callback to update the roles query cache with the latest changes
     * @param updatedRole   Newly modified role
     */
    const onSuccess = (updatedRole: IRole) => {
        // Update the roles query
        queryClient.setQueryData<IRole[]>([routeUrl], (roles): IRole[] => {
            if (typeof role === "undefined") {
                return [...(roles ?? []), updatedRole];
            } else {
                return (roles ?? []).map((existingRole) =>
                    existingRole.roleId === updatedRole.roleId ? updatedRole : existingRole
                );
            }
        });

        const successMessage =
            typeof role === "undefined"
                ? `Successfully created ${updatedRole.role}`
                : `Successfully updated ${updatedRole.role}`;

        toast.success(successMessage, { duration: getTimeToRead(successMessage, 1000) });
    };
    /**
     * Show a toast message with the request error
     */
    const onError = () => {
        if (typeof role !== "undefined") {
            toast.error("Failed to edit a role");
        } else {
            toast.error("Failed to create a role");
        }
    };

    const mutationSettings = { onSuccess, onError, ...configuration };
    return useMutation<IRole, AxiosError, string>(createOrUpdateRole(routeUrl, axiosInstance, role), mutationSettings);
};

export default useCreateRole;

import { Fragment } from "react";

import GridContext from "../../grid-context";
import Empty from "./empty";
import Error from "./error";
import ExtraRows from "./extra-rows";
import Loading from "./loading";
import { IProps } from "./table-body.types";
import TableRow from "./table-row";

/**
 * Body of the table.
 * @returns Table body.
 */
const TableBody = ({ children }: IProps) => (
    <GridContext.Consumer>
        {({ fixedRows, getRowKey, pageIndex, pageSize, rows, hasError }) => (
            <tbody>
                {hasError ? (
                    <Error />
                ) : !Array.isArray(rows) ? (
                    <Loading>{children}</Loading>
                ) : rows.length === 0 ? (
                    <Empty />
                ) : (
                    <Fragment>
                        {fixedRows
                            .concat(
                                Number.isFinite(pageSize)
                                    ? rows.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
                                    : rows
                            )
                            .map((row: Record<any, any>, index) => (
                                <TableRow
                                    isFixed={index < fixedRows.length}
                                    key={`${getRowKey(row)}-${Object.values(row).join(",")}`}
                                    row={row}
                                >
                                    {children}
                                </TableRow>
                            ))}

                        <ExtraRows />
                    </Fragment>
                )}
            </tbody>
        )}
    </GridContext.Consumer>
);

export default TableBody;

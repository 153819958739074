import GridContext from "../../../grid-context";
import { IProps } from "./row-expander.types";

/**
 * Each row in the table can have an expandable section. In practice, this adds another row to the table that is hidden
 * until a button is clicked. Only one can be open at a time. The key of the row is used to determine which one is open.
 * @param props             Contains the children to render, the currrent rows index, and number of columns to span.
 * @param props.children    Components that will render inside the expanded row.
 * @param props.columnCount Number of columns in the table. The expanded row must span these.
 * @param props.rowIndex    Current row number.
 */
const RowExpander = <T extends {}>({ children, columnCount, row }: IProps<T>) => (
    <GridContext.Consumer>
        {({ expandedRowKey, getRowKey }) =>
            getRowKey(row) === expandedRowKey && (
                <tr>
                    <td colSpan={columnCount}>{children}</td>
                </tr>
            )
        }
    </GridContext.Consumer>
);

export default RowExpander;

import { Permission } from "@edgetier/types";

export const PERMISSION_DESCRIPTION: Record<number, string> = {
    [Permission.HandleInteraction]: "Handle customer chats and emails.",
    [Permission.ViewSearch]: "Search for chats, emails, and calls.",
    [Permission.ViewTemplate]: "View text templates.",
    [Permission.EditUser]: "Edit user details, translations and departments.",
    [Permission.EditUserSkill]: "Add or remove assigned skills from a user.",
    [Permission.EditUserLanguage]: "Add or remove selected languages from a user.",
    [Permission.EditAgentState]: "Create, edit, and delete user states.",
    [Permission.EditWidget]: "Create, edit, and delete chat widgets.",
    [Permission.EditChatBan]: "Ban disruptive or abusive customers from chat by email.",
    [Permission.ToggleWidget]: "Enable or disable chat widgets.",
    [Permission.EditLanguage]: "Edit the properties of languages in the system.",
    [Permission.EditEmailRoutingRule]: "Create, edit, and delete rules to assign skills to emails.",
    [Permission.EditDepartment]: "Create, edit, and delete departments.",
    [Permission.EditSkill]: "Create, edit, and delete skills.",
    [Permission.EditTextLocalisation]: "Create and edit text localisations.",
    [Permission.EditSystemSetting]: "Edit system-wide settings",
    [Permission.EditLogic]: "View logic related to chat deflection, email automation etc.",
    [Permission.ViewReporting]:
        "View live chat, email, and agent reporting and create, edit, and delete custom reports.",
    [Permission.EditTemplate]: "Create, edit, and delete text templates.",
    [Permission.EditTheme]: "Create, edit, and delete chat widget themes.",
    [Permission.EditForm]: "View and edit forms.",
    [Permission.ViewEmailAssignment]: "View the details of emails assigned to users.",
    [Permission.EditBrand]: "Create, edit, and delete brands.",
    [Permission.EditUserBasic]: "Edit basic user details.",
    [Permission.RedactContent]: "Remove sensitive content from chat and emails.",
    [Permission.DeleteAttachment]: "Remove attachments from chats and emails.",
    [Permission.EditFormField]: "Edit fields within forms.",
    [Permission.AssignInteraction]: "Assign interactions to agents.",
    [Permission.EditRole]: "Create, edit, and delete roles and permissions.",
    [Permission.EditEmailAccount]: "Create, and edit email account.",
    [Permission.HandleUnRoutedEmail]: "Resolve unhandled emails assigned to other users.",
    [Permission.ViewAction]: "View the outbox (completed, in progress, and failing emails and/or other actions).",
    [Permission.EditAction]:
        "Edit items in the outbox (completed, in progress, and failing emails and/or other actions).",
    [Permission.EditWrapUpDetails]: "Edit a wrap-up form after it has been submitted.",
};

export const SETTINGS_PERMISSIONS = [
    Permission.EditSystemSetting,
    Permission.EditAgentState,
    Permission.EditWidget,
    Permission.EditChatBan,
    Permission.ToggleWidget,
    Permission.EditLanguage,
    Permission.EditEmailRoutingRule,
    Permission.EditDepartment,
    Permission.EditSkill,
    Permission.EditTextLocalisation,
    Permission.EditTheme,
    Permission.EditForm,
    Permission.EditBrand,
    Permission.EditLogic,
    Permission.EditFormField,
    Permission.EditEmailAccount,
];

export const PERMISSION_GROUPS = {
    "Data Privacy": [Permission.RedactContent, Permission.DeleteAttachment],
    Interactions: [
        Permission.HandleInteraction,
        Permission.AssignInteraction,
        Permission.ViewSearch,
        Permission.ViewEmailAssignment,
        Permission.HandleUnRoutedEmail,
        Permission.EditWrapUpDetails,
    ],
    Outbox: [Permission.ViewAction, Permission.EditAction],
    Reporting: [Permission.ViewReporting],
    "System Configuration": SETTINGS_PERMISSIONS,
    Templates: [Permission.ViewTemplate, Permission.EditTemplate],
    Uncategorised: [],
    "User Management": [
        Permission.EditUser,
        Permission.EditUserSkill,
        Permission.EditUserLanguage,
        Permission.EditRole,
    ],
};

import { useLayoutEffect } from "react";

/**
 * Scroll the menu into view when it is opened.
 * @param menuShouldScrollIntoView Whether the menu should scroll into view.
 * @param isOpen                   Whether the menu is open.
 * @param inputElement             Input element.
 */
export const useScrollMenuIntoView = (
    menuShouldScrollIntoView: boolean,
    isOpen: boolean,
    inputElement: HTMLInputElement | null
) => {
    useLayoutEffect(() => {
        if (
            menuShouldScrollIntoView &&
            isOpen &&
            inputElement !== null &&
            typeof inputElement.scrollIntoView === "function"
        ) {
            inputElement.scrollIntoView({ behavior: "smooth" });
        }
    }, [inputElement, isOpen, menuShouldScrollIntoView]);
};

import { ISkill } from "redux/modules/setup/setup.types";

/**
 * Create function to get a department name from a skill ID.
 * @param skillLookup Skill ID to skill mapping.
 * @returns Function.
 */
export const createGetDepartment = (skillLookup: Record<number, ISkill>) => (skillId: number) => {
    return skillLookup[skillId]?.department ?? "Unknown";
};

/**
 * Create function to get a skill name from a skill ID.
 * @param skillLookup Skill ID to skill mapping.
 * @returns Function.
 */
export const createGetName = (skillLookup: Record<number, ISkill>) => (skillId: number) => {
    return skillLookup[skillId]?.skill ?? "Unknown";
};

import { formatEnumOptions } from "@edgetier/client-components";
import { FunctionComponent, memo, useMemo } from "react";

import SelectField from "../select-field";
import "./select-enum.scss";
import { IProps } from "./select-enum.types";

/**
 * Display a select input containing any enum values.
 * @param props.excludeItems Optional list of items to exclude.
 * @param props.name         Form field name.
 * @param props.items        An enum.
 * @param props.otherProps   Props to pass on to inner select component.
 * @returns                  Select component.
 */
const SelectEnum: FunctionComponent<IProps> = ({
    excludeItems,
    getLabel = ({ label }: ReturnType<typeof formatEnumOptions>[0]) => label,
    items,
    name,
    ...otherProps
}) => {
    const formattedItems = useMemo(() => formatEnumOptions(items, excludeItems), [items, excludeItems]);
    return (
        <SelectField
            getLabel={getLabel}
            getValue={({ value }) => value}
            items={formattedItems}
            name={name}
            {...otherProps}
        />
    );
};

export default memo(SelectEnum);

import { createFieldErrorId, doNothing } from "@edgetier/utilities";
import { Field, FieldProps } from "formik";

import UniqueId from "../unique-id";
import { IProps } from "./checkbox.types";
import CheckedIcon from "./checked-icon";
import UncheckedIcon from "./unchecked-icon";

/**
 * Render a checkbox with custom icons that uses Formik.
 * @param props.autoFocus      Automatically focus on the field when it renders.
 * @param props.checked        Optional override for checked value.
 * @param props.isDisabled     Optionally disable the input.
 * @param props.label          Checkbox label.
 * @param props.name           Field name.
 * @param props.onChange       Optional change handler.
 * @param props.transformValue Optional method to change the value stored.
 * @returns                    Checkbox input.
 */
const Checkbox = <T extends {}>({
    autoFocus,
    checked,
    isDisabled = false,
    label,
    name,
    onChange = doNothing,
    transformValue,
    ...inputProps
}: IProps<T>) => (
    <UniqueId>
        {(uniqueId) => (
            <Field name={name}>
                {({ field, form }: FieldProps) => {
                    const isChecked = typeof checked === "boolean" ? checked : field.value;
                    return (
                        <div className="radio">
                            <input
                                {...inputProps}
                                aria-describedby={createFieldErrorId(name)}
                                aria-invalid={typeof form.errors[name] === "string"}
                                autoFocus={autoFocus}
                                checked={isChecked}
                                disabled={isDisabled}
                                id={uniqueId}
                                name={name}
                                onChange={(changeEvent) => {
                                    onChange(changeEvent.target.checked);
                                    const transformedValue =
                                        typeof transformValue === "function"
                                            ? transformValue(changeEvent.target.checked)
                                            : changeEvent.target.checked;
                                    form.setFieldValue(field.name, transformedValue);
                                }}
                                type="checkbox"
                            />
                            <label htmlFor={uniqueId}>
                                {isChecked ? <CheckedIcon /> : <UncheckedIcon />}
                                <span className="radio__label-text">{label}</span>
                            </label>
                        </div>
                    );
                }}
            </Field>
        )}
    </UniqueId>
);

export default Checkbox;

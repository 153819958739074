/**
 * Groups the items based on the provided getGroup function and includes the index of each item.
 * If no getGroup function is provided, all items are considered to be in the same group.
 * @param items Items to be grouped with their indexes.
 * @returns A record where the keys are group names and the values are arrays of items with their indexes.
 */
export const groupItemsWithIndex = <IItem extends {}>(items: IItem[], getGroup?: (item: IItem) => string) => {
    if (typeof getGroup !== "function") return { "": items.map((item, index) => ({ item, index })) };

    return items.reduce(
        (acc, item, index) => {
            const group = getGroup(item);
            if (!acc[group]) {
                acc[group] = [];
            }
            acc[group].push({ item, index });
            return acc;
        },
        {} as Record<string, { item: IItem; index: number }[]>
    );
};

/**
 * Calculates grouped, flattened, and indexed item data based on the provided items and group function.
 * @param items Items to be processed.
 * @param getGroup Function to determine the group of each item.
 * @returns An object containing flatItems, flatItemIndexMap, and groupedItemsWithIndex.
 */
export const calculateGroupedFlatItemIndex = <IItem extends {}>(items: IItem[], getGroup?: (item: IItem) => string) => {
    const groupedItemsWithIndex = groupItemsWithIndex(items, getGroup);

    const flatItems = Object.entries(groupedItemsWithIndex).reduce(
        (acc: { item: IItem; group: string; index: number }[], [group, items]) => {
            items.forEach(({ item, index }) => {
                acc.push({ item, group, index });
            });
            return acc;
        },
        []
    );

    const flatItemIndexMap = new Map<IItem, number>();
    flatItems.forEach(({ item, index }) => {
        flatItemIndexMap.set(item, index);
    });

    return { flatItems, flatItemIndexMap, groupedItemsWithIndex };
};

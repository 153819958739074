import { AxiosError, AxiosInstance } from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { IRole } from "@edgetier/types";
import { createGetAllPaginatedItems } from "@edgetier/utilities";

/**
 * Request all roles
 * @param routeUrl  Route URL
 * @param axiosInstance     Axios instance specific to an application
 */
const getRoles = async (routeUrl: string, axiosInstance: AxiosInstance) => {
    const getAllPaginatedItems = createGetAllPaginatedItems(axiosInstance);

    const roles = await getAllPaginatedItems<IRole>(routeUrl);
    return roles.sort((roleOne, roleTwo) => roleOne.role.localeCompare(roleTwo.role));
};

/**
 * Get all roles
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 * @param configuration     Optional Axios configuration
 * @returns React Query result.
 */
const useRoles = <T = IRole[]>(
    routeUrl: string,
    axiosInstance: AxiosInstance,
    configuration: UseQueryOptions<IRole[], AxiosError<void>, T> = {}
) => {
    return useQuery<IRole[], AxiosError<void>, T>([routeUrl], () => getRoles(routeUrl, axiosInstance), {
        ...configuration,
    });
};

export default useRoles;

import type { IFeatureToggles } from "redux/modules/setup/setup.types";
import type { IRouteHandleEnabledValue } from "types-for/routes";

/**
 * Determines if a route is enabled based on the feature toggles and the enabled configuration.
 * @param rawEnabledConfiguration        The enabled configuration.
 * @param featureToggles The feature toggles object.
 */
export const isRouteEnabled = (
    rawEnabledConfiguration: IRouteHandleEnabledValue,
    featureToggles: IFeatureToggles | undefined
) => {
    const enabledConfigurationArray = Array.isArray(rawEnabledConfiguration)
        ? rawEnabledConfiguration
        : [rawEnabledConfiguration];

    return enabledConfigurationArray.every((enabledConfiguration) => {
        if ("featureToggleKey" in enabledConfiguration) {
            return enabledConfiguration.validation(featureToggles?.[enabledConfiguration.featureToggleKey]) === true;
        }
        return enabledConfiguration.validation();
    });
};

import { IFormComponent, ISelectFormComponent } from "@edgetier/types";
import { keyById } from "@edgetier/utilities";

import { IFormComponentTranslated, TChildFieldsByParentId, TFieldsByFieldId } from "./dynamic-form.types";
import SelectFormField from "./form-fields/select-form-field";

/**
 * Take a configuration and process through decorateConfiguration if needed
 */
export const normaliseConfiguration = (
    configuration: IFormComponent["configuration"],
    formComponents: IFormComponent[]
): IFormComponent["configuration"] => {
    if (Object.prototype.hasOwnProperty.call(configuration, "parentFormFieldId")) {
        return SelectFormField.decorateConfiguration(
            configuration as ISelectFormComponent["configuration"],
            formComponents
        );
    }
    return configuration;
};

/**
 * Check if a field is a child field. Check if the field has a parentFormFieldId in its configuration. If there is,
 * check if the parentFormFieldId is in the list of fieldIds; we shouldn't treat the field as a child if we can't
 * render its parent
 * @param normalisedConfiguration   Normalised configuration
 * @param fieldIds                  List of field ids
 * @returns                         True if the field is a child field
 */
const isChildField = (
    normalisedConfiguration: ReturnType<typeof normaliseConfiguration>,
    fieldIds: number[]
): normalisedConfiguration is ISelectFormComponent["configuration"] => {
    return (
        "parentFormFieldId" in normalisedConfiguration &&
        normalisedConfiguration?.parentFormFieldId !== null &&
        fieldIds.includes(normalisedConfiguration.parentFormFieldId)
    );
};

/**
 * Convert form fields into a format we can work with
 */
export const normaliseFields = (
    formComponents: IFormComponent[]
): {
    fieldIds: number[];
    childFieldsByParentId: TChildFieldsByParentId;
    fields: TFieldsByFieldId;
} => {
    const allFieldIds = formComponents.map((field) => field.formFieldId);

    const { fieldIds, fields, childFieldsByParentId } = formComponents
        .sort((one, two) => one.displayOrder - two.displayOrder)
        .reduce<{
            fieldIds: number[];
            fields: TFieldsByFieldId;
            childFieldsByParentId: TChildFieldsByParentId;
        }>(
            (fieldObject, field) => {
                const { configuration, formFieldTranslations, ...rest } = field;
                const normalisedConfiguration = normaliseConfiguration(configuration, formComponents);

                const isChild = isChildField(normalisedConfiguration, allFieldIds);

                // If a field has a 'parent' we will track all that parent's children in an array.
                if (isChild) {
                    const parentId = String(normalisedConfiguration.parentFormFieldId);
                    fieldObject.childFieldsByParentId[parentId] = [
                        ...(fieldObject.childFieldsByParentId[parentId] ?? []),
                        field.formFieldId,
                    ];
                }

                // Only store the field IDs of top-level fields. The dynamic form components will recursively render the
                // children.
                if (!isChild) {
                    fieldObject.fieldIds.push(field.formFieldId);
                }

                // All fields should be normalised and put in the 'fields' property.
                fieldObject.fields[String(field.formFieldId)] = {
                    ...rest,
                    configuration: normalisedConfiguration,
                    formFieldTranslations,
                    translationLookup: keyById(formFieldTranslations, (item) => item.languageId),
                } as IFormComponentTranslated;

                return fieldObject;
            },
            {
                fieldIds: [],
                fields: {},
                childFieldsByParentId: {},
            }
        );

    return { fields, fieldIds, childFieldsByParentId };
};

/**
 * Create a message value from submitted form translation labels and values
 * @param labels            Translation labels
 * @param values            Form values
 * @returns                 Array of messages as label: value or value
 */
export const pairLabelValue = (labels: string[], values: string[]) => {
    if (values.length > 1) {
        return values.map((value, index) => {
            if (typeof labels[index] === "string" && labels[index] !== "") {
                return `${labels[index]}: ${value}`;
            }
            return value;
        });
    }

    return values;
};

/**
 * Find translation labels
 * @param formComponents    Any form components sent
 * @param languageId        Current language
 * @returns                 List of labels for current language
 */
export const getTranslationLabels = (formComponents: IFormComponent[] | undefined, languageId: number) => {
    if (typeof formComponents !== "undefined") {
        // Obtain all labels from form components
        return formComponents.map((formComponent) => {
            const labelTranslation = formComponent.formFieldTranslations.find(
                (fieldTranslation) => fieldTranslation.languageId === languageId
            );

            return typeof labelTranslation !== "undefined" ? labelTranslation.formFieldTranslation : "";
        });
    }

    return [];
};

import { IModalUtilities } from "types";

export const fakeModalProps = (): IModalUtilities => ({
    canDismiss: true,
    canClose: true,
    closeModal: jest.fn(),
    isOpen: false,
    onCloseModal: jest.fn(),
    openModal: jest.fn(),
    updateTitle: jest.fn(),
});

import ActionStatus from "constants/action-status";
import { AxiosError } from "axios";
import { Url } from "@edgetier/types";
import { useQuery, UseQueryOptions } from "react-query";
import { getAllPaginatedItems } from "utilities/get-all-paginated-items";

export interface IActionsCount {
    readonly count: number;
}

export interface IActionsCountQueryParameters {
    readonly actionStatusId?: ActionStatus | ActionStatus[];
}

/**
 * Count actions.
 * @param url Action count URL.
 * @param queryParameters Optional query parameters.
 * @returns Promise of counts.
 */
const getAllActionsCounts = async (url: string, queryParameters: {}) => {
    const data = await getAllPaginatedItems<IActionsCount>(url, { params: queryParameters });
    return data;
};

/**
 * Counts actions.
 * @param queryParameters Optional query parameters.
 * @param options Query options.
 * @returns React Query result.
 */
export const useAllActionsCounts = <T = IActionsCount[]>(
    queryParameters: IActionsCountQueryParameters = {},
    options: Omit<UseQueryOptions<IActionsCount[], AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const url = Url.ActionsCounts;
    return useQuery<IActionsCount[], AxiosError, T>(
        [url, queryParameters],
        () => getAllActionsCounts(url, queryParameters),
        options
    );
};

/**
 * Counts actions, returning the first result.
 * @param queryParameters Optional query parameters.
 * @param options Query options.
 * @returns React Query result.
 */
export const useActionsCount = (
    queryParameters: IActionsCountQueryParameters = {},
    options: Omit<UseQueryOptions<IActionsCount[], AxiosError, IActionsCount>, "queryKey" | "queryFn"> = {}
) => useAllActionsCounts({ ...queryParameters }, { ...options, select: (data) => data[0] ?? undefined });

import { AbsolutePaths } from "constants/routes";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageNames } from "types-for/routes";
import ChatEvent from "redux/modules/chat/chat-event";
import { getSocket } from "redux/modules/chat/socket";
import { hotToastOperations } from "utilities-for/toast";

/**
 * Listen for the administrator sign out event and redirect the user to the login page with a toast message when the
 * event is received.
 */
export const useUserForceSignOutListener = () => {
    const navigate = useNavigate();

    const signOutUser = useCallback(() => {
        navigate(AbsolutePaths[PageNames.Login]());

        hotToastOperations.showInfoToast("Signed Out", "You have been signed out by an administrator.");
    }, [navigate]);

    useEffect(() => {
        const createSocketEventListener = async () => {
            const socket = await getSocket();
            socket.on(ChatEvent.AdministratorSignOut, signOutUser);
        };
        createSocketEventListener();

        return () => {
            (async () => {
                const socket = await getSocket();
                socket.off(ChatEvent.UserStateUpdate);
            })();
        };
    }, [signOutUser]);
};

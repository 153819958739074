import addSeconds from "date-fns/addSeconds";
import Axios, { CancelTokenSource, AxiosRequestConfig } from "axios";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { ChatEventType, MessageRecipientType, Url, IMessage } from "@edgetier/types";
import { getServerError } from "@edgetier/utilities";

import ActivityType from "constants/activity-type";
import axios from "utilities/axios";
import dispatchServerError from "utilities/dispatch-server-error";
import isSignedIn from "utilities/is-signed-in";
import { IActivity, IProposedActivitiesPayload, IChatEventActivity } from "redux/modules/chat/chat.types";
import { IApplicationState } from "redux/types";
import { hotToastOperations } from "utilities-for/toast";
import requestCurrentUser from "utilities/request-current-user";
import { enableChat, disableChat } from "utilities/enable-chat";
import { ISettings } from "redux/application.types";

import actions from "./chat-actions";
import { cancelRequests, postEvent, requestChats, decorateChat } from "./chat-utilities";

// How long to wait for a transfer to happen before cancelling it.
const TRANSFER_LIMIT_SECONDS = 90;

type IChatThunk<T = any> = ThunkAction<T, IApplicationState, void, AnyAction>;

// Cancel tokens for proposed activities. They may need to be cancelled at various times such as when an agent signs
// out, a chat ends, or just the backend sends new ones to replace the ones on screen.
let proposedActivityCancelTokenSources: { [index: string]: CancelTokenSource } = {};

/**
 * Cancel any proposed activity timeouts. A proposed activity could be a chat message suggested by the backend that will
 * be automatically sent after a period of time. For some such messages, the backend may indicate that any customer
 * message should cancel the proposed message sending.
 * @param chatToken The chat which may or may not have some proposed activity timer running.
 */
function clearActivityTimeouts(chatToken: string): IChatThunk {
    return async (_, getState) => {
        const chat = getState().chat.chats[chatToken];
        const proposedActivities = typeof chat === "undefined" ? {} : chat.proposedActivities;
        Object.values(proposedActivities)
            .map(({ timeoutId }) => timeoutId)
            .filter((timeoutId): timeoutId is number => typeof timeoutId === "number")
            .forEach((timeoutId) => window.clearTimeout(timeoutId));
    };
}

/**
 * Fetches the current chats and populates the chat state accordingly.
 *  - Does not enable chat
 *  - fetches chats, transcripts, settings, user & customerUrls via requestActiveChats
 *  - throws on error (should be handled by consumer)
 *
 * @param   configuration:      AxiosRequestConfig for cancelling requests
 * @return                      Thunk Promise, rejects with serverError
 */
export function requestActiveChats(configuration: AxiosRequestConfig = {}): IChatThunk {
    return async (dispatch) => {
        try {
            const [{ data: settings }, { data: user }, chats] = await Promise.all([
                axios.get<ISettings>(Url.Settings, configuration),
                requestCurrentUser(configuration),
                requestChats(configuration),
            ]);

            dispatch(actions.updateChatSettings(settings, user));

            const decoratedChats = await Promise.all(chats.map((chat) => decorateChat(chat, settings, configuration)));

            dispatch(actions.resumeChats(decoratedChats));

            return decoratedChats;
        } catch (serverError) {
            dispatch(actions.resumeChats([]));
            throw serverError;
        }
    };
}

/**
 * Turns chat on or off, sends socket events
 *  - fetches chats, transcripts, settings, user & customerUrls via requestActiveChats
 *  - throws on error (should be handled by consumer)
 *
 * @param   configuration:      AxiosRequestConfig for cancelling requests
 * @return                      Thunk Promise, rejects with serverError
 */
function toggleChatOn(configuration: AxiosRequestConfig = {}): IChatThunk {
    return async (dispatch) => {
        dispatch(actions.setHasBeenForceDisabled({ hasBeenForceDisabled: false }));
        await enableChat();
        await dispatch(requestActiveChats(configuration));
        dispatch(actions.toggleChat(true));
    };
}

/**
 * Turns chat on or off, sends socket events
 *  - fetches chats, transcripts, settings, user & customerUrls via requestActiveChats
 *  - throws on error (should be handled by consumer)
 *
 * @param   configuration:      AxiosRequestConfig for cancelling requests
 * @return                      Thunk Promise, rejects with serverError
 */
function toggleChatOff(): IChatThunk {
    return async (dispatch) => {
        dispatch(actions.toggleChat(false));
        return disableChat();
    };
}

/**
 * Clear any timeouts and cancel any requests associated with a given chat.
 * @param chatToken Identifier for a chat from the store.
 */
function cleanUpChat(chatToken: string): IChatThunk {
    return (dispatch, getState) => {
        dispatch(chatOperations.clearActivityTimeouts(chatToken));
        const chat = getState().chat.chats[chatToken];
        const proposedActivities = typeof chat === "undefined" ? {} : chat.proposedActivities;
        Object.keys(proposedActivities).forEach((proposedActivityId) => {
            proposedActivityCancelTokenSources = cancelRequests(proposedActivityCancelTokenSources, proposedActivityId);
        });
    };
}

/**
 * Clear any timeouts and cancel any requests for all chats. This is used when all chats are being cleared such as when
 * an agent signs out. Without doing this, proposed activities like suggested messages could execute after an agent
 * signs out.
 */
function cleanUpChats(): IChatThunk {
    return (dispatch, getState) => {
        Object.values(getState().chat.chats).forEach(({ chatToken }) =>
            dispatch(chatOperations.cleanUpChat(chatToken))
        );
    };
}

/**
 * Exit out of a chat. The chat will be removed from the store and also any background tasks will be cancelled such as
 * proposed activities etc.
 * @param chatToken Identifier for the chat being removed.
 */
function leaveChat(chatToken: string): IChatThunk {
    return (dispatch) => {
        dispatch(chatOperations.cleanUpChat(chatToken));
        dispatch(actions.removeChat({ chatToken }));
    };
}

/**
 * After a successful chat transfer, show a success message and remove the chat.
 * @param payload Data from the backend containing the chat ID that was transferred and a message.
 * @returns       Remove chat thunk action.
 */
function removeTransferredChat(payload: { chatToken: string; message: string }): IChatThunk {
    return (dispatch) => {
        const { chatToken, message } = payload;
        dispatch(chatOperations.leaveChat(chatToken));
        hotToastOperations.showSuccessToast("Chat Transferred", message);
    };
}

/**
 * A chat transfer might be unsuccessful if the receiving agent rejects it. In this case, the agent must be informed.
 * @param payload Data from the backend containing the chat ID that was transferred and an error message.
 * @returns       Report transfer failure thunk action.
 */
function reportTransferFailure(payload: { chatToken: string; message: string }): IChatThunk {
    return (dispatch) => {
        const { chatToken, message } = payload;
        dispatch(actions.toggleTransferring({ chatToken, isTransferring: false }));
        hotToastOperations.showErrorToast("Transfer Failed", message);
        dispatch(actions.setTransferTimeoutId({ chatToken, transferTimeoutId: null }));
    };
}

/**
 * Execute some proposed chat activities either automatically after some time has passed or when an agent explicitly
 * says to send them.
 * @param chatToken          The chat to which the proposed activity belongs.
 * @param proposedActivityId Identifier for the proposed activity being executed.
 * @param activities         One or more activities to perform.
 * @param timeoutId
 */
function performActivities(
    chatToken: string,
    proposedActivityId: string,
    activities: IActivity[] | null,
    timeoutId: number | null
): IChatThunk {
    return async (dispatch) => {
        // Stop the timeout activities being performed if the agent has triggered or cancelled it explicitly.
        if (typeof timeoutId === "number") {
            window.clearTimeout(timeoutId);
        }

        // Now that the activity is being completed, it can be removed from the screen.
        dispatch(actions.removeProposedActivity(chatToken, proposedActivityId));

        try {
            if (Array.isArray(activities)) {
                // If any of the activities are messages, store them so they appear on the chat screen. In the future,
                // there may be more known activity types that will result in something special happening here.
                activities.forEach((activity) => {
                    if (
                        activity.activityTypeId === ActivityType.ChatEvent &&
                        activity.data.chatEventTypeId === ChatEventType.Message
                    ) {
                        dispatch(actions.storeMessage(activity.data));
                    }
                });

                // Execute each activity. The backend determines the URL, HTTP method and data to send.
                const cancelTokenSource = Axios.CancelToken.source();
                const cancelToken = cancelTokenSource.token;

                // Perform one activity at a time. The backend defines the activities in the order it wants them to
                // execute because one may depend on the success of another.
                for (let activityIndex = 0; activityIndex < activities.length; activityIndex++) {
                    const { data, method, url } = activities[activityIndex];
                    await axios({ cancelToken, data, method, url });
                }

                // Store the cancel token source for later possible cancelling.
                proposedActivityCancelTokenSources[proposedActivityId] = cancelTokenSource;
            }
        } catch (serverError) {
            if (!Axios.isCancel(serverError) && Axios.isAxiosError(serverError)) {
                hotToastOperations.showErrorToast("Error", "Failed to execute proposed event.");
            }
        }
    };
}

/**
 * Send a message to the backend.
 * @param payload Message to send to the backend.
 * @returns       A send message action.
 */
function sendMessage(index: number, message: IMessage, attachments: number[]): IChatThunk {
    return async (dispatch) => {
        try {
            // Clear the form of any messages/attachments.
            dispatch(actions.updateChat({ chatToken: message.chatToken, data: { form: null } }));

            // Store the message.
            dispatch(actions.storeMessage(message));

            const postData = { ...message, attachments };
            const { data } = await postEvent<IMessage>(ChatEventType.Message, message.chatToken, postData);

            // Update the message with any new translations.
            dispatch(
                actions.updateMessage({
                    index,
                    chatToken: message.chatToken,
                    message: { ...message, messageId: data.messageId, translations: data.translations },
                })
            );
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                const error = getServerError(serverError);
                dispatch(actions.updateMessage({ index, chatToken: message.chatToken, message: { error } }));
                hotToastOperations.showErrorToast("Message Error", error);
            }
        }
    };
}

/**
 * Store and maybe set a timeout to execute a proposed activity if the backend has instructed to do so.
 * @param proposedActivities Activities proposed by the backend.
 */
function setUpProposedActivities(proposedActivities: IProposedActivitiesPayload): IChatThunk {
    return (dispatch, getState) => {
        // Don't do anything for an unrecognised chat.
        if (!(proposedActivities.chatToken in getState().chat.chats)) {
            return;
        }

        const { append, chatToken, items } = proposedActivities;

        // Cancel any existing timeouts if the backend is not appending these activities. Ongoing HTTP requests are
        // deliberately not cancelled here because once they start being executed the agent would expect them to have
        // been sent. All proposed activities are also cleared so they disappear from the screen.
        if (append === false) {
            dispatch(clearActivityTimeouts(chatToken));
        }

        // The received data is necessary to display a countdown timer if the activity is being executed automatically.
        const receivedAt = new Date();

        // Add some additional information to each proposed activity and maybe start a timer to execute them.
        const proposedActivitiesFormatted = items.map((item, index) => {
            // Each one needs a unique identifier. The titles should be unique.
            const titles = item.activitiesOnExecute.map(({ title }) => title).join("-");
            const proposedActivityId = `${receivedAt.toISOString()}-${titles}-${index}`;

            // Maybe set up some events to execute the activities.
            if (typeof item.sendAfterSeconds === "number") {
                // When will the activity be executed automatically.
                const sendAt = addSeconds(receivedAt, item.sendAfterSeconds);

                // Set up a timeout to execute the activities later.
                const dispatchActivities = () =>
                    dispatch(performActivities(chatToken, proposedActivityId, item.activitiesOnTimeout, null));

                const timeoutId = window.setTimeout(dispatchActivities, item.sendAfterSeconds * 1000);
                return { ...item, isPending: false, proposedActivityId, receivedAt, sendAt, timeoutId };
            } else {
                return { ...item, isPending: false, proposedActivityId, receivedAt, sendAt: null, timeoutId: null };
            }
        });

        // Store all the activities in the store.
        dispatch(actions.storeProposedActivities(chatToken, append, proposedActivitiesFormatted));
    };
}

/**
 * updateProposedActivityAction will update a single action in a set of proposed activities.
 * Will also ensure timeout is not still counting down because editing is obviously happening.
 * @props proposedActivityId the proposedActivity owning the action
 * @props actionIndex        the action index in the 'activitiesOnExecute' array
 * @props action             the partial activity which will be merged with the existing activity
 */
function updateProposedActivityAction(
    proposedActivityId: string,
    actionIndex: number,
    action: Partial<IChatEventActivity>
): IChatThunk {
    return (dispatch, getState) => {
        const {
            chat: { activeChat, chats },
        } = getState();
        if (!activeChat) return;
        const { proposedActivities } = chats[activeChat];
        const { activitiesOnExecute, timeoutId } = proposedActivities[proposedActivityId];
        if (timeoutId) {
            window.clearTimeout(timeoutId);
        }
        const updatedActivitiesOnExecute = [...activitiesOnExecute];
        updatedActivitiesOnExecute.splice(actionIndex, 1, { ...activitiesOnExecute[actionIndex], ...action });

        dispatch(
            actions.updateProposedActivity(activeChat, proposedActivityId, {
                activitiesOnExecute: updatedActivitiesOnExecute,
                timeoutId: null,
                sendAt: null,
            })
        );
    };
}

/**
 * Store messages emitted by the backend. Depending on the type of message, further action may be required. For example,
 * if there is a proposed activity on the screen that the backend has said should be cancelled any time the customer
 * sends a message, that proposed activity will need to be removed.
 * @param message Chat message from the backend.
 */
function storeIncomingMessage(message: IMessage): IChatThunk {
    return (dispatch, getState) => {
        // Store the message so that it will appear on the agent's screen.
        dispatch(actions.storeMessage(message));

        // Some proposed activities should be cancelled when the customer sends a message.
        const chat = getState().chat.chats[message.chatToken];
        if (typeof chat !== "undefined" && message.messageRecipientTypeId === MessageRecipientType.Agent) {
            Object.values(chat.proposedActivities)
                .filter(({ cancelAfterCustomerMessage }) => cancelAfterCustomerMessage)
                .forEach(({ proposedActivityId }) => {
                    dispatch(clearActivityTimeouts(chat.chatToken));
                    dispatch(actions.removeProposedActivity(chat.chatToken, proposedActivityId));
                });
        }
    };
}

/**
 * Proposed activities have no confirmation button. Feedback from agents suggests they're sent accidentally some times. A
 * button was introduced that delays sending by a few seconds that allows them to cancel if necessary before it sends.
 * After clicking the send button, the proposed activity goes into a pending state that will prohibit some actions like
 * removing or editing the proposed activity.
 *
 * Since some proposed activities send automatically after some time has passed, the timers on these are cancelled so
 * there aren't two conflicting timers running.
 * @param chatToken          Chat to which the proposed activity belongs.
 * @param proposedActivityId Identifier for the pending proposed activity.
 * @param timeoutId          Timeout ID if the proposed activity is a timed one.
 * @param isPending          New pending state (a state of true means the proposed activity will send shortly).
 */
function togglePendingProposedActivity(
    chatToken: string,
    proposedActivityId: string,
    timeoutId: number | null,
    isPending: boolean
): IChatThunk {
    return (dispatch) => {
        // Stop any automatic sending if it applies to this activity.
        if (isPending && typeof timeoutId === "number") {
            window.clearTimeout(timeoutId);
        }

        // Clear timing details and set the pending state.
        const updates = { isPending, sendAt: null, timeoutId: null };
        dispatch(actions.updateProposedActivity(chatToken, proposedActivityId, updates));
    };
}

/**
 * Request that the backend transfer a chat to another agent.
 * @param chatToken Identifier for the chat being transferred.
 * @param note      Reason for transfer or details about the chat for the next agent.
 * @param toUserId  Transfer target.
 * @returns         Transfer chat thunk action.
 */
function transferChat(
    chatToken: string,
    {
        note,
        toUserId,
        timeoutErrorCallback,
    }: Partial<{ note: string; toUserId: number; timeoutErrorCallback: VoidFunction }> = {}
): IChatThunk {
    return async (dispatch, getState) => {
        try {
            await postEvent(ChatEventType.TransferRequest, chatToken, { note, toUserId });

            // Mark that the chat is being transferred.
            dispatch(actions.toggleTransferring({ chatToken, isTransferring: true }));

            // Create a timeout to check the transfer status after the time has elapsed.
            const transferTimeoutId = window.setTimeout(async function (): Promise<void> {
                const state = getState();

                // Can't do anything if the agent has signed out.
                if (!isSignedIn(state)) {
                    return;
                }

                // Check if the chat is even with the agent any more.
                const chat = state.chat.chats[chatToken];
                if (typeof chat === "undefined") {
                    return;
                }

                if (chat.transferTimeoutId === transferTimeoutId) {
                    try {
                        // Ask the backend to cancel the transfer. Any error here still results in the agent holding
                        // on to the chat.
                        await postEvent(ChatEventType.TransferCancel, chatToken);
                    } finally {
                        // The chat is no longer being transferred.
                        dispatch(actions.toggleTransferring({ chatToken, isTransferring: false }));

                        // Remove the timeout tracking.
                        dispatch(actions.setTransferTimeoutId({ chatToken, transferTimeoutId: null }));

                        // Notify the agent that the transfer failed. This is a toast rather than a modal because
                        // the agent is probably elsewhere such as working on another chat.

                        hotToastOperations.showErrorToast(
                            "Transfer Failed",
                            `No agents have accepted the transfer request for ${chat.customerName}.`
                        );

                        timeoutErrorCallback && timeoutErrorCallback();
                    }
                }
            }, TRANSFER_LIMIT_SECONDS * 1000);

            // Save the timeout value in the store. When the wait time has passed, this value is checked to ensure
            // it was the same timeout.
            dispatch(actions.setTransferTimeoutId({ chatToken, transferTimeoutId }));
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                dispatchServerError(dispatch, "Failed to transfer chat.")(serverError);
            }
            throw serverError;
        }
    };
}

/**
 * Stop the inactivity timer of a chat so that it will not end from inactivity.
 * @param chatToken The chat token of the chat who's timer is being stopped.
 */
function stopInactivityTimer(chatToken: string): IChatThunk {
    return async (dispatch) => {
        try {
            await postEvent(ChatEventType.StopInactivityTimer, chatToken);
            dispatch(actions.updateChat({ chatToken, data: { inactivityTimerStopped: true } }));
        } catch (serverError) {
            hotToastOperations.showErrorToast("Failed to stop inactivity timer");
            throw serverError;
        }
    };
}

/**
 * Start the inactivity timer of a chat.
 * @param chatToken The chat token of the chat who's timer is being resumed.
 */
function startInactivityTimer(chatToken: string): IChatThunk {
    return async (dispatch) => {
        try {
            await postEvent(ChatEventType.StartInactivityTimer, chatToken);
            dispatch(actions.updateChat({ chatToken, data: { inactivityTimerStopped: false } }));
        } catch (serverError) {
            hotToastOperations.showErrorToast("Failed to start inactivity timer");
            throw serverError;
        }
    };
}

const chatOperations = {
    ...actions,
    cleanUpChat,
    cleanUpChats,
    clearActivityTimeouts,
    leaveChat,
    performActivities,
    removeTransferredChat,
    reportTransferFailure,
    requestActiveChats,
    sendMessage,
    setUpProposedActivities,
    startInactivityTimer,
    stopInactivityTimer,
    storeIncomingMessage,
    toggleChatOff,
    toggleChatOn,
    togglePendingProposedActivity,
    transferChat,
    updateProposedActivityAction,
};

export default chatOperations;

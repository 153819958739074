import { formatDate } from "@edgetier/utilities";

import { IProps } from "./split-timestamp.types";
import "./split-timestamp.scss";

/**
 * Split a date into date and time so they can be styled separately.
 * @param date A date to format.
 * @returns    Date and time in separate elements.
 */
const SplitTimestamp = ({ date }: IProps) => (
    <span className="split-timestamp">
        <span>{formatDate(date, { intlOptions: { dateStyle: "short" } })}</span>
        <span className="split-timestamp__time">{formatDate(date, { intlOptions: { timeStyle: "short" } })}</span>
    </span>
);

export default SplitTimestamp;

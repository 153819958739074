import { FunctionComponent, memo, useContext } from "react";
import type { IProps } from "./navigation-group-notification-count.types";
import navigationGroupNotificationsContext from "context-for/navigation-group-notifications/navigation-group-notifications-context";
import { NavigationItemNotificationCount } from "../navigation-item-notification-count";

/**
 * Displays the notification count for the navigation group. It gets the value through context.
 */
const NavigationGroupNotificationCount: FunctionComponent<IProps> = () => {
    const { notificationCount } = useContext(navigationGroupNotificationsContext);

    if (notificationCount === 0) {
        return null;
    }

    return <NavigationItemNotificationCount notificationCount={notificationCount} />;
};

export default memo(NavigationGroupNotificationCount);

import { FunctionComponent, memo, useRef, useState, useEffect } from "react";
import Axios from "axios";
import { Button } from "@edgetier/components";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { getServerError } from "@edgetier/utilities";
import { downloadEmailTranscript } from "./download-email-transcript-button.utilities";
import { hotToastOperations } from "utilities-for/toast";
import { IProps } from "./download-email-transcript-button.types";
import "./download-email-transcript-button.scss";

/**
 * Button for downloading email transcript
 * @param props.emailId
 */
const DownloadEmailTranscriptButton: FunctionComponent<IProps> = ({ conversationId }) => {
    const cancelTokenSource = useRef(Axios.CancelToken.source());
    const [submitting, setSubmitting] = useState(false);

    /**
     * Submit request for download email transcript
     */
    const onDownload = async () => {
        try {
            setSubmitting(true);
            await downloadEmailTranscript(conversationId, {
                cancelToken: cancelTokenSource.current.token,
                params: { includeAllContent: true },
            });
        } catch (serverError) {
            hotToastOperations.showErrorToast(getServerError(serverError));
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        return cancelTokenSource.current.cancel;
    }, [cancelTokenSource]);

    return (
        <Button
            className="download-email-transcript__button button--no-modal"
            icon={faDownload}
            isLoading={submitting}
            onClick={onDownload}
            isIconOnly
        >
            Transcript
        </Button>
    );
};

export default memo(DownloadEmailTranscriptButton);

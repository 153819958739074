import { IChatState } from "redux/modules/chat/chat.types";

import actions from "../chat-actions";
import { chatIndices } from "../chat-utilities";

/**
 * Leave a chat. If this was the active chat, another one will be activated.
 * @param state  Application state before removing a given chat.
 * @param action Action containing a payload of the chat to remove.
 * @returns      Chat state without the chat in question.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.removeChat>): IChatState {
    const { chatToken } = action.payload;
    const chats = { ...state.chats };
    const chatToRemove = chats[chatToken];

    if (typeof chatToRemove !== "undefined") {
        chatIndices.hold(chatToRemove.chatIndex);
    }
    delete chats[chatToken];

    // Activate another chat if possible.
    const chatTokens = Object.keys(state.chats).filter((token) => token !== chatToken);
    if (chatTokens.length === 0) {
        return { ...state, activeChat: null, chats };
    } else {
        const activeChat = state.activeChat && state.activeChat !== chatToken ? state.activeChat : chatTokens[0];
        const chat = state.chats[activeChat];
        return { ...state, activeChat, chats: { ...chats, [activeChat]: { ...chat, unreadMessages: 0 } } };
    }
}

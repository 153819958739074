import { NumericValue } from "@edgetier/client-components";
import { FunctionComponent, memo } from "react";

import "./select-list-title.scss";
import { IProps } from "./select-list-title.types";

/**
 * Display a title above a select list.
 * @param props.count Number of items.
 * @param props.title Text description.
 * @returns           Title and number.
 */
const SelectListTitle: FunctionComponent<IProps> = ({ count, title }) => {
    return (
        <div className="select-list-title">
            {title}
            <span>
                (<NumericValue value={count} />)
            </span>
        </div>
    );
};

export default memo(SelectListTitle);

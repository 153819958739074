import Dotdotdot from "react-dotdotdot";
import { AccordionItem, AccordionItemContent, AccordionItemHeader, DetailsFields } from "@edgetier/client-components";
import { VariableType } from "@edgetier/types";

import { IProps } from "./customer-url.types";
import "./customer-url.scss";
import { formatDate } from "@edgetier/utilities";

export const URL_REGEX = /^(https?:)?(\/\/)?(www\.)?/i;

/**
 * Display the URL of a page a customer visited. This is to help agents handle chats.
 * @param props.customerUrl Details of a URL the customer visited.
 * @returns                 URL title, hyperlink, and maybe expandable section to see parsed URL parameters.
 */
const CustomerUrl = ({ customerUrl }: IProps) => {
    return (
        <div className="customer-urls__customer-url">
            <AccordionItem canOpen={customerUrl.details.length > 0}>
                <AccordionItemHeader>
                    <div className="customer-urls__customer-url__title">
                        <span className="customer-urls__customer-url__time">
                            {formatDate(customerUrl.dateTime, { intlOptions: { timeStyle: "short" } })}
                        </span>

                        <Dotdotdot clamp={1}>
                            {typeof customerUrl.pageTitle === "string" ? customerUrl.pageTitle : "Unknown Page"}
                        </Dotdotdot>
                    </div>
                </AccordionItemHeader>

                <a href={customerUrl.url} rel="noopener noreferrer" target="_blank">
                    <Dotdotdot clamp={3}>{customerUrl.url.replace(URL_REGEX, "")}</Dotdotdot>
                </a>

                <AccordionItemContent>
                    <DetailsFields
                        fields={customerUrl.details.map((detail) => ({
                            properties: null,
                            title: detail.title,
                            value: detail.value!,
                            variableTypeId: VariableType.String,
                            warning: null,
                        }))}
                    />
                </AccordionItemContent>
            </AccordionItem>
        </div>
    );
};

export default CustomerUrl;

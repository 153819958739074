/**
 * Prevent markdown from collapsing whitespace by prepending newline characters with two spaces.
 * @param markdownString
 * @returns A markdown string with newline characters prepended with two spaces.
 */
export const preserveNewlines = (markdownString: string) => {
    return markdownString.replace(/\n/gi, " &nbsp;\n");
};

/**
 * Preprocess the string to escape periods in numbered sequences that are likely not intended as markdown lists.
 * For example, "1234. hello" will become "1234\\. hello", preventing markdown from interpreting it as a list.
 * @param message The markdown string to preprocess.
 * @returns A preprocessed markdown string with large numbered sequences escaped.
 */
export const preprocessString = (message: string) => {
    return message.replace(/(^|\n)(\d+)\.(\s|$)/g, (match, p1, p2) => {
        // Only replace if the number is large enough to not be a common list item
        if (p2.length > 2) {
            return `${p1}${p2}\\. `;
        }
        return match;
    });
};

import { IBookingDetailsData } from "redux/application.types";
import axios from "./axios";
import urlJoin from "url-join";
import { Url } from "@edgetier/types";

/**
 * Request the booking details from the backend if there is a bookingId passed.
 * @param url The backend route for details.
 * @param brandId The ID of the brand, if it is passed.
 * @returns The booking details if there is a brand ID, null otherwise.
 */
export const getBookingDetails = async (brandId: number | null, interactionDetailId?: number | null) => {
    const url =
        typeof interactionDetailId === "number"
            ? urlJoin(Url.Interactions, interactionDetailId.toString(), Url.AgentInformation)
            : Url.BookingDetails;

    if (typeof brandId === "number") {
        const { data } = await axios.get<IBookingDetailsData>(url, { params: { brandId } });

        return data;
    }

    return null;
};

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import "./expand-collapse-arrows.scss";
import { IProps } from "./expand-collapse-arrows.types";

/**
 * Animated up/down arrow to open some content.
 * @param props.isUp Current state of the arrow.
 * @returns          Animated up/down arrow icon.
 */
const ExpandCollapseArrows = ({ isUp }: IProps) => {
    return (
        <div className={classNames("expand-collapse-arrow", { "expand-collapse-arrow--up": isUp })}>
            <FontAwesomeIcon icon={faAngleDown} />
        </div>
    );
};

export default ExpandCollapseArrows;

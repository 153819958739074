import React, { FunctionComponent, memo, useRef, isValidElement } from "react";
import classnames from "classnames";
import ResizeObserver from "resize-observer-polyfill";
import { useLayer, useHover, mergeRefs } from "react-laag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProps } from "./category-navigation.types";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import "./category-navigation.scss";
import CategoryNavigationContext from "./category-navigation-context/category-navigation-context";

/**
 * Display navigation as categories
 * @param props.children    Navigation content.
 * @param props.icon        Icon showed on trigger
 * @param props.label       Text label for category
 * @param props.placement   Menu placement
 * @returns
 */
const CategoryNavigation: FunctionComponent<IProps> = ({
    children,
    icon,
    isActive = false,
    label,
    placement = "right-center",
    className,
    indicator,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isOver, hoverProps] = useHover({ delayEnter: 50, delayLeave: 50, hideOnScroll: false });

    // Didn't know how to display the menu on hover, but I found this comment on a closed issue on
    // react-laag. It provides a basic example.
    // https://github.com/everweij/react-laag/issues/65#issuecomment-845070906
    const mouseLeaveHandler = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const { relatedTarget } = event;

        if (!(relatedTarget instanceof Element && ref.current?.contains(relatedTarget))) {
            hoverProps.onMouseLeave(event);
        }
    };

    const close = () => {
        hoverProps.onMouseLeave();
    };

    const { renderLayer, triggerProps, layerProps } = useLayer({
        auto: true,
        isOpen: isOver,
        placement,
        possiblePlacements: ["right-center", "right-end", "right-start"],
        overflowContainer: true,
        ResizeObserver,
        triggerOffset: 0,
    });

    const categoryMenu = (
        <div
            {...layerProps}
            ref={mergeRefs(layerProps.ref, ref)}
            className="category-navigation__menu"
            onMouseLeave={hoverProps.onMouseLeave}
        >
            <CategoryNavigationContext.Provider value={{ close }}>
                <ul role="menu">{children}</ul>
            </CategoryNavigationContext.Provider>
        </div>
    );

    return (
        <>
            <li
                id={`category-navigation-item__${label}`}
                className={classnames("category-navigation__item", className)}
                aria-label="category-navigation-item"
                {...triggerProps}
                {...hoverProps}
                onMouseLeave={mouseLeaveHandler}
            >
                <div
                    className={classnames("category-navigation__item__inner", {
                        "category-navigation__item__inner--active": isActive,
                    })}
                >
                    <FontAwesomeIcon fixedWidth={true} icon={icon} />
                    <div className="category-navigation__item__label">{label}</div>

                    <div className="category-navigation__item__caret">
                        <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} />
                    </div>
                </div>
                {isValidElement(indicator) && <div className="category-navigation__item__indicator">{indicator}</div>}
            </li>

            {isOver && renderLayer(categoryMenu)}
        </>
    );
};

export default memo(CategoryNavigation);

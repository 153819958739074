import { FormFieldType } from "@edgetier/types";
import { formatDate, unreachableCaseError } from "@edgetier/utilities";
import { IFormSubmissionField } from "redux/application.types";

/**
 * Get the form field value based on the form field type.
 * @param field The form submission field.
 */
export const getFormFieldValue = (field: IFormSubmissionField) => {
    switch (field.formField.formFieldTypeId) {
        case FormFieldType.Text:
        case FormFieldType.Textarea:
        case FormFieldType.Email:
        case FormFieldType.Boolean:
        case FormFieldType.Float:
        case FormFieldType.Integer:
        case FormFieldType.SourcedSelect:
            return field.value || "";

        case FormFieldType.Select:
        case FormFieldType.Radios:
        case FormFieldType.Checkboxes:
        case FormFieldType.Buttons:
            return field.selectedOptionIds || [];

        case FormFieldType.Timestamp:
        case FormFieldType.Date:
            return formatDate(new Date(field.value as string), { explicitFormat: "yyyy-MM-dd" });

        case FormFieldType.Time:
            // NOTE: The time picker only allows hours and minutes, so we remove the seconds.
            return field.value?.toString().slice(0, -3);

        case FormFieldType.DateTime:
            return formatDate(new Date(field.value as string), { explicitFormat: "yyyy-MM-dd hh:mm" });

        case FormFieldType.NetPromoterScore:
        case FormFieldType.Star:
            return field.value || 0;

        default:
            throw unreachableCaseError(field.formField);
    }
};

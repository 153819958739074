import React from "react";

import { IProps } from "./reupload.types";

/**
 * When the user is attaching a file, leaves, and then comes back, the upload is resumed by this component.
 */
export default class Reload extends React.PureComponent<IProps> {
    /**
     * Continue the upload of any files currently in progress.
     */
    componentDidMount(): void {
        this.props.attachments
            .filter(({ attachmentId }) => typeof attachmentId !== "number")
            .forEach(({ file }) => this.props.upload(file));
    }

    /**
     * This component only exists to run on mount.
     */
    render(): null {
        return null;
    }
}

import defaultUserEvent from "@testing-library/user-event";
import { screen } from "@testing-library/react";
import { ISelectOptions } from "./select";

/**
 * Open or close the menu.
 * @param menuMatcher Optional matcher to find the menu in case there are multiple.
 * @returns           Menu button.
 */
const clickMenuButton = async ({ menuMatcher, userEventInstance }: ISelectOptions = {}) => {
    const userEvent = userEventInstance ?? defaultUserEvent;
    const menuButton =
        menuMatcher instanceof HTMLElement
            ? menuMatcher
            : typeof menuMatcher !== "undefined"
              ? screen.getByLabelText(menuMatcher)
              : screen.getByRole("combobox");
    await userEvent.click(menuButton);
};

export default clickMenuButton;

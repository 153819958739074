import GridContext from "~/grid/grid-context";

/**
 * Show something in the table if there are no results.
 * @returns Table row.
 */
const Empty = () => (
    <GridContext.Consumer>
        {({ columnCount, emptyMessage }) => (
            <tr className="grid__empty">
                <td colSpan={columnCount}>{emptyMessage}</td>
            </tr>
        )}
    </GridContext.Consumer>
);

export default Empty;

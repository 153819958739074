import Attachments from "components/attachments";
import axios from "utilities/axios";
import InboundEmailParticipants from "components-for/emails/email-participants/inbound-email-participants";
import OutboundEmailParticipants from "components-for/emails/email-participants/outbound-email-participants";
import RedactAction from "components-for/query-history/redact-action";
import { EllipsisMenu } from "@edgetier/client-components";
import { InteractionLabels } from "components/interaction-label/interaction-label.constants";
import { IProps } from "./email.types";
import { ToggleTranslationSection } from "shared/toggle-translation";
import "./email.scss";
import VerifyPermission from "components-for/verify-permission/verify-permission";
import { Permission, Url } from "@edgetier/types";
import urlJoin from "url-join";

/**
 * Display an email in the interaction search results.
 * @param props Props containing email details such as the body, subject, email address etc.
 * @returns     Email display.
 */
const Email = ({
    attachments,
    bodyHtml,
    bodyText,
    emailAddress,
    emailId,
    fromEmailAddress,
    interactionUrl,
    isEmailIn,
    recipients,
    subject,
}: IProps) => (
    <div className="interaction-search__email">
        <div className="interaction-search__email__title">
            <div>
                <ToggleTranslationSection translations={subject}>
                    {({ translation }) => typeof translation === "string" && <strong>{translation.trim()}</strong>}
                </ToggleTranslationSection>
                {isEmailIn ? (
                    <InboundEmailParticipants fromEmailAddress={emailAddress} recipients={recipients} />
                ) : (
                    <OutboundEmailParticipants fromEmailAddress={fromEmailAddress} toEmailAddress={emailAddress} />
                )}
            </div>
            {isEmailIn ? InteractionLabels.EmailIn : InteractionLabels.EmailOut}
        </div>
        <Attachments attachments={attachments} getAttachment={axios.get} limitHeight={true} />
        <ToggleTranslationSection translations={bodyText}>
            {({ translation }) =>
                typeof translation === "string" && <p>{translation.replace(/\n{3,}/g, "\n\n").trim()}</p>
            }
        </ToggleTranslationSection>

        <VerifyPermission permission={Permission.RedactContent}>
            <div className="interaction-search__email__redact">
                {isEmailIn && typeof emailId === "number" && (
                    <EllipsisMenu placement="bottom-start" overflowContainer>
                        <RedactAction
                            interactionUrl={interactionUrl}
                            sections={[
                                {
                                    label: "Subject",
                                    property: "subject",
                                    translations: subject,
                                },
                                {
                                    label: "Plain Text",
                                    property: "bodyText",
                                    translations: bodyText,
                                },
                                ...(bodyHtml === null
                                    ? []
                                    : [
                                          {
                                              label: "Raw (HTML)",
                                              property: "bodyHtml",
                                              translations: bodyHtml,
                                          },
                                      ]),
                            ]}
                            size="wide"
                            url={urlJoin(Url.Emails, emailId.toString())}
                        />
                    </EllipsisMenu>
                )}
            </div>
        </VerifyPermission>
    </div>
);

export default Email;

import classNames from "classnames";
import { format } from "date-fns";
import { FunctionComponent, memo } from "react";

import FormattedDate from "../formatted-date";
import "./formatted-date-time.scss";
import { IProps } from "./formatted-date-time.types";

/**
 * Format a date for a user including both the date and time.
 * @param props.colourTime Whether the time should have a different colour or not.
 * @param props.dateTime   Date to render.
 * @returns                Formatted date.
 */
const FormattedDateTime: FunctionComponent<IProps> = ({ colourTime = true, dateTime }) => {
    return (
        <div className="formatted-date-time">
            <FormattedDate dateTime={dateTime} />{" "}
            <span
                className={classNames("formatted-date-time__time", {
                    "formatted-date-time__time--different-colour": colourTime,
                })}
            >
                {format(dateTime, "p")}
            </span>
        </div>
    );
};

export default memo(FormattedDateTime);

import { ConditionalIcon } from "@edgetier/client-components";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";

import SelectField from "../select-field";
import "./select-boolean.scss";
import { IProps } from "./select-boolean.types";

/**
 * Select input for true or false with appropriate labels.
 * @param props.falseLabel Label for false values.
 * @param props.name       Field name.
 * @param props.falseLabel Label for true values.
 * @returns                Select input.
 */
const SelectBoolean = ({ falseLabel, name, trueLabel, ...otherProps }: IProps) => (
    <SelectField
        {...otherProps}
        name={name}
        isSearchable={false}
        isSingleSelect
        isSortedAlready
        items={[
            { label: trueLabel, value: true },
            { label: falseLabel, value: false },
        ]}
        getLabel={({ label }) => label}
        getValue={({ value }) => value}
    >
        {(item) => (
            <span className="select-boolean__item">
                <ConditionalIcon
                    classNameFalse="select-boolean__item--false"
                    classNameTrue="select-boolean__item--true"
                    condition={item.value}
                    iconFalse={faTimes}
                    iconTrue={faCheck}
                />{" "}
                {item.label}
            </span>
        )}
    </SelectField>
);

export default SelectBoolean;

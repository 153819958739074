import { AxiosError, AxiosInstance } from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { IPermission } from "@edgetier/types";
import { createGetAllPaginatedItems } from "@edgetier/utilities";

/**
 * Request permissions for a specific role
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 */
const getPermissions = async (routeUrl: string, axiosInstance: AxiosInstance) => {
    const getAllPaginatedItems = createGetAllPaginatedItems(axiosInstance);

    const permissions = await getAllPaginatedItems<IPermission>(routeUrl);
    return permissions;
};

/**
 * Hook to get all permissions for a specific role
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 * @param roleId
 * @param configuration     Optional Axios configuration
 * @returns React Query result.
 */
const useRolePermissions = <T = IPermission[]>(
    routeUrl: string,
    axiosInstance: AxiosInstance,
    roleId: string,
    configuration: UseQueryOptions<IPermission[], AxiosError<void>, T> = {}
) => {
    const url = routeUrl.replace(":roleId", roleId);

    return useQuery<IPermission[], AxiosError<void>, T>([url], () => getPermissions(url, axiosInstance), {
        ...configuration,
    });
};

export default useRolePermissions;

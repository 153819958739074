import { Url } from "@edgetier/types";
import { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { IUser } from "redux/application.types";
import { authenticationOperations } from "redux/modules/authentication";
import { IThunkDispatch } from "redux/types";
import ChatEvent from "redux/modules/chat/chat-event";
import { getSocket } from "redux/modules/chat/socket";
import urlJoin from "url-join";

const RECONNECT_FAILED_EVENT = "reconnect_failed";

/**
 * If the socket connection reconnects, refetch the user's data because their user state may have been changed by the
 * backend.
 */
export const useUserStateReconnectListener = (user: IUser | undefined) => {
    const dispatch = useDispatch<IThunkDispatch>();
    const queryClient = useQueryClient();

    /**
     * Refetch the user's data to update the query cache with the latest userStateId.
     */
    const refetchQueries = useCallback(async () => {
        await queryClient.refetchQueries({ queryKey: urlJoin(Url.Users, user?.userId.toString() ?? "") });
    }, [queryClient, user?.userId]);

    // If the socket reconnects, refetch the user's data.
    useEffect(() => {
        const reconnectListener = async () => {
            const socket = await getSocket();
            socket.io.on(ChatEvent.Reconnect, refetchQueries);
        };
        reconnectListener();

        return () => {
            (async () => {
                const socket = await getSocket();
                socket.io.off(ChatEvent.Reconnect);
            })();
        };
    }, [refetchQueries]);

    // If the socket fails to reconnect, redirect the user to the login page.
    useEffect(() => {
        const createFailedReconnectListener = async () => {
            const socket = await getSocket();
            socket.io.on(RECONNECT_FAILED_EVENT, () => {
                dispatch(authenticationOperations.signOut());
            });
        };
        createFailedReconnectListener();

        return () => {
            (async () => {
                const socket = await getSocket();
                socket.io.off(RECONNECT_FAILED_EVENT);
            })();
        };
    }, [dispatch]);
};

import { useQuery, UseQueryOptions } from "react-query";
import { Url } from "@edgetier/types";
import { AxiosError } from "axios";
import axios from "utilities/axios";
import { IUser } from "redux/application.types";
import urlJoin from "url-join";

/**
 * Request a user from the backend.
 * @param url             The url to get the user.
 * @param queryParameters Any query parameters to send with the request.
 * @returns A user object.
 */
const requestUser = async (url: string, queryParameters: {}) => {
    const { data } = await axios.get(url, { params: queryParameters });
    return data;
};

/**
 * A query to get a user from the backend.
 * @param userId          The ID of the user being requested.
 * @param queryParameters Optional query parameters to send with the request.
 * @param options         An optional react query configuration.
 */
const useUser = <T = IUser>(
    userId: number,
    queryParameters: {} = {},
    options: Omit<UseQueryOptions<IUser, AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const url = urlJoin(Url.Users, userId.toString());
    return useQuery<IUser, AxiosError, T>([url, queryParameters], () => requestUser(url, queryParameters), options);
};

export default useUser;

/**
 * Get all unique items from an array of object, without using new Set()
 * @param allItems        Array of items
 * @param comparator      Callback function for comparing indexed values
 */
export const uniqueBy = <Item extends {}>(allItems: Item[], comparator: (item: Item, indexedItem: Item) => boolean) => {
    return allItems.filter(
        (item, index, items) => items.findIndex((indexedItem) => comparator(item, indexedItem)) === index
    );
};

export enum FormType {
    StartChat = 1,
    ChatSurvey = 2,
    WrapUp = 3,
    Deflection = 4,
    IVR = 5,
    OfflineDeflection = 6,
}

export default FormType;

import { formatDate } from "./format-date";

/**
 * Split a date into date and time and return it as a string.
 * @param date A date to format.
 * @returns    A date and time string.
 */
export const getSplitTimestamp = (date: Date) => {
    return `${formatDate(date, { intlOptions: { dateStyle: "short" } })} ${formatDate(date, { intlOptions: { timeStyle: "short" } })}`;
};

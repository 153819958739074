import { formatNumber } from "./format-number";

const MAXIMUM_FRACTION_DIGITS = 1;

// All potential time units and methods to convert from seconds to that unit.
type ITimeUnitProperties = [
    name: string,
    shortName: string,
    convert: (seconds: number) => number,
    maximumFactionDigits: number,
];
const TIME_UNITS: ITimeUnitProperties[] = [
    ["week", "w", (seconds) => seconds / 604_800, MAXIMUM_FRACTION_DIGITS],
    ["day", "d", (seconds) => seconds / 86_400, MAXIMUM_FRACTION_DIGITS],
    ["hour", "h", (seconds) => seconds / 3_600, MAXIMUM_FRACTION_DIGITS],
    ["minute", "m", (seconds) => seconds / 60, MAXIMUM_FRACTION_DIGITS],
    ["second", "s", (seconds) => seconds, 0],
];

/**
 * Figure out the best unit to use from seconds. If the range of the data goes from say 10 to 30 seconds, it's best
 * just to display the data in seconds. When the range is more than a minute (or an hour/day) then the data will all be
 * displayed in the largest appropriate unit.
 * @param seconds Number of seconds.
 * @returns Unit and conversion from seconds to the desired unit.
 */
export const convertTimeUnit = (
    seconds: number,
    useShortUnit?: boolean
): [string, (value: number | null) => string] => {
    const timeUnit = TIME_UNITS.find(([, , convert]) => convert(seconds) >= 1) ?? TIME_UNITS[TIME_UNITS.length - 1];

    const [unit, shortUnit, convert, maximumFractionDigits] = timeUnit;
    // We're using a single fraction digit and every value between 1 and 1.099.. is considered singular unit,
    // everything else is for multiple units
    const parsedUnit = Math.abs(convert(seconds)) >= 1.1 ? `${unit}s` : unit;

    return [
        useShortUnit ? shortUnit : parsedUnit,
        (value: number | null) => formatNumber(convert(value ?? 0), { maximumFractionDigits }),
    ];
};

import classNames from "classnames";
import useAccordionItemContext from "~/accordion/use-accordion-item-context";

import "./accordion-item-container.scss";
import { IProps } from "./accordion-item-container.types";

/**
 * Container that applies some styling to accordion items such as when they are open or closed.
 * @param props.children Accordion item content.
 * @returns              Container around accordion item content.
 */
const AccordionItemContainer = ({ children }: IProps) => {
    const { canOpen, isOpen } = useAccordionItemContext();
    return (
        <div
            className={classNames("accordion__accordion-item", {
                "accordion__accordion-item--can-open": canOpen,
                "accordion__accordion-item--is-open": isOpen,
            })}
        >
            {children}
        </div>
    );
};

export default AccordionItemContainer;

import { FunctionComponent, PropsWithChildren, memo } from "react";
import "./information-field.scss";

/**
 * Component for displaying an information field.
 * @param {object} props - The props for the InformationField component.
 * @param {ReactNode} props.children - The child components containing information.
 * @returns {JSX.Element} InformationField component.
 */
const InformationField: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    return <div className="information-field">{children}</div>;
};

export default memo(InformationField);

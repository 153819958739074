import { useField } from "formik";
import Select from "~/select";

import "./select-field.scss";
import { IProps } from "./select-field.types";

/**
 * Wrapper to connect a select to a Formik field. Hidden inputs are also added so it behaves like a proper form control.
 * This technique was inspired by React Select.
 * @param props.name  Field name.
 * @param selectProps Other props to pass down to the select component.
 */
const SelectField = <IItem extends {}, IValue extends {} = string>({
    fieldProps,
    name,
    onSelect,
    isMultipleSelectCheckbox,
    enableAutoFocus = true,
    labelledBy,
    ...selectProps
}: IProps<IItem, IValue>) => {
    const [{ value }, , { setValue }] = useField<IValue | IValue[]>({ ...(fieldProps as any), name });
    const values = Array.isArray(value) ? value : [value];

    return (
        <>
            <Select<IItem, IValue>
                labelledBy={labelledBy}
                inputId={name}
                onSelect={(values, items) => {
                    setValue(values);
                    if (typeof onSelect === "function") {
                        onSelect(items);
                    }
                }}
                selectedValues={value}
                {...(selectProps as any)}
                isMultipleSelectCheckbox={isMultipleSelectCheckbox}
                enableAutoFocus={enableAutoFocus}
            />

            {values.map((value) => (
                <input
                    key={String(value)}
                    name={name}
                    aria-hidden="true"
                    type="hidden"
                    value={typeof value === "undefined" || value === null ? "" : String(value)}
                />
            ))}
        </>
    );
};

export default SelectField;

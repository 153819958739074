import { FunctionComponent } from "react";

import ColumnContext from "../column-context";
import { IProps } from "./column-provider.types";

export const ColumnProvider: FunctionComponent<IProps> = ({ children, ...context }) => {
    return <ColumnContext.Provider value={context}>{children}</ColumnContext.Provider>;
};

export default ColumnProvider;

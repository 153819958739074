import { AxiosError } from "axios";
import { hotToastOperations } from "utilities-for/toast";

/**
 * Show a toast error.
 */
export const onNotesRequestError = (serverError: AxiosError) => {
    hotToastOperations.showServerErrorToast("Note Request Failed", serverError);
};

/**
 * Only show toast error if the server error is not a 404.
 */
export const onUserRequestError = (serverError: AxiosError) => {
    // If the user request returns a 404, it means the note may be been written by a user who cannot be accessed by
    // the current user. This may happen if the current user is department restricted.
    if (serverError.response?.status === 404) {
        return;
    }
    onNotesRequestError(serverError);
};

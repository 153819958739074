import { FunctionComponent, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IProps } from "./handle-query-button.types";
import { Button } from "@edgetier/components";
import Axios from "axios";
import { faCheck, faCheckCircle, faEnvelopeOpen, faExchangeAlt, faWarning } from "@fortawesome/pro-solid-svg-icons";
import Modal, { ModalContent, ModalFooter, ModalHeader, useModal } from "@edgetier/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState, IThunkDispatch } from "redux/types";
import { useNavigate } from "react-router-dom";
import { IQueryEmail } from "redux/modules/email/email.types";
import { emailOperations } from "redux/modules/email";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";
import axios from "utilities/axios";
import { Url } from "@edgetier/types";
import { StatusCodes } from "http-status-codes";
import { hotToastOperations } from "utilities-for/toast";
import { doNothing, getServerError } from "@edgetier/utilities";
import "./handle-query-button.scss";
import { useCurrentUser } from "utilities/use-current-user";
import useUserStates from "hooks-for/users/use-user-states";

/**
 * Button for handling an email
 * @param props.interaction         Current interaction
 * @param props.toggleRoutedEmail   Callback for converting the handle query to send email button
 */
const HandleQueryButton: FunctionComponent<IProps> = ({ interaction, toggleRoutedEmail }) => {
    const { data: user } = useCurrentUser();
    const { data: userStates } = useUserStates(
        {},
        {
            enabled: typeof user !== "undefined",
        }
    );

    const [isHandlingInProgress, setIsHandlingInProgress] = useState(true);
    const { modalProps, openModal, closeModal } = useModal({
        onCloseModal: isHandlingInProgress ? doNothing : toggleRoutedEmail,
    });

    const cancelTokenSource = useRef(Axios.CancelToken.source());
    useEffect(() => cancelTokenSource.current.cancel, []);

    const dispatch = useDispatch<IThunkDispatch>();
    const navigate = useNavigate();

    const email = useSelector<IApplicationState, IQueryEmail | null>(({ email }) => email.emails.query);
    const isEmailEnabled = useSelector<IApplicationState, boolean>(({ email }) => email.enabled);

    // Check if the current user state can handle emails
    const isUserHandlingEmails = useMemo(() => {
        if (typeof user === "undefined" || typeof userStates === "undefined") return false;

        const { userStateId } = user;
        const [userState] = userStates.filter((state) => state.userStateId === userStateId);
        return userState.isEmailEnabled;
    }, [user, userStates]);

    /**
     * Toggle and navigate to the current email in email screen
     */
    const navigateToEmail = useCallback(() => {
        dispatch(emailOperations.toggleEmail(true));
        dispatch(emailOperations.toggleIsUserHandlingUnassignedEmail(!isUserHandlingEmails));

        // When email is enabled but the user is not currently handling an email, there are timers running in the
        // background to request new emails. When we navigate to the email screen here, we want to clear these timers
        // and immediately request a new email.
        if (isEmailEnabled) {
            // If isQueueEmpty is true, it will display an incorrect view when we navigate so we need to disable it.
            dispatch(emailOperations.toggleQueueEmpty(false));
            dispatch(emailOperations.clearTimers());
            dispatch(emailOperations.requestQuery());
        }

        navigate(AbsolutePaths[PageNames.Email]());
    }, [dispatch, isEmailEnabled, isUserHandlingEmails, navigate]);

    /**
     * For emails check if the email is handled by someone else
     */
    const handleUnRoutedEmail = useCallback(async () => {
        if ("emailId" in interaction) {
            try {
                await axios.post(Url.EmailsHandleQuery.replace(":emailId", interaction.emailId.toString()));
                navigateToEmail();
            } catch (serverError) {
                if (
                    Axios.isAxiosError(serverError) &&
                    serverError.response &&
                    serverError.response.status === StatusCodes.CONFLICT
                ) {
                    openModal();
                    setIsHandlingInProgress(false);
                } else {
                    hotToastOperations.showErrorToast(getServerError(serverError));
                }
            }
        }
    }, [interaction, navigateToEmail, openModal]);

    /**
     * Discard the email that's currently in the email screen and set up a new email.
     */
    const discardAndRedirect = useCallback(() => {
        dispatch(emailOperations.removeQueryEmail());
        handleUnRoutedEmail();
    }, [dispatch, handleUnRoutedEmail]);

    /**
     * Set up a new query email or show the modal giving users a choice of how to handle the current email.
     */
    const handleEmail = useCallback(async () => {
        if (email === null) {
            if ("emailId" in interaction) {
                handleUnRoutedEmail();
            } else {
                navigateToEmail();
            }
        } else {
            openModal();
            setIsHandlingInProgress(true);
        }
    }, [navigateToEmail, email, handleUnRoutedEmail, interaction, openModal]);

    return (
        <>
            <Modal {...modalProps}>
                <ModalHeader>
                    <h2>Handle Query</h2>
                </ModalHeader>
                <ModalContent>
                    {isHandlingInProgress ? (
                        <div className="handle-query-button__in-progress">
                            <FontAwesomeIcon icon={faWarning} size="2x" />
                            <h3>
                                <strong>Email in progress</strong>
                            </h3>
                            <p>Handling this query will discard your current email.</p>
                        </div>
                    ) : (
                        <div className="handle-query-button__is-handled">
                            <FontAwesomeIcon icon={faWarning} size="2x" />
                            <h3>
                                <strong>This email has been routed</strong>
                            </h3>
                            <p>
                                You cannot handle this query now, because it's just been routed to another agent. You
                                can still reply from the "New Email" screen if necessary.
                            </p>
                        </div>
                    )}
                    <ModalFooter>
                        <div className="handle-query-button__buttons">
                            {isHandlingInProgress ? (
                                <>
                                    <Button icon={faCheckCircle} styleName="primary" onClick={navigateToEmail}>
                                        Continue draft
                                    </Button>
                                    <Button icon={faEnvelopeOpen} styleName="positive" onClick={discardAndRedirect}>
                                        Create new email
                                    </Button>
                                </>
                            ) : (
                                <Button icon={faCheck} styleName="positive" onClick={closeModal}>
                                    Confirm
                                </Button>
                            )}
                        </div>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <div className="handle-query-button">
                <Button disabled={interaction === null} icon={faExchangeAlt} onClick={handleEmail} styleName="positive">
                    Handle Query
                </Button>
            </div>
        </>
    );
};

export default memo(HandleQueryButton);

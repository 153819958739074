import { PureComponent } from "react";

import RowContext from "../row-context";
import { IRowContext } from "../row-context/row-context.types";
import { IProps } from "./row-provider.types";

/**
 * Provider so that the content of an item can be passed to individual row.
 */
export default class RowProvider<T> extends PureComponent<IProps<T>, IRowContext<T>> {
    state = { ...this.props };

    /**
     * Wrap children in the provider.
     */
    render(): React.ReactNode {
        return <RowContext.Provider value={this.state}>{this.props.children}</RowContext.Provider>;
    }
}

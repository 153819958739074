import { Fragment, FunctionComponent, memo } from "react";

import "./enum-label.scss";
import { IProps } from "./enum-label.types";
import { formatName } from "./enum-label.utilities";

/**
 * Make an enum name nicer.
 * @param children  Label to format.
 * @param lowerCase Whether to lower case the result or not.
 * @returns         Formatted enum label.
 */
const EnumLabel: FunctionComponent<IProps> = ({ children, lowerCase = false }) => {
    return <Fragment>{formatName(children, lowerCase)}</Fragment>;
};

export default memo(EnumLabel);

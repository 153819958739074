import { FunctionComponent, memo, useMemo, useState } from "react";
import type { IProps } from "./navigation-group-notifications-provider.types";
import { PageNames } from "types-for/routes";
import NavigationGroupNotificationsContext from "context-for/navigation-group-notifications/navigation-group-notifications-context";
import { useQueries } from "react-query";
import { getRouteHandle } from "constants/routes";
import usePermission from "hooks-for/permissions/use-permission";
import { isRouteEnabled } from "utilities-for/routes/is-route-enabled";
import { useSelector } from "react-redux";
import type { IApplicationState } from "redux/types";
import { getFeatureToggles } from "redux/modules/setup/setup-selectors";
import { getHasPermission } from "utilities-for/permissions/get-has-permissions";
import { getIsProactiveChatEnabledForUser } from "redux/modules/proactive-chat/proactive-chat-selectors";
import { hotToastOperations } from "utilities-for/toast";
import { getPagePermissions } from "./navigation-group-notifications-provider.utilities";

/**
 * Provider for the navigation group notifications. It will request the notifications for each page and provide the
 * total notification count and map of the number of notifications for each page.
 * @param children                       The children to render.
 * @param pageNotificationsConfiguration The configuration for the notifications for each page.
 */
const NavigationGroupNotificationsProvider: FunctionComponent<IProps> = ({
    children,
    pageNotificationsConfiguration,
}) => {
    const [notificationMap, setNotificationMap] = useState<{ [key in PageNames]?: number }>({});
    const notificationCount = useMemo(() => {
        return Object.values(notificationMap).reduce((total, count) => total + (count ?? 0), 0);
    }, [notificationMap]);

    const navigationItemPermissions = useMemo(
        () => getPagePermissions(pageNotificationsConfiguration),
        [pageNotificationsConfiguration]
    );

    const { permissions: userPermissions, isLoading: arePermissionsLoading } = usePermission(navigationItemPermissions);

    const featureToggles = useSelector(({ setup }: IApplicationState) => getFeatureToggles(setup));

    const isProactiveChatEnabledForUser = useSelector(({ proactiveChat }: IApplicationState) =>
        getIsProactiveChatEnabledForUser(proactiveChat)
    );

    useQueries(
        pageNotificationsConfiguration.map(({ pageName, notificationRequest }) => {
            const handle = getRouteHandle(pageName);
            const isEnabled =
                typeof handle?.enabled !== "undefined" ? isRouteEnabled(handle.enabled, featureToggles) : true;

            const hasPermission =
                typeof handle?.permission !== "undefined"
                    ? getHasPermission(handle?.permission, userPermissions)
                    : true;

            const hasPassedProactiveChatCheck =
                pageName === PageNames.ProactiveChat ? isProactiveChatEnabledForUser : true;

            return {
                enabled: !arePermissionsLoading && hasPermission && isEnabled && hasPassedProactiveChatCheck,
                queryKey: `${pageName}-notifications`,
                queryFn: notificationRequest,
                onSuccess: (count: number) => {
                    setNotificationMap((previous) => ({ ...previous, [pageName]: count }));
                },
                onError: () => {
                    hotToastOperations.showErrorToast("Error requesting notifications");
                },
            };
        })
    );

    return (
        <NavigationGroupNotificationsContext.Provider value={{ notificationCount, notificationMap }}>
            {children}
        </NavigationGroupNotificationsContext.Provider>
    );
};

export default memo(NavigationGroupNotificationsProvider);

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, isValidElement, memo, useContext } from "react";
import { NavLink } from "react-router-dom";
import CategoryNavigationContext from "~/category-navigation/category-navigation-context/category-navigation-context";

import "./navigation-link.scss";
import { IProps } from "./navigation-link.types";

/**
 * Link to a page with a label and icon.
 * @param props.children  Optional children that will be display alongside the navigation link.
 * @param props.label     Link text.
 * @param props.icon      Link icon.
 * @param props.page      Click destination.
 * @param props.indicator Optional indicator to show on the link.
 * @returns  Clickable page link.
 */
const NavigationLink: FunctionComponent<IProps> = ({ children, label, icon, page, indicator }) => {
    const { close } = useContext(CategoryNavigationContext);
    return (
        <li className="navigation-link" aria-label={label} onClick={() => close()}>
            <NavLink to={page} className={({ isActive }) => "navigation-link__inner " + (isActive ? "active" : "")}>
                <FontAwesomeIcon fixedWidth={true} icon={icon} />
                <div>{label}</div>
                {isValidElement(indicator) && <div className="navigation-link__indicator">{indicator}</div>}
                {children}
            </NavLink>
        </li>
    );
};

export default memo(NavigationLink);

import axios from "utilities/axios";
import { AxiosError } from "axios";
import { hotToastOperations } from "utilities-for/toast";
import { ISettings } from "redux/application.types";
import { Url } from "@edgetier/types";
import { useQuery, UseQueryOptions } from "react-query";

// Modal title if there's an error.
export const ERROR_TITLE = "Settings Request Failed";

// Settings rarely change so this cache can be long.
const CACHE_MINUTES = 30;

/**
 * Request system settings from the backend.
 * @param url URL to request settings from.
 */
const requestSettings = async (url: string) => {
    const response = await axios.get(url);
    return response.data;
};

/**
 * Get system settings.
 * @param configuration React Query settings.
 * @returns             React Query result.
 */
export const useSettings = <T = ISettings>(configuration: UseQueryOptions<ISettings, AxiosError, T> = {}) => {
    const url = Url.Settings;

    return useQuery<ISettings, AxiosError, T>([url], () => requestSettings(url), {
        ...configuration,
        staleTime: CACHE_MINUTES * 60 * 1000,
        onError: (serverError: AxiosError<void>) => {
            hotToastOperations.showServerErrorToast(ERROR_TITLE, serverError);
        },
    });
};

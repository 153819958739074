import { useCallback, FunctionComponent, memo, useState } from "react";
import QueryHistoryTimeline from "components-for/query-history/query-history-timeline";
import { IQueryHistoryItem } from "redux/application.types";
import QueryHistoryItem from "./query-history-item";
import { IProps } from "./query-history-thread.types";
import "./query-history-thread.scss";

/**
 * Show all notes and interactions belonging to a query.
 * @param props.interactions           The interactions that will be displayed in the thread.
 * @param props.initialOpenInteraction The interaction that's initially opened when the thread is rendered.
 * @param props.onUpdateQueryState     Optional function to call when the user changes the query's query state.
 * @returns                            View of a query's thread.
 */
const QueryHistoryThread: FunctionComponent<IProps> = ({
    interactions,
    notes,
    openInteraction: initialOpenInteraction,
}) => {
    // The currently open interaction or note.
    const [openInteraction, setOpenInteraction] = useState(initialOpenInteraction);

    /**
     * Show a note or interaction when selected.
     * @param item Note or interaction.
     */
    const onSelectItem = useCallback((item: IQueryHistoryItem | undefined) => {
        if (typeof item !== "undefined" && ("interactionId" in item || "noteId" in item)) {
            setOpenInteraction(item);
        }
    }, []);

    return (
        <div className="query-history-thread">
            <div className="query-history-thread__content">
                <QueryHistoryTimeline
                    activeInteraction={null}
                    initialValue={openInteraction}
                    interactionDetailId={null}
                    interactions={interactions}
                    notes={Object.values(notes)}
                    onItemsChanged={onSelectItem}
                    onSelect={onSelectItem}
                    queryId={initialOpenInteraction.queryId}
                    showPreview={false}
                    submitOnChange={true}
                />
                <QueryHistoryItem queryHistoryItem={openInteraction} />
            </div>
        </div>
    );
};

export default memo(QueryHistoryThread);

import React from "react";
import classNames from "classnames";
import memoizeOne from "memoize-one";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { Form, Formik, FormikHelpers, FormikErrors, FormikValues } from "formik";
import { IFormComponent, FormFieldType, ISourcedSelectOption } from "@edgetier/types";
import { Button, SubmitOnChange } from "@edgetier/components";
import { unreachableCaseError, keyById } from "@edgetier/utilities";
import { CacheForm } from "@edgetier/client-components";

import DynamicFormFieldSet from "./dynamic-form-field-set";
import { EMPTY_FIELD_STRING_VALUE, FIELD_MAPPING } from "./dynamic-form.constants";
import { IFormValues, IProps } from "./dynamic-form.types";
import { getTranslationLabels, normaliseFields, pairLabelValue } from "./dynamic-form.utils";

/**
 * Display a form using fields defined by the backend. This is used for the opening chat form, surveys, agent wrap-up
 * forms etc.
 */
export default class DynamicForm extends React.PureComponent<IProps> {
    static defaultProps = {
        autoFocusFirstField: false,
        enableReinitialize: false,
        simplifySingleFields: false,
        submitIcon: faCheck,
        submitLabel: "Submit",
    };

    /**
     * Map the data provided by the backend to class object for each form field type.
     */
    createFieldLookup = memoizeOne((formComponents: IFormComponent[]) =>
        formComponents.map((field) => new FIELD_MAPPING[field.formFieldTypeId](field))
    );

    /**
     * Create a translation lookup and sort the form fields. This is memoised because it shouldn't happen again on every
     * render.
     */
    formatFields = memoizeOne(normaliseFields);

    /**
     * Create a form initial values, should only need to run once per form
     */
    makeInitialValues = memoizeOne((formComponents: IFormComponent[]): FormikValues => {
        const fieldLookup = this.createFieldLookup(formComponents);
        return formComponents.reduce((data, field, index) => {
            const initialValue = this.props.initialValues?.[field.fieldName];
            return {
                ...data,
                [field.fieldName]: initialValue ?? fieldLookup[index].getDefaultValue(),
            };
        }, {});
    });

    /**
     * Get a string representation of each field's value.
     * @param values Field name to field value array.
     * @return       Array of strings for each field.
     */
    convertValuesToStringArray(values: IFormValues) {
        const { formComponents, languageId } = this.props;
        return formComponents.map(function (formField) {
            const value = values[formField.fieldName];
            if (value === null || typeof value === "undefined") {
                return EMPTY_FIELD_STRING_VALUE;
            } else {
                switch (formField.formFieldTypeId) {
                    case FormFieldType.Integer:
                    case FormFieldType.Float:
                    case FormFieldType.Text:
                    case FormFieldType.Date:
                    case FormFieldType.Time:
                    case FormFieldType.DateTime:
                    case FormFieldType.Timestamp:
                    case FormFieldType.Textarea:
                    case FormFieldType.Email:
                    case FormFieldType.Star:
                    case FormFieldType.NetPromoterScore:
                        return value.toString().trim() === "" ? EMPTY_FIELD_STRING_VALUE : value.toString().trim();

                    case FormFieldType.Buttons:
                    case FormFieldType.Checkboxes:
                    case FormFieldType.Radios:
                    case FormFieldType.Select:
                        // eslint-disable-next-line no-case-declarations
                        const lookup = keyById(
                            formField.configuration.selectOptions,
                            ({ selectOptionId }) => selectOptionId
                        );

                        // Selects can have multiple options so they need to be joined into a readable list.
                        return (value as number[])
                            .map(function (selectOptionId) {
                                const options = lookup[selectOptionId].selectOptionTranslations;
                                const translation = options.find((option) => option.languageId === languageId);
                                return typeof translation !== "undefined" ? translation.translation : "???";
                            })
                            .join(", ");

                    case FormFieldType.SourcedSelect:
                        // eslint-disable-next-line no-case-declarations
                        const items = (Array.isArray(value) ? value : [value]) as unknown as ISourcedSelectOption[];
                        return items.map(({ label }) => label).join(", ");

                    case FormFieldType.Boolean:
                        return value ? "Yes" : "No";

                    default:
                        unreachableCaseError(formField);
                        return "";
                }
            }
        });
    }

    /**
     * Submit the form. The backend expects a mapping from form field ID to field value.
     * @param values  Form values.
     * @param actions Formik form actions.
     */
    onSubmit = (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
        const { formComponents, languageId } = this.props;

        // Create an object mapping field IDs to their cleaned values.
        const fieldLookup = this.createFieldLookup(formComponents);
        const form = formComponents.reduce<any>(
            (data, { fieldName, formFieldId }, index) => ({
                ...data,
                [formFieldId]: fieldLookup[index].formatValue(values[fieldName]),
            }),
            {}
        );

        // Obtain translated labels
        const translatedLabels = getTranslationLabels(formComponents, languageId);

        // Send the data to the backend.
        const stringValues = this.convertValuesToStringArray(values);

        // Generate messages pairing the translated label with submitted values
        const pairMessages = pairLabelValue(translatedLabels, stringValues);
        this.props.onSubmit({
            clearSubmitting: setSubmitting.bind(this, false),
            form,
            formId: this.props.formId,
            stringValues: pairMessages,
        });
    };

    /**
     * Render a form with dynamic fields. The form language will be defined by the language ID. The user may be able to
     * change this, causing all the labels to be translated.
     * @returns Form with dynamic fields.
     */
    render(): JSX.Element {
        const {
            enableReinitialize,
            formComponents,
            languageId,
            name,
            selectField,
            selectedField = null,
            simplifySingleFields,
            submitButton,
        } = this.props;
        const { fields, fieldIds, childFieldsByParentId } = this.formatFields(formComponents);
        const initialValues = this.makeInitialValues(formComponents);

        // A form may have no submit button if there is only one field of a certain type (buttons, select field etc.).
        // In such cases, the form submits on change.
        const singleField =
            simplifySingleFields &&
            formComponents.length === 1 &&
            [FormFieldType.Buttons, FormFieldType.Select].includes(formComponents[0].formFieldTypeId);

        const isSelectFieldAFunction = typeof selectField === "function";
        return (
            <Formik<IFormValues>
                key={JSON.stringify(initialValues)}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                validate={this.validate}
                enableReinitialize={enableReinitialize}
            >
                {({ isSubmitting, submitCount, values, submitForm }) => (
                    <Form
                        autoComplete="off"
                        className={classNames("dynamic-form", { "dynamic-form--single-field": singleField })}
                        name={name}
                    >
                        {typeof this.props.cacheValues !== "undefined" && (
                            <CacheForm cacheValues={this.props.cacheValues} />
                        )}
                        {/* Fields may contain children, if they do this component will be called recursively  */}
                        {fieldIds.map((fieldId, index) => (
                            <DynamicFormFieldSet
                                axiosInstance={this.props.axiosInstance}
                                autoFocusFirstField={this.props.autoFocusFirstField && index === 0}
                                key={fieldId}
                                fieldId={fieldId}
                                fields={fields}
                                childMappings={childFieldsByParentId}
                                values={values}
                                languageId={languageId}
                                labelColour={this.props.labelColour}
                                selectedFieldId={selectedField}
                                onSelectField={selectField}
                                isSingleField={singleField}
                                isDisabled={singleField && submitCount > 0}
                            />
                        ))}

                        {!isSelectFieldAFunction &&
                            (singleField ? (
                                <SubmitOnChange />
                            ) : (
                                <>
                                    {submitButton ? (
                                        submitButton(submitForm)
                                    ) : (
                                        <Button icon={this.props.submitIcon} isLoading={isSubmitting} type="submit">
                                            {this.props.submitLabel}
                                        </Button>
                                    )}
                                </>
                            ))}
                    </Form>
                )}
            </Formik>
        );
    }

    /**
     * Validate all fields according to rules specified by the backend.
     * @param values Form values.
     * @returns      Any errors in the form.
     */
    validate = (values: IFormValues): FormikErrors<IFormValues> => {
        const errors: FormikErrors<IFormValues> = {};
        const fieldLookup = this.createFieldLookup(this.props.formComponents);

        // See if each field is required and blank or invalid.
        this.props.formComponents.forEach((field, index) => {
            const value = values[field.fieldName];
            if (fieldLookup[index].isBlank(value)) {
                errors[field.fieldName] = this.props.requiredError;
            } else if (fieldLookup[index].isInvalid(value)) {
                errors[field.fieldName] = this.props.invalidError;
            }
        });

        return errors;
    };
}

import { AxiosError, AxiosInstance } from "axios";
import { UseQueryOptions, useQueries } from "react-query";
import { IPermission, IRole, IRolePermission } from "@edgetier/types";
import { createGetAllPaginatedItems } from "@edgetier/utilities";

/**
 * Request permissions for a specific role
 * @param routeUrl      Start URL to get roles.
 * @param roleId        User role id
 * @param axiosInstance     Axios instance specific to an application
 */
const getRolePermissions = async (routeUrl: string, roleId: string, axiosInstance: AxiosInstance) => {
    const getAllPaginatedItems = createGetAllPaginatedItems(axiosInstance);
    const permissions = await getAllPaginatedItems<IPermission>(routeUrl);
    return { [roleId]: permissions };
};

/**
 * Request all roles permissions. Loop though all roles and retrieve permissions
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 * @param roles             List of roles
 * @param configuration     Optional Axios configuration
 */
const useAllRolesPermissions = <T = IPermission[]>(
    routeUrl: string,
    axiosInstance: AxiosInstance,
    roles: IRole[],
    configuration: UseQueryOptions<IRolePermission, AxiosError<void>, T> = {}
) => {
    return useQueries(
        roles.map((role) => {
            const url = routeUrl.replace(":roleId", role.roleId.toString());
            return {
                queryKey: [url, role.roleId],
                queryFn: () => getRolePermissions(url, role.roleId.toString(), axiosInstance),
                ...configuration,
            };
        })
    );
};

export default useAllRolesPermissions;

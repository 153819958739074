import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";

import GridContext from "../grid-context";
import PaginationButton from "./pagination-button";
import PaginationNumbers from "./pagination-numbers";
import "./pagination.scss";
import { IProps } from "./pagination.types";

/**
 * Next and previous page controls for the table.
 * @returns Buttons to go forward and back through the pages.
 */
const Pagination = ({ showPageNumbers = true }: IProps) => (
    <GridContext.Consumer>
        {({ hasNext, hasPrevious, goToNextPage, goToPreviousPage, pageIndex, pageSize, rows }) => (
            <div className="grid__pagination">
                <div className="grid__pagination__content">
                    <PaginationButton
                        icon={faAngleLeft}
                        isDisabled={
                            !Array.isArray(rows) ||
                            hasPrevious === false ||
                            (typeof hasPrevious !== "boolean" && pageIndex === 0)
                        }
                        onClick={goToPreviousPage}
                    />

                    {showPageNumbers && Number.isFinite(pageSize) && <PaginationNumbers />}

                    <PaginationButton
                        icon={faAngleRight}
                        isDisabled={
                            !Array.isArray(rows) ||
                            hasNext === false ||
                            (typeof hasNext !== "boolean" && (pageIndex + 1) * pageSize >= rows.length)
                        }
                        onClick={goToNextPage}
                    />
                </div>
            </div>
        )}
    </GridContext.Consumer>
);

export default Pagination;

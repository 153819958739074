import flagsmith from "flagsmith";
import { FlagsmithContextType } from "flagsmith/react";

/**
 * Initialises Flagsmith with the provided options.
 * @param {object} options - The options for the Flagsmith init function.
 */
export const initialiseFeatureFlags = (options: Partial<FlagsmithContextType["options"]>) => {
    return flagsmith.init({
        environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID || "",
        ...options,
    });
};

import { FunctionComponent, memo, useRef, useState } from "react";
import Axios from "axios";
import { IProps } from "./download-chat-transcript-button.types";
import { Button } from "@edgetier/components";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { downloadChatTranscript } from "utilities/download-chat-transcript";
import { getServerError } from "@edgetier/utilities";
import { hotToastOperations } from "utilities-for/toast";
import "./download-chat-transcript-button.scss";

/**
 * Component displaying a button to download the chat transcript
 * @param props.chatId
 */
const DownloadChatTranscriptButton: FunctionComponent<IProps> = ({ chatId }) => {
    const cancelTokenSource = useRef(Axios.CancelToken.source());
    const [submitting, setSubmitting] = useState(false);

    /**
     * Submit a transcript request.
     */
    const onDownload = async () => {
        try {
            setSubmitting(true);
            await downloadChatTranscript(chatId.toString(), { cancelToken: cancelTokenSource.current.token });
        } catch (serverError) {
            hotToastOperations.showErrorToast(getServerError(serverError));
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Button className="button--no-modal" icon={faDownload} isLoading={submitting} onClick={onDownload} isIconOnly>
            Transcript
        </Button>
    );
};

export default memo(DownloadChatTranscriptButton);

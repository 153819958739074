import ChatLinkIndicators from "./chat-link-indicators";
import "./chat-link.scss";
import { NavigationLink } from "@edgetier/client-components";
import { faComments } from "@fortawesome/pro-solid-svg-icons";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";
import { useSelector } from "react-redux";
import type { IApplicationState } from "redux/types";
import { NavigationItemNotificationCount } from "components-for/navigation/navigation-item-notification-count";
import { getUnreadMessageCount } from "redux/modules/chat/chat-selectors";
import { memo } from "react";

/**
 * Link to the chat screen. This shows unread message and invite indicators.
 * @returns Link to the chat screen.
 */
const ChatLink = () => {
    const unreadMessageCount = useSelector(({ chat }: IApplicationState) => getUnreadMessageCount(chat));
    const isChatScreenOpen = useSelector(({ chat }: IApplicationState) => chat.isChatScreenOpen);

    const shouldShowNotification = !isChatScreenOpen && unreadMessageCount > 0;

    return (
        <NavigationLink
            label="Chat"
            icon={faComments}
            page={AbsolutePaths[PageNames.Chat]()}
            indicator={
                shouldShowNotification && <NavigationItemNotificationCount notificationCount={unreadMessageCount} />
            }
        >
            <ChatLinkIndicators />
        </NavigationLink>
    );
};

export default memo(ChatLink);

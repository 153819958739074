import { differenceInMinutes, parseISO } from "date-fns";
import { LAST_SEEN_THRESHOLD_MINUTES } from "./query-history-interaction-content.constants";

/**
 * Check if the last seen date time and current time is over a threshold
 * @param lastSeenDateTime
 */
export const checkLastSeenActivity = (lastSeenDateTime: Date | null): boolean => {
    if (lastSeenDateTime === null) return true;

    const lastSeenDate = typeof lastSeenDateTime === "string" ? parseISO(lastSeenDateTime) : lastSeenDateTime;

    return differenceInMinutes(new Date(), lastSeenDate) >= LAST_SEEN_THRESHOLD_MINUTES;
};

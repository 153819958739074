import classNames from "classnames";
import {
    CSSProperties,
    Children,
    ReactElement,
    isValidElement,
    useContext,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import ColumnProvider from "~/grid/column-provider";
import ExpandedRow from "~/grid/expanded-row";
import GridContext from "~/grid/grid-context";
import RowProvider from "~/grid/row-provider";
import TableColumns from "~/grid/table-columns";
import { IProps } from "./table-row.types";
import RowExpander from "../row-expander";
import "./table-row.scss";

/**
 * Row of a table.
 * @param props.children
 */
const TableRow = <T extends {}>({ children, isFixed, row }: IProps<T>) => {
    const { activeRowKey, columnCount, getRowKey, getRowHasNewActivity, highlightedRowsKeys, onRowClick, rows } =
        useContext(GridContext);

    const ref = useRef<HTMLTableRowElement>(null);
    const [style, setStyle] = useState<CSSProperties>({});
    useLayoutEffect(() => {
        if (isFixed && ref.current && Array.isArray(rows)) {
            // Find closest table ancestor to avoid getting the header of a different table.
            const header = ref.current.closest("table")?.querySelector("thead");
            const headerTop = header?.getBoundingClientRect().top;

            const rowTop = ref.current.getBoundingClientRect().top;
            const style =
                typeof headerTop === "number" ? ({ position: "sticky", top: rowTop - headerTop - 2 } as const) : {};
            setStyle(style);
        }
    }, [isFixed, rows]);

    return (
        <RowProvider row={row}>
            <tr
                className={classNames("grid__row", {
                    "grid__row--active": row !== null && getRowKey(row) === activeRowKey,
                    "grid__row--fixed": isFixed,
                    "grid__row--warning": row !== null && Array.isArray(row.warnings) && row.warnings.length > 0,
                    "grid__row--new-activity":
                        row !== null &&
                        typeof getRowHasNewActivity === "function" &&
                        getRowHasNewActivity(row) === true,
                    "grid__row--is-clickable": Array.isArray(rows) && typeof onRowClick === "function",
                    "grid__row--is-loading": !Array.isArray(rows),
                    "grid__row--highlighted":
                        Array.isArray(highlightedRowsKeys) && highlightedRowsKeys.includes(getRowKey(row)),
                })}
                style={style}
                ref={ref}
                onClick={
                    Array.isArray(rows) && typeof onRowClick === "function"
                        ? (event) => onRowClick(row, event)
                        : undefined
                }
                aria-label="grid__row"
            >
                <ColumnProvider isBody={true}>
                    {Children.toArray(children).filter((child) => isValidElement(child) && child.type === TableColumns)}
                </ColumnProvider>
            </tr>

            {row !== null &&
                Children.toArray(children)
                    .filter(isValidElement)
                    .some((child) => child.type === ExpandedRow) && (
                    <RowExpander columnCount={columnCount} row={row}>
                        {Children.toArray(children)
                            .filter<ReactElement>(isValidElement)
                            .find((child) => child.type === ExpandedRow)}
                    </RowExpander>
                )}
        </RowProvider>
    );
};

export default TableRow;

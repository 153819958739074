import { Fragment } from "react";

import GridContext from "../../grid-context";
import "./pagination-numbers.scss";

/**
 * Display what page in a table the user is currently on.
 * @returns Current page and total page numbers.
 */
const PaginationNumbers = () => (
    <GridContext.Consumer>
        {({ pageIndex, pageSize, rows }) => (
            <div className="grid__pagination-numbers">
                {Array.isArray(rows) && (
                    <Fragment>
                        {pageIndex + 1}
                        <Fragment> of </Fragment>
                        {Number.isFinite(pageSize) ? Math.ceil(rows.length / pageSize) : 1}
                    </Fragment>
                )}
            </div>
        )}
    </GridContext.Consumer>
);

export default PaginationNumbers;

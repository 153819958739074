import LocalStorageKey from "constants/local-storage-key";

/**
 * Get authentication information (access token and user ID) from the local storage.
 * @param token  Access token from the server.
 * @param userId The user's database identifier.
 * @returns The token and user ID, or null for each if they are not in local storage.
 */
export function retrieveAuthenticationDetails(): { token: string | null; userId: number | null } {
    const token = localStorage.getItem(LocalStorageKey.Token);
    const userId = getUserId();
    return { token, userId };
}

/**
 * Save a user's authentication information. When they log in, the access token will allow them access to the server's
 * API (until the token expires).
 * @param token  Access token from the server.
 * @param userId The user's database identifier.
 */
export function saveAuthenticationDetails(token: string, userId: string | number): void {
    localStorage.setItem(LocalStorageKey.Token, token);
    localStorage.setItem(LocalStorageKey.UserId, userId.toString());
}

/**
 * Get the signed in user's ID from the local storage.
 * @returns User ID.
 */
export function getUserId(): number | null {
    const userIdString = localStorage.getItem(LocalStorageKey.UserId);
    // Reading from local storage will return a string. It's possible for that string to be exactly "null"
    return userIdString === null || userIdString === "null" ? null : parseInt(userIdString, 10);
}

import { MAXIMUM_TOOLTIP_ITEMS } from "~/select.constants";
import { IProps } from "./select-preview-tooltip.types";

/**
 * SelectPreviewTooltip component displays a tooltip with a preview of selected items. If more than the maximum allowed
 * items are selected, it shows a message indicating how many more items are selected.
 * @props.description   Item description.
 * @props.selectedItems The array of selected items to display in the tooltip.
 * @props.getValue      A function to get the value of an item.
 * @props.getLabel      A function to get the label of an item.
 */
const SelectPreviewTooltip = <IItem extends {}, IValue extends {} = string>({
    description,
    selectedItems,
    getValue,
    getLabel,
}: IProps<IItem, IValue>) => {
    return (
        <div className="select-preview__tooltip">
            {selectedItems.slice(0, MAXIMUM_TOOLTIP_ITEMS).map((item) => (
                <div key={String(getValue(item))}>{getLabel(item)}</div>
            ))}

            {selectedItems.length > MAXIMUM_TOOLTIP_ITEMS && (
                <div>{`and ${selectedItems.length - MAXIMUM_TOOLTIP_ITEMS} more ${description}(s)`}&hellip;</div>
            )}
        </div>
    );
};

export default SelectPreviewTooltip;

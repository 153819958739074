import { createFieldErrorId } from "@edgetier/utilities";
import { Field, FieldProps } from "formik";

import { IProps } from "./date-time-form-field.types";
import { formatDate } from "./date-time-form-field.utilities";

/**
 * Render a datetime field.
 * @param props.formField   datetime form field.
 * @param props.translation Text elements in the user's language.
 * @param props.inputProps  Any other props to pass to the input element.
 * @returns                 datetime input.
 */
const DateTimeFormField = ({ formField, translation, ...inputProps }: IProps) => {
    const defaultValue = formField.configuration.defaultValue;
    const maximumDateTime = formField.configuration.maximumValue;
    const minimumDateTime = formField.configuration.minimumValue;
    return (
        <Field name={formField.fieldName}>
            {({ field, form }: FieldProps) => (
                <input
                    {...inputProps}
                    {...field}
                    aria-describedby={createFieldErrorId(field.name)}
                    aria-invalid={typeof form.errors[field.name] === "string"}
                    aria-required={formField.required}
                    autoComplete="off"
                    id={formField.fieldName}
                    max={formatDate(maximumDateTime)}
                    min={formatDate(minimumDateTime)}
                    name={field.name}
                    placeholder={translation.placeholderText || ""}
                    type="datetime-local"
                    defaultValue={formatDate(defaultValue)}
                />
            )}
        </Field>
    );
};

export default DateTimeFormField;

import "./toggle-enabled.scss";
import { IProps } from "./toggle-enabled.types";

/**
 * Label for a toggle control when the control is enabled.
 * @param props.children Label.
 * @returns              Label text.
 */
const ToggleEnabled = ({ children }: IProps) => <span className="toggle__state--enabled">{children}</span>;

export default ToggleEnabled;

import { formatDate } from "@edgetier/utilities";

/**
 * Format the date for an interaction event. This includes the date and time. The time includes seconds. The format will
 * change based on the user's locale.
 * @param dateTime Date of the event.
 */
export const formatEventTime = (dateTime: Date) => {
    return `${formatDate(dateTime, { intlOptions: { dateStyle: "short" } })} ${formatDate(dateTime, { intlOptions: { timeStyle: "medium" } })}`;
};

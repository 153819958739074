import isEqual from "react-fast-compare";
import RedactFormField from "./redact-form-field";
import RedactFormOptions from "./redact-form-options";
import { Button } from "@edgetier/components";
import { faSave, faUndoAlt } from "@fortawesome/pro-solid-svg-icons";
import { Form, Formik } from "formik";
import { FunctionComponent, memo, useState } from "react";
import { getInitialActiveOption, patchData } from "./redact-form.utilities";
import { IFormValues, IProps } from "./redact-form.types";
import { hotToastOperations } from "utilities-for/toast";
import "./redact-form.scss";

/**
 * Edit a chat or email to remove sensitive information e.g. bank details. This is made a lot more complicated by
 * translations because there could be multiple things to edit. Also emails have subjects, plain text, and HTML that may
 * all have to be redacted.
 * @param props.sections  Details of the different properties and languages that can be redacted.
 * @param props.onUpdate  Handler for when changes have been submitted.
 * @param props.url       Where to patch the primary changes (probably "/emails/:emailId" or "/messages/:messageId").
 * @returns               Form with tabs and a textbox.
 */
const RedactForm: FunctionComponent<IProps> = ({ sections, onCancel, onUpdate, url }) => {
    const [activeOption, setActiveOption] = useState(getInitialActiveOption(sections));

    /**
     * Submit redactions.
     * @param values Edited values.
     */
    const onSubmit = async (values: IFormValues) => {
        // Don't bother submitting if no changes were made.
        if (isEqual(sections, values.sections)) {
            hotToastOperations.showInfoToast("Cancelled", "No changes made.");
            onCancel();
            return;
        }

        try {
            await patchData(values.sections, url);
            hotToastOperations.showSuccessToast("Redacted", "Content successfully updated.");
            onUpdate();
        } catch (serverError) {
            hotToastOperations.showServerErrorToast("Redaction Failed", serverError);
        }
    };

    const fieldName = `sections[${activeOption.index}].translations[${activeOption.languageId}].translation`;

    return (
        <Formik initialValues={{ sections }} onSubmit={onSubmit}>
            <Form className="redact-form" name="redact-form">
                <p>
                    Please note that you can only type one or more "X" (or "x") characters to hide content. Any changes
                    here are made in Arthur only.
                </p>

                {(sections.length > 1 || Object.keys(sections[0].translations).length > 1) && (
                    <RedactFormOptions
                        activeOption={activeOption}
                        sections={sections}
                        setActiveOption={setActiveOption}
                    />
                )}

                <RedactFormField name={fieldName} />

                <div className="redact-form__buttons">
                    <Button icon={faUndoAlt} styleName="neutral" type="reset">
                        Reset
                    </Button>

                    <Button icon={faSave} styleName="positive" type="submit">
                        Save
                    </Button>
                </div>
            </Form>
        </Formik>
    );
};

export default memo(RedactForm);

import { DetailsFieldTitle, DetailsFieldValue } from "~/details";

import { IProps } from "./numeric-details-field.types";

/**
 * Render a numeric value from the booking details.
 * @param props.value Numeric value.
 * @returns           Numeric value.
 */
const NumericDetailsField = ({ field }: IProps) => (
    <tr className="numeric-details-field">
        <td>
            <DetailsFieldTitle>{field.title}</DetailsFieldTitle>
        </td>
        <td>
            <DetailsFieldValue
                copyText={field.value.toString()}
                isNull={typeof field.value !== "number"}
                warning={field.warning}
            >
                {field.value}
            </DetailsFieldValue>
        </td>
    </tr>
);

export default NumericDetailsField;

import getInteractionEventDateTime from "utilities/get-interaction-event-date-time";

import { IProps } from "./interaction-event-time.types";
import { formatEventTime } from "./interaction-event-time.utilities";
import "./interaction-event-time.scss";

/**
 * Render the time a call or chat event occurred. These unfortunately have different property names in the backend, so a
 * small bit of logic is required.
 * @param props.event Call or chat event.
 * @returns           Formatted event time.
 */
const InteractionEventTime = ({ event }: IProps) => {
    const dateTime = getInteractionEventDateTime(event);
    return <div className="interaction-event-time">{formatEventTime(dateTime)}</div>;
};

export default InteractionEventTime;

import { Url } from "@edgetier/types";
import type { IEmailAccountsCount } from "redux/application.types";
import axios from "utilities/axios";

/**
 * Fetch from the API the number of email accounts with errors.
 */
export const getEmailAccountsError = async () => {
    const { data } = await axios.get<IEmailAccountsCount<number>[]>(Url.EmailAccountsCounts, {
        params: { hasErrors: "true" },
    });
    return data?.[0]?.count ?? 0;
};

import { FunctionComponent, memo } from "react";
import { IProps } from "./device-details.types";
import {
    DetailsFieldTitle,
    DetailsFieldValue,
    DetailsSection,
    DetailsSectionTitle,
    InfoTooltip,
} from "@edgetier/client-components";
import { useBrowser, useDevice, useIPAddress, useOperatingSystem } from "./device-details.utilities";
import "./device-details.scss";

/**
 * Show details of the device that the customer is using.
 * @param props.chatId The ID of the chat.
 */
const DeviceDetails: FunctionComponent<IProps> = ({ chatId }) => {
    const { data: device, isLoading: isDeviceLoading, isError: isDeviceError } = useDevice(chatId);
    const { data: browser, isLoading: isBrowserLoading, isError: isBrowserError } = useBrowser(chatId);
    const {
        data: operatingSystem,
        isLoading: isOperatingSystemLoading,
        isError: isOperatingSystemError,
    } = useOperatingSystem(chatId);
    const { data: ipAddress, isLoading: isIPAddressLoading, isError: isIPAddressError } = useIPAddress(chatId);

    return (
        <DetailsSection>
            <DetailsSectionTitle title="Device">
                <InfoTooltip
                    content={
                        <>
                            Information retrieved from the &ldquo;User Agent&rdquo; of the customer's browser.{" "}
                            <strong>Note:</strong> if the information or the customer looks suspicious, please be aware
                            that it is technically possible to spoof the User Agent.
                        </>
                    }
                />
            </DetailsSectionTitle>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <DetailsFieldTitle>Device</DetailsFieldTitle>
                        </td>
                        <td>
                            <DetailsFieldValue isLoading={isDeviceLoading} isError={isDeviceError}>
                                {device?.deviceFamily}
                            </DetailsFieldValue>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <DetailsFieldTitle>Browser</DetailsFieldTitle>
                        </td>
                        <td>
                            <DetailsFieldValue isLoading={isBrowserLoading} isError={isBrowserError}>
                                {browser?.browser}&nbsp;
                                <span className="device-details__version">{browser?.browserVersion}</span>
                            </DetailsFieldValue>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <DetailsFieldTitle>Operating System</DetailsFieldTitle>
                        </td>
                        <td>
                            <DetailsFieldValue isLoading={isOperatingSystemLoading} isError={isOperatingSystemError}>
                                {operatingSystem?.operatingSystem}&nbsp;
                                {typeof operatingSystem?.operatingSystemVersion === "string" && (
                                    <span className="device-details__version">
                                        {operatingSystem?.operatingSystemVersion}
                                    </span>
                                )}
                            </DetailsFieldValue>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <DetailsFieldTitle>IP Address</DetailsFieldTitle>
                        </td>
                        <td>
                            <DetailsFieldValue isLoading={isIPAddressLoading} isError={isIPAddressError}>
                                {ipAddress?.ipAddress}
                            </DetailsFieldValue>
                        </td>
                    </tr>
                </tbody>
            </table>
        </DetailsSection>
    );
};

export default memo(DeviceDetails);

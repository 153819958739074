import { InteractionIconType } from "@edgetier/types";
import { faClipboard, faComments, faEnvelope, faMessages, faQuestion } from "@fortawesome/pro-duotone-svg-icons";
import { faInboxIn, faInboxOut, faRobot, faShareNodes, faStickyNote } from "@fortawesome/pro-duotone-svg-icons";
import { faPhoneArrowDownLeft, faPhoneArrowUpRight, faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import { unreachableCaseError } from "@edgetier/utilities";
import { faPhone } from "@fortawesome/pro-solid-svg-icons";

export const InteractionIcons = {
    [InteractionIconType.Automated]: faRobot,
    [InteractionIconType.Call]: faPhone,
    [InteractionIconType.CallIn]: faPhoneArrowUpRight,
    [InteractionIconType.CallOut]: faPhoneArrowDownLeft,
    [InteractionIconType.Chat]: faComments,
    [InteractionIconType.Email]: faEnvelope,
    [InteractionIconType.EmailIn]: faInboxIn,
    [InteractionIconType.EmailOut]: faInboxOut,
    [InteractionIconType.External]: faRectangleList,
    [InteractionIconType.Messages]: faMessages,
    [InteractionIconType.Notes]: faStickyNote,
    [InteractionIconType.SocialMedia]: faShareNodes,
    [InteractionIconType.Survey]: faClipboard,
};

/**
 * Get the icon applicable to the interaction being displayed.
 * @param interactionType The type of interaction.
 * @returns An icon relevant to the interaction.
 */
export const getInteractionIcon = (interactionType: InteractionIconType) => {
    let icon = InteractionIcons[interactionType];

    if (typeof icon === "undefined") {
        unreachableCaseError(interactionType as never);
        icon = faQuestion;
    }
    return icon;
};

import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PureComponent } from "react";

import GridContext from "../grid-context";
import { IGridContext } from "../grid-context/grid-context.types";
import LoadingCell from "../loading-cell";
import RowContext from "../row-context";
import "./expand-button.scss";
import { IProps } from "./expand-button.types";

/**
 * Button to expand or collapse a row in the table.
 */
export default class ExpandButton<T, F> extends PureComponent<IProps> {
    /**
     * Expand the row if it or none are open already. If it is already expanded, then hide it.
     * @param context Grid context.
     */
    collapseOrExpandRow(context: IGridContext<T, F>, row: T | null): void {
        if (row === null) {
            return;
        }

        const rowKey = context.getRowKey(row);
        if (rowKey === context.expandedRowKey) {
            context.collapseRow();
        } else {
            context.expandRow(rowKey);
        }
    }

    /**
     * Render a table cell with a checkbox.
     * @returns Table cell.
     */
    render(): React.ReactNode {
        const { children } = this.props;

        return (
            <GridContext.Consumer>
                {(context) => (
                    <RowContext.Consumer>
                        {({ row }) => (
                            <td
                                className="grid__expand-button"
                                onClick={this.collapseOrExpandRow.bind(this, context, row)}
                            >
                                {row === null ? (
                                    <LoadingCell />
                                ) : (
                                    <>
                                        {typeof children !== "undefined" ? (
                                            <>{children}</>
                                        ) : (
                                            <FontAwesomeIcon
                                                fixedWidth={true}
                                                icon={
                                                    context.expandedRowKey === context.getRowKey(row)
                                                        ? faAngleUp
                                                        : faAngleDown
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </td>
                        )}
                    </RowContext.Consumer>
                )}
            </GridContext.Consumer>
        );
    }
}

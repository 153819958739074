import { createFieldErrorId } from "@edgetier/utilities";
import { Field, FieldProps } from "formik";

import { IProps } from "./text-form-field.types";

/**
 * Render a text-like field (text, email address etc.).
 * @param props.formField   Text form field.
 * @param props.translation Text elements in the user's language.
 * @param props.inputProps  Any other props to pass to the input element.
 * @returns                 Text input.
 */
const TextFormField = ({ formField, translation, ...inputProps }: IProps) => (
    <Field name={formField.fieldName}>
        {({ field, form }: FieldProps) => (
            <input
                {...inputProps}
                {...field}
                aria-describedby={createFieldErrorId(field.name)}
                aria-required={formField.required}
                autoComplete="off"
                id={formField.fieldName}
                maxLength={formField.configuration.maximumLength || undefined}
                name={field.name}
                placeholder={translation.placeholderText || ""}
            />
        )}
    </Field>
);

export default TextFormField;

/**
 * Truncates a string to a specified length.
 *
 * **Note:** The maximum length does not include the ellipsis. So it will be 12 characters plus the ellipsis.
 *
 * @param value - The string to truncate.
 * @param maxLength - The maximum length of the string.
 * @returns The truncated string.
 */
export const truncateStringWithEllipsis = (value: string, maxLength: number = 12) => {
    if (!value) {
        return "";
    }
    if (value.length + 1 > maxLength) {
        return value.slice(0, maxLength - 1).trim() + "…";
    }
    return value;
};

// A collection of all the interaction types used in all applications. This would be used only
// for interaction icon
export enum InteractionIconType {
    Automated = "automated",
    Call = "call",
    CallIn = "call-in",
    CallOut = "call-out",
    Chat = "chat",
    Email = "email",
    EmailIn = "email-in",
    EmailOut = "email-out",
    External = "external",
    Messages = "messages",
    Notes = "notes",
    SocialMedia = "social-media",
    Survey = "survey",
}

import { IRole } from "types-for/permissions/permissions.types";
import AuthenticationType from "./authentication-type";

const createAction = <T extends { type: AuthenticationType }>(action: T) => action;

/**
 * Sign a user into the application. Their user role is needed to determine the level of access they will have.
 * @param roleId Identifier that will determine what pages the user can access.
 * @returns          Sign in action.
 */
const signIn = (payload: { roleId: IRole["roleId"]; userEmail: string }) =>
    createAction({
        type: AuthenticationType.SignIn,
        payload,
    });

/**
 * Sign the user out.
 * @returns Sign out action.
 */
const signOut = () =>
    createAction({
        type: AuthenticationType.SignOut,
    });

/**
 * Note that the user's session token has been examined.
 */
const checkedToken = () =>
    createAction({
        type: AuthenticationType.CheckedToken,
    });

export default { checkedToken, signIn, signOut };

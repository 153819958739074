import { useFlags as useFlagsmithFlags } from "flagsmith/react";
import { IFlagsmithFeature, IFlagsmithTrait } from "flagsmith/types";

/**
 * Hook to get flags by traits(optional).
 * @param _flags An array of FEATURE_FLAGS enum values to fetch.
 * @param _traits The traits to filter the flags by.
 * @returns A list of feature flags with their current status
 * based on the actual user and the traits used in the fetch.
 */
const useFlags = <F extends string, T extends string = string>(
    _flags: readonly F[],
    _traits?: readonly T[]
): {
    [K in F]: IFlagsmithFeature;
} & {
    [K in T]: IFlagsmithTrait;
} => {
    return useFlagsmithFlags(_flags, _traits);
};

export default useFlags;

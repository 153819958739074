import maximumDate from "date-fns/max";
import minimumDate from "date-fns/min";
import { FunctionComponent, memo } from "react";
import Qs from "qs";
import UpdateQueryState from "../update-query-state";
import QueryHistoryTitleDates from "./query-history-title-dates";
import QueryHistoryTitleDescription from "./query-history-title-description";
import { IProps } from "./query-history-header.types";
import "./query-history-header.scss";
import { useSelector } from "react-redux";
import { IApplicationState } from "redux/types";
import { getFeatureToggles } from "redux/modules/setup/setup-selectors";
import useSimpleQuery from "queries/use-simple-query";
import urlJoin from "url-join";
import { hotToastOperations } from "utilities-for/toast";
import { Url } from "@edgetier/types";
import { faExpandArrowsAlt } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@edgetier/components";
import { useNavigate } from "react-router-dom";
import getInteractionInformation from "utilities/get-interaction-information";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Descriptive title for a query's thread. This will state the interaction types, booking ID, and dates.
 * @param props.interactions All interactions in a given thread.
 * @param props.notes        All notes in a give thread.
 * @returns                  Thread title.
 */
const QueryHistoryHeader: FunctionComponent<IProps> = ({
    interactions,
    isFullscreen = false,
    isProactiveChat,
    notes,
    onUpdateQueryState,
    openInteraction,
}) => {
    const navigate = useNavigate();
    // Take any booking ID from the thread as they should hopefully all be the same.
    const bookingId = interactions.find(({ bookingId }) => bookingId !== null)?.bookingId;
    const { blockedQueryStateEnabled } = useSelector(({ setup }: IApplicationState) => getFeatureToggles(setup));

    // Combine interaction and note dates.
    const interactionDates = interactions.map(({ dateTime }) => dateTime);
    const noteDates = notes.map(({ dateTime }) => dateTime);
    const dates = interactionDates.concat(noteDates);

    /**
     * Open the interaction in a fullscreen page of its own.
     */
    const goToFullscreen = () => {
        if (!("noteId" in openInteraction)) {
            const searchParameters = {
                queryId: openInteraction.queryId,
                interactionId: getInteractionInformation(openInteraction).idValue,
            };
            navigate({
                pathname: AbsolutePaths[PageNames.QueryHistory](),
                search: Qs.stringify(searchParameters),
            });
        }
    };

    // All interaction types in the thread.
    const interactionTypeIds = interactions.map(({ interactionTypeId }) => interactionTypeId);

    const { data: queryStateId } = useSimpleQuery<{ queryStateId: number }, number>(
        urlJoin(Url.Query, openInteraction.queryId.toString()),
        {
            enabled: blockedQueryStateEnabled,
            onError: (error) => hotToastOperations.showServerErrorToast("Interaction Search Failed", error),
            select: ({ queryStateId }) => queryStateId,
        }
    );

    return (
        <div className="query-history-header">
            <h2 className="query-history-header__title">
                <QueryHistoryTitleDescription
                    interactionTypeIds={interactionTypeIds}
                    isProactiveChat={isProactiveChat}
                />{" "}
                Thread
                <span>
                    {bookingId && (
                        <>
                            <span>&nbsp;&ndash;&nbsp;</span>
                            {bookingId}
                        </>
                    )}
                    {dates.length > 1 && (
                        <>
                            <span>&nbsp;&ndash;&nbsp;</span>
                            <QueryHistoryTitleDates
                                firstDateTime={minimumDate(dates)}
                                lastDateTime={maximumDate(dates)}
                            />
                        </>
                    )}
                </span>
            </h2>
            {blockedQueryStateEnabled && typeof queryStateId !== "undefined" && (
                <UpdateQueryState
                    onUpdateQueryState={onUpdateQueryState}
                    queryId={openInteraction.queryId}
                    queryStateId={queryStateId}
                />
            )}
            {!isFullscreen && (
                <div className="query-history-header__expand-button-container">
                    <Button
                        isUnstyled
                        title="Expand to fullscreen"
                        className="query-history-header__expand-button"
                        onClick={goToFullscreen}
                        icon={faExpandArrowsAlt}
                        isIconOnly
                    />
                </div>
            )}
        </div>
    );
};

export default memo(QueryHistoryHeader);

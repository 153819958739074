import isSameDay from "date-fns/isSameDay";
import { Fragment, FunctionComponent, memo } from "react";

import { IProps } from "./query-history-title-dates.types";
import { formatDate } from "@edgetier/utilities";
import "./query-history-title-dates.scss";

/**
 * Show the range of dates in a thread for the title. If there is only one date or all the interactions in a thread
 * have happened on the same day, then only one date is shown.
 * @param firstDateTime Earliest date in the thread.
 * @param lastDateTime  Last date in the thread.
 * @returns             Date display.
 */
const QueryHistoryTitleDates: FunctionComponent<IProps> = ({ firstDateTime, lastDateTime }) => {
    return (
        <Fragment>
            {formatDate(firstDateTime, { intlOptions: { dateStyle: "short" } })}
            {!isSameDay(firstDateTime, lastDateTime) && (
                <Fragment> to {formatDate(lastDateTime, { intlOptions: { dateStyle: "short" } })}</Fragment>
            )}
        </Fragment>
    );
};

export default memo(QueryHistoryTitleDates);

import { FunctionComponent, memo } from "react";
import { IProps } from "./select-menu-message.types";
import "./select-menu-message.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

/**
 * Component for displaying a message in a select menu, accompanied by an info icon.
 * @param {object} props - The props for the SelectMenuMessage component.
 * @param {string} props.message - The message to display in the select menu.
 * @returns {JSX.Element} SelectMenuMessage component.
 */
const SelectMenuMessage: FunctionComponent<IProps> = ({ message }) => {
    return (
        <div className="select-menu__message">
            <FontAwesomeIcon icon={faQuestionCircle} />
            {message}
        </div>
    );
};

export default memo(SelectMenuMessage);

import { Fragment, FunctionComponent, memo, useCallback, useState } from "react";
import urlJoin from "url-join";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { Url } from "@edgetier/types";
import { parseISO } from "date-fns";

import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { IBackendNote, INote } from "redux/application.types";
import { Modal, ModalContainer } from "shared/modal";
import { hotToastOperations } from "utilities-for/toast";
import useSimpleQuery from "queries/use-simple-query";
import NoteForm from "../note-form";
import { IProps } from "./note-modal.types";
import { IThunkDispatch } from "redux/types";

/**
 * Open a modal to create or edit a note. If a note is being edited that must be requested first before opening the
 * modal.
 * @param props.children Render prop children.
 * @param props.interactionDetailId Identifier for the current chat or email.
 * @param props.queryId             Notes belong to a queries. This is the query the note will be attached to.
 * @param props.noteId              Optional note to edit. If missing a new one is being created.
 * @returns                         Loading indicator and then modal when everything is ready.
 */
const NoteModal: FunctionComponent<IProps> = ({ children, interactionDetailId, queryId, noteId }) => {
    const dispatch = useDispatch<IThunkDispatch>();
    const [isOpening, setIsOpening] = useState(false);
    const isEditing = typeof noteId === "number";

    /**
     * Show any errors requesting the note in a toast message.
     * @param serverError Backend error.
     */
    const onError = (serverError: AxiosError) => {
        hotToastOperations.showServerErrorToast("Error", serverError);
    };

    // Request the note if one is being edited.
    const enabled = isEditing && isOpening;
    const onSettled = () => dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
    const querySettings = {
        enabled,
        onError,
        onSettled,
        select: (note: IBackendNote) => ({ ...note, dateTime: parseISO(note.dateTime) }),
    };
    const noteQuery = useSimpleQuery<IBackendNote, INote>(urlJoin(Url.Notes, String(noteId)), querySettings);
    const note = isEditing && noteQuery.data ? noteQuery.data : undefined;

    /**
     * Set the state to opening which should request an existing note if editing, or just open an empty note form in a
     * modal otherwise.
     */
    const open = useCallback(() => {
        if (isEditing) {
            dispatch(loadingBlockerOperations.showLoadingBlocker(true));
        }
        setIsOpening(true);
    }, [dispatch, isEditing]);

    /**
     * Update the state when the modal is closed.
     */
    const onHide = useCallback(() => {
        setIsOpening(false);
    }, []);

    return (
        <Fragment>
            {isOpening && !noteQuery.isLoading && (
                <ModalContainer isOpen onHide={onHide}>
                    <Modal>
                        <h2>{isEditing ? "Edit" : "Create"} Note</h2>
                        <NoteForm
                            interactionDetailId={interactionDetailId}
                            queryId={queryId}
                            note={note}
                            onComplete={onHide}
                        />
                    </Modal>
                </ModalContainer>
            )}

            {children({ open })}
        </Fragment>
    );
};

export default memo(NoteModal);

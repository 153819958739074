// Languages as stored in the database.
enum Language {
    Afrikaans = 1,
    Albanian = 43,
    Arabic = 2,
    Bengali = 4,
    Bulgarian = 3,
    Catalan = 5,
    Chinese = 54,
    ChineseTaiwan = 55,
    Croatian = 20,
    Czech = 6,
    Danish = 8,
    Dutch = 33,
    English = 11,
    Estonian = 13,
    Finnish = 15,
    French = 16,
    German = 9,
    Greek = 10,
    Gujarati = 17,
    Hebrew = 18,
    Hindi = 19,
    Hungarian = 21,
    Indonesian = 22,
    Italian = 23,
    Japanese = 24,
    Kannada = 25,
    Korean = 26,
    Latvian = 28,
    Lithuanian = 27,
    Macedonian = 29,
    Malayalam = 30,
    Marathi = 31,
    Nepali = 32,
    Norwegian = 34,
    Panjabi = 35,
    Persian = 14,
    Polish = 36,
    Portuguese = 37,
    Romanian = 38,
    Russian = 39,
    Slovak = 40,
    Slovenian = 41,
    Somali = 42,
    Spanish = 12,
    Swahili = 45,
    Swedish = 44,
    Tagalog = 49,
    Tamil = 46,
    Telugu = 47,
    Thai = 48,
    Turkish = 50,
    Ukrainian = 51,
    Urdu = 52,
    Vietnamese = 53,
    Welsh = 7,
    Bosnian = 56,
    Serbian = 57,
    Malay = 58,
    Icelandic = 102,
}

export default Language;

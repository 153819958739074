import { useDispatch, useSelector } from "react-redux";
import { chatOperations } from "redux/modules/chat";
import { emailOperations } from "redux/modules/email";
import { proactiveChatOperations } from "redux/modules/proactive-chat";
import { IApplicationState, IThunkDispatch } from "redux/types";

/**
 * A hook that provides functions for toggling email and chat.
 * @returns An object containing the functions for toggling email and chat.
 */
export const useChatEmailToggles = () => {
    const dispatch = useDispatch<IThunkDispatch>();

    const isChatEnabled = useSelector((state: IApplicationState) => state.chat.enabled);
    const isEmailEnabled = useSelector((state: IApplicationState) => state.email.enabled);
    const isProactiveChatEnabled = useSelector((state: IApplicationState) => state.proactiveChat.enabled);

    /**
     * Toggle chat on or off. If the correct state is already set, nothing is done.
     * @param enableChat A boolean indicating if the chat should be enabled.
     */
    const toggleChat = (enableChat: boolean) => {
        if (enableChat && !isChatEnabled) {
            dispatch(chatOperations.toggleChatOn());
            return;
        }

        if (!enableChat && isChatEnabled) {
            dispatch(chatOperations.toggleChatOff());
            return;
        }
    };

    /**
     * Toggle email on or off. If the correct state is already set, nothing is done.
     * @param enableEmail A boolean indicating if the email should be enabled.
     */
    const toggleEmail = (enableEmail: boolean) => {
        if (enableEmail && !isEmailEnabled) {
            dispatch(emailOperations.toggleEmail(true));
            return;
        }

        if (!enableEmail && isEmailEnabled) {
            dispatch(emailOperations.toggleEmail(false));
            return;
        }
    };

    /**
     * Toggle proactiveChat on or off. If the correct state is already set, nothing is done.
     * @param enableProactiveChat A boolean indicating if the proactive chat should be enabled.
     */
    const toggleProactiveChat = (enableProactiveChat: boolean) => {
        if (enableProactiveChat && !isProactiveChatEnabled) {
            dispatch(proactiveChatOperations.toggleProactiveChat(true));
            return;
        }

        if (!enableProactiveChat && isProactiveChatEnabled) {
            dispatch(proactiveChatOperations.toggleProactiveChat(false));
            return;
        }
    };

    return { toggleChat, toggleEmail, toggleProactiveChat };
};

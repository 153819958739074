import Qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

type IResult<Transforms> = { [Key in keyof Transforms]: Transforms[Key] extends (...args: any[]) => infer R ? R : any };
export type IPrefix<Transforms> = string | { [index: string]: (keyof Transforms)[] };

export interface IUseQueryParametersOptions<Transforms> {
    readonly defaults?: Partial<IResult<Transforms>>;
    readonly prefix?: IPrefix<Transforms>;
    readonly isPrefixOptional?: boolean;
}

/**
 * Get query parameters from the URL and perform transformations on them. Parameters in the URL are all strings so they
 * often need to be converted to numbers, arrays, dates etc. Only known parameters are returned.
 * @param transforms               Functions to transform the parameters before returning.
 * @param options.defaults         Optional default values if they're missing from the URL.
 * @param options.prefix           Optional string or mapping to strings before parameters.
 * @param options.isPrefixOptional The prefix can be optional which means prefixed or non-prefixed parameters will be
 *                                 returned. Prefixed are preferred if both are found.
 * @returns                        Transformed query parameter values.
 */
export const useQueryParameters = <Transforms extends { [index: string]: (value: string) => any }>(
    transforms: Transforms,
    options: IUseQueryParametersOptions<Transforms> = {}
) => {
    const { search } = useLocation();
    return useMemo(
        () => parseUrl(search, transforms, options.defaults ?? {}, options.isPrefixOptional ?? false, options.prefix),
        [options.defaults, options.isPrefixOptional, options.prefix, search, transforms]
    );
};

/**
 * Parse the URL parameters and transform them.
 * @param search     URL query string.
 * @param transforms Functions to transform parameters.
 * @param defaults   Optional default values if they're missing from the URL.
 * @param prefix     Optional string or mapping to strings before parameters.
 * @returns          Transformed query parameter values.
 */
export const parseUrl = <Transforms extends { [index: string]: Function }>(
    search: string,
    transforms: Transforms,
    defaults: Partial<IResult<Transforms>>,
    isPrefixOptional: boolean,
    prefix?: IPrefix<Transforms>
) => {
    const queryParameters = Qs.parse(search, { ignoreQueryPrefix: true });
    const getParameterName = createGetParameterName(prefix);
    return Object.entries(transforms)
        .filter(
            ([key]) =>
                // If the prefix is optional, any parameter with or without the prefix can be used.
                getParameterName(key) in queryParameters ||
                (isPrefixOptional && key in queryParameters) ||
                key in defaults
        )
        .reduce((data, [key, transform]) => {
            const maybePrefixedParameter = getParameterName(key);
            // Where the prefix is optional, take prefixed values first and then non-prefixed if they exist.
            return {
                ...data,
                [key]:
                    maybePrefixedParameter in queryParameters
                        ? transform(queryParameters[maybePrefixedParameter])
                        : isPrefixOptional && key in queryParameters
                          ? transform(queryParameters[key])
                          : defaults[key],
            };
        }, {}) as IResult<Transforms>;
};

/**
 * Get the name of a parameter which may need to be prefixed. This is done as a closure because it manipulates data that
 * should only happen once.
 * @param key    Parameter name.
 * @param prefix Optional string or mapping to strings before parameters.
 * @returns      Parameter key lookup function.
 */
const createGetParameterName = <Transforms extends { [index: string]: Function }>(prefix?: IPrefix<Transforms>) => {
    if (typeof prefix === "undefined") {
        return (key: string) => key;
    } else if (typeof prefix === "string") {
        return (key: string) => `${prefix}.${key}`;
    } else {
        // For convenience the function takes an object keyed by prefix to parameters. The reverse is needed to find the
        // prefixed name from the parameter.
        const reverseMapping = Object.entries(prefix)
            .flatMap(([key, values]) => values.map((value) => ({ key, value })))
            .reduce<{ [value: string]: string }>((data, { key, value }) => ({ ...data, [value]: key }), {});

        return (key: string) => {
            const prefixedKey = reverseMapping[key];
            return typeof prefixedKey === "undefined" ? key : `${prefixedKey}.${key}`;
        };
    }
};

import { IItem, InteractionType } from "common";

import { MessageDirection } from "./messages";

export enum SearchType {
    Query = "Query",
    Similarity = "Similarity",
    Regex = "Regex",
    Exact = Query,
    Similar = Similarity,
}

export enum PhraseTriggerMessageSource {
    Agent = "Agent",
    Bot = "Bot",
    Customer = "Customer",
    InteractionDetails = "INTERACTION_DETAILS",
    System = "System",
}

export interface IPhraseTrigger {
    readonly phraseTriggerId: string;
    readonly phrase: string;
    readonly tagId: string;
    readonly searchType: string;
    readonly similarityThreshold: number;
    readonly inboundMessages: boolean;
    readonly outboundMessages: boolean;
    readonly botMessages: boolean;
    readonly interactionTypes: InteractionType[];
    readonly languageIsoCodes?: string[] | null;
    readonly createDateTime?: Date | null;
    readonly updateDateTime?: Date | null;
}

export interface IPhraseTriggerSample {
    readonly interactionId: string;
    readonly interactionMessageId: string;
    readonly score: number;
    readonly sentence: string;
    readonly sentenceId: string;
    readonly languageIsoCode?: string;
    readonly messageDirection?: MessageDirection;
}
export interface IPhraseTriggerSampleParameters {
    readonly clientId?: string;
    readonly searchType: string;
    readonly search: string;
    readonly messageDirection?: MessageDirection[];
    readonly interactionType?: InteractionType[];
    readonly languageIsoCode?: string[] | null;
    readonly similarityThreshold?: number;
    readonly limit?: number;
}

export interface IPhraseTriggerResult {
    readonly phraseTriggerResultId: string;
    readonly phraseTriggerId: string;
    readonly clientId: string;
    readonly interactionMessageId: string;
    readonly tagId: string;
    readonly similarity: number;
    readonly sentence: string;
    readonly deletedByUserId: string | null;
    readonly deletedDateTime: string | null;
    /** This is added only in frontend, it's not sent from API */
    readonly languageIsoCode?: string;
}

export interface IPhraseTriggerResultParameters {
    readonly interactionMessageId?: string;
    readonly phraseTriggerId?: string;
    readonly startDateTime?: Date;
    readonly endDateTime?: Date;
    readonly orderBy?: string;
    readonly tagId?: string;
}

export type IPhraseTriggerPostRequest = IPhraseTrigger;
export type IPhraseTriggersResponse = IItem<IPhraseTrigger>;
export type IPhraseTriggerSamplesResponse = IItem<IPhraseTriggerSample>;
export type IPhraseTriggerResultsResponse = IItem<IPhraseTriggerResult>;

import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FunctionComponent, memo } from "react";

import ConditionalIcon from "../conditional-icon";
import "./menu-open-closed-indicator.scss";
import { IProps } from "./menu-open-closed-indicator.types";

/**
 * Up/down arrows to indicate a menu is open.
 * @param props.isOpen Whether the menu is open or not.
 * @returns            Icon depending on opened state.
 */
const MenuOpenClosedIndicator: FunctionComponent<IProps> = ({ isOpen }) => {
    return (
        <div className="menu-open-closed-indicator">
            <ConditionalIcon condition={isOpen} iconFalse={faChevronDown} iconTrue={faChevronUp} />
        </div>
    );
};

export default memo(MenuOpenClosedIndicator);

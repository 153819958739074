import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Star icon for the rating.
 * @returns Solid star icon.
 */
const FilledStar = () => <FontAwesomeIcon icon={faStar} size="lg" />;

export default FilledStar;

import { SubmitOnChange } from "@edgetier/components";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";
import { Form, Formik } from "formik";

import GridContext from "../grid-context";
import "./table-filters.scss";
import { IProps } from "./table-filters.types";

/**
 * Filters for a table. This is a form that will automatically submit when the filters change.
 * @param props.children Children containing various filters.
 * @returns              Filter form.
 */
const TableFilter = ({ children, onFiltersChange, delay }: IProps) => (
    <GridContext.Consumer>
        {({ filters, searchKeys, setFilters }) => (
            <Formik
                initialValues={filters}
                onSubmit={(values, formikHelpers) => {
                    onFiltersChange?.(values);
                    setFilters(values);
                }}
            >
                {() => (
                    <Form className="grid__filters" name="grid__filters-form">
                        {Array.isArray(searchKeys) && (
                            <div className="field field-inline">
                                <div className="field-inline__icon">
                                    <FontAwesomeIcon icon={faSearch} />
                                </div>
                                <Field
                                    autoComplete="off"
                                    autoFocus={true}
                                    name="search"
                                    placeholder="Search&hellip;"
                                    spellCheck={false}
                                    type="text"
                                />
                            </div>
                        )}

                        {children}

                        <SubmitOnChange delay={delay} />
                    </Form>
                )}
            </Formik>
        )}
    </GridContext.Consumer>
);

export default TableFilter;

import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { NavigationLink } from "@edgetier/client-components";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Link to the agent home page.
 * @returns A link to the page.
 */
const AgentHomeLink = () => <NavigationLink label="Home" icon={faHome} page={AbsolutePaths[PageNames.AgentHome]()} />;

export default AgentHomeLink;

import { faStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Star icon for the rating.
 * @returns Empty star icon.
 */
const EmptyStar = () => <FontAwesomeIcon icon={faStar} size="lg" />;

export default EmptyStar;

import { FunctionComponent, memo, useContext } from "react";
import type { IProps } from "./navigation-group-child-notification-count.types";
import { NavigationItemNotificationCount } from "../navigation-item-notification-count";
import NavigationGroupNotificationsContext from "context-for/navigation-group-notifications/navigation-group-notifications-context";

/**
 * Displays the notification count for the navigation group child. It gets the value through context.
 * @param pageName The name of the page to get the notification count for.
 */
const NavigationGroupChildNotificationCount: FunctionComponent<IProps> = ({ pageName }) => {
    const { notificationMap } = useContext(NavigationGroupNotificationsContext);

    const notificationCount = notificationMap[pageName];

    if (typeof notificationCount === "undefined" || notificationCount === 0) {
        return null;
    }

    return <NavigationItemNotificationCount notificationCount={notificationCount} />;
};

export default memo(NavigationGroupChildNotificationCount);

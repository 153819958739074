import { FunctionComponent, memo } from "react";
import { IProps } from "./authenticated-routes-wrapper.types";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AuthenticatedRoutes from "components/application/authenticated-routes";
import { ServerErrorModal } from "shared/modal";
import { getUserId } from "utilities/local-storage";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";
import { REDIRECT_ROUTE_KEY } from "../authenticated-redirect/authenticated-redirect.constants";

import "./authenticated-routes-wrapper.scss";

/**
 * A wrapper component around the authenticated routes component. This is used to get access to the useNavigate hook.
 */
const AuthenticatedRoutesWrapper: FunctionComponent<IProps> = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userId = getUserId();

    // If the user is not logged in, redirect them to the login page and temporarily store their original route
    if (userId === null) {
        localStorage.setItem(REDIRECT_ROUTE_KEY, `${location.pathname}${location.search}`);
        return <Navigate to={AbsolutePaths[PageNames.Login]()} />;
    }

    return (
        <ServerErrorModal>
            {(showServerError) => (
                <AuthenticatedRoutes
                    pathname={window.location.pathname}
                    push={navigate}
                    showServerError={showServerError}
                />
            )}
        </ServerErrorModal>
    );
};

export default memo(AuthenticatedRoutesWrapper);

import { FunctionComponent, memo } from "react";
import classNames from "classnames";
import { IProps } from "./navigation-link-indicator.types";
import "./navigation-link-indicator.scss";

/**
 * Show a numeric or other indicator on a navigation link e.g. number of unread messages.
 * @param props.page Indicator content.
 * @returns Styled indicator.
 */
const NavigationLinkIndicator: FunctionComponent<IProps> = ({ children, className, position = "bottom" }) => {
    return (
        <div className={classNames("navigation-link-indicator", `navigation-link-indicator--${position}`, className)}>
            {children}
        </div>
    );
};

export default memo(NavigationLinkIndicator);

import { IRedactFormSection } from "./redact-form.types";
import axios from "utilities/axios";

/**
 * Get the initially selected language and section.
 * @param props.sections Details of the different properties and languages that can be redacted.
 * @returns              Initially selected language and section.
 */
export const getInitialActiveOption = (sections: IRedactFormSection[]) => () => {
    const firstSectionTranslations = Object.entries(sections[0].translations);
    const firstNonTranslation = firstSectionTranslations.find(([_, translation]) => translation.translationId === null);
    const languageId = parseInt(firstNonTranslation?.[0] ?? Object.keys(sections[0].translations)[0], 10);
    return { index: 0, languageId };
};

/**
 * Send patch requests for redacted content.
 * @param sections Details of the different properties and languages that can be redacted.
 * @param url      Where to patch the primary changes (probably "/emails/:emailId" or "/messages/:messageId").
 * @returns        Promises.
 */
export const patchData = async (sections: IRedactFormSection[], url: string) => {
    const { mainData, translationData } = createPatchData(sections);
    await axios.patch(url, mainData);

    const translationPatches = translationData.map(({ translation, translationId }) => {
        return axios.patch(`/translations/${translationId}`, { translation: translation?.trim() });
    });

    await Promise.all(translationPatches);
};

/**
 * Format data for patching.
 * @param sections Details of the different properties and languages that can be redacted.
 * @returns        Main patch data and translation patch data.
 */
const createPatchData = (sections: IRedactFormSection[]) => {
    const data = sections.flatMap(({ property, translations }) => {
        return Object.values(translations).map(({ translation, translationId }) => ({
            translationId,
            isTranslation: typeof translationId === "number",
            property,
            translation,
        }));
    });

    return {
        mainData: data
            .filter(({ isTranslation }) => !isTranslation)
            .reduce((data, { property, translation }) => ({ ...data, [property]: translation?.trim() }), {
                isRedaction: true,
            }),
        translationData: data.filter(({ isTranslation }) => isTranslation),
    };
};

import { Button, FieldError } from "@edgetier/components";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { Field, Form, Formik } from "formik";
import { FunctionComponent, memo } from "react";
import * as Yup from "yup";
import useCreateRole from "~/hooks/use-create-role";

import "./role-form.scss";
import { IProps } from "./role-form.types";

/**
 * Form used to create or edit a role
 * @param props.role            Role selected for edit
 * @param props.toggleAction    Callback ran on a successfully created role
 * @param props.routeUrl    API roles route
 * @param props.axiosInstance
 */
const RoleForm: FunctionComponent<IProps> = ({ routeUrl, axiosInstance, role, toggleAction }) => {
    const { mutate: submitRole, isLoading } = useCreateRole(routeUrl, axiosInstance, role, { onSettled: toggleAction });

    return (
        <Formik
            initialValues={{ role: role?.role ?? "" }}
            onSubmit={({ role }) => submitRole(role)}
            validationSchema={Yup.object().shape({ role: Yup.string().trim().required("Required") })}
        >
            <Form autoComplete="off" name="role-form">
                <div className="field">
                    <FieldError name="roleName" />
                    <label htmlFor="roleName">Role Name</label>
                    <Field id="roleName" name="role" type="text" placeholder="Role name" autoFocus />
                </div>
                <Button type="submit" icon={faSave} isLoading={isLoading}>
                    Save Role
                </Button>
            </Form>
        </Formik>
    );
};

export default memo(RoleForm);

import { AxiosError, AxiosInstance } from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { createGetAllPaginatedItems } from "@edgetier/utilities";
import { IPermission } from "@edgetier/types";

/**
 * Request all permissions
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 */
const getPermissions = async (routeUrl: string, axiosInstance: AxiosInstance) => {
    const getAllPaginatedItems = createGetAllPaginatedItems(axiosInstance);

    const permissions = await getAllPaginatedItems<IPermission>(routeUrl);
    return permissions.sort((permissionOne, permissionTwo) =>
        permissionOne.permission.localeCompare(permissionTwo.permission)
    );
};

/**
 * Get all permissions
 * @param routeUrl          API route URL
 * @param axiosInstance     Axios instance specific to an application
 * @param configuration     Optional Axios configuration
 * @returns React Query result.
 */
const usePermissions = <T = IPermission[]>(
    routeUrl: string,
    axiosInstance: AxiosInstance,
    configuration: UseQueryOptions<IPermission[], AxiosError<void>, T> = {}
) => {
    return useQuery<IPermission[], AxiosError<void>, T>([routeUrl], () => getPermissions(routeUrl, axiosInstance), {
        ...configuration,
    });
};

export default usePermissions;

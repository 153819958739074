import { Button } from "@edgetier/components";
import Modal, { useModal } from "@edgetier/modal";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FunctionComponent, memo, useContext } from "react";

import SelectContext from "../../select-context/select-context";
import "./add-item-button.scss";
import { IProps } from "./add-item-button.types";

/**
 * A button that, when clicked, will open a menu for adding an item to the select component.
 * @param props.addItemMenu A react component that will contain the menu to add an item.
 */
const AddItemButton: FunctionComponent<IProps> = ({ addItemMenu }) => {
    const { lockOpen, unlock } = useContext(SelectContext);
    const { modalProps, openModal } = useModal({ onCloseModal: unlock });
    return (
        <>
            <Modal {...modalProps}>{addItemMenu}</Modal>
            <Button
                onClick={() => {
                    openModal();
                    lockOpen();
                }}
                icon={faPlus}
            >
                Add item
            </Button>
        </>
    );
};

export default memo(AddItemButton);

import { FunctionComponent } from "react";
import { IProps } from "./typing-indicator.types";
import "./typing-indicator.scss";

/**
 * Display to indicate that the other person is typing.
 * @returns Flashing dots.
 */
const TypingIndicator: FunctionComponent<IProps> = () => (
    <div className="chat-messages__typing-indicator">
        <div /> <div /> <div />
    </div>
);

export default TypingIndicator;

import { FunctionComponent } from "react";
import { IProps } from "./attach.types";
import React, { Fragment } from "react";
import { Field, FieldProps } from "formik";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons/faPaperclip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAttachFile } from "@edgetier/utilities";

import { ALLOWED_EXTENSIONS } from "./attach.constants";
import Reupload from "./reupload";

/**
 * A button that, when clicked, opens a dialog to upload an attachment.
 * @param props.name            Name of the field.
 * @param props.postAttachment  Method to call when the user uploads a file.
 * @param props.identifier      Identifier of the attachment.
 * @param props.onUploadError   Method to call when the upload fails.
 */
export const Attach: FunctionComponent<IProps> = ({ name, postAttachment, identifier, onUploadError }) => {
    const { attachFile, onSelect } = useAttachFile({ name, postAttachment, identifier, onUploadError });

    /**
     * Handle the user uploading one or more files.
     * @param changeEvent Change event triggered when the agent uploads a file.
     */
    const onFileChange = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
        const { files } = changeEvent.target;
        if (files !== null && files.length >= 1) {
            attachFile(files[0]);
        }

        // The file must be removed here, otherwise removing and reuploading doesn't work because the change event
        // won't fire again if it thinks the file hasn't changed.
        changeEvent.target.value = "";
    };

    return (
        <Field name={name}>
            {({ field }: FieldProps) => (
                <Fragment>
                    <Reupload attachments={field.value} upload={onSelect.bind(this, field.value)} />

                    <div className="attach">
                        <input
                            accept={ALLOWED_EXTENSIONS}
                            id={field.name}
                            onChange={onFileChange}
                            tabIndex={0}
                            type="file"
                            role="button"
                        />

                        <label htmlFor={field.name}>
                            <FontAwesomeIcon icon={faPaperclip} />
                        </label>
                    </div>
                </Fragment>
            )}
        </Field>
    );
};

export default Attach;

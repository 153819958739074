import { Tooltip } from "@edgetier/components";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import { IInfoTooltipProps } from "./info-tooltip.types";

const InfoTooltip: FunctionComponent<IInfoTooltipProps> = (props) => {
    return (
        <Tooltip useArrow {...props}>
            <FontAwesomeIcon fixedWidth={true} icon={faInfoCircle} />
        </Tooltip>
    );
};

export default InfoTooltip;

import { Tooltip } from "@edgetier/components";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, memo } from "react";

import "./search-help.scss";
import { IProps } from "./search-help.types";

/**
 * Tooltip to explain search functionality.
 * @returns Question mark icon that will show a help tooltip on hover.
 */
const SearchHelp: FunctionComponent<IProps> = () => (
    <Tooltip
        content={
            <div className="search-help__content">
                <p>Type one or more words/phrases to search.</p>

                <ul>
                    <li>
                        Typing several terms separated by spaces e.g. <code>error payment</code> will find interactions
                        that contain all of those words.
                    </li>
                    <li>
                        Match any phrase with <code>OR</code>: <code>error OR issue</code> will find interactions with
                        either word.
                    </li>
                    <li>
                        Wrap in double quotes to search for multiple words appearing together e.g.{" "}
                        <code>"website down"</code>.
                    </li>
                    <li>
                        Exclude with <code>-</code>: <code>complaint -delivery</code> will find complaints not
                        mentioning &ldquo;delivery&rdquo;.
                    </li>
                </ul>
                <p>
                    Words are lemmatised so that, for example, searching for &ldquo;cancellation&rdquo; will also show
                    interactions containing &ldquo;cancelled&rdquo;, &ldquo;cancelling&rdquo; etc.
                </p>
            </div>
        }
        placement="bottom-center"
    >
        <div className="search-help">
            <FontAwesomeIcon icon={faQuestionCircle} />
        </div>
    </Tooltip>
);

export default memo(SearchHelp);

import { ICall, IInteractionSearchItem } from "components/interaction-search/interaction-search.types";
import { InteractionIconType } from "@edgetier/types";
import HandlingType from "constants/handling-type";
import InteractionType from "constants/interaction-type";
import ConversationType from "constants/conversation-type";
import { unreachableCaseError } from "@edgetier/utilities";

/**
 * Get the appropriate icon depending on if the interaction is an inbound or outbound email.
 * @param interaction An email interaction.
 */
const getEmailLabel = (interaction: IInteractionSearchItem) => {
    return "emailId" in interaction ? InteractionIconType.EmailIn : InteractionIconType.EmailOut;
};

/**
 * Get the appropriate icon depending on if the interaction is an inbound, outbound or internal call.
 * @param interaction.conversationTypeId The id for the call conversation type.
 * @param interaction.inbound            A boolean indicating if the call is inbound.
 */
const getCallLabel = ({ conversationTypeId, inbound }: ICall) => {
    if (conversationTypeId === ConversationType.Internal) {
        return InteractionIconType.Call;
    }
    return inbound ? InteractionIconType.CallIn : InteractionIconType.CallOut;
};

/**
 * Get the interaction type icon based on interactionTypeId, handlingTypeId or conversationTypeId
 * @param props.interaction
 */
export const getInteractionIconType = (interaction: IInteractionSearchItem) => {
    if (interaction.handlingTypeId === HandlingType.System) {
        return InteractionIconType.Automated;
    }

    switch (interaction.interactionTypeId) {
        case InteractionType.Chat:
            return InteractionIconType.Chat;
        case InteractionType.Email:
            return getEmailLabel(interaction);
        case InteractionType.Call:
            return getCallLabel(interaction);
        default:
            unreachableCaseError(interaction);
            return InteractionIconType.Notes;
    }
};

import { DetailsFieldTitle, DetailsFieldValue } from "@edgetier/client-components";

import BlankField from "../blank-field";

import { IProps } from "./form-submission-timestamp-field.types";
import { formatDateValue, isValidDateString } from "./form-submission.timestamp-field.utilities";
import "./form-submission-timestamp-field.scss";

/**
 * Display a timestamp form field submission. This is presented to users as a date picker.
 * @param props.field Timestamp form field submission.
 * @returns           Field title and formatted date.
 */
const FormSubmissionTimestampField = ({ field }: IProps) => (
    <tr>
        <td>
            <DetailsFieldTitle>{field.formFieldTranslation}</DetailsFieldTitle>
        </td>

        <td>
            {!isValidDateString(field.value) ? (
                <BlankField />
            ) : (
                <DetailsFieldValue copyText={field.value}>
                    <span>{formatDateValue(field.formFieldTypeId, field.value)}</span>
                </DetailsFieldValue>
            )}
        </td>
    </tr>
);
export default FormSubmissionTimestampField;

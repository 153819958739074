export const CHAT_OPEN_CLASS = "edge-tier-chat-open";
export const PROACTIVE_CHAT_OPEN_CLASS = "edge-tier-proactive-chat-open";
export const ROOT_ID = "root";
export const PROACTIVE_CHAT_ROOT_ID = "proactive-chat-root";
export const CHAT_FRAME_NAME = "chat-frame";
export const DATA_FRAME_NAME = "chat-data-frame";
export const DATA_FRAME_PAYLOAD_ID = "edgetier-data";
export const PROACTIVE_CHAT_FRAME_NAME = "proactive-chat-frame";
export const SOCKET_NAMESPACE = "chat";
export const FRAME_STORAGE_NAME = "EdgeTierFrames";

// Fallback language when a translation or something is missing.
export const DEFAULT_LANGUAGE_ID = 11;

import { Page, PageContent, PageHeader } from "@edgetier/client-components";
import { Button } from "@edgetier/components";

import "./chunk-load-error-fallback.scss";

/**
 * Component for handling chunk loading errors and prompting a page refresh.
 * @returns {JSX.Element} ChunkLoadErrorFallback component.
 */
const ChunkLoadErrorFallback = () => {
    const retryPageLoading = () => {
        window.location.reload();
    };

    return (
        <Page className="chulk-load-error-fallback">
            <PageHeader title="New update available" />
            <PageContent width="full">
                <p className="chulk-load-error-fallback__description">
                    Please refresh the page to start using the latest version.
                </p>
                <Button className="chulk-load-error-fallback__button" onClick={retryPageLoading}>
                    Refresh browser
                </Button>
            </PageContent>
        </Page>
    );
};

export default ChunkLoadErrorFallback;

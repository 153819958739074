import { IPaginated } from "@edgetier/types";

export enum TagGroupType {
    External = "EXTERNAL",
    PhraseSearch = "PHRASE_SEARCH",
    Agent = "AGENT",
    AutomaticTag = "AUTOMATIC_TAG",
}

export interface ITagGroup {
    readonly isCommonFilter: boolean;
    readonly isExternalEnabled: boolean;
    readonly isUnaryForInteractions: boolean;
    readonly isUnaryForAgents: boolean;
    readonly tagGroupId: string;
    readonly tagGroup: string;
    readonly tagGroupType: TagGroupType;
}

export interface ITagGroupsGetQueryParameters {
    readonly limit?: number;
    readonly tagGroupType?: TagGroupType;
}

export type ITagGroupsGetResponse = IPaginated<ITagGroup>;

export interface ITagGroupInteractionsVariance {
    readonly tag: string;
    readonly proportion: number;
    readonly interactionsCount: number;
    readonly proportionChange: number;
}

export type ITagGroupInteractionsVariancesResponse = IPaginated<ITagGroupInteractionsVariance>;
export type ITagGroupPostRequest = ITagGroup;

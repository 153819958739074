// How the backend defines the state of a chat. After socket reconnections or page refreshes, the frontend requests all
// the agents' currently active chats. The backend will return a state ID so the frontend can determine if the customer
// has left the chat, or the it's mid-transfer etc.
export enum ChatStateStatus {
    WaitingForAgent = 1,
    Chatting = 2,
    Transferring = 3,
    CustomerGone = 4,
    AgentWrapUp = 5,
    Terminated = 6,
    DeflectingCustomer = 7,
    OfflineDeflection = 8,
}

export default ChatStateStatus;

import {
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle,
    faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, memo } from "react";
import { IProps } from "./callout.types";

/**
 * Callout to display information, warning or error messages.
 * @param props.children    Text or JSX elemnt to display in the callout.
 * @param props.hasIcon     Option to hide icon.
 * @param props.icon        Optional icon to override icon displayed via variant type.
 * @param props.title       Text title for callout.
 * @param props.variant     Variant type where the default is neutral.
 * @param props.otherProps  Other props for callout parent.
 */
const Callout: FunctionComponent<IProps> = ({
    children,
    hasIcon = true,
    icon,
    title,
    variant = "neutral",
    ...otherProps
}) => {
    const iconVariant = {
        info: faInfoCircle,
        warning: faExclamationTriangle,
        error: faExclamationCircle,
        neutral: faQuestionCircle,
    };

    return (
        <div className={`callout callout--${variant}`} data-testid="callout">
            {hasIcon && (
                <div className="callout__icon">
                    <FontAwesomeIcon aria-hidden={false} icon={icon ?? iconVariant[variant]} />
                </div>
            )}
            {title && <h5 className="callout__title">{title}</h5>}
            <div className="callout__content">{children}</div>
        </div>
    );
};

export default memo(Callout);

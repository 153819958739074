import { NavigationLink } from "@edgetier/client-components";
import { faEnvelopeOpen } from "@fortawesome/pro-solid-svg-icons";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Link to the blank email view.
 * @returns A link to the page.
 */
const CreateEmailLink = () => (
    <NavigationLink label="New Email" icon={faEnvelopeOpen} page={AbsolutePaths[PageNames.CreateEmail]()} />
);

export default CreateEmailLink;

import { FunctionComponent, memo } from "react";

import "./box-subheading.scss";
import { IProps } from "./box-subheading.types";

/**
 * Box subtitle.
 * @param props.children Subtitle text.
 * @returns              Box subtitle.
 */
const BoxSubheading: FunctionComponent<IProps> = ({ children }) => {
    return <div className="box-subheading">{children}</div>;
};

export default memo(BoxSubheading);

import { useEffect, useState } from "react";

import ToggleTranslationContext, { defaultContext } from "../toggle-translation-context";
import { IProps } from "./toggle-translation-provider.types";

/**
 * Container for elements that require text that can be translated for example, an email body, chat message, subject
 * line of an email etc. The translation will sometimes be available straight away, or it might need to be requested
 * when the user wants to see it.
 *
 * This container just remembers the translation state.
 */
const ToggleTranslationProvider = ({
    children,
    preferredLanguageId,
    languages,
    languageId,
    languageIds,
    showServerError,
}: IProps) => {
    const [state, setState] = useState({
        ...defaultContext,
        languageId: languageId ?? preferredLanguageId,
        languageIds: languageIds.filter((languageId, index, items) => items.indexOf(languageId) === index),
        preferredLanguageId,
    });

    useEffect(() => {
        setState({
            ...defaultContext,
            languageId: languageId ?? preferredLanguageId,
            languageIds: languageIds.filter((languageId, index, items) => items.indexOf(languageId) === index),
            preferredLanguageId,
        });
    }, [languageId, languageIds, preferredLanguageId]);

    /**
     * Toggle between the original text and translated text.
     */
    const setLanguageId = (languageId: number): void => {
        setState({ ...state, languageId });
    };

    /**
     * Update the list of languages the user can switch between.
     */
    const setLanguageIds = (preferredLanguageId: number, languageIds: number[]): void => {
        setState({
            ...state,
            languageId: preferredLanguageId,
            languageIds: languageIds.filter((languageId, index, items) => items.indexOf(languageId) === index),
            preferredLanguageId,
        });
    };

    return (
        <ToggleTranslationContext.Provider
            value={{
                ...state,
                languages,
                isTranslated: state.languageId !== state.preferredLanguageId,
                preferredLanguageId: state.preferredLanguageId,
                setLanguageId,
                setLanguageIds,
                showServerError,
            }}
        >
            {children}
        </ToggleTranslationContext.Provider>
    );
};

export default ToggleTranslationProvider;

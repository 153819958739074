import { Url } from "@edgetier/types";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import urlJoin from "url-join";

import FormSubmission from "components/form-submission";
import { IFormSubmissionField } from "redux/application.types";
import axios from "utilities/axios";

import { IProps } from "./form-submission-loader.types";

/**
 * Request and display a form submission.
 * @param props.actionButton Pass a button element to perform an action, it is placed at the bottom of the details section.
 * @param props.formSubmissionId The ID of the form to get the form submission fields.
 * @param props.preferredLanguageId Default language to show if there are multiple translations available.
 * @param props.secondaryLanguageId Alternative translation available, if any.
 * @param props.title Title for the form details section.
 */
export const FormSubmissionLoader: React.FunctionComponent<IProps> = ({
    actionButton,
    formSubmissionId,
    preferredLanguageId,
    secondaryLanguageId,
    title,
}) => {
    const [formSubmissionFields, setFormSubmissionFields] = useState<IFormSubmissionField[]>([]);

    const cancelTokenSource = useRef(Axios.CancelToken.source());
    useEffect(() => cancelTokenSource.current.cancel, []);

    useEffect(() => {
        /**
         * Request the form submission.
         */
        const getFormSubmission = async () => {
            try {
                const configuration = { cancelToken: cancelTokenSource.current.token };
                const url = urlJoin(Url.FormSubmissions, formSubmissionId.toString());
                const response = await axios.get<{ formSubmissionFields: IFormSubmissionField[] }>(url, configuration);
                setFormSubmissionFields(response.data.formSubmissionFields);
            } catch (serverError) {
                if (!Axios.isCancel(serverError) && Axios.isAxiosError(serverError)) {
                    setFormSubmissionFields([]);
                }
            }
        };
        getFormSubmission();
    }, [formSubmissionId]);

    /**
     * Display a spinner until the form submission has been requested and then show that.
     * @returns Spinner or form submission.
     */
    return (
        <FormSubmission
            actionButton={actionButton}
            formSubmissionFields={formSubmissionFields}
            preferredLanguageId={preferredLanguageId}
            secondaryLanguageId={secondaryLanguageId}
            title={title}
        />
    );
};

export default FormSubmissionLoader;

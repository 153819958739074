import { AxiosError } from "axios";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";

import axios from "utilities/axios";

const getData = async <T extends {}>(url: string, queryParameters: Record<string, any>) => {
    const { data } = await axios.get<T | { items: T }>(url, { params: queryParameters });
    if ("items" in data) {
        return data.items;
    } else {
        return data;
    }
};

export const useSimplePrefetch = async (url: string, queryParameters: Record<string, any>) => {
    const queryClient = useQueryClient();
    queryClient.prefetchQuery(url, () => getData(url, queryParameters));
};

const useSimpleQuery = <T extends {}, P = T>(
    url: string,
    configuration: UseQueryOptions<T, AxiosError, P> = {},
    queryParameters: Record<string, any> = {}
) => {
    return useQuery<T, AxiosError, P>(url, () => getData<T>(url, queryParameters), configuration);
};

export default useSimpleQuery;

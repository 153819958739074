import * as sentry from "@sentry/react";
import AuthenticatedRedirect from "components-for/authentication/authenticated-redirect";
import AuthenticatedRoutesWrapper from "components-for/authentication/authenticated-routes-wrapper";
import errorBuilder from "components-for/errors/error-builder";
import Authenticate from "components/authenticate";
import OAuthCallback from "components/callbacks/oauth/oauth-callback";
import LoadingBlocker from "components/loading-blocker/loading-blocker";
import Login from "components/login/login";
import Modal from "components/modal/modal";
import { AbsolutePaths } from "constants/routes";
import { Fragment } from "react";
import { Toaster } from "react-hot-toast";
import LoadingBar from "react-redux-loading-bar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ServerErrorModal } from "shared/modal";
import { PageNames } from "types-for/routes";

import StackingLayers from "./stacking-layers";

/**
 * Render all global components like the loading block, toaster  etc. and all basic high-level routes like the login
 * screen. Most other routes require the user to be logged in so they are contained in another component.
 * @returns Various top-level components and routes.
 */
const Application = () => (
    <Fragment>
        <Toaster position="bottom-right" toastOptions={{ style: { backgroundColor: "#34495e", color: "white" } }} />
        <LoadingBar className="loading" />
        <Modal />

        <LoadingBlocker>
            {/* Render StackingLayers as a child of LoadingBlocker so loading blockers will work in modals */}
            <StackingLayers />
            <BrowserRouter>
                <Routes>
                    <Route path={`${AbsolutePaths[PageNames.Main]()}/*`} element={<AuthenticatedRoutesWrapper />} />

                    <Route
                        path={AbsolutePaths[PageNames.Login]()}
                        element={
                            <ServerErrorModal>
                                {(showServerError) => <Login showServerError={showServerError} />}
                            </ServerErrorModal>
                        }
                    />

                    <Route
                        path={AbsolutePaths[PageNames.Authenticate]()}
                        element={<Authenticate search={window.location.search} />}
                    />

                    <Route path={AbsolutePaths[PageNames.CallbackOAuth]()} element={<OAuthCallback />} />

                    <Route
                        path={AbsolutePaths[PageNames.AuthenticatedRedirect]()}
                        element={<AuthenticatedRedirect />}
                    />
                </Routes>
            </BrowserRouter>
        </LoadingBlocker>
    </Fragment>
);
export default sentry.withErrorBoundary(Application, { fallback: errorBuilder });

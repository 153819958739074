import { getTranslation } from "@edgetier/utilities";

import { IProps } from "./translation.types";

/**
 * Find a translation for a given language or, if missing, take the first one.
 * @param props.children     Children components to render.
 * @param props.languageId   The translation language to find.
 * @param props.translations Dictionary of translations.
 * @returns                  Children with translation passed as a render prop.
 */
const Translation = ({ children, languageId, translations }: IProps) => (
    <>{children(getTranslation(translations, languageId))}</>
);

export default Translation;

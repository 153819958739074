import { FunctionComponent, memo } from "react";
import { Url } from "@edgetier/types";
import urlJoin from "url-join";
import { DetailsFieldTitle, DetailsFieldValue } from "@edgetier/client-components";
import useSimpleQuery from "queries/use-simple-query";
import { hotToastOperations } from "utilities-for/toast";
import { IChatDeflection, IProps } from "./chat-deflection-fields.types";
import "./chat-deflection-fields.scss";

/**
 * Display chat deflection attempt field and chat deflected field
 * @param chatId
 */
const ChatDeflectionFields: FunctionComponent<IProps> = ({ chatId }) => {
    const { data, isLoading } = useSimpleQuery<IChatDeflection>(urlJoin(Url.Chat, chatId.toString()), {
        onError: (serverError) => hotToastOperations.showServerErrorToast("Chat Request Failed", serverError),
        staleTime: 1000 * 60 * 5,
    });

    return (
        <>
            <tr>
                <td>
                    <DetailsFieldTitle>Deflection Attempted</DetailsFieldTitle>
                </td>
                <td>
                    <DetailsFieldValue copyText={data?.deflectionAttempted ? "Yes" : "No"} isLoading={isLoading}>
                        {data?.deflectionAttempted ? "Yes" : "No"}
                    </DetailsFieldValue>
                </td>
            </tr>
            <tr>
                <td>
                    <DetailsFieldTitle>Deflected</DetailsFieldTitle>
                </td>
                <td>
                    <DetailsFieldValue copyText={data?.deflected ? "Yes" : "No"} isLoading={isLoading}>
                        {data?.deflected ? "Yes" : "No"}
                    </DetailsFieldValue>
                </td>
            </tr>
        </>
    );
};

export default memo(ChatDeflectionFields);

/**
 * Splits the name by a space and returns an object with the firstname and surname.
 *
 * @param {string} name - The name to split
 * @returns {{firstName: string, surname: string}} - The first and last names
 */
export const splitNameIntoFirstAndLast = (name: string) => {
    // Check if the name is empty
    if (!name) {
        return { firstName: "", surname: "" };
    }
    // Check if the name contains a space
    if (name.indexOf(" ") === -1) {
        return { firstName: name, surname: "" };
    }
    // Split the name into parts
    const parts = name.split(" ");

    // If the parts are more than 2, return the first part and the rest joined with a space
    if (parts.length > 2) {
        return { firstName: `${parts[0]}`, surname: `${parts.slice(1).join(" ")}` };
    }

    // If the parts are exactly 2, return the first and second parts
    return {
        firstName: `${parts[0]}`,
        surname: `${parts[1]}`,
    };
};

import { IAttachmentProps, IOutboundAttachment } from "@edgetier/types";
import Axios from "axios";
import { useField } from "formik";
import memoizeOne from "memoize-one";
import { useEffect, useRef } from "react";

import { isCancelled, makeCancellable } from "./make-cancellable";

/**
 * Create a form and include the file to be posted to the backend. This function is memoised because the user may
 * navigate away from the page and on their return the upload will run again but shouldn't cause a duplicate upload.
 * @returns Method to post a file.
 */
const uploadFile = memoizeOne(
    ([file]: [File, string], post: IAttachmentProps["postAttachment"]) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        return post(formData);
    },
    (([[oldFile, oldIdentifier]]: [File, string][], [[newFile, newIdentifier]]: [File, string][]) => {
        if (typeof oldFile === "undefined") {
            return true;
        } else {
            return (
                oldIdentifier === newIdentifier &&
                oldFile.lastModified === newFile.lastModified &&
                oldFile.name === newFile.name &&
                oldFile.size === newFile.size &&
                oldFile.type === newFile.type
            );
        }
    }) as any // TODO: LEFTOVER - Fix this cast
);

/**
 * A hook to attach a file to a form. This will upload the file to the server and display it on the screen.
 * @param props.name             The name of the field where the attachment will be added.
 * @param props.postAttachment   The method to post the attachment to the server.
 * @param props.identifier       The identifier of the attachment.
 * @param props.onUploadError    The method to call when an upload error occurs.
 */
export const useAttachFile = ({ name, postAttachment, identifier, onUploadError }: IAttachmentProps) => {
    const cancels = useRef<VoidFunction[]>([]);

    useEffect(() => {
        const currentCancels = cancels.current;
        return () => {
            currentCancels.forEach((cancel) => cancel());
        };
    }, []);

    const [field, , fieldHelpers] = useField<IOutboundAttachment[]>(name);

    /**
     * Upload attachments to the server. They will display on the screen when selected with a loading icon.
     * @param current Current attachments.
     * @param file    Attachment.
     */
    const onSelect = async (current: IOutboundAttachment[], file: File): Promise<void> => {
        const index = current.findIndex(({ attachment }) => attachment === file.name);

        try {
            // Multiple posts will be made simulaneously if there is more than one file to upload.
            const { cancel, promise } = makeCancellable(uploadFile([file, identifier], postAttachment));

            // Record cancel method to cancel on unmount.
            cancels.current.push(cancel);

            // Update the attachment on screen after the backend responds.
            const { data: attachmentId } = await promise;
            const newAttachments = current.map((attachment, attachmentIndex) => {
                if (attachmentIndex === index) {
                    return { attachment: file.name, attachmentId, file } as IOutboundAttachment;
                }
                return attachment;
            });
            fieldHelpers.setValue(newAttachments);
        } catch (serverError) {
            // Remove the attachment after a server error as long as it wasn't cancelled (because then the component
            // will be unmounted).
            if (isCancelled(serverError) || (Axios.isAxiosError(serverError) && Axios.isCancel(serverError))) {
                return;
            }

            fieldHelpers.setValue(
                current.filter((_attachment, attachmentIndex) => {
                    return attachmentIndex !== index;
                })
            );

            onUploadError(serverError);
        }
    };

    /**
     * Handle the user uploading one or more files. Check for any basic errors like the uploaded having not worked. If
     * nothing has gone wrong, pass the file or files to the parent component.
     * @param file The file to upload.
     */
    const attachFile = async (file: File) => {
        const current = field.value;

        const existing = current.map(({ attachment }) => attachment);
        if (existing.includes(file.name)) {
            return;
        }

        // Add the attachment. It will still be loading at this point.
        const item = { attachment: file.name, attachmentId: null, file, url: "" };

        fieldHelpers.setValue([...current, item]);
        await onSelect(current.concat(item), file);
    };

    return { attachFile, onSelect };
};

import useShortcutScope from "components-for/shortcuts/use-shortcut-scope";
import logo from "images/icon-colour-light-background.svg";
import { ActionsLink } from "./actions-link";
import AgentHomeLink from "./agent-home-link";
import ChatLink from "./chat-link";
import CreateEmailLink from "./create-email-link";
import DeferredQueriesLink from "./deferred-queries-link";
import QueryLink from "./query-link";
import SignOut from "./sign-out/sign-out";
import TemplatesLink from "./templates-link";
import UsersLink from "./users-link";
import { IProps } from "./navigation.types";
import { Permission } from "@edgetier/types";
import { CategoryNavigation, NavigationLink } from "@edgetier/client-components";
import {
    faAddressBook,
    faBan,
    faBuilding,
    faCalendar,
    faChartBar,
    faLanguage,
    faMailbox,
    faTrademark,
    faUsers,
    faUserTag,
} from "@fortawesome/pro-solid-svg-icons";
import { faCog, faComment, faCommentLines, faComments, faDesktop, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { faFile, faFileChartLine, faGlobeAmericas, faListAlt, faPaintRoller } from "@fortawesome/pro-solid-svg-icons";
import { faSearch, faSitemap, faTags, faWrench } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useCurrentUser } from "utilities/use-current-user";
import "./navigation.scss";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";
import VerifyPermission from "components-for/verify-permission/verify-permission";
import { SETTINGS_PERMISSIONS } from "constants/permission";
import { NavigationGroupNotificationsProvider } from "components-for/navigation/navigation-group-notifications-provider";
import { NavigationGroupNotificationCount } from "components-for/navigation/navigation-group-notification-count";
import NavigationGroupChildNotificationCount from "components-for/navigation/navigation-group-child-notification-count";
import { getEmailAccountsError } from "utilities-for/email-accounts/get-email-accounts-errors";
import { useFlags } from "hooks-for/feature-flags";
import { FEATURE_FLAGS } from "types-for/feature-flags";

/**
 * Display links to other routes. Certain links are only displayed depending on the user's role.
 */
const Navigation = ({
    callFunctionalityEnabled,
    chatFunctionalityEnabled,
    emailFunctionalityEnabled,
    proactiveChatEnabled,
}: IProps) => {
    useShortcutScope();
    const { data: user } = useCurrentUser();
    const flags = useFlags([FEATURE_FLAGS.ChatWidgetIntroductionMessage]);

    return (
        <div className="navigation">
            <div
                className={classNames("navigation__logo", {
                    "navigation__logo--inverted": process.env.NODE_ENV !== "production",
                })}
            >
                <img src={logo} alt="EdgeTier" />
            </div>
            <div className="navigation__links">
                <VerifyPermission permission={Permission.HandleInteraction}>
                    <AgentHomeLink />
                </VerifyPermission>

                {emailFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <CreateEmailLink />
                    </VerifyPermission>
                )}

                {emailFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <QueryLink />
                    </VerifyPermission>
                )}

                {chatFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <ChatLink />
                    </VerifyPermission>
                )}

                {proactiveChatEnabled && user?.isProactiveChatEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <NavigationLink
                            icon={faCommentLines}
                            page={AbsolutePaths[PageNames.ProactiveChat]()}
                            label="Proactive Chat"
                        />
                    </VerifyPermission>
                )}

                {emailFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <DeferredQueriesLink />
                    </VerifyPermission>
                )}

                <VerifyPermission permission={Permission.ViewReporting}>
                    <CategoryNavigation label="Reporting" icon={faChartBar}>
                        <NavigationLink
                            icon={faUsers}
                            page={AbsolutePaths[PageNames.ReportingAgents]()}
                            label="Active Agents"
                        />
                        {chatFunctionalityEnabled && (
                            <NavigationLink
                                icon={faComments}
                                page={AbsolutePaths[PageNames.ReportingChat]()}
                                label="Chat Queue"
                            />
                        )}
                        {emailFunctionalityEnabled && (
                            <NavigationLink
                                icon={faEnvelope}
                                page={AbsolutePaths[PageNames.ReportingQueue]()}
                                label="Email Queue"
                            />
                        )}
                        <NavigationLink
                            icon={faCalendar}
                            page={AbsolutePaths[PageNames.ReportingHistoric]()}
                            label="Historic"
                        />
                        <NavigationLink
                            icon={faFileChartLine}
                            page={AbsolutePaths[PageNames.ReportingReports]()}
                            label="Reports"
                        />
                        {emailFunctionalityEnabled && (
                            <NavigationLink
                                icon={faDesktop}
                                page={AbsolutePaths[PageNames.ReportingPublicEmail]()}
                                label="Email Wallboard"
                            />
                        )}
                        {chatFunctionalityEnabled && (
                            <NavigationLink
                                icon={faDesktop}
                                page={AbsolutePaths[PageNames.ReportingPublicChat]()}
                                label="Chat Wallboard"
                            />
                        )}
                        {callFunctionalityEnabled && (
                            <NavigationLink
                                icon={faDesktop}
                                page={AbsolutePaths[PageNames.CallWallboard]()}
                                label="Call Wallboard"
                            />
                        )}
                    </CategoryNavigation>
                </VerifyPermission>

                <VerifyPermission permission={Permission.ViewSearch}>
                    <CategoryNavigation icon={faSearch} label="Search">
                        <NavigationLink
                            icon={faComments}
                            page={AbsolutePaths[PageNames.SearchInteractions]()}
                            label="Interactions"
                        />
                        <NavigationLink
                            icon={faAddressBook}
                            page={AbsolutePaths[PageNames.SearchQueries]()}
                            label="Queries"
                        />
                    </CategoryNavigation>
                </VerifyPermission>

                <VerifyPermission permission={SETTINGS_PERMISSIONS}>
                    <NavigationGroupNotificationsProvider
                        pageNotificationsConfiguration={[
                            { pageName: PageNames.SettingsEmailAccounts, notificationRequest: getEmailAccountsError },
                        ]}
                    >
                        <CategoryNavigation
                            icon={faCog}
                            label="Settings"
                            indicator={<NavigationGroupNotificationCount />}
                        >
                            <VerifyPermission permission={Permission.EditAgentState}>
                                <NavigationLink
                                    icon={faUserTag}
                                    page={AbsolutePaths[PageNames.SettingsUserStates]()}
                                    label="Agent States"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditWidget, Permission.ToggleWidget]}>
                                <NavigationLink
                                    icon={faListAlt}
                                    page={AbsolutePaths[PageNames.SettingsSetups]()}
                                    label="Chat Widgets"
                                />
                            </VerifyPermission>

                            {flags.chat_widget_introduction_message.enabled && (
                                <VerifyPermission permission={[Permission.EditWidget]}>
                                    <NavigationLink
                                        icon={faCommentLines}
                                        page={AbsolutePaths[PageNames.SettingsIntroductionMessages]()}
                                        label="Introduction Messages"
                                    />
                                </VerifyPermission>
                            )}

                            <VerifyPermission permission={[Permission.EditWidget]}>
                                <NavigationLink
                                    icon={faComment}
                                    page={AbsolutePaths[PageNames.SettingsChatWidgetAppearances]()}
                                    label="Chat Widget Appearances"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditChatBan]}>
                                <NavigationLink
                                    icon={faBan}
                                    page={AbsolutePaths[PageNames.SettingsBans]()}
                                    label="Chat Bans"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditBrand]}>
                                <NavigationLink
                                    icon={faTrademark}
                                    page={AbsolutePaths[PageNames.SettingsBrands]()}
                                    label="Brands"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditTheme]}>
                                <NavigationLink
                                    icon={faPaintRoller}
                                    page={AbsolutePaths[PageNames.SettingsThemes]()}
                                    label="Themes"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditLanguage]}>
                                <NavigationLink
                                    icon={faGlobeAmericas}
                                    page={AbsolutePaths[PageNames.SettingsLanguages]()}
                                    label="Languages"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={Permission.EditEmailAccount}>
                                <NavigationLink
                                    icon={faMailbox}
                                    page={AbsolutePaths[PageNames.SettingsEmailAccounts]()}
                                    label="Email Accounts"
                                    indicator={
                                        <NavigationGroupChildNotificationCount
                                            pageName={PageNames.SettingsEmailAccounts}
                                        />
                                    }
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditEmailRoutingRule]}>
                                <NavigationLink
                                    icon={faEnvelope}
                                    page={AbsolutePaths[PageNames.SettingsEmailRoutingRules]()}
                                    label="Email Routing Rules"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditDepartment]}>
                                <NavigationLink
                                    icon={faBuilding}
                                    page={AbsolutePaths[PageNames.SettingsDepartments]()}
                                    label="Departments"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditSkill]}>
                                <NavigationLink
                                    icon={faTags}
                                    page={AbsolutePaths[PageNames.SettingsSkills]()}
                                    label="Skills"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditTextLocalisation]}>
                                <NavigationLink
                                    icon={faLanguage}
                                    page={AbsolutePaths[PageNames.SettingsTextLocalisations]()}
                                    label="System Text"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditForm]}>
                                <NavigationLink
                                    icon={faFile}
                                    page={AbsolutePaths[PageNames.SettingsForms]()}
                                    label="Forms"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditSystemSetting]}>
                                <NavigationLink
                                    icon={faWrench}
                                    page={AbsolutePaths[PageNames.SettingsSystem]()}
                                    label="System"
                                />
                            </VerifyPermission>

                            <VerifyPermission permission={[Permission.EditLogic]}>
                                <NavigationLink
                                    icon={faSitemap}
                                    page={AbsolutePaths[PageNames.LogicFlows]()}
                                    label="Flows"
                                />
                            </VerifyPermission>
                        </CategoryNavigation>
                    </NavigationGroupNotificationsProvider>
                </VerifyPermission>

                <VerifyPermission permission={[Permission.ViewTemplate, Permission.EditTemplate]}>
                    <TemplatesLink />
                </VerifyPermission>

                <VerifyPermission permission={[Permission.ViewAction, Permission.EditAction]}>
                    <ActionsLink />
                </VerifyPermission>

                <VerifyPermission permission={[Permission.EditUser, Permission.EditUserBasic, Permission.EditRole]}>
                    <CategoryNavigation icon={faUsers} label="User Management">
                        <VerifyPermission permission={Permission.EditRole}>
                            <NavigationLink
                                icon={faUserTag}
                                page={AbsolutePaths[PageNames.Permissions]()}
                                label="Permissions"
                            />
                        </VerifyPermission>
                        <VerifyPermission permission={[Permission.EditUser, Permission.EditUserBasic]}>
                            <UsersLink />
                        </VerifyPermission>
                    </CategoryNavigation>
                </VerifyPermission>

                <SignOut />
            </div>
        </div>
    );
};

export default Navigation;

import { Fragment, FunctionComponent } from "react";

import { IProps } from "./table-columns.types";

/**
 * Container for a table's columns.
 * @param props.children Columns.
 * @returns              Columns in the table.
 */
const TableColumns: FunctionComponent<IProps> = ({ children }) => {
    return <Fragment>{children}</Fragment>;
};

export default TableColumns;

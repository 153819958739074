import { ChatStateStatus, MessageRecipientType } from "@edgetier/types";
import { filterMessages } from "@edgetier/utilities";
import { IChatState } from "redux/modules/chat/chat.types";
import actions from "../chat-actions";
import { chatIndices } from "../chat-utilities";

/**
 * Join a chat. This is called after an agent has accepted a chat invitation.
 * @param state  Application state before entering the chat.
 * @param action Incoming chat details.
 */
export default function (state: IChatState, action: ReturnType<typeof actions.enterChat>): IChatState {
    // Activate the chat if it is the only one.
    const { chat, chatToken, customerUrls, settings, translatedToLanguageId } = action.payload;
    const hasActiveChat = state.activeChat !== null && typeof state.chats[state.activeChat] !== "undefined";
    const activeChat = hasActiveChat ? state.activeChat : action.payload.chatToken;
    const messages = filterMessages(chat.events);

    return {
        ...state,
        activeChat,
        chats: {
            ...state.chats,
            [chatToken]: {
                active:
                    chat.chatStateStatusId !== ChatStateStatus.AgentWrapUp &&
                    chat.chatStateStatusId !== ChatStateStatus.Terminated,
                agentLanguageId: typeof translatedToLanguageId === "number" ? translatedToLanguageId : chat.languageId,
                bookingId: chat.bookingId,
                chatId: chat.chatId,
                chatIndex: chatIndices.next(state.chats),
                chatSourceId: chat.chatSourceId,
                chatStateStatusId: chat.chatStateStatusId,
                chatToken,
                contactId: chat.contactId,
                conversationKey: chat.conversationKey,
                customerName: chat.customerName,
                customerUrls,
                emailDraft: null,
                enteredAt: new Date(),
                events: chat.events,
                form: null,
                formSubmission: chat.formSubmission,
                messages,
                languageId: chat.languageId,
                lastActiveUser: chat.lastActiveUser,
                lastActiveUserId: chat.lastActiveUserId,
                interactionDetailId: chat.interactionDetailId,
                inactivityTimerStopped: false,
                isInAutomaticWrapUp: false,
                isTyping: false,
                isTransferring: chat.chatStateStatusId === ChatStateStatus.Transferring,
                proposedActivities: {},
                queryId: chat.queryId,
                setup: chat.setup,
                setupId: chat.setupId,
                settings,
                skills: chat.skills,
                surveyFormSubmissionId: chat.surveyFormSubmissionId,
                transferTimeoutId: null,
                translatedToLanguageId,
                unreadMessages:
                    activeChat === chatToken && state.isChatScreenOpen
                        ? 0
                        : messages.filter(
                              ({ messageRecipientTypeId }) => messageRecipientTypeId === MessageRecipientType.Agent
                          ).length,
                wrapUpExtended: false,
                wrapUpForm: null,
                wrapUpFormDraft: null,
                wrapUpDeadline: null,
                websiteSessionInvitationId: chat.websiteSessionInvitationId,
                websiteSessionId: chat.websiteSessionId,
            },
        },
    };
}

import { ITag, IWritingIssue } from "../index";
import { IItem } from "common";

import { ISentimentIndicators } from "./sentiment";
import { IPaginated } from "@edgetier/types";

export enum MessageDirection {
    Inbound = "Inbound",
    Outbound = "Outbound",
}

export enum WritingIssueType {
    Spelling = "SPELLING",
    Grammar = "GRAMMAR",
}

export enum InteractionMessageType {
    Standard = "Standard",
    System = "System",
}

export interface ISentimentUpdate {
    readonly interactionMessageSentimentUpdateId: string;
    readonly interactionMessageSentimentId: string;
    readonly sentiment: string;
    readonly userId: string;
    readonly newSentimentScore: number;
    readonly previousSentimentScore: number;
    readonly dateTime: Date;
}

export interface IInteractionMessage extends ISentimentIndicators {
    readonly agentId: string | null;
    readonly clientId: string;
    readonly direction: MessageDirection;
    readonly interactionId: string;
    readonly interactionMessageId: string;
    readonly isBot: boolean;
    readonly message: string;
    readonly order: number;
    readonly sendDateTime: Date;
    readonly tags?: ITag[];
    readonly messageType: InteractionMessageType;
    readonly languageIsoCode: string;
}

export type IInteractionMessageWritingIssue = IWritingIssue;

export type IInteractionsMessagesGetResponse = IPaginated<IInteractionMessage>;
export type IInteractionMessagesGetResponse = IItem<IInteractionMessage>;
export type IInteractionMessagesWritingIssuesGetResponse = IPaginated<IInteractionMessageWritingIssue>;

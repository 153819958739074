import { IPaginated } from "@edgetier/types";

export enum AgentStateType {
    Available = "Available",
    Unavailable = "Unavailable",
    Offline = "Offline",
}

export interface IAgentState {
    readonly agentState: string;
    readonly agentStateId: string;
    readonly agentStateType: AgentStateType;
}
export declare type IAgentStatesGetQueryParameters = {
    readonly agentStateType?: AgentStateType;
    readonly clientId?: string;
    readonly cursor?: string;
    readonly externalReference?: string;
    readonly isEnabled?: boolean;
    readonly limit?: number;
};

export type IAgentStatesGetResponse = IPaginated<IAgentState>;

import { FunctionComponent, memo } from "react";
import { IProps } from "./warning-box.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import "./warning-box.scss";

/**
 * Style some content as a warning.
 * @param props.children Warning content.
 * @returns Warning component.
 */
const WarningBox: FunctionComponent<IProps> = ({ children }) => {
    return (
        <div className="warning-box">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <div className="warning-box__content">{children}</div>
        </div>
    );
};

export default memo(WarningBox);

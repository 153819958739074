import classNames from "classnames";

import { IProps } from "./spinner.types";

/**
 * SVG spinner to show that something is loading.
 * @param props.fullPage If the spinner should be a large full page display.
 * @returns              Animated spinner.
 */
const Spinner = ({ fullPage = false }: IProps) => (
    <div className={classNames("spinner", { "spinner--full-page": fullPage })}>
        <svg viewBox="0 0 148 148" role="spinner">
            <circle cx="9" cy="9" r="9" />
            <circle cx="35" cy="9" r="9" />
            <circle cx="61" cy="9" r="9" />
            <circle cx="87" cy="9" r="9" />
            <circle cx="113" cy="9" r="9" />

            <circle cx="9" cy="35" r="9" />
            <circle cx="35" cy="35" r="9" />
            <circle cx="61" cy="35" r="9" />
            <circle cx="87" cy="35" r="9" />
            <circle cx="113" cy="35" r="9" />
            <circle cx="139" cy="35" r="9" />

            <circle cx="9" cy="61" r="9" />
            <circle cx="35" cy="61" r="9" />
            <circle cx="113" cy="61" r="9" />
            <circle cx="139" cy="61" r="9" />

            <circle cx="9" cy="87" r="9" />
            <circle cx="35" cy="87" r="9" />
            <circle cx="113" cy="87" r="9" />
            <circle cx="139" cy="87" r="9" />

            <circle cx="9" cy="113" r="9" />
            <circle cx="35" cy="113" r="9" />
            <circle cx="61" cy="113" r="9" />
            <circle cx="87" cy="113" r="9" />
            <circle cx="113" cy="113" r="9" />
            <circle cx="139" cy="113" r="9" />

            <circle cx="35" cy="139" r="9" />
            <circle cx="61" cy="139" r="9" />
            <circle cx="87" cy="139" r="9" />
            <circle cx="113" cy="139" r="9" />
            <circle cx="139" cy="139" r="9" />
        </svg>
    </div>
);

export default Spinner;

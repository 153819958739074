import { captureMessage } from "@sentry/react";
import { useCurrentUser } from "utilities/use-current-user";
import { useEffect } from "react";
import { useFlagsUtilities } from "@edgetier/feature-flags";
import { IUser } from "redux/application.types";

/**
 * Checks if the user object has the required keys for identifying the user on Flagsmith.
 * @param user The user object to check.
 */
const isUserValid = (user: IUser) => {
    const keys = Object.keys(user);
    const requiredKeys = ["userId", "firstName", "surname"] as const;
    const otherKeys = ["email", "roleId", "nativeLanguage", "nativeLanguageId"] as const;

    const missingRequiredKeys = requiredKeys.filter((key) => !keys.includes(key));
    const otherMissingKeys = otherKeys.filter((key) => !keys.includes(key));

    if (otherMissingKeys.length > 0) {
        captureMessage(`Flagsmith identity is missing optional keys: ${otherMissingKeys.join(", ")}`, "warning");
    }

    if (missingRequiredKeys.length > 0) {
        captureMessage(`Flagsmith identity is missing keys: ${missingRequiredKeys.join(", ")}`, "error");
        return false;
    }

    return true;
};

/**
 * Calls the useFlagsUtilities().identify method when the current
 * user exists and it hasn't been identified.
 */
const useFlagsIdentify = () => {
    const flagsUtils = useFlagsUtilities();
    const { data } = useCurrentUser();
    useEffect(() => {
        const flagsmithIdentify = async (user: IUser) => {
            try {
                await flagsUtils.identify(`${user.userId}_${user.firstName}_${user.surname}`, {
                    name: `${user.firstName} ${user.surname}`,
                    client: window.location.hostname,
                    emailAddress: user?.email,
                    roleId: user?.roleId,
                    nickname: user?.nickname ?? null,
                    nativeLanguage: user?.nativeLanguage,
                    nativeLanguageId: user?.nativeLanguageId,
                });
            } catch (_error) {
                // There's nothing we can do there. The user is most likely blocking the Flagsmith domain. The app will
                // still work regardless.
            }
        };

        if (process.env.NODE_ENV !== "development" && data && isUserValid(data) && !flagsUtils.identity) {
            flagsmithIdentify(data);
        }
    }, [data, flagsUtils]);
};

export default useFlagsIdentify;

import { FunctionComponent, memo } from "react";

import "./box-header.scss";
import { IProps } from "./box-header.types";

/**
 * Box header.
 * @param props.children Header content.
 * @returns              Header for a box.
 */
const BoxHeader: FunctionComponent<IProps> = ({ children }) => {
    return <div className="box-header">{children}</div>;
};

export default memo(BoxHeader);

import { SelectField } from "@edgetier/select";
import { ISourcedSelectOption, Translation } from "@edgetier/types";
import { getChatWindowTranslation } from "@edgetier/utilities";
import { useEffect, useMemo, useState } from "react";
import { FunctionComponent } from "react";
import { debounce } from "throttle-debounce";

import { CHARACTER_LENGTH_THRESHOLD, DEBOUNCE_DELAY } from "./sourced-select-form-field.constants";
import { IProps } from "./sourced-select-form-field.types";
import { useSourcedSelectItems } from "./sourced-select-form-field.utilities";

/**
 * A select menu who's options are requested as the user starts typing.
 * @param props.axiosInstance An axios instance which will be used to request the select's options.
 * @param props.formField     Sourced select component field.
 */
const SourcedSelectFormField: FunctionComponent<IProps> = ({ axiosInstance, formField, languageId, translation }) => {
    // The sourcedSelectItems will be empty when the select is closed. So, selectedItems is always passed to the select
    // so that they will be shown in the preview even if there's no sourced select items.
    const [selectedItems, setSelectedItems] = useState<ISourcedSelectOption[]>([]);

    const [isTyping, setIsTyping] = useState(false);
    const [search, setSearch] = useState("");
    const debouncedSetSearch = useMemo(
        () =>
            debounce(
                DEBOUNCE_DELAY,
                (search) => {
                    setSearch(search);
                    setIsTyping(false);
                },
                { atBegin: false }
            ),
        []
    );

    // Cancel the debounced function if the component unmounts.
    useEffect(() => {
        return () => {
            debouncedSetSearch.cancel();
            setIsTyping(false);
        };
    }, [debouncedSetSearch]);

    const enabled = useMemo(() => search.length >= CHARACTER_LENGTH_THRESHOLD, [search.length]);
    const { data: sourcedSelectItems, isLoading } = useSourcedSelectItems(
        axiosInstance,
        formField,
        { search, languageId },
        { enabled }
    );

    /**
     * Set the search parameter when the user changes the input.
     * @param value The value of the input.
     */
    const onInputChange = (value: string | undefined) => {
        setIsTyping(true);
        const trimmedValue = value?.trim();
        // Only perform a search if user input is the character length threshold or more.
        if (typeof trimmedValue !== "undefined" && trimmedValue.length >= CHARACTER_LENGTH_THRESHOLD) {
            debouncedSetSearch(trimmedValue);
            return;
        }

        // Clear the search if the value is undefined or less than the character length threshold.
        debouncedSetSearch("");
    };

    /**
     * Set in state which items have been selected.
     * @param items The items that the user has selected.
     */
    const onSelect = (items: ISourcedSelectOption[]) => {
        const itemsArray = Array.isArray(items) ? items : [items];
        if (itemsArray.length === 0 || itemsArray[0] === null) {
            setSelectedItems([]);
            return;
        }
        setSelectedItems(itemsArray);
    };

    /**
     * Clear the search when the input closes. We don't want the field to be populated when it's re-opened.
     */
    const onClose = () => {
        debouncedSetSearch("");
    };

    return (
        <SelectField<ISourcedSelectOption, ISourcedSelectOption>
            isSingleSelect
            onClose={onClose}
            items={enabled && typeof sourcedSelectItems !== "undefined" ? sourcedSelectItems : selectedItems}
            getLabel={({ label }) => label}
            getValue={(option) => option}
            description="item"
            noItemsFoundLabel={getChatWindowTranslation(Translation.NoItemsFound, languageId)}
            isEmptyLabel={getChatWindowTranslation(Translation.EmptySelect, languageId)}
            isLoading={isTyping || isLoading}
            isSourcedSelect
            minimumWidth={100}
            menuShouldScrollIntoView
            name={formField.fieldName}
            onInputChange={onInputChange}
            onSelect={onSelect}
            possiblePlacements={["bottom-center", "top-center"]}
            placeholder={getChatWindowTranslation(Translation.TypeToSearch, languageId)}
        />
    );
};

export default SourcedSelectFormField;

export enum VariableType {
    Boolean = 1,
    String = 2,
    Numeric = 3,
    Date = 4,
    Time = 5,
    Link = 6,
    Array = 7,
}

export default VariableType;

import { Field, FieldProps } from "formik";

import { IProps } from "./textarea-form-field.types";

/**
 * Render a large text field.
 * @param props.formField   Textarea form field.
 * @param props.translation Text elements in the user's language.
 * @returns                 Textarea input.
 */
const TextareaFormField = ({ formField, translation }: IProps) => (
    <Field name={formField.fieldName}>
        {({ field }: FieldProps) => (
            <textarea
                {...field}
                aria-describedby="field-error"
                aria-required={formField.required}
                autoComplete="off"
                id={formField.fieldName}
                maxLength={formField.configuration.maximumLength || undefined}
                name={field.name}
                rows={4}
                placeholder={translation.placeholderText || ""}
            />
        )}
    </Field>
);

export default TextareaFormField;

export enum ChatEventType {
    Create = 1,
    SubmitForm = 2,
    AgentConnect = 3,
    CustomerConnect = 4,
    Message = 5,
    CustomerDisconnect = 6,
    AgentDisconnect = 7,
    TransferRequest = 8,
    TransferSuccess = 9,
    TransferFailure = 10,
    Finish = 11,
    LanguageChange = 12,
    TransferCancel = 13,
    ChangeBookingId = 14,
    CustomerEndChat = 15,
    CustomerRequestTranscript = 16,
    AgentRequestTranscript = 17,
    AgentRejectInvite = 18,
    AgentInvite = 19,
    AgentInviteTimeout = 20,
    ResetInactivityTimer = 21,
    IncreaseInactivityTimer = 22,
    CustomerUrlChange = 23,
    EnterQueue = 24,
    StopInactivityTimer = 25,
    StartInactivityTimer = 26,
    ProposedMessage = 27,
    ChangeSkill = 28,
}

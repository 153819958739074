import classNames from "classnames";
import React from "react";
import ReactModal from "react-modal";

import ModalContext from "./../context";
import CloseButton from "./close-button";
import { IProps } from "./modal.types";
import { MODAL_CLASS, OVERLAY_CLASS } from "./modal.constants";
import { PARENT_SELECTOR, ROOT_ID, STACKING_LAYERS_ID } from "@edgetier/modal";
import "./modal.scss";

/*
const fromStyle = { opacity: 0, transform: "scale3d(0.85, 0.85, 1)" };
const fromStyleDrawer = { opacity: 0, transform: "scale3d(1, 0, 1)" };
const enterStyle = { opacity: 1, transform: "scale3d(1, 1, 1)" };*/

/**
 * Display a modal if it is open.
 */
export default class Modal extends React.PureComponent<IProps> {
    element = React.createRef<HTMLDivElement>();

    /**
     * Define into which element the modal should load.
     */
    componentDidMount(): void {
        if (document.getElementById(ROOT_ID)) {
            ReactModal.setAppElement(`#${ROOT_ID}`);
        }
    }

    /**
     * Render the modal.
     * @returns React Modal component.
     */
    render(): React.ReactNode {
        return (
            <ModalContext.Consumer>
                {({ canClose, canDismiss, hideModal, isOpen }) =>
                    !isOpen ? null : (
                        <ReactModal
                            ariaHideApp={false}
                            className={`${MODAL_CLASS}__outer`}
                            isOpen={isOpen}
                            overlayClassName={classNames(OVERLAY_CLASS, {
                                [`${MODAL_CLASS}__overlay--drawer`]: this.props.drawer === true,
                            })}
                            shouldCloseOnEsc={canDismiss && canClose}
                            shouldCloseOnOverlayClick={canDismiss && canClose}
                            shouldFocusAfterRender={true}
                            onRequestClose={hideModal}
                            contentLabel={this.props.contentLabel || ""}
                            parentSelector={() =>
                                document.querySelector(STACKING_LAYERS_ID) ??
                                document.querySelector(PARENT_SELECTOR) ??
                                document.querySelector(ROOT_ID) ??
                                document.body
                            }
                        >
                            <div
                                className={
                                    typeof this.props.size === "string"
                                        ? `${MODAL_CLASS} ${MODAL_CLASS}-${this.props.size}`
                                        : MODAL_CLASS
                                }
                            >
                                <div className={`${MODAL_CLASS}__content`} ref={this.element}>
                                    {canClose !== false && <CloseButton label={this.props.closeButtonLabel || ""} />}
                                    {this.props.children}
                                </div>
                            </div>
                        </ReactModal>
                    )
                }
            </ModalContext.Consumer>
        );
    }
}

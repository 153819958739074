import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Icon for chat messages that failed to send. This is a separate component for testing purposes.
 * @returns Icon.
 */
const AutomatedIcon = () => <FontAwesomeIcon icon={faExclamationTriangle} />;

export default AutomatedIcon;

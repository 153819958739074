import { FunctionComponent, memo } from "react";
import urlJoin from "url-join";
import { Markdown, SpinnerUntil, Tooltip } from "@edgetier/components";
import { Url } from "@edgetier/types";
import useSimpleQuery from "queries/use-simple-query";
import { getUserId } from "utilities/local-storage";
import { INote } from "redux/application.types";
import { IUser } from "redux/application.types";
import QueryHistoryNoteControls from "./query-history-note-controls";
import { IProps } from "./query-history-note.types";
import { STALE_MILLISECONDS } from "./query-history.constants";
import "./query-history-note.scss";
import { onNotesRequestError, onUserRequestError } from "./query-history-note.utilities";

/**
 * Display the contents and details of a note. Two requests need to be made; one for the note itself and a second for
 * the user who wrote the note.
 * @param props.noteId Note identifier.
 * @returns            Note text and details.
 */
const QueryHistoryNote: FunctionComponent<IProps> = ({ noteId }) => {
    // Request the note.
    const noteQueryOptions = { onError: onNotesRequestError, staleTime: STALE_MILLISECONDS };
    const noteQuery = useSimpleQuery<INote>(urlJoin(Url.Notes, noteId.toString()), noteQueryOptions);

    // Request the user when the note has been retrieved.
    const enabled = typeof noteQuery.data?.userId === "number";
    const userQueryOptions = { enabled, onError: onUserRequestError, staleTime: STALE_MILLISECONDS };
    const userUrl = urlJoin(Url.Users, String(noteQuery.data?.userId));
    const userQuery = useSimpleQuery<IUser>(userUrl, userQueryOptions);

    return (
        <SpinnerUntil<{ note: INote; user: IUser | undefined }>
            data={{ note: noteQuery.data, user: userQuery.data }}
            isReady={typeof noteQuery.data !== "undefined" && !userQuery.isLoading}
        >
            {({ note, user }) => (
                <div className="query-history-note">
                    <div className="query-history-note__header">
                        <Tooltip
                            content={
                                <div className="query-history-note__user-tooltip">
                                    You do not have permission to see users from other departments.
                                </div>
                            }
                            disableTooltip={typeof user !== "undefined"}
                            useArrow
                        >
                            <strong className="query-history-note__user">
                                {typeof user === "undefined" ? "Unknown User" : `${user.firstName} ${user.surname}`}
                            </strong>
                        </Tooltip>
                        Note
                    </div>

                    <Markdown markdownString={note.note} />

                    {getUserId() === note.userId && (
                        <QueryHistoryNoteControls queryId={note.queryId} noteId={note.noteId} />
                    )}
                </div>
            )}
        </SpinnerUntil>
    );
};

export default memo(QueryHistoryNote);

import { FunctionComponent, memo, useMemo } from "react";
import usePermission from "hooks-for/permissions/use-permission";
import { SpinnerUntil } from "@edgetier/components";
import { NoMatch } from "@edgetier/client-components";
import { IProps } from "./verify-permission.types";
import "./verify-permission.scss";

/**
 * Display page or element when the user has permission for it.
 * @param props.children   Element displayed.
 * @param props.finalCheck A function that's given the usePermission results and makes the final permission decision.
 * @param props.permission The permission(s) to check. If multiple are passed, the permissions are OR-ed.
 * @param props.isRoute    For routes, don't show the spinner.
 */
const VerifyPermission: FunctionComponent<IProps> = ({ children, finalCheck, permission, isRoute = false }) => {
    const { hasPermission: initialHasPermission, permissions, isLoading } = usePermission(permission);

    const hasPermission = useMemo(() => {
        if (typeof finalCheck !== "undefined" && initialHasPermission === true) {
            return finalCheck(permissions);
        }
        return initialHasPermission;
    }, [finalCheck, initialHasPermission, permissions]);

    return (
        <SpinnerUntil isReady={!isLoading} data={hasPermission}>
            {isRoute ? <>{hasPermission ? <>{children}</> : <NoMatch />}</> : <>{hasPermission && children}</>}
        </SpinnerUntil>
    );
};

export default memo(VerifyPermission);

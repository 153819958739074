import { MessageDirection } from "./messages";

export enum Sentiment {
    Frustration = "hasFrustration",
    Gratitude = "hasGratitude",
    Praise = "hasPraise",
    Empathy = "hasEmpathy",
    Confusion = "hasConfusion",
}

export type ISentimentIndicators = {
    [key in Sentiment]: boolean;
};

export const sentimentLabels: Record<Sentiment, string> = {
    [Sentiment.Frustration]: "Frustration",
    [Sentiment.Gratitude]: "Gratitude",
    [Sentiment.Praise]: "Praise",
    [Sentiment.Empathy]: "Empathy",
    [Sentiment.Confusion]: "Confusion",
};

export const sentimentScoreLabels: Record<Sentiment, string> = {
    [Sentiment.Frustration]: "frustrationScore",
    [Sentiment.Gratitude]: "gratitudeScore",
    [Sentiment.Praise]: "praiseScore",
    [Sentiment.Empathy]: "empathyScore",
    [Sentiment.Confusion]: "confusionScore",
};
export const sentimentAllowedDirections: Record<Sentiment, MessageDirection[]> = {
    [Sentiment.Frustration]: [MessageDirection.Inbound],
    [Sentiment.Gratitude]: [MessageDirection.Inbound],
    [Sentiment.Praise]: [MessageDirection.Inbound],
    [Sentiment.Empathy]: [MessageDirection.Outbound],
    [Sentiment.Confusion]: [MessageDirection.Inbound],
};

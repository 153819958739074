import classNames from "classnames";
import { forwardRef } from "react";

import { IProps } from "./interaction-events.types";
import "./interaction-events.scss";

/**
 * Wrapper for call or chat events. This adds some styling including a vertical timeline.
 * @returns Wrapped children.
 */
const InteractionEvents = forwardRef<HTMLDivElement, IProps>(({ children, showTimeline = true }, ref) => (
    <div
        className={classNames("interaction-events", {
            "interaction-events--has-timeline": showTimeline === true,
        })}
        ref={ref}
    >
        <div className="interaction-events__timeline-container">
            {showTimeline && <div className="interaction-events__vertical-timeline-marker" />}
            {children}
        </div>
    </div>
));

export default InteractionEvents;

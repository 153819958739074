import { FunctionComponent, memo } from "react";
import ReactLoadingSkeleton from "react-loading-skeleton";

import "./loading-skeleton.scss";
import { IProps } from "./loading-skeleton.types";

/**
 * Wrapper around React Loading Skeleton to provide some default props.
 * @param props.isDark          Optional boolean flag for dark loading skeleton
 * @param props.skeletonProps   Props to pass to React Loading Skeleton.
 * @returns     Loading skeleton.
 */
const LoadingSkeleton: FunctionComponent<IProps> = ({ isDark, ...skeletonProps }) => {
    const loadingSkeletonColours = {
        baseColour: !isDark ? "#f2f5f5" : "#435e7a",
        highlightColour: !isDark ? "#dfe6e6" : "#3b5268",
    };

    return (
        <span aria-label="loading placeholder">
            <ReactLoadingSkeleton
                baseColor={loadingSkeletonColours.baseColour}
                highlightColor={loadingSkeletonColours.highlightColour}
                {...skeletonProps}
            />
        </span>
    );
};

export default memo(LoadingSkeleton);

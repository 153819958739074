import Axios from "axios";
import { Fragment, FunctionComponent, useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Url } from "@edgetier/types";

import { authenticationOperations } from "redux/modules/authentication";
import axios from "utilities/axios";
import { IApplicationState } from "redux/types";
import { IQueryEmail } from "redux/modules/email/email.types";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { Modal, ModalContainer } from "shared/modal";

import Warnings from "./warnings";
import { IProps } from "./sign-out.types";
import usePermission from "hooks-for/permissions/use-permission";
import { Permission } from "@edgetier/types";
import "./sign-out.scss";
import { IThunkDispatch } from "redux/types";
import { useFlagsUtilities } from "@edgetier/feature-flags";

/**
 * Sign out button.
 */
export const SignOut: FunctionComponent<IProps> = () => {
    const flagsUtils = useFlagsUtilities();
    const cancelTokenSource = useRef(Axios.CancelToken.source());
    useEffect(() => cancelTokenSource.current.cancel, []);

    const dispatch = useDispatch<IThunkDispatch>();

    const chatCount = useSelector((state: IApplicationState) => Object.keys(state.chat.chats).length);

    const [deferredQueryCount, setDeferredQueryCount] = useState(0);

    const { hasPermission: handlesInteractions } = usePermission(Permission.HandleInteraction);

    /**
     * Request deferred queries for the user.
     * @returns List of deferred queries.
     */
    const countDeferredQueries = async (): Promise<number> => {
        try {
            dispatch(loadingBlockerOperations.showLoadingBlocker(true));
            const configuration = { cancelToken: cancelTokenSource.current.token };
            const response = await axios.get<IQueryEmail[]>(Url.EmailsDeferred, configuration);
            return response.data.length;
        } catch (serverError) {
            return 0;
        } finally {
            dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
        }
    };

    /**
     * Agents need to be warned about any deferred queries and/or active chats when they sign out.
     * @param showModal Method to show warnings in a modal.
     */
    const generateWarnings = async (showModal: VoidFunction): Promise<void> => {
        if (handlesInteractions) {
            const deferredQueryCount = await countDeferredQueries();
            if (chatCount === 0 && deferredQueryCount === 0) {
                dispatch(authenticationOperations.signOut());
            } else {
                setDeferredQueryCount(deferredQueryCount);
                showModal();
            }
        } else {
            dispatch(authenticationOperations.signOut());
        }
    };

    /**
     * Sign the user out of the application.
     */
    const signOut = useCallback(
        (hideModal: VoidFunction): void => {
            if (process.env.NODE_ENV !== "development") {
                flagsUtils.logout();
            }
            hideModal();
            dispatch(authenticationOperations.signOut());
        },
        [dispatch, flagsUtils]
    );

    return (
        <ModalContainer>
            {({ hideModal, showModal }) => (
                <Fragment>
                    <Modal>
                        <h2>Warning</h2>
                        <Warnings
                            chatCount={chatCount}
                            deferredQueryCount={deferredQueryCount}
                            hideModal={hideModal}
                            signOut={signOut.bind(this, hideModal)}
                        />
                    </Modal>
                    <div
                        className="navigation__sign-out"
                        onClick={generateWarnings.bind(this, showModal)}
                        role="button"
                    >
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        Sign Out
                    </div>
                </Fragment>
            )}
        </ModalContainer>
    );
};

export default SignOut;

import { UniqueId } from "@edgetier/components";
import { createFieldErrorId } from "@edgetier/utilities";
import classNames from "classnames";
import { Field, FieldProps } from "formik";

import { IProps } from "./rating-form-field.types";

/**
 * Show a number of stars or NPS input to allow customers to rate their chat experience.
 * @param props.autoFocus   Automatically focus on the field when it renders.
 * @param props.children    Handler for each rating label.
 * @param props.formField   NPS or star form field.
 * @param props.translation Labels in the user's language.
 * @returns                 An NPS or star rating field.
 */
const RatingFormField = ({
    autoFocus,
    children,
    formField: { configuration, fieldName, required },
    translation,
}: IProps) => (
    <Field name={fieldName}>
        {({ field: { name, value }, form: { errors, setFieldValue } }: FieldProps) => (
            <div
                aria-label={translation.formFieldTranslation}
                aria-required={required}
                className="dynamic-form__rating"
                role="radiogroup"
            >
                {Array(configuration.maximumValue - configuration.minimumValue + 1)
                    .fill(0)
                    .map((_, index) => (
                        <UniqueId key={index}>
                            {(uniqueId) => (
                                <div
                                    className={classNames("dynamic-form__rating__item", {
                                        "dynamic-form__rating__item--selected":
                                            value !== null && index + configuration.minimumValue === value,
                                        "dynamic-form__rating__item--in-range":
                                            value !== null && index + configuration.minimumValue < value,
                                    })}
                                >
                                    <input
                                        aria-describedby={createFieldErrorId(name)}
                                        aria-invalid={typeof errors[name] === "string"}
                                        autoFocus={autoFocus && index === 0}
                                        checked={index + configuration.minimumValue === value}
                                        id={uniqueId}
                                        name={name}
                                        onChange={(changeEvent) =>
                                            changeEvent.target.checked &&
                                            setFieldValue(name, index + configuration.minimumValue)
                                        }
                                        type="radio"
                                    />
                                    <label htmlFor={uniqueId}>
                                        {children({
                                            isSelected: value !== null && index + 1 <= value,
                                            value: index + configuration.minimumValue,
                                        })}
                                    </label>
                                </div>
                            )}
                        </UniqueId>
                    ))}
            </div>
        )}
    </Field>
);

export default RatingFormField;

import { SpinnerUntil } from "@edgetier/components";
import { faChevronLeft, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef } from "react";
import { usePrevious } from "react-use";

import SelectListTitle from "../select-list-title";
import "./select-selected-list.scss";
import { IProps } from "./select-selected-list.types";
import { LIMIT_REACHED_MESSAGE } from "~/select.constants";

/**
 * List of selected items that automatically scrolls to the bottom as each are added.
 * @param props.getGroup             Get a group name for an item.
 * @param props.getLabel             Get a label for an item.
 * @param props.getSelectedItemProps Function to get Downshift props for each item.
 * @param props.getValue             Get a value for an item.
 * @param props.removeSelectedItem   Method to remove an item.
 * @param props.selectedItems        Items chosen by the user.
 * @param props.limitReached         Boolean that checks whether the selected items limit has been reached
 * @returns                          Scrollable list of items.
 */
const SelectSelectedList = <IItem extends {}, IValue extends {}>({
    children,
    getLabel,
    getSelectedItemProps,
    getGroup,
    getValue,
    isLoading,
    removeSelectedItem,
    selectedItems: items,
    limitReached,
    limitMessage,
}: IProps<IItem, IValue>) => {
    const element = useRef<HTMLDivElement>(null);

    // Record the count of the selected items to decide whether scrolling needs to happen or not.
    const previousCount = usePrevious(items.length);

    const selectedItems = useMemo(
        () =>
            items.sort((one, two) =>
                // Now they will be sorted by group if any
                typeof getGroup === "function" ? getGroup(one).localeCompare(getGroup(two)) : 0
            ),
        [getGroup, items]
    );
    // Scroll down when new items are added.
    useEffect(() => {
        if (element.current && typeof previousCount === "number" && items.length > previousCount) {
            element.current.scrollTop = element.current?.scrollHeight;
        }
    }, [items.length, previousCount]);

    return (
        <div className="select-menu__list select-menu__list--is-selected">
            <SelectListTitle count={items.length} title="Selected" />

            <div className="select-menu__options" ref={element}>
                {limitReached && (
                    <div className="select-menu__message">
                        <FontAwesomeIcon icon={faWarning} />
                        {limitMessage ?? LIMIT_REACHED_MESSAGE}
                    </div>
                )}

                <SpinnerUntil data={[]} isReady={!isLoading}>
                    <ul>
                        {selectedItems.map((selectedItem, index) => {
                            const value = getValue(selectedItem);
                            const key = typeof value === "object" ? Object.values(value).join() : value.toString();

                            return (
                                <li key={key}>
                                    {typeof getGroup === "function" &&
                                        (index === 0 ||
                                            getGroup(selectedItem) !== getGroup(selectedItems[index - 1])) && (
                                            <div
                                                aria-label={getGroup(selectedItem)}
                                                className="select-menu__group-title"
                                            >
                                                {getGroup(selectedItem)}
                                            </div>
                                        )}
                                    <div
                                        aria-label={getLabel(selectedItem)}
                                        className="select-menu__option"
                                        {...getSelectedItemProps({ selectedItem, index })}
                                        onClick={() => removeSelectedItem(selectedItem)}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                        <div className="select-menu__option__label">
                                            {typeof children === "function"
                                                ? children(selectedItem)
                                                : getLabel(selectedItem)}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </SpinnerUntil>
            </div>
        </div>
    );
};

export default SelectSelectedList;

import { FunctionComponent, memo } from "react";
import classNames from "classnames";
import type { IStatusProps } from "./status.types";

/**
 * Tag component.
 * Render a tag that is coloured based on type or custom colors.
 * @returns Tag element.
 */
const Status: FunctionComponent<IStatusProps> = ({ children, className, type, backgroundColor, textColor }) => (
    <div
        style={{ backgroundColor, color: textColor }}
        className={classNames("tag-component", className, type ?? "custom")}
    >
        {children}
    </div>
);

export default memo(Status);

import { InteractionMetric } from "@edgetier/watchtower-types";

/**
 * Checks if a value is an InteractionMetric.
 *
 * @param value - The value to check if it is an InteractionMetric.
 * @returns
 */
export function isInteractionMetric(value: string | null | undefined): value is InteractionMetric {
    if (!value) {
        return false;
    }
    return Object.values(InteractionMetric).includes(value as InteractionMetric);
}

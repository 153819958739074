export interface IItem<Item> {
    readonly items: Item[];
}

export interface IBackendModel {
    readonly createDateTime: Date;
    readonly updateDateTime: Date | null;
}

export enum TimePeriodGroupBy {
    Days = "day",
    Hours = "hour",
    Minutes = "minute",
    Months = "month",
    Weeks = "week",
}

export enum ModelType {
    Tag = "Tag",
    Text = "Text",
    Metric = "Metric",
}

export enum InteractionType {
    Chat = "CHAT",
    Email = "EMAIL",
    Call = "CALL",
    Survey = "SURVEY",
    Messaging = "MESSAGING",
    SocialMedia = "SOCIAL MEDIA",
    SocialMediaAlternative = "SOCIAL_MEDIA",
}

export enum InteractionMetric {
    AverageCustomerResponseTime = "meanCustomerResponseTimeSeconds",
    AgentMessagesCount = "agentMessagesCount",
    AgentFirstResponseTime = "agentFirstResponseTimeSeconds",
    AgentSatisfaction = "agentSatisfaction",
    AgentScore = "agentScore",
    AverageResponseTime = "meanResponseSeconds",
    BotMessagesCount = "botMessagesCount",
    CustomerMessagesCount = "customerMessagesCount",
    CustomerSatisfaction = "customerSatisfaction",
    ExperienceScore = "experienceScore",
    FirstResponseTime = "firstResponseTimeSeconds",
    HandlingTime = "handlingTimeSeconds",
    LongestAgentResponseTime = "maximumAgentResponseTimeSeconds",
    MessagesCount = "messagesCount",
    NetPromoterScore = "netPromoterScore",
    QueueTime = "queueTimeSeconds",
    QueryResolution = "isQueryResolved",
    ServiceLevelAgreement = "serviceLevelAgreementPercentage",
    InteractionCount = "interactionCount",
}

export const MINIMUM_LABEL = "minimum";
export const MAXIMUM_LABEL = "maximum";

import GridContext from "../grid-context";
import { IProps } from "./expanded-row.types";

/**
 * Tables can have an expandable/collapsible row that will show extra details about a specific row in the table.
 */
const ExpandedRow = <T extends {}>({ children }: IProps<T>) => (
    <GridContext.Consumer>
        {({ expandedRowKey, getRowKey, rows }) =>
            Array.isArray(rows) &&
            expandedRowKey !== null &&
            children(
                rows.find((row) => getRowKey(row) === expandedRowKey),
                Array.isArray(rows) ? rows : []
            )
        }
    </GridContext.Consumer>
);

export default ExpandedRow;

import { formatDate } from "@edgetier/utilities";
import { DetailsFieldTitle, DetailsFieldValue } from "~/details";
import SplitTimestamp from "~/split-timestamp";

import { IProps } from "./date-details-field.types";

/**
 * Render a date value from the booking details.
 * @param props.value Date value.
 * @returns           Formatted date.
 */
const DateDetailsField = ({ field }: IProps) => {
    const date = new Date(field.value);
    return (
        <tr className="date-details-field">
            <td>
                <DetailsFieldTitle>{field.title}</DetailsFieldTitle>
            </td>
            <td>
                <DetailsFieldValue
                    copyText={
                        typeof field.value === "string"
                            ? `${formatDate(date, { intlOptions: { dateStyle: "short" } })} ${formatDate(date, { intlOptions: { timeStyle: "short" } })}`
                            : undefined
                    }
                    isNull={typeof field.value !== "string"}
                    warning={field.warning}
                >
                    <SplitTimestamp date={date} />
                </DetailsFieldValue>
            </td>
        </tr>
    );
};

export default DateDetailsField;

import { EllipsisMenu } from "@edgetier/client-components";
import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FunctionComponent, memo } from "react";

import RemoveRole from "../remove-role";
import RoleForm from "../role-form";
import RoleActionItem from "./role-action-item";
import "./role-actions-menu.scss";
import { IProps } from "./role-actions-menu.types";

/**
 * A dropdown menu to edit or remove a role
 * @param props.role        Role selected to edit or remove
 * @param props.routeUrl    API roles route
 * @param props.axiosInstance
 */
const RoleActionsMenu: FunctionComponent<IProps> = ({ routeUrl, axiosInstance, role }) => (
    <div className="role-actions-menu">
        <EllipsisMenu placement="bottom-end" overflowContainer>
            <RoleActionItem heading="Edit role" menuItemLabel="Edit" icon={faPencil}>
                {({ closeModal }) => (
                    <RoleForm routeUrl={routeUrl} axiosInstance={axiosInstance} toggleAction={closeModal} role={role} />
                )}
            </RoleActionItem>
            <RoleActionItem heading="Delete role" menuItemLabel="Delete" icon={faTrash}>
                {({ closeModal }) => (
                    <RemoveRole
                        routeUrl={routeUrl}
                        axiosInstance={axiosInstance}
                        toggleAction={closeModal}
                        role={role}
                    />
                )}
            </RoleActionItem>
        </EllipsisMenu>
    </div>
);

export default memo(RoleActionsMenu);

import { toast, ToastOptions } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faInfoCircle, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import Axios from "axios";
import { getServerError } from "@edgetier/utilities";

interface IOptions extends ToastOptions {}

const DEFAULT_OPTIONS: IOptions = { position: "bottom-right", duration: 3000 };

/**
 * Custom element for rendering toast messages
 */
const renderMessage = (title: string, message?: string) => (
    <div>
        <strong>{title}</strong>
        {typeof message === "string" && <div>{message}</div>}
    </div>
);

/**
 * Show an error toast message. These are on display for longer than normal toasts.
 * @param title   Toast title.
 * @param message Optional toast message.
 * @param options React hot toast options.
 */
export const showErrorToast = (title: string, message?: string, options: ToastOptions = {}) => {
    return toast.error(renderMessage(title, message), {
        ...DEFAULT_OPTIONS,
        icon: <FontAwesomeIcon icon={faTimesCircle} size="xl" className="hot-toast-icon--error" />,
        ...options,
    });
};

/**
 * Show an information toast message.
 * @param title   Toast title.
 * @param message Optional toast message.
 * @param options React hot toast options.
 */
export const showInfoToast = (title: string, message?: string, options: ToastOptions = {}) => {
    return toast(renderMessage(title, message), {
        ...DEFAULT_OPTIONS,
        icon: <FontAwesomeIcon icon={faInfoCircle} size="xl" className="hot-toast-icon--info" />,
        ...options,
    });
};

/**
 * Show a success toast message.
 * @param title   Toast title.
 * @param message Optional toast message.
 * @param options React hot toast options.
 */
export const showSuccessToast = (title: string, message?: string, options: ToastOptions = {}) => {
    return toast.success(renderMessage(title, message), {
        ...DEFAULT_OPTIONS,
        icon: <FontAwesomeIcon icon={faCheckCircle} size="xl" className="hot-toast-icon--success" />,
        ...options,
    });
};

/**
 * Show a server error as a toast message.
 * @param title   Toast title.
 * @param serverError Optional toast message.
 */
export const showServerErrorToast = (title: string, serverError: any, options: ToastOptions = {}) => {
    if (!Axios.isCancel(serverError) && Axios.isAxiosError(serverError)) {
        showErrorToast(title, getServerError(serverError), options);
    }
};

export default { showErrorToast, showInfoToast, showSuccessToast, showServerErrorToast };

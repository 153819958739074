import { Button } from "@edgetier/components";
import { faChevronDoubleLeft, faChevronDoubleRight } from "@fortawesome/pro-solid-svg-icons";

import "./select-bulk-buttons.scss";
import { IProps } from "./select-bulk-buttons.types";

/**
 * Buttons to select all or no items.
 * @param props.notSelectedItemsCount Number of items not yet selected.
 * @param props.onSelectAll           Function to call when the "select all" button is pressed.
 * @param props.onSelectNone          Function to call when the "select none" button is pressed.
 * @param props.selectedItemsCount    Number of items already selected.
 * @param props.limitReached          Whether the limit of selected items has been reached.
 * @params props.hideBulkActionSelectAll Whether to hide the "select all" bulk action button.
 * @params props.hideBulkActionDeselectAll Whether to hide the "deselect all" bulk action button.
 * @returns                           Component with two buttons.
 */
const SelectBulkButtons = ({
    notSelectedItemsCount,
    isDisabled = false,
    onSelectAll,
    onSelectNone,
    selectedItemsCount,
    limitReached,
    hideBulkActionSelectAll,
    hideBulkActionDeselectAll,
}: IProps) => {
    return (
        <div className="select-bulk-buttons">
            {!hideBulkActionSelectAll && (
                <Button
                    aria-label="Select all"
                    disabled={notSelectedItemsCount === 0 || isDisabled || limitReached}
                    icon={faChevronDoubleRight}
                    isIconOnly={true}
                    onClick={onSelectAll}
                    outline={true}
                    title="Select all"
                />
            )}
            {!hideBulkActionDeselectAll && (
                <Button
                    aria-label="Select none"
                    disabled={selectedItemsCount === 0 || isDisabled}
                    icon={faChevronDoubleLeft}
                    isIconOnly={true}
                    onClick={onSelectNone}
                    outline={true}
                    styleName="negative"
                    title="Select none"
                />
            )}
        </div>
    );
};

export default SelectBulkButtons;

export enum FormFieldType {
    Integer = 1,
    Float = 2,
    Text = 3,
    Timestamp = 4,
    Textarea = 5,
    Email = 6,
    Select = 7,
    Star = 8,
    Radios = 9,
    NetPromoterScore = 10,
    Buttons = 11,
    Checkboxes = 12,
    Boolean = 13,
    SourcedSelect = 14,
    Date = 15,
    Time = 16,
    DateTime = 17,
}

export default FormFieldType;

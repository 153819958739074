import { FunctionComponent, memo } from "react";
import type { IProps } from "./navigation-item-notification-count.types";
import "./navigation-item-notification-count.scss";

/**
 * Display the number of notifications for a navigation item.
 * @param props.notificationCount Number of notifications.
 */
const NavigationItemNotificationCount: FunctionComponent<IProps> = ({ notificationCount }) => {
    return <div className="navigation-item-notification-count">{notificationCount}</div>;
};

export default memo(NavigationItemNotificationCount);

import { IInteractionSearchItem } from "components/interaction-search/interaction-search.types";

/**
 * Change the skill of interaction in an array.
 * @param interactions Array of interactions.
 * @param interactionDetailId Identifier of which interaction to update.
 * @param skillId Skill being updated.
 * @returns New array of interactions.
 */
export const updateSkill = (interactions: IInteractionSearchItem[], interactionDetailId: number, skillId: number) => {
    return interactions.map((interaction) =>
        interaction.interactionDetailId === interactionDetailId ? { ...interaction, skillId } : interaction
    );
};

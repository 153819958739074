import React, { memo } from "react";
import { Button } from "@edgetier/components";
import { useDispatch } from "react-redux";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import Axios from "axios";

import axios from "utilities/axios";
import { IShowServerError } from "shared/modal/server-error-modal";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { Modal, ModalContainer, ServerErrorModal } from "shared/modal";
import { hotToastOperations } from "utilities-for/toast";

import { IProps } from "./delete-button.types";
import "./delete-button.scss";
import { IThunkDispatch } from "redux/types";

/**
 * Several tables have a delete button that all do the same thing i.e. display a confirmation modal with a button that,
 * when clicked, shows the loading blocker, sends a request to the backend, and displays a successful toast message.
 */
const DeleteButton = ({ disabled, buttonLabel, children, onDelete, url, disableItem, ...buttonProps }: IProps) => {
    const dispatch = useDispatch<IThunkDispatch>();
    /**
     * Send a delete request to the given URL.
     * @param showServerError Display server errors in a modal.
     * @param hideModal       Close the confirmation modal.
     * @param data            Optional data argument which can be sent along with the delete request.
     */
    const deleteItem =
        (showServerError: IShowServerError, hideModal: VoidFunction) =>
        async (data: any = null) => {
            hideModal();
            try {
                dispatch(loadingBlockerOperations.showLoadingBlocker(true));

                const configuration = data ? { data } : undefined;
                if (!disableItem) {
                    await axios.delete(url, configuration);
                } else {
                    const valuesToSubmit = { enabled: false };

                    await axios.patch(url, valuesToSubmit, configuration);
                }

                hotToastOperations.showSuccessToast("Deleted", "The item has been deleted.");
                onDelete();
            } catch (serverError) {
                if (Axios.isAxiosError(serverError)) {
                    showServerError(serverError);
                }
            } finally {
                dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
            }
        };

    /**
     * Render a confirmation modal and children components.
     * @returns Modal and children.
     */
    return (
        <ServerErrorModal>
            {(showServerError) => (
                <ModalContainer>
                    {({ hideModal, showModal }) => (
                        <React.Fragment>
                            <Modal>{children(deleteItem(showServerError, hideModal))}</Modal>
                            <Button
                                icon={faTrashAlt}
                                styleName="negative"
                                onClick={showModal}
                                disabled={disabled}
                                {...buttonProps}
                            >
                                {buttonLabel}
                            </Button>
                        </React.Fragment>
                    )}
                </ModalContainer>
            )}
        </ServerErrorModal>
    );
};

export default memo(DeleteButton);

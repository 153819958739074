import ActionStatus from "constants/action-status";
import Qs from "qs";
import urlJoin from "url-join";
import { AbsolutePaths } from "constants/routes";
import { faInboxOut } from "@fortawesome/pro-solid-svg-icons";
import { MAXIMUM_INDICATOR, REFRESH_INTERVAL_MINUTES } from "./actions-link.constants";
import { NavigationLink } from "@edgetier/client-components";
import { NavigationLinkIndicator } from "@edgetier/client-components";
import { PageNames } from "types-for/routes";
import { useActionsCount } from "hooks-for/actions/use-actions-counts";
import { useLocation } from "react-router-dom";

/**
 * Link to the agent home page.
 * @returns A link to the page.
 */
const ActionsLink = () => {
    const parameters = Qs.stringify({ actionStatusId: ActionStatus.Failed }, { addQueryPrefix: true });
    const url = urlJoin(AbsolutePaths[PageNames.Actions](), parameters);

    // Don't show the indicators when the user is looking at the actions page.
    const { pathname } = useLocation();
    const isPageOpen = pathname === AbsolutePaths[PageNames.Actions]();

    // Poll for failed actions so an indicator can be shown.
    const { data } = useActionsCount(
        { actionStatusId: ActionStatus.Failed },
        { enabled: !isPageOpen, refetchInterval: REFRESH_INTERVAL_MINUTES * 60 * 60 }
    );

    return (
        <NavigationLink label="Outbox" icon={faInboxOut} page={url}>
            {!isPageOpen && typeof data?.count === "number" && data.count > 0 && (
                <NavigationLinkIndicator
                    children={data.count > MAXIMUM_INDICATOR ? `${MAXIMUM_INDICATOR}+` : data.count}
                />
            )}
        </NavigationLink>
    );
};

export default ActionsLink;

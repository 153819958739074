import { shouldRequestRetry } from "@edgetier/utilities";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Application from "components/application/application";
import { ROOT_ID } from "constants/index";
import "core-js/features/array/every";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/flat-map";
import "core-js/features/array/is-array";
import "core-js/features/array/some";
import "core-js/features/number/is-finite";
import "core-js/features/object/entries";
import "core-js/features/object/values";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { requestPermission } from "utilities/browser-notifications";

import "./index.scss";
import store from "./redux/store";
import { FeatureFlagsProvider, initialiseFeatureFlags } from "@edgetier/feature-flags";
import state from "feature-flags.json";
import { createRoot } from "react-dom/client";

if (process.env.REACT_APP_WORKBENCH === "true") {
    /**
     * Workbench will only be included in workbench mode
     * Uses React.lazy to ensure dependency only imported in this mode
     * The dead code removal step (WebPack) will remove all references to it in production
     */
    const WorkBench = React.lazy(() => import("workbench"));
    const root = createRoot(document.getElementById(ROOT_ID)!);
    root.render(
        <React.Suspense fallback="just a sec">
            <WorkBench />
        </React.Suspense>
    );
} else {
    requestPermission();
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                retry: shouldRequestRetry,
            },
        },
    });

    Sentry.init({
        dsn: "https://9459a46f93744acbb5c3a747ff52b54b@o1008192.ingest.sentry.io/5971779",
        environment: process.env.NODE_ENV,
        integrations: [new Integrations.BrowserTracing()],
        release: `agent-interface@${process.env.REACT_APP_VERSION}`,
        tracesSampleRate: 0.2,
        ignoreErrors: ["ChunkLoadError"],
    });

    (async () => {
        try {
            await initialiseFeatureFlags({
                state,
                ...(process.env.NODE_ENV === "development" ? { preventFetch: true } : {}),
            });
        } catch (_error) {
            // There's nothing we can do there. The user is most likely blocking the Flagsmith domain. The app will
            // still work regardless.
        }
    })();

    const root = createRoot(document.getElementById(ROOT_ID)!);
    root.render(
        <FeatureFlagsProvider initInProvider={false}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools
                        initialIsOpen={false}
                        position="top-left"
                        toggleButtonProps={{ className: "react-query-devtools__button" }}
                    />
                    <Application />
                </QueryClientProvider>
            </Provider>
        </FeatureFlagsProvider>
    );
}

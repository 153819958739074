import { useEffect } from "react";

export default function OAuthCallback() {
    useEffect(() => {
        const searchParameters = new URLSearchParams(window.location.search);
        const paramsObject: Record<string, string> = {};

        // Iterate over the URLSearchParams key-value pairs and populate the object
        searchParameters.forEach((value, key) => {
            paramsObject[key] = value;
        });

        window.opener?.postMessage(paramsObject, "*");
    }, []);
    return <div className="oauth-callback">Login response processed. You can now close this page.</div>;
}

import { IPaginated } from "@edgetier/types";

import { IBackendModel, IItem, ModelType, TimePeriodGroupBy } from "../common";

export interface IAnomaly extends IBackendModel {
    readonly anomalyId: string;
    readonly clientId: string;
    readonly displayName: string;
    readonly startDateTime: Date;
    readonly detectDateTime: Date;
    readonly endDateTime: Date | null;
    readonly modelId: string;
    readonly summary: string | null;
    readonly description: string | null;
}

export type IAnomaliesGetResponse = IPaginated<IAnomaly>;

export type IAnomaliesGetQueryParameters = {
    readonly lastAnomalyId?: string;
    readonly orderBy?: string;
    readonly endDateTime: Date;
    readonly limit?: number;
    readonly groupBy?: TimePeriodGroupBy;
    readonly modelType?: ModelType | ModelType[];
    readonly anomalyEventType?: AnomalyEventType;
    readonly startDateTime: Date;
    readonly timeZone?: string;
};

export interface IAnomalyTagVariance {
    readonly interactionsCount: number;
    readonly growthPercentage: number;
    readonly expectedInteractionCount: number;
    readonly tagId: string;
    readonly tag: string;
    readonly tagGroup: string;
}

export type IAnomalyTagVariancesGetResponse = IPaginated<IAnomalyTagVariance>;

export type IAnomalyTagVariancesGetQueryParameters = {
    readonly anomalyId: string;
    readonly startDateTime: Date;
    readonly endDateTime: Date;
    readonly referenceStartDateTime: Date;
    readonly referenceEndDateTime: Date;
};
export interface IAnomalyCount {
    readonly dateTime: Date;
    readonly count: number;
}

export type IAnomaliesCountGetResponse = IItem<IAnomalyCount>;

export enum AnomalyEventType {
    New = "new",
    Related = "related",
    UnrelatedQueries = "unrelated_queries",
    Repeats = "repeats",
}

interface IBaseAnomalyEvent<T> extends IBackendModel {
    readonly anomalyEventId: string;
    readonly clientId: string;
    readonly detectDateTime: Date;
    readonly anomalyId: string;
    readonly anomalyEventType: AnomalyEventType;
    readonly startDateTime: Date;
    readonly details: T;
}

export type IMetricAnomalyEvent = IBaseAnomalyEvent<{
    readonly metric: string;
    readonly metricId: string;
    readonly metricModelOutputId: string;
}>;

export type ITextAnomalyEvent = IBaseAnomalyEvent<{
    readonly phrase: string;
    readonly frequency: number;
    readonly expectedFrequency: number;
    readonly timeFrameHours: number;
    readonly probabilityValue: number;
}>;

export type IAnomalyEvent = IMetricAnomalyEvent | ITextAnomalyEvent;

export type IAnomalyEventsGetResponse = IItem<IAnomalyEvent>;

export interface IAnomalyTrendingPhrasesVariances {
    readonly phrase: string;
    readonly proportion: number;
    readonly interactionsCount: number;
    readonly proportionChange: number;
}

export type IAnomalyTrendingPhrasesVariancesResponse = IPaginated<IAnomalyTrendingPhrasesVariances>;

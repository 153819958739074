import { IProps } from "./message-time.types";
import { SplitTimestamp } from "@edgetier/client-components";
import "./message-time.scss";

/**
 * Display the time a message was sent.
 * @param props.timestamp The time of the message.
 * @returns               Formatted time string.
 */
const MessageTime = ({ date }: IProps) => (
    <div className="chat-messages__message__message-time">
        <SplitTimestamp date={date} />
    </div>
);

export default MessageTime;

import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Unchecked radio button icon.
 * @returns Icon.
 */
const UncheckedIcon = () => <FontAwesomeIcon aria-label="unchecked-icon" fixedWidth={true} icon={faSquare} size="lg" />;

export default UncheckedIcon;

import GridContext from "~/grid/grid-context";

import "./extra-rows.scss";

/**
 * Add extra rows to the end of the table so the height remains constant. This is to stop the height changing all over
 * the place as the user goes to another page in the table. No extra rows will be added if the table has no pages (i.e.
 * if all rows fit on the first page).
 * @returns Empty rows.
 */
const ExtraRows = () => (
    <GridContext.Consumer>
        {({ columnCount, constantRows, pageIndex, pageSize, rows }) => {
            // How many rows are on the current page.
            const pageLength =
                !Array.isArray(rows) || !Number.isFinite(pageSize)
                    ? 0
                    : rows.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize).length;

            return (
                constantRows &&
                Array.isArray(rows) &&
                Number.isFinite(pageSize) &&
                pageLength < pageSize &&
                rows.length > pageSize &&
                Array(pageSize - pageLength)
                    .fill(0)
                    .map((_, index) => (
                        <tr className="grid__extra-row" key={index}>
                            <td colSpan={columnCount}>
                                <div>&nbsp;</div>
                            </td>
                        </tr>
                    ))
            );
        }}
    </GridContext.Consumer>
);

export default ExtraRows;
